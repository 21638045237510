@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27,12);
}
.lps_sec {
  position: relative;
  &:nth-child(odd) {
    .bg-wh {
      background-color: #F2F9FF;
    }
  }
  &:nth-child(even) {
    background-color: #F2F9FF;
  }
  &.lps_sec_bg {
    min-height: rem(800);
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}
.layout_one_column .lps_wrap {
  max-width: rem(1560);
  width: 90%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  @include mq(sp) {
    flex-direction: column;
  }
}
.layout_one_column .lps_parts_wrap {
  width: rem(920);
  @include mq(sp) {
    width: 100%;
  }
}
.layout_one_column .inner {
  width: 100%;
}
.layout_one_column .lps_sec_ttl {
  flex: 1;
  margin-right: rem(64);
  @include mq(sp) {
    margin-right: 0;
  }
}
.layout_one_column .lps_sec_ttl > .inner {
  display: block;
}
.layout_one_column .lps_sec_ttl .ttl-01-sub {
  margin-top: rem(24);
  margin-left: 0;
  margin-bottom: 0;
}
.lps_sec:has(+.nami_sec) {
  padding-bottom: rem(200);
}
.nami_sec + .lps_sec {
  padding-top: rem(200);
  @include mq(sp) {
    padding-top: sprem(80);
  }
}
.lps_sec.nami_sec {
  padding-block: 0 !important;
  background-color: transparent !important;
  &::before {
    content: "";
    position: absolute;
    top: 276px;
    left: 0;
    width: 100%;
    height: calc(100% - 552px);
    background-color: var(--color-primary-default);
    @include mq(sp) {
      top: 20vh;
      height: calc(100% - 40vh);
    }
  }
  .sec_line_wave {
    pointer-events: none;
    position: relative;
    z-index: 1;
  }
  .sec_line_wave.top {
    margin-top: rem(-138);
  }
  .sec_line_wave.btm {
    margin-bottom: rem(-138);
  }
  .lps_wrap {
    padding-block: rem(80);
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}