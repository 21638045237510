@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: minmax(0,1fr) rem(264);
  gap: rem(80);
  @include mq(med) {
    grid-template-columns: repeat(1,minmax(0,1fr));
    gap: sprem(40);
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
}
.post--img {
  background-color: var(--color-wht);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    color: var(--color-primary-default);
  }
  &:hover svg {
    fill: var(--color-primary-default);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(2);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: calc-fz(13);
  background-color: var(--color-primary-default);
  color: var(--color-wht);
  padding-inline: 1em;
  border-radius: rem(4);
}
.cat_list a:hover {
  opacity: .7;
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.side--ttl {
  letter-spacing: .2em;
  line-height: 1;
  margin-bottom: rem(16);
}
.side--ttl span {
  opacity: .5;
  letter-spacing: .15em;
  display: block;
  line-height: 1;
  margin-top: rem(8);
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side {
  .post {
    display: grid;
    grid-template-columns: rem(64) minmax(0,1fr);
    gap: rem(16);
    align-items: center;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--date {
    margin-bottom: 0;
  }
  .post--ttl {
    line-height: var(--line-height-hdr);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  .archive_list {
    position: relative;
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
  .archive_list a {
    display: block;
    padding: .25em 1em;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-bg-02);
    }
    &:hover {
      background-color: var(--color-bg-02);
    }
  }
  .archive_list--label {
    margin-right: rem(8);
  }
  .archive_list--btn {
    cursor: pointer;
    border: none;
    outline: none;
    appearance: none;
    padding: .25em 4em .25em 2em;
    background-color: var(--color-wht);
    border: 1px solid #ccc;
    color: inherit;
    font-family: var(--font-primary);
    font-size: calc-fz(16);
    line-height: var(--line-height-default);
    font-weight: 500;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: .4em;
      right: .8em;
      margin: auto;
      width: .6em;
      height: .6em;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    &:hover {
      background-color: var(--color-bg-02);
    }
  }
  .active .archive_list--btn::after {
    bottom: -.2em;
    transform: rotate(225deg);
  }
  .archive_list--menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: var(--color-wht);
    border: 1px solid #ccc;
    visibility: hidden;
    margin-top: -1px;
    opacity: 0;
    transition: var(--transit-default);
  }
  .active .archive_list--menu {
    visibility: visible;
    opacity: 1;
  }
}

/* interview
**************************************** */
.posts-interview .post {
  @include mq(sp) {
    margin-bottom: sprem(64);
  }
}
.posts-interview .post--img {
  position: relative;
  height: rem(374);
  display: flex;
  align-items: flex-end;
  img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 91%;
    object-fit: contain;
  }
}
.posts-interview .post--catch {
  margin-bottom: rem(16);
  span {
    display: inline;
    font-size: rem(24);
    color: #fff;
    background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
    box-decoration-break: clone;
    padding: rem(6) rem(16);
    line-height: 2.2;
    font-feature-settings: "palt";
  }
}
.posts-interview .name {
  margin-right: rem(16);
  font-size: rem(40);
  line-height: 1;
}
.posts-interview .data {
  line-height: 1.5;
}

/* requirements
**************************************** */
.posts-requirements .post {
  @include mq(sp) {
    margin-bottom: sprem(40);
  }
}

/* blog
**************************************** */
.posts-blog {
  .post {
    display: grid;
    gap: rem(40);
    padding-block: rem(48);
    border-bottom: 1px solid #e5e5e5;
    @include mq(sp, min, ps) {
      grid-template-columns: rem(280) minmax(0,1fr);
      align-items: center;
    }
    @include mq(sp) {
      gap: sprem(24);
      padding-block: sprem(32);
    }
    &:first-child {
      border-top: 1px solid #e5e5e5;
    }
  }
  .post--txtarea {
    flex: 1;
    word-break: break-word;
  }
  .post--ttl {
    font-size: calc-fz(18);
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--color-wht);
}

/* --- posts-news --- */
.posts-news {
  .post {
    padding-bottom: rem(24);
    padding-top: rem(24);
    margin-bottom: 0;
    border-bottom: 1px solid #dedede;
    &:first-child {
      border-top: 1px solid #dedede;
    }
    @include mq(sp) {
      display: block;
    }
  }
  .post--info {
    @include mq(sp, min, ps) {
      margin-left: rem(32);
      margin-right: rem(32);
    }
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--txtarea {
    flex: 1;
  }
  .post--ttl {
    font-size: calc-fz(18);
  }
  .post--info.flex .post--date + .cat_list {
    margin-left: rem(16);
  }
}