@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(14) rem(40) rem(14) rem(32);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(280), 100%);
  color: var(--color-primary-default);
  background-color: var(--color-wht);
  border: 1px solid;
  @include mq(sp) {
    min-width: 100%;
  }
  &:hover {
    background-color: var(--color-primary-default);
    color: var(--color-wht);
  }
  &:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(36);
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    rotate: 45deg;
    transition: var(--transit-default);
  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}
.clr-wh .btn a {
  &:hover {
    background-color: #DAEEFF;
    color: var(--color-primary-default);
  }
  svg {
    color: var(--color-primary-default);
    fill: var(--color-primary-default);
  }
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--color-primary-default);
  background-color: var(--color-wht);
  &:hover {
    color: var(--color-wht);
    background-color: var(--color-primary-default);
  }
  svg {
    fill: var(--color-primary-default);
  }
  &:hover svg {
    fill: var(--color-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--color-secondary-default);
  &:hover {
    background-color: var(--color-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--color-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--color-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}
