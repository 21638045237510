@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes txt_slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
.txt_slide_deco {
  width: 100%;
  height: rem(200);
  overflow: hidden;
  display: flex;
  pointer-events: none;
  @include mq(sp) {
    height: rem(64);
  }
}
.txt_slide_deco--line {
  display: flex;
  word-break: keep-all;
  white-space: pre;
  animation: txt_slide 100s infinite linear 0.5s both;
  font-size: rem(200);
  line-height: 1.19;
  @include mq(sp) {
    animation: txt_slide 72s infinite linear 0.5s both;
    font-size: rem(64);
  }
}

@keyframes scale_anime {
  0% {
    scale: 1;
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    scale: 1.5;
  }
}

@keyframes bgroop {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4469px 0;
  }
}

@keyframes sliderNav {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}