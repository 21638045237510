@use '../abstracts' as *;

/* Wysiwyg editor
 **************************************** */
.mce-content-body {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  } 
}
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul,
.mce-content-body ol {
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  padding-left: perc(20,16,em);
  padding-top: .5em;
  &::marker {
    color: var(--color-primary-default);
    font-weight: 600;
    letter-spacing: perc(3.2,16,em);
  }
}
.mce-content-body ul li {
  list-style: none;
  &::before {
    content: '';
    position: absolute;
    top: perc(18,16,em);
    left: 0;
    width: perc(10,16,em);
    aspect-ratio: 1/1;
    border: 1px solid var(--color-primary-default);
    background-color: var(--color-primary-default);
  }
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--color-wht);
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body ol {
  list-style-type: decimal-leading-zero;
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body .wp-caption {
  width: fit-content;
  max-width: 100%;
}
.mce-content-body hr {
  margin-top: rem(32);
  margin-bottom: rem(32);
  border-width: 2px;
  border-color: var(--color-primary-default);
}
.mce-content-body a img {
  transition: var(--transit-default);
}
.mce-content-body a:hover img {
  opacity: .8;
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  @include auto-margin;
  margin-bottom: 1em;
}
.mce-content-body img:not(.pc-none):not(.sp-none).aligncenter {
  display: block;
}
#tinymce img.pc-none,
#tinymce img.sp-none {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: rem(16) rem(40);
  border-radius: rem(3);
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--color-bg-02);
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1em;
    height: 1em;
    opacity: .25;
  }
  &::before {
    background-image: url('../images/common/icon-quote_left.svg');
    top: rem(14);
    left: rem(12);
  }
  &::after {
    background-image: url('../images/common/icon-quote_right.svg');
    bottom: rem(14);
    right: rem(12);
  }
}
.mce-content-body blockquote p { margin-bottom: 0; }
.mce-content-body a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.mce-content-body table {
  border: none;
  height: auto !important;
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  vertical-align: top;
  color: var(--color-text-primary);
  & * {
    color: var(--color-text-primary);
  }
}
.mce-content-body th {
  min-width: rem(120);
  text-align: left;
}
.mce-content-body sup {
  font-size: calc-fz(12);
  color: #555;
}
.bold {
  font-weight: bold;
  color: var(--color-primary-default);
}
.clr-wh .bold {
  color: #fff;
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}

@include mq(sp) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: rem(16);
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: .5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }

  /* table scroll */
  .table-sp-scroll {
    width: sprem(700);
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table { margin: 0; }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: .25;
    font-size: calc-fz(14);
  }
}

/* AddQuicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-jp);
  &:not(:first-child) {
    margin-top: 2em;
    @include mq(sp) {
      margin-top: 1.5em;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1em;
    @include mq(sp) {
      margin-bottom: .75em;
    }
  }
}
.editor-ttl {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.editor-ttl2 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.editor-ttl3 {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(16);
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: calc-fz(14);
  opacity: .6;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: '※';
  }
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em .5em;
  @include mq(sp) {
    padding: 0;
    border-bottom: 1px solid;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.table1 th {
  color: var(--color-primary-default);
  text-align: left;
  @include mq(sp) {
    padding-top: 1em;
  }
  &::before {
    left: 0;
    border-bottom: 2px solid;
    @include mq(sp) {
      border-bottom: none;
    }
  }
}
.table1 td {
  @include mq(sp) {
    padding: .2em 0 1em;
    border-bottom: 1px solid #D3D3D3;
  }
  &::before {
    right: 0;
    border-bottom: 1px solid #D3D3D3;
  }
}
.table1.table-sp-block  td + td {
  padding-top: 1em;
}
.table2 {
  background-color: var(--color-wht);
  th,
  td {
    border-color: #DAEEFF;
  }
}
.table2 tr {
  &:nth-child(odd) th {
    background-color: #DAEEFF;
  }
  &:nth-child(even) th {
    background-color: #F2F9FF;
  }
}

/* ボックス */
.box {
  padding: rem(24) rem(32);
  background-color: #F2F9FF;
  border-radius: rem(8);
  @include mq(sp) {
    padding: rem(24);
  }
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}
.box *:not([class*="clr-"]):not([class*="bold"]):not([class*="editor-ttl"]) {
  color: var(--color-text-primary);
}
.lps_sec:nth-child(even),
.lps_sec[style*="background-color"] {
  *:not(.bg-wh) .box {
    background-color: var(--color-wht);
  }
  *.bg-wh .box {
    background-color: #F2F9FF;
  }
}

.bg-wh * {
  color: #000;
}

/* リスト */
ul.check--list li {
  padding-left: rem(32);
  &::before,
  &::after {
    content: '';
    position: absolute;
  }
  &::before {
    top: perc(10,16,em);
    left: 0;
    width: perc(24,16,em);
    aspect-ratio: 1/1;
    background-color: var(--color-primary-default);
    border-radius: 50%;
  }
  &::after {
    top: perc(17,16,em);
    left: rem(7);
    width: perc(10,16,em);
    height: perc(7,16,em);
    border-left: 2px solid var(--color-wht);
    border-bottom: 2px solid var(--color-wht);
    transform: rotate(-45deg);
  }
}

@include mq(sp) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
}

.txt-decoration1 {
  display: flex;
  align-items: center;
  @include mq(sp) {
    flex-wrap: wrap;
  }
  .icon {
    width: rem(52);
    height: rem(16);
    margin-right: rem(16);
    background-image: url(../images/common/wave.svg);
    background-size: cover;
  }
  .en {
    margin-right: rem(16);
    font-size: rem(24);
    color: var(--color-primary-default);
  }
  .jp {
    font-size: rem(18);
  }
}
.txt-decoration2 {
  display: flex;
  align-items: center;
  @include mq(sp) {
    display: table;
    width: 100%;
  }
  .num {
    margin-right: rem(14);
    font-size: rem(60);
    color: var(--color-primary-default);
    line-height: 1;
    @include mq(sp) {
      float: left;
      font-size: 3rem;
    }
  }
}
.txt-decoration2 .txt {
  .en {
    display: block;
    font-size: rem(32);
    line-height: 1.2;
    @include mq(sp) {
      font-size: 3.15rem;
      transform: translateY(-6px);
    }
  }
  .jp {
    font-size: rem(18);
    @include mq(sp) {
      display: inline-block;
      font-size: sprem(15);
      line-height: 1.5;
    }
  }
}
.txt-decoration3 {
  span {
    display: inline-block;
    padding: 4px rem(24);
    background-color: var(--color-primary-default);
    color: var(--color-wht);
    font-size: rem(18);
  }
}
.txt-decoration4 {
  display: flex;
  align-items: flex-end;
  @include mq(sp) {
    align-items: center;
  }
  .deco {
    margin-right: rem(16);
    @include mq(sp) {
      position: relative;
      margin-right: sprem(10);
      height: 48px;
      aspect-ratio: 1 / 1;
    }
    img {
      margin: 0 !important;
      @include mq(sp) {
        width: 100% !important;
        height: 100%;
        position: absolute;
        object-fit: cover;
      }
    }
  }
  .txt {
    padding-bottom: rem(6);
    border-bottom: 1px solid;
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(18);
      line-height: 1.65;
    }
  }
}
.clr-wh .txt-decoration4 .deco {
  filter: brightness(0) invert(1);
}
.txt-decoration5 {
  display: table;
  .deco {
    float: left;
    margin-right: rem(16);
    img {
      margin: 0 !important;
    }
  }
  .txt {
    border-bottom: 1px solid;
    padding-bottom: 3px;
    line-height: 1.95;
    font-size: rem(24);
  }
}

/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
}
.mce-content-body.wp-editor {
  font-size: 16px;
  padding: rem(24) !important;
}

@include mq(sp) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}

.number_ttl {
  margin-bottom: 2.5em;
}
.number_ttl_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .num {
    position: relative;
    z-index: 1;
    display: inline-block;
    letter-spacing: 0.04em;
    font-size: rem(48);
    color: var(--color-primary-default);
    text-shadow: 2px 2px 0 #FFF, -2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px -2px 0 #FFF, 0px 2px 0 #FFF,  0 -2px 0 #FFF, -2px 0 0 #FFF, 2px 0 0 #FFF;
    line-height: 1;
    transform: translateY(5px);
    margin-left: rem(10);
    margin-right: auto;
    @include mq(1320) {
      margin-left: 0;
    }
  }
  .txt {
    margin-right: rem(42);
    line-height: 1;
    font-size: rem(24);
    font-weight: 400;
    @include mq(1320) {
      margin-right: 0;
    }
  }
  .fukidashi {
    position: relative;
    display: inline-block;
    min-width: 230px;
    margin-inline: auto;
    padding: 8px rem(16);
    background-color: var(--color-primary-default);
    color: #fff;
    font-size: rem(28);
    text-align: center;
    @include mq(1320) {
      min-width: 100%;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: rem(-20);
      margin: auto;
      @include sprect(20);
      background-color: var(--color-primary-default);
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }
}
