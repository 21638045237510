@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: rem(32);
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: rem(24);
    background-color: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: rem(10);
  }
  div#ez-toc-container p.ez-toc-title {
    color: var(--color-text-primary);
    font-weight: bold;
    font-size: calc-fz(20);
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: calc-fz(17);
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: rem(8);
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: rem(16);
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: rem(16);
    &::before {
      content: "";
      position: absolute;
      top: rem(10);
      left: 0;
      width: rem(8);
      height: 0;
      border-bottom: 1px solid;
    }
  }

/* requirements
**************************************** */
.requirements--info .ttl-03 {
  margin-right: rem(40);
  @include mq(sp) {
    margin-right: 0;
    margin-bottom: sprem(16);
  }
}
.requirements--info .table1 {
  th {
    width: 24%;
    @include mq(sp) {
      width: 100%;
    }
  }
  td {
    padding-inline: rem(24);
    @include mq(sp) {
      padding-inline: 0;
    }
  }
}
.requirements--info .editor-ttl3 {
  color: var(--color-primary-default);
}
.salary_ttl {
  font-size: rem(20);
  color: var(--color-primary-default);
}

/* interview
**************************************** */
.profile_bloc {
  position: relative;
  z-index: 10;
  margin-top: rem(-340);
  margin-bottom: rem(160);
  @include mq(sp) {
    margin-top: sprem(-320);
    margin-bottom: sprem(56);
  }
}
.profile_bloc .inner {
  border-bottom: 1px solid;
}
.profile_bloc .txtarea {
  margin-left: rem(70);
  padding-bottom: rem(40);
  @include mq(sp) {
    margin-left: 0;
    padding-bottom: sprem(24);
  }
}
.profile_bloc .catch {
  margin-bottom: rem(120);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  span {
    display: inline;
    font-size: rem(40);
    color: #fff;
    background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
    box-decoration-break: clone;
    padding: rem(8) rem(16);
    line-height: 2.2;
    @include mq(sp) {
      font-size: 5.5vw;
    }
  }
}
.profile_bloc .name {
  margin-right: rem(40);
  font-size: rem(60);
  line-height: 1;
}
.profile_bloc .data {
  font-size: rem(18);
  line-height: 1.8;
}

.interviews_wrap {
  padding-bottom: rem(80);
}
.interviews_nav {
  position: sticky;
  top: rem(120);
}
.interviews_nav li {
  display: none;
  &.is-active {
    display: block;
  }
}
.interviews_nav li,
.schedule_bloc--ttl,
.interviews_bloc--ttl,
.other_interview--ttl {
  @include mq(sp) {
    text-align: center;
  }
  .en {
    display: block;
    margin-bottom: rem(40);
    font-size: rem(120);
    color: var(--color-primary-default);
    line-height: 1.1;
    @include mq(sp) {
      margin-bottom: sprem(16);
      font-size: 10vw;
      br {
        display: none;
      }
    }
  }
  .jp {
    display: inline-block;
    padding: rem(8) 0;
    border-top: 1px solid var(--color-primary-default);
    border-bottom: 1px solid var(--color-primary-default);
    font-size: rem(40);
    @include mq(sp) {
      padding: sprem(8) 0;
      font-size: sprem(20);
    }
  }
}
.interviews_bloc--ttl {
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.schedule_bloc {
  @include mq(sp) {
    padding-top: 0;
  }
}
.interviews_bloc--cont {
  max-width: rem(920);
}
.interviews_bloc--qa dt {
  padding-bottom: rem(32);
  font-size: rem(40);
  color: var(--color-primary-default);
  @include mq(sp) {
    padding-bottom: sprem(8);
    font-size: sprem(22);
  }
  &::before {
    content: "Q.";
  }
}
.interviews_bloc--qa dd {
  line-height: 2;
  border-bottom: 1px solid;
  padding-bottom: rem(48);
  margin-bottom: rem(40);
}
.interviews_bloc--img {
  margin-top: rem(120);
  margin-right: -18.5%;
  @include mq(sp) {
    margin-top: sprem(40);
    margin-left: -8%;
    margin-right: -8%;
  }
}
.interviews_bloc--cont:not(:last-child) .interviews_bloc--img {
  margin-bottom: rem(90);
}

.schedule_bloc--timeline {
  flex: 1;
  margin-top: rem(124);
  margin-left: rem(90);
  column-count: 2;
  @include mq(sp) {
    margin-top: sprem(32);
    margin-left: 0;
    column-count: 1;
  }
}
.timeline_list li {
  position: relative;
  break-inside: avoid;
  margin-left: rem(40);
  padding-bottom: rem(16);
  font-size: rem(24);
  @include mq(sp) {
    margin-left: 0;
    font-size: sprem(16);
  }
  &::before {
    content: "";
    position: absolute;
    top: rem(42);
    left: rem(10);
    height: calc(100% - rem(42));
    border-left: 3px dashed var(--color-primary-default);
  }
}
.timeline_list .time {
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: inline-block;
    width: rem(52);
    height: rem(16);
    margin-right: rem(16);
    background-size: cover;
    background-image: url(../images/common/wave.svg);
  }
}
.timeline_list .txt {
  padding-left: rem(68);
  line-height: 1.45;
}

.life_bloc .sec_line_wave.top {
  margin-top: 0;
}
.life_bloc .sec_line_wave.btm {
  margin-bottom: 0;
}
.life_bloc--wrap {
  padding: rem(80) 0;
  background-color: var(--color-primary-default);
}
.life_bloc--ttl {
  position: relative;
  @include mq(sp) {
    margin-bottom: sprem(56);
  }
  .en {
    font-size: rem(240);
    color: #004987;
    line-height: 1;
    opacity: .5;
    mix-blend-mode: multiply;
    letter-spacing: rem(4.8);
    @include mq(sp) {
      font-size: 12vw;
    }
  }
  .jp {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: inline-block;
    padding: rem(16) rem(60);
    border-top: 1px solid;
    border-bottom: 1px solid;
    color: #fff;
    font-size: rem(40);
    @include mq(sp) {
      padding: sprem(16) sprem(24);
      font-size: 8vw;
      width: 100%;
      text-align: center;
    }
  }
}
.life_bloc--img {
  max-width: rem(865);
  padding-left: rem(32);
  @include mq(sp) {
    padding-left: 0;
  }
  img {
    transform: rotate(-4deg);
    border: 16px solid #fff;
    @include mq(sp) {
      border: 8px solid #fff;
    }
  }
}
.life_bloc--message {
  flex: 1;
  margin-left: rem(140);
  @include mq(sp) {
    margin-left: 0;
  }
  span {
    display: inline;
    font-size: rem(32);
    color: var(--color-primary-default);
    background: linear-gradient(transparent 0%, #fff 0%);
    box-decoration-break: clone;
    padding: rem(8) rem(16);
    line-height: 2.5;
    @include mq(sp) {
      font-size: 5vw;
    }
  }
}

.other_interview--ttl {
  text-align: center;
  margin-bottom: rem(56);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
.other_interview--scroll {
  @include mq(sp) {
    overflow-x: scroll;
    .posts-interview {
      width: 1168px;
    }
  }
}
.posts-interview .post {
  @include mq(sp) {
    margin-bottom: 0;
  }
}
.posts-interview .post--catch span {
  @include mq(sp) {
    word-break: keep-all;
  }
}

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: rem(5);
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: rem(4) rem(6);
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: rem(4);
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* share
**************************************** */
  .share--list a {
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: calc-fz(12);
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .share--list li {
    &.x a { color: #000; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .share--list img {
    margin-right: rem(10);
  }
  .share--list a:hover {
    opacity: .5;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calc-clamp(4, $wid-var);
    white-space: nowrap;
  }
  .wp-pagenavi > * {
    @include center-flex;
  }
  .wp-pagenavi .pages {
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: 0 1em;
    height: calc-clamp(40, $wid-var);
    background-color: var(--color-primary-default);
    color: var(--color-wht);
    @include mq(sp) {
      height: sprem(36);
    }
    &:hover {
      background-color: var(--color-primary-dark);
    }
  }
  .archive .wp-pagenavi > *:not(.pages) {
    width: calc-clamp(40, $wid-var);
    aspect-ratio: 1/1;
    @include mq(sp) {
      width: sprem(36);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    aspect-ratio: 1/1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: .5em;
    aspect-ratio: 1/1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: rem(-6);
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: rem(-6);
  }
