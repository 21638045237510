@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  position: sticky;
  z-index: 999;
  top: 35%;
  right: 0;
  float: right;
  transform: translateX(100%);
  transition: all .4s ease-out;
  margin-right: -100%;
  @include mq(sp) {
    position: fixed;
    margin-right: 0;
  }
}
.anchor_link--list_wrap.active {
  transform: translateX(0);
}
.anchor_link--list {
  background-color: #fff;
  border-radius: 0 0 0 8px;
  box-shadow: 0 0 24px 0 rgba(30, 42, 77, 0.15);
  padding: rem(40);
  @include mq(sp) {
    padding: sprem(24);
  }
}
.anchor_link--list li {
  &:not(:last-child) {
    margin-bottom: rem(8);
  }
}
.anchor_link--list a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: rem(16);
  color: #404044;
  &.current {
    color: #F94B2E;
  }
  &:hover {
    opacity: .7;
  }
  &::before {
    content: "";
    display: inline-block;
    width: rem(52);
    height: rem(16);
    margin-right: rem(12);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(../images/common/wave.svg);
    @include mq(sp) {
      display: none;
    }
  }
}
.anchor_link--ttl {
  position: absolute;
  top: 0;
  left: -62px;
  width: 62px;
  height: rem(163);
  padding-block: 24px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px 0px rgba(30, 42, 77, 0.15);
  background-color: var(--color-primary-default);
  color: #fff;
  font-weight: 300;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: rem(24);
  letter-spacing: 1px;
  @include mq(sp) {
    left: -46px;
    width: 46px;
    height: auto;
    font-size: sprem(20);
  }
}
.anchor_link--ttl .icon {
  position: relative;
  display: inline-block;
  width: rem(14);
  height: rem(14);
  transform: translateX(1px);
  margin-bottom: 8px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: all .4s ease-out;
  }
  &::after {
    transform: rotate(90deg);
  }
}
.anchor_link--ttl.active .icon {
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
}
.anchor_link--list_wrap + .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(30, 42, 77, 0.4);
  opacity: 0;
  transition: all .4s ease-out;
  z-index: 10;
}
.anchor_link--list_wrap.active + .overlay {
  opacity: 1;
}