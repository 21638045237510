@use '../abstracts' as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* related_page
********************************************** */
.section-related_page > .inner {
  border-bottom: 1px solid;
  padding-bottom: rem(32);
}
.related_page--ttl {
  font-size: rem(120);
  line-height: 1.1;
  color: var(--color-primary-default);
  @include mq(sp) {
    margin-bottom: sprem(8);
    font-size: sprem(32);
  }
}
.related_page--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 1;
  @include mq(sp) {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.related_page--list li {
  margin-left: rem(60);
  @include mq(sp) {
    margin-top: sprem(8);
    margin-left: 0;
  }
}
.related_page--list a {
  position: relative;
  display: inline-block;
  padding-left: rem(48);
  font-size: rem(18);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  &::before {
    left: 0;
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    background-color: var(--color-primary-default);
  }
  &::after {
    top: rem(-4);
    left: rem(11);
    width: rem(8);
    height: rem(14);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
}


.page-company .mce-content-body span[style="font-size: 400%;"] {
  @include mq(sp) {
    font-size: 250% !important;
  }
}
.page-company .mce-content-body span[style="font-size: 300%;"] {
  @include mq(sp) {
    font-size: 200% !important;
  }
}