@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding-top: rem(100);
  @include mq(sp) {
    padding-top: sprem(40);
  }
}
.page_ttl--bg {
  width: 100%;
  height: rem(699);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  @include mq(sp) {
    height: sprem(200);
    background-size: cover;
  }
}
.single-interview .page_ttl--bg {
  @include mq(sp) {
    height: sprem(400);
    background-position: center right 34%;
  }
}
.page_ttl > .inner {
  display: flex;
  align-items: flex-end;
  @include mq(sp) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.page_ttl-jp {
  margin-left: rem(60);
  margin-bottom: rem(16);
  padding-inline: rem(16);
  padding-bottom: rem(8);
  border-bottom: 1px solid #0052B2;
  font-size: rem(18);
  @include mq(sp) {
    margin-left: 0;
    margin-bottom: 0;
    padding-inline: 0;
    font-size: sprem(16);
  }
  &::before {
    content: "";
    display: inline-block;
    width: rem(52);
    height: rem(16);
    margin-right: rem(16);
    background-size: cover;
    background-image: url(../images/common/wave.svg);
  }
}
.page_ttl-en {
  display: block;
  text-transform: capitalize;
  font-size: rem(152);
  line-height: 1.11;
  letter-spacing: 0.05em;
  color: var(--color-primary-default);
  @include mq(sp) {
    margin-bottom: sprem(16);
    font-size: 12.5vw;
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  .breadcrumbs span span:not(:last-child)::after {
    background-color: #CCC;
  }
}

.page_ttl .breadcrumbs {
  margin-left: auto;
  margin-right: -4%;
  margin-bottom: rem(24);
  @include mq(sp) {
    margin-top: sprem(16);
    margin-left: 0;
    margin-right: 0;
  }
}

/* --- ttl --- */
.lps_sec_ttl {
  position: relative;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48, 16, em);
  }
  &:not(.txt-ctr) > .inner {
    display: flex;
    align-items: flex-end;
    @include mq(sp) {
      flex-direction: column;
      align-items: center;
    }
  }
}
// センタリング
.lps_sec_ttl.txt-ctr .ttl-01 + .ttl-01-sub {
  margin-top: rem(24);
}
// センタリングしない
.lps_sec_ttl:not(.txt-ctr) .ttl-01-sub {
  margin-left: rem(60);
  margin-bottom: 1rem;
  @include mq(sp) {
    width: 100%;
    margin-top: sprem(16);
    margin-left: 0;
  }
}
.ttl-01 {
  font-size: rem(48);
  color: var(--color-primary-default);
  @include mq(sp) {
    font-size: sprem(28);
    text-align: center;
  }
  &.clr-wh + .ttl-01-sub {
    color: var(--color-wht);
  }
  &.font-en span {
    display: block;
    font-size: rem(120);
    line-height: 1.1;
    @include mq(sp) {
      margin-top: sprem(8);
      font-size: 15vw;
    }
  }
  &.font-jp span {
    display: block;
  }
}
.ttl-01-sub {
  display: block;
  color: var(--color-primary-default);
  @include mq(sp) {
    text-align: center;
  }
  span {
    display: inline-block;
    padding-block: 8px;
    border-top: 1px solid var(--color-primary-default);
    border-bottom: 1px solid var(--color-primary-default);
    font-size: rem(32);
    color: #000;
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}
.clr-wh .ttl-01-sub {
  span {
    border-top-color: #fff;
    border-bottom-color: #fff;
    color: #fff;
  }
}
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
}
.ttl-02 {
  position: relative;
  padding-block: rem(40);
  border-top: 1px solid;
  border-bottom: 1px solid;
  font-size: rem(32);
  line-height: 1.5;
  letter-spacing: .03em;
  @include mq(sp) {
    padding-block: sprem(16);
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 2em;
  }
  @include mq(sp) {
    font-size: sprem(24);
  }
  &::first-letter {
    color: var(--color-primary-default);
  }
}
.clr-wh .ttl-02 {
  &::first-letter {
    color: #fff;
  }
}
.ttl-03 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.ttl-04 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
