@use '../abstracts' as *;

/* sitemap
*************************************************** */
.sitemap--menu {
  @include mq(sp, min, ps) {
    column-count: 2;
    gap: 5%;
  }
}
.sitemap--menu > li {
  break-inside: avoid;
}
.sitemap--menu > li > a {
  font-size: calc-fz(18);
  border-bottom: 1px solid #D3D3D3;
  padding-top: .5em;
  padding-bottom: .5em;
  margin-bottom: .5em;
}
.sitemap--menu a,
.sitemap--menu span {
  display: block;
}
.sitemap--menu a {
  position: relative;
  &:hover {
    color: var(--color-primary-default);
  }
}
.sitemap_sub_nav--blc {
  padding-left: 1em;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.sitemap--tax {
  border-bottom: 1px solid #eee;
  padding-top: .5em;
  padding-bottom: .5em;
  margin-bottom: .5em;
}
.sitemap_sub_nav a {
  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: 1.25em;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    @include rect(6);
    color: var(--color-primary-default);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
  }
}