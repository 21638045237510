@use "../abstracts" as *;

.sec_line_wave {
  position: relative;
  height: 276px;
  overflow: hidden;
  @include mq(sp) {
    height: 20vh;
  }
  &.top {
    margin-top: -200px;
    @include mq(sp) {
      margin-top: -5vh;
    }
  }
  &.btm {
    margin-bottom: -200px;
    @include mq(sp) {
      margin-bottom: -10vh;
    }
  }
}
.sec_line_wave svg {
  position: absolute;
  top: 0;
  left: 0;
}
.sec_line_wave svg {
  &.wave1,
  &.wave3 {
    transform: scale(-1, 1);
  }
}
.sec_line_wave.top svg {
  &.wave2 {
    top: 10px;
  }
  &.wave3 {
    top: 30px;
  }
  &.wave4 {
    top: 60px;
  }
  &.wave5 {
    top: 100px;
  }
}
.sec_line_wave.btm svg {
  &.wave2 {
    bottom: 10px;
  }
  &.wave3 {
    bottom: 30px;
  }
  &.wave4 {
    bottom: 60px;
  }
  &.wave5 {
    bottom: 100px;
  }
}
.js-text span {
  display: inline-block;
}

/* hero
********************************************** */
.hero {
  position: relative;
  height: rem(1000);
}
.hero .txt_slide_deco {
  position: absolute;
  left: 0;
  bottom: rem(-126);
  width: 100%;
  mix-blend-mode: multiply;
  color: var(--color-primary-default);
  @include mq(sp) {
    bottom: -6%;
  }
}
.hero_slide {
  overflow: hidden;
}
.hero_slide-sp {
  display: none !important;
}
.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}
.hero--catch {
  top: 15%;
  left: 3%;
  @include mq(sp) {
    top: 9%;
    left: 2%;
    width: 88%;
  }
}
.home_news {
  position: absolute;
  right: 0;
  bottom: 8%;
  max-width: rem(660);
  width: 92%;
  border-bottom: 1px solid;
  padding: 0 rem(80) rem(16) rem(16);
  @include mq(sp) {
    background-color: rgba(255,255,255,.4);
    padding: sprem(8) sprem(16) sprem(8) sprem(16);
  }
}
.home_news--list .post {
  display: flex !important;
}
.home_news--list .post--txtarea {
  position: relative;
  flex: 1;
  margin-left: rem(20);
  padding-left: rem(20);
  overflow: hidden;
  &::before {
    content: "/";
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1.3;
  }
}
.home_news--list .post--ttl {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@include mq(sp) {
  .hero {
    height: 100vh;
  }
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
}

/* home_deco_area
********************************************** */
.home_deco_area {
  height: rem(270);
  padding-top: rem(160);
  background-position: bottom left;
  background-repeat: repeat-x;
  background-image: url(../images/home/txt_deco.png);
  animation: bgroop 100s linear infinite;
  @include mq(sp) {
    height: sprem(100);
    padding-top: sprem(50);
    background-size: auto 100%;
  }
  .font-antonio {
    line-height: 1.6;
  }
}

/* home_story
********************************************** */
// .home_story {
//   overflow: hidden;
// }
.home_story--catch {
  position: sticky;
  top: 0;
  padding-top: rem(180);
  padding-bottom: rem(206);
  @include mq(sp) {
    padding-top: sprem(80);
    padding-bottom: sprem(40);
  }
}
#story_catch {
  width: rem(1000);
  height: rem(476);
  margin: 0 auto;
  @include mq(sp) {
    width: 100%;
    height: sprem(210);
  }
}
// .st0 {
//   stroke: #0052B2;
//   fill: transparent;
//   stroke-dasharray: 2100;
//   stroke-dashoffset: 2100;
//   stroke-width: 1;
// }
// .active .st0 {
//   -webkit-animation: catch-anime 3s ease-out 0s forwards;
//   animation: catch-anime 3s ease-out 0s forwards;
// }
@keyframes catch-anime {
  0% {
    stroke-dashoffset: 2100;
    fill: transparent;
  }
  40% {
    stroke-dashoffset: 2100;
    fill: transparent;
  }
  70% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #0052B2;
  }
}
.home_story--catch .ttl .line {
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: all .6s ease-out;
  }
  &.line2 {
    margin-top: rem(-24);
    padding-left: rem(24);
  }
}
.home_story--catch .ttl .line.active {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: #fff;
  }
}
.home_story--scroll {
  display: flex;
  width: 500%;
  @include mq(sp) {
    display: block;
    width: 100%;
  }
}
.home_story--sec {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  @include mq(sp) {
    position: relative;
    z-index: 1;
    display: block;
    height: auto;
  }
}
.home_story--sec1 {
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 10%);
  @include mq(sp) {
    padding-bottom: sprem(46);
  }
}
.home_story--sec1 .txtarea {
  .ttl {
    margin-bottom: rem(40);
    font-size: 6.48vh;
    line-height: 1.4;
    letter-spacing: rem(2.1);
    @include mq(sp) {
      font-size: 9.5vw;
    }
  }
  p {
    font-size: rem(18);
    line-height: 2.2;
    letter-spacing: rem(0.54);
  }
}
.bgclipping,
.bgclipping-blue {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.home_story--sec1 .imgarea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  @include mq(sp) {
    width: 100%;
  }
}
.home_story--sec1 .brids {
  position: relative;
  max-width: 471px;
  width: 100%;
  height: 418px;
  @include mq(sp) {
    height: 32vh;
    margin-bottom: sprem(40);
  }
  .bird1,
  .bird2,
  .bird3 {
    position: absolute;
  }
  .bird1 {
    top: rem(70);
    right: 0;
    @include mq(sp) {
      top: 0;
      width: rem(301);
      height: rem(177);
      transform: scale(.8);
    }
  }
  .bird2 {
    left: 0;
    bottom: rem(40);
    @include mq(sp) {
      bottom: 0;
      width: rem(229);
      height: rem(135);
      transform: scale(.6);
    }
  }
  .bird3 {
    right: rem(20);
    bottom: rem(20);
    @include mq(sp) {
      bottom: 0;
      width: rem(147);
      height: rem(87);
      transform: scale(.5);
    }
  }
}
.home_story--sec2 .imgarea {
  width: 54%;
  height: 100%;
  padding-top: rem(102);
  align-items: flex-start;
  overflow: hidden;
  @include mq(sp) {
    width: 100%;
    height: 58vh;
    padding-top: sprem(24);
  }
  img.js-parallax {
    top: 0;
    right: 0;
    max-width: none;
    width: 110% !important;
    height: auto;
    @include mq(sp) {
      top: 0;
      right: -11%;
      object-fit: cover;
      width: 125% !important;
      height: auto;
    }
  }
  .ttl {
    position: relative;
    color: #fff;
    font-size: rem(24);
    letter-spacing: rem(0.72);
    @include mq(sp) {
      margin-bottom: 0;
      font-size: sprem(24);
    }
    span {
      display: block;
    }
  }
  .catch {
    position: relative;
    span {
      font-size: rem(80);
      letter-spacing: rem(1.6);
      line-height: 1;
      color: #fff;
      @include mq(sp) {
        font-size: 10vw;
      }
    }
  }
}
.home_story--sec2 .txtarea,
.home_story--sec4 .txtarea {
  flex: 1;
  padding-inline: 6vw;
  @include mq(sp) {
    padding-block: sprem(46);
    padding-inline: 8%;
  }
}
.home_story--sec2 .txtarea .txt,
.home_story--sec4 .txtarea .txt {
  z-index: 1;
  flex: 1;
  .deco {
    bottom: rem(-94);
    z-index: -1;
    margin-left: 0 !important;
    font-size: rem(260);
    line-height: 1;
    letter-spacing: rem(5.2);
    color: #F2F9FF;
    word-break: keep-all;
    @include mq(sp) {
      bottom: 0;
      font-size: 28vw;
    }
  }
}
.home_story--sec2 .txtarea .ttl,
.home_story--sec4 .txtarea .ttl {
  margin-right: -58%;
  margin-bottom: rem(46);
  @include mq(sp) {
    margin-right: 0;
  }
  &::before {
    content: "";
    display: block;
    width: rem(52);
    height: rem(16);
    background-image: url(../images/common/wave2.svg);
    background-repeat: repeat;
    background-size: cover;
    margin-bottom: rem(30);
  }
}
.home_story--sec2 .txtarea .more,
.home_story--sec4 .txtarea .more {
  position: relative;
  z-index: 1;
  margin-left: 4.65vw;
  @include mq(sp) {
    margin-top: sprem(40);
    margin-left: 0;
  }
}
.home_story--sec2 .txtarea .more a,
.home_story--sec4 .txtarea .more a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(160);
  height: rem(160);
  border-radius: 50%;
  border: 1px solid var(--color-primary-default);
  color: var(--color-primary-default);
  text-align: center;
  font-size: rem(18);
  letter-spacing: rem(4);
  background-color: #fff;
  @include mq(sp) {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 99px;
    font-size: sprem(18);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(#0060B2, 0.1);
    z-index: -1;
    @include mq(sp) {
      border-radius: 99px;
    }
  }
  &::after {
    animation-delay: -2s;
  }
  &:hover::before,
  &:hover::after {
    animation: scale_anime 3s infinite;
  }
}
.home_story--sec2 .txtarea .txt p:not(.mgn-btm72) {
  margin-left: 4.38vw;
  @include mq(sp) {
    margin-left: 0;
  }
}
.home_story--sec3 {
  overflow: hidden;
  @include mq(sp) {
    height: 46vh;
  }
  img {
    top: -5%;
    right: 0;
    max-width: none;
    width: 110% !important;
    height: auto;
    @include mq(sp) {
      top: 0;
      right: -15%;
      object-fit: cover;
      width: 130% !important;
      height: auto;
    }
  }
}
.home_story--sec4 .imgarea {
  position: relative;
  width: 54%;
  height: 100%;
  @include mq(sp) {
    width: 100%;
    height: 72vh;
  }
  .img1,
  .img2,
  .img3 {
    position: absolute;
  }
  .img1 {
    top: 5vh;
    left: 6vh;
    width: 65.28vh;
    height: 43.52vh;
    @include mq(sp) {
      top: 3%;
      left: 0;
      width: 86%;
      height: 50%;
    }
  }
  .img2 {
    right: 44vh;
    bottom: rem(90);
    z-index: 1;
    width: 29.26vh;
    height: 19.54vh;
    @include mq(sp) {
      left: 13%;
      right: auto;
      bottom: 17%;
      width: 40%;
      height: 21%;
    }
  }
  .img3 {
    right: 17vh;
    bottom: 0;
    width: 29.63vh;
    height: 39.81vh;
    @include mq(sp) {
      right: 0;
      bottom: 6%;
      width: 37%;
      height: 44%;
    }
  }
}
.home_story--sec5 {
  position: relative;
  background-repeat: repeat;
  background-size: cover;
  background-position: top 60% center;
  background-image: url(../images/home/home_story7.jpg);
  @include mq(sp) {
    height: 39vh;
  }
}

/* home_works
********************************************** */
.home_works {
  @include mq(sp) {
    z-index: 1;
  }
}
.home_works--wrap {
  position: relative;
  background-color: var(--color-primary-default);
  padding-top: rem(200);
  padding-bottom: rem(120);
  @include mq(sp) {
    padding-top: sprem(32);
  }
}
.home_works--deco {
  position: absolute;
  top: 21%;
  right: 0;
  font-size: 12.5vw;
  color: #004987;
  letter-spacing: rem(4.8);
  line-height: 1.04;
  opacity: 0.5;
  mix-blend-mode: multiply;
}
.home_works--bg_img {
  opacity: .6;
  &.img1 {
    top: 4%;
    left: 19.5%;
    width: rem(420);
    height: rem(280);
    @include mq(sp) {
      top: auto;
      left: 0;
      bottom: 32%;
      width: 30%;
      height: auto;
    }
  }
  &.img2 {
    top: 32.5%;
    right: 0;
    width: rem(598);
    height: rem(400);
    @include mq(sp) {
      top: auto;
      bottom: 20%;
      width: 44%;
      height: auto;
    }
  }
  &.img3 {
    left: 0;
    bottom: 9%;
    width: rem(594);
    height: rem(467);
    @include mq(sp) {
      top: auto;
      bottom: 0;
      width: 40%;
      height: auto;
    }
  }
}
.home_works--point .txtarea {
  position: relative;
  z-index: 1;
  max-width: rem(1200);
  @include mq(sp) {
    flex-direction: column-reverse;
  }
}
.home_works--ttl {
  position: relative;
  display: flex;
  font-size: rem(60);
  color: #fff;
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  word-break: keep-all;
  margin-left: rem(70);
  letter-spacing: rem(6);
  padding-top: rem(86);
  @include mq(sp) {
    margin-left: 0;
    writing-mode: horizontal-tb;
    padding-top: sprem(28);
    font-size: 7.5vw;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: rem(157);
    height: rem(48);
    background-image: url(../images/common/wave3.svg);
    background-size: cover;
    @include mq(sp) {
      width: rem(52);
      height: rem(16);
    }
  }
}
.home_works--point .txtarea .txt {
  width: rem(322);
  margin-top: rem(200);
  font-size: rem(18);
  @include mq(sp) {
    margin-top: sprem(24);
  }
}
.home_works--point .txtarea .more {
  margin-top: rem(40);
  text-align: center;
}
.home_works--point .txtarea .more a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(160);
  height: rem(160);
  border: 1px solid;
  border-radius: 50%;
  background-color: rgba(#FFF, 0.2);
  color: #fff;
  letter-spacing: rem(4);
  z-index: 1;
  @include mq(sp) {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 99px;
    font-size: sprem(18);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(#fff, 0.1);
    z-index: -1;
    @include mq(sp) {
      border-radius: 99px;
    }
  }
  &::after {
    animation-delay: -2s;
  }
  &:hover::before,
  &:hover::after {
    animation: scale_anime 3s infinite;
  }
}
.home_works--point .imgarea {
  margin-top: rem(-410);
  transform: translateX(rem(-84));
  max-width: rem(1584);
  overflow: hidden;
  @include mq(sp) {
    margin-top: sprem(32);
    transform: none;
    max-width: 100%;
    width: 100%;
  }
}
.home_works--point .maparea {
  .map {
    display: block;
    text-align: right;
    .pin-office {
      right: 12%;
      bottom: 35%;
      z-index: 6;
      @include mq(sp) {
        right: 11%;
        bottom: 36%;
        width: 19%;
      }
    }
    .pin-beach {
      right: 22%;
      bottom: 23%;
      z-index: 6;
      @include mq(sp) {
        right: 31%;
        bottom: 23%;
        width: 19%;
      }
    }
    .pin-enoshima {
      right: 7.5%;
      bottom: 16%;
      z-index: 6;
      @include mq(sp) {
        right: 14.5%;
        bottom: 11%;
        width: 13%;
      }
    }
  }
}
.home_works--point .maparea .map img {
  @include mq(sp) {
    transform: translateX(-3%);
  }
}
.home_works--point .maparea .pin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: rem(50);
  &.pin1 {
    left: 90.9%;
    bottom: 35%;
    @include mq(sp) {
      left: 83.5%;
      bottom: 33%;
    }
  }
  &.pin2 {
    left: 92.3%;
    bottom: 18.5%;
    @include mq(sp) {
      left: 85.3%;
    }
    &::after {
      height: 50px;
    }
  }
  &.pin3 {
    left: 83%;
    bottom: 26%;
    @include mq(sp) {
      left: 72%;
    }
  }
  &.pin4 {
    top: 23.5%;
    left: 76%;
    margin: auto;
    @include mq(sp) {
      left: 69%;
    }
  }
  &.pin5 {
    left: 91.5%;
    bottom: 40%;
    @include mq(sp) {
      left: 87.5%;
    }
  }
  &.pin6 {
    left: 85.5%;
    bottom: 48%;
    @include mq(sp) {
      left: 77.5%;
      bottom: 50%;
    }
  }
  &.pin7 {
    right: 42.5%;
    bottom: 24%;
    @include mq(sp) {
      right: 48.5%;
      bottom: 21%;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 7px;
    height: 40px;
    border-radius: 88px;
    background-color: var(--color-primary-default);
    margin-top: -4px;
    @include mq(sp) {
      display: none;
    }
  }
}
.home_works--point .maparea .pin i {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(50);
  height: rem(50);
  border-radius: 50%;
  background-color: var(--color-primary-default);
  color: #fff;
  font-size: rem(24);
  line-height: 1;
  @include mq(sp) {
    width: sprem(24);
    height: sprem(24);
    font-size: sprem(12);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(#0052B2, 0.4);
    z-index: -1;
    animation: scale_anime 3s infinite;
  }
  &::after {
    animation-delay: -2s;
  }
}
.home_works--ex_scroll {
  position: relative;
  overflow: hidden;
  padding-top: 70px;
  @include mq(sp) {
    padding-top: 30px;
  }
}
.home_works--ex {
  padding-block: 2px;
  border-top: 16px solid var(--color-primary-default);
  border-bottom: 1px solid #fff;
  box-shadow: 0 0 0 1px #fff, inset 0 1px 0 0 #fff;
}
.home_works--ex .slick-list {
  overflow: visible !important;
}
.home_works--ex .slick-track {
  display: flex !important;
}
.home_works--ex .slick-arrow {
  position: absolute;
  top: 45%;
  z-index: 10;
  @include sprect(68);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(sp) {
    @include sprect(40);
  }
  &::before {
    content: "";
    display: inline-block;
    @include sprect(8);
    border-right: 2px solid var(--color-primary-default);
    border-bottom: 2px solid var(--color-primary-default);
  }
  &.slick-prev {
    left: 0;
    &::before {
      transform: rotate(135deg);
    }
  }
  &.slick-next {
    right: 0;
    &::before {
      transform: rotate(-45deg);
    }
  }
}
.home_works--ex .post {
  position: relative;
  width: rem(628);
  padding: rem(44);
  margin: 2px;
  border: 1px solid;
  color: #fff;
  counter-increment: number;
  height: auto !important;
  min-height: auto !important;
  @include mq(sp) {
    width: sprem(280);
    padding: sprem(8);
  }
}
.home_works--ex .post .num {
  position: absolute;
  top: -94px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(50);
  font-family: var(--font-en);
  color: #fff;
  line-height: 1;
  text-align: center;
  @include mq(sp) {
    top: -48px;
    justify-content: flex-start;
    padding-left: 8px;
    font-size: sprem(32);
  }
  .suji {
    margin-left: rem(20);
    font-family: var(--font-en);
    font-size: rem(120);
    @include mq(sp) {
      margin-left: 4px;
      font-size: sprem(40);
    }
  }
}
.home_works--ex .post--img {
  margin-bottom: rem(16);
}
.home_works--ex .post--ttl {
  font-size: rem(40);
  color: #fff;
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.home_works--ex .post--txt p {
  margin-top: rem(16);
  @include mq(sp) {
    margin-top: sprem(8);
  }
  span {
    display: inline-block;
    background-image: linear-gradient(180deg, #fff 1px, transparent 1px); /* 罫線の色と太さ  */
    background-size: 100% 3em; /* 行の高さ */
    line-height: 3em; /* 文字の高さ */
    padding-bottom: 1px; /* 最終行の下にも罫線を引く */
    @include mq(sp) {
      background-image: none;
      line-height: 1.85;
      padding-bottom: 8px;
      opacity: .8;
    }
  }
}
.home_works--exwrap {
  padding-top: rem(110);
  margin-top: rem(-110);
  @include mq(sp) {
    margin-top: 0;
  }
}
.home_works--more {
  position: absolute;
  top: 0;
  right: 26.8%;
  z-index: 2;
  @include mq(sp) {
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
}
.home_works--more a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(260);
  height: rem(100);
  background-color: #3375c1;
  border-radius: 8px;
  padding-left: rem(16);
  border: 1px solid #fff;
  color: #fff;
  &:hover {
    background-color: #2061ab;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(-12);
    width: rem(24);
    height: rem(24);
    margin: auto;
    background-color: #3375c1;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: scaleX(.5) rotate(-45deg);
    transition: all .4s ease-out;
  }
  &:hover::after {
    background-color: #2061ab;
  }
  svg {
    margin-left: 8px;
  }
}

/* home_interview
********************************************** */
.home_interview {
  padding-top: rem(250);
  @include mq(sp) {
    padding-top: sprem(60);
  }
}
.home_interview--ttl {
  margin-bottom: rem(40);
  .ttl {
    font-size: 13.02vw;
    color: var(--color-primary-default);
    line-height: 1;
    overflow: hidden;
  }
  .lead {
    width: rem(345);
    margin-left: rem(80);
    padding-bottom: rem(8);
    font-size: rem(18);
    color: var(--color-primary-default);
    @include mq(sp) {
      margin-top: sprem(16);
      margin-left: 0;
      font-size: sprem(16);
      line-height: 1.65;
    }
  }
}
.home_interview_member {
  .member_nav {
    width: 31.25vw;
    background: linear-gradient(180deg, rgba(255,255,255,0) 50%, var(--color-primary-default) 50%);
  }
  .member_conts {
    width: calc(100% - 31.25vw);
    @include mq(sp) {
      display: none;
    }
  }
}
.home_interview_member .member_nav .slick-list {
  height: 100%;
}
.home_interview_member .member_nav .slick-track {
  width: 100% !important;
  height: 100%;
  transform: none !important;
}
.home_interview_member .member_nav li {
  position: relative;
  float: none !important;
  width: 100% !important;
  height: calc(100% / 4);
  border-bottom: 1px solid #000;
  transition: all .4s ease-out;
  cursor: pointer;
  & > .flex {
    align-items: flex-end;
    padding-left: 6.61vw;
  }
  &:hover {
    color: var(--color-primary-default);
  }
  &:nth-child(2) {
    border-bottom-color: #fff;
  }
  &:nth-child(3),
  &:nth-child(4) {
    color: #fff;
    border-bottom-color: #fff;
  }
  &:nth-child(3):hover,
  &:nth-child(4):hover {
    color: #64ADEB;
  }
  .imgarea {
    margin-right: 2.08vw;
    width: rem(220);
    height: rem(220);
    overflow: hidden;
  }
  .txtarea {
    flex: 1;
    padding-bottom: rem(16);
  }
}
@include mq(sp) {
  .home_interview_member .member_nav li .catch {
    padding: 0 5% 5%;
    span {
      display: inline;
      font-size: 5.5vw;
      color: #fff;
      background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
      box-decoration-break: clone;
      padding: sprem(4) sprem(12);
      line-height: 2;
    }
  }
  .home_interview_member .member_nav li:nth-child(3) .catch,
  .home_interview_member .member_nav li:nth-child(4) .catch {
    span {
      color: var(--color-primary-default);
      background: linear-gradient(transparent 0%, #fff 0%);
    }
  }
}
.home_interview_member .member_nav .imgarea {
  &[data-name="R.M"],
  &[data-name="T.T"] {
    display: flex;
    align-items: flex-end;
  }
  &[data-name="K.Y"] {
    padding-top: rem(8);
  }
  &[data-name="R.T"] img {
    width: 91% !important;
    margin-left: auto;
  }
  &[data-name="T.T"] img {
    width: 91% !important;
  }
  &[data-name="K.Y"] img {
    width: 75% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.home_interview_member .member_nav .name {
  margin-bottom: rem(8);
  font-size: rem(60);
  letter-spacing: rem(-0.5);
  line-height: 1;
}
.home_interview_member .member_nav li:nth-child(3) .name a,
.home_interview_member .member_nav li:nth-child(4) .name a {
  color: #fff;
  &:hover {
    color: #64ADEB;
  }
}
.home_interview_member .member_nav .info {
  font-size: rem(18);
  letter-spacing: rem(0.9);
  line-height: 1.6;
}
.home_interview_member .member_conts .slick-list,
.home_interview_member .member_conts .slick-track,
.home_interview_member .member_conts--bloc {
  height: 100%;
}
.home_interview_member .member_conts--bloc .catch {
  position: absolute;
  left: 7vw;
  bottom: rem(120);
  z-index: 1;
  color: #fff;
  span {
    display: inline;
    background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
    box-decoration-break: clone;
    font-size: rem(40);
    padding: rem(4) rem(16);
    line-height: 2.2;
  }
}
.member_conts .slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
}
.member_conts .slick-dots li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
}
.member_conts .slick-dots button {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  font-size: 0;
  background-color: #c91a1a;
  opacity: 1;
}
.member_conts .slick-dots .slick-active button {
  -webkit-animation: sliderNav 5.7s linear 0s normal forwards running;
  animation: sliderNav 5.7s linear 0s normal forwards running;
}

/* home_life
********************************************** */
.home_life {
  position: relative;
  padding-top: rem(180);
  padding-bottom: rem(400);
  background-color: var(--color-primary-default);
  overflow: hidden;
  @include mq(sp) {
    padding-top: sprem(46);
    padding-bottom: 55vh;
  }
}
.home_life > .inner {
  @include mq(sp) {
    flex-direction: column-reverse;
  }
}
.home_life--ttl {
  position: relative;
  font-size: rem(60);
  color: #fff;
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  word-break: keep-all;
  margin-left: 4.375rem;
  letter-spacing: 0.375rem;
  padding-top: rem(86);
  @include mq(sp) {
    padding-top: sprem(28);
    font-size: 7.5vw;
    writing-mode: horizontal-tb;
    margin-left: 0;
    margin-right: auto;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem(157);
    height: rem(48);
    background-size: cover;
    background-image: url(../images/common/wave3.svg);
    @include mq(sp) {
      right: auto;
      width: rem(52);
      height: rem(16);
    }
  }
}
.home_life--lead {
  width: rem(304);
  color: #fff;
  @include mq(sp) {
    width: 100%;
    margin-top: sprem(24);
  }
}
.home_life--lead .more {
  margin-top: rem(70);
  text-align: center;
}
.home_life--lead .more a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(160);
  height: rem(160);
  border: 1px solid;
  border-radius: 50%;
  background-color: rgba(#FFF, 0.2);
  color: #fff;
  letter-spacing: rem(4);
  @include mq(sp) {
    width: 100%;
    height: auto;
    border-radius: 99px;
    padding: 10px;
    font-size: sprem(18);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(#fff, 0.1);
    z-index: -1;
    @include mq(sp) {
      border-radius: 99px;
    }
  }
  &::after {
    animation-delay: -2s;
  }
  &:hover::before,
  &:hover::after {
    animation: scale_anime 3s infinite;
  }
}
.home_life--photo1 {
  top: rem(80);
  left: 0;
  width: rem(821);
  height: rem(638);
  @include mq(sp) {
    top: auto;
    bottom: 21vh;
    width: 63%;
    height: auto;
  }
}
.home_life--photo2 {
  top: 48%;
  left: 15%;
  width: rem(632);
  height: rem(466);
  @include mq(sp) {
    top: auto;
    left: auto;
    right: 0;
    bottom: 5vh;
    width: 50%;
    height: auto;
  }
}
.home_life--img {
  right: 0;
  bottom: rem(-96);
  width: rem(961);
  height: rem(569);
  @include mq(sp) {
    bottom: sprem(-30);
    width: 78%;
    height: auto;
  }
}
.home_life--bg {
  left: 0;
  width: 100%;
  @include mq(sp) {
    bottom: 0;
  }
}

/* home_job
********************************************** */
.home_job {
  position: relative;
  padding-top: rem(80);
  padding-bottom: rem(160);
  background: linear-gradient(180deg, rgba(255,255,255,0) 30%, var(--color-primary-default) 30%);
  @include mq(sp) {
    padding-top: sprem(46);
    padding-bottom: sprem(64);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    top: rem(-99);
    left: 0;
    width: 45%;
    height: rem(100);
    background-color: #fff;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
    @include mq(sp) {
      display: none;
    }
  }
  &::after {
    left: 0;
    right: 0;
    bottom: rem(-63);
    margin: 0 auto;
    width: rem(162);
    height: rem(64);
    background-color: var(--color-primary-default);
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    @include mq(sp) {
      bottom: -23px;
      width: 64px;
      height: 24px;
    }
  }
}
.home_job--ttl {
  display: flex;
  align-items: flex-start;
  margin-bottom: rem(40);
  font-size: rem(70);
  letter-spacing: rem(2.1);
  color: var(--color-primary-default);
  line-height: 1;
  @include mq(sp) {
    flex-direction: column;
    font-size: sprem(40);
  }
  &::before {
    content: "";
    display: inline-block;
    margin-top: rem(8);
    margin-left: rem(20);
    margin-right: rem(30);
    width: rem(52);
    height: rem(16);
    background-image: url(../images/common/wave.svg);
    background-size: cover;
    @include mq(sp) {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: sprem(16);
    }
  }
}
.home_job--blocs li {
  position: relative;
  width: 50%;
  height: rem(860);
  overflow: hidden;
  @include mq(sp) {
    height: auto;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    transition: all .4s ease-out;
  }
  &::before {
    filter: blur(15px);
    background-size: cover;
    background-position: center;
  }
  &.job1::before {
    background-image: url(../images/home/job_img1.png);
  }
  &.job2::before {
    background-image: url(../images/home/job_img2.jpg);
  }
  &::after {
    background: linear-gradient(0deg, rgba(0, 82, 178, 0.70) 0%, rgba(0, 82, 178, 0.70) 100%);
    background-blend-mode: multiply, normal;
  }
}
.home_job--blocs a {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  @include mq(sp) {
    padding-block: sprem(60);
  }
  .txtarea {
    width: rem(375);
    @include mq(sp) {
      width: 80%;
    }
  }
}
.home_job--blocs .ttl {
  border-bottom: 1px solid;
  padding-bottom: rem(40);
  margin-bottom: rem(40);
  font-size: rem(60);
  text-align: center;
  @include mq(sp) {
    padding-bottom: sprem(16);
    margin-bottom: sprem(16);
    font-size: sprem(28);
  }
}
.home_job--other {
  position: relative;
  overflow: hidden;
  margin-top: rem(32);
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    transition: all .4s ease-out;
  }
  &::before {
    filter: blur(15px);
    background-size: cover;
    background-position: center;
    background-image: url(../images/home/job_img3.jpg);
  }
  &::after {
    background: linear-gradient(0deg, rgba(0, 82, 178, 0.70) 0%, rgba(0, 82, 178, 0.70) 100%);
    background-blend-mode: multiply, normal;
  }
  a {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: rem(280);
    color: #fff;
    text-align: center;
    font-size: rem(60);
    @include mq(sp) {
      height: sprem(200);
      font-size: sprem(28);
    }
  }
}
.home_job--blocs li:hover,
.home_job--other:hover {
  &::before {
    filter: blur(0);
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}
