@use "../abstracts" as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: rem(1440);
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-sm {
  max-width: rem(920);
}
.inner-lg {
  max-width: rem(1560);
}
.inner-xl {
  max-width: rem(1760);
}
