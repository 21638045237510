@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 132;
$header-hgt-sp: 72;
$header-hgt-fx: 80;

// 色
$clr-main: #0052B2;
$bg-primary: #0052B2;

// CSS変数
:root {
  // typography
  --ltr-space-default: normal;
  --line-height-default: 2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary:  "Midashi Go MB31 JIS2004 AP", sans-serif;
  --font-secondary:  "ヒラギノ角ゴ オールド W6 JIS2004", sans-serif;
  --font-jp: "Midashi Go MB31 JIS2004 AP", sans-serif;
  --font-en: "Anton", sans-serif;
  --font-antonio: "Antonio", sans-serif;
  --font-kaushan: "Kaushan Script", cursive;
  --font-mb101: "Gothic MB101 Bold", sans-serif;

  // colors
  --color-primary-default: #0052B2;
  --color-primary-dark: #0F6593;
  --color-primary-light: #2E9AD4;

  /* ※CTA周りは、デフォルトは secondary を当てています。自由に変えてください */
  --color-secondary-default: #EB3535;
  --color-secondary-dark: #D03030;
  --color-secondary-light: #F25353;

  --color-bg-01: #F7F7F4;
  --color-bg-02: #F0EFEC;

  --color-sub-01: #51AAA6;
  --color-sub-02: #A8BF71;
  --color-sub-03: #D7A761;
  --color-sub-04: #CF727C;
  --color-sub-05: #A2689B;
  --color-sub-06: #6790B7;

  --color-mono-01: #F2F2F2;
  --color-mono-02: #DFDFE0;
  --color-mono-03: #BABDC4;
  --color-mono-04: #9599A3;
  --color-mono-05: #646B7A;
  --color-mono-06: #333841;

  --color-text-primary: #000;
  --color-text-secondary: #7A7A81;
  --color-text-placeholder: #CCC;
  --color-text-light: #F7F7F7;

  --color-wht: #fff;
  --color-blk: #000;

  // animation
  --transit-default: all .4s ease-out;

  // formy settings
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  "blue": (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  "red": (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  "green": (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  ),
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
