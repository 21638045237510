@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  z-index: z(header);
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(24);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: rem(30);
    }
  }
  @include mq(sp) {
    & > .inner {
      padding-left: 0;
    }
  }
}
.page-contact-simple .header {
  position: absolute;
}

/* --- logo -- */
.header--logo {
  width: rem(220);
}
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  @include mq(med) {
    width: auto;
    max-height: rem(40);
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(24) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--color-bg-02);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.gnav a {
  @include mq(med, min, ps) {
    @include center-flex;
    font-size: rem(18);
  }
  & > span {
    padding-inline: rem(16);
    transition: all .4s ease-out;
  }
  .font-en {
    letter-spacing: .2em;
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  transition: all .4s ease-out;
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--color-primary-default);
    }
  }
}
.gnav--menu > li > a > span {
  text-shadow: 1px 1px 3px rgba(255,255,255,.5), -1px -1px 3px rgba(255,255,255,.5),
              -1px 1px 3px rgba(255,255,255,.5), 1px -1px 3px rgba(255,255,255,.5),
              0px 1px 3px rgba(255,255,255,.5),  0 -1px 3px rgba(255,255,255,.5),
              -1px 0 3px rgba(255,255,255,.5), 1px 0 3px rgba(255,255,255,.5);
}
.gnav--link {
  padding: 0 rem(16);
  @include mq(med) {
    display: block;
    padding: rem(18) rem(16);
  }
  @include mq(med, min, ps) {
    text-align: center;
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: sprem(10);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid var(--color-primary-default);
    border-right: 1px solid var(--color-primary-default);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    &:hover > span {
      color: var(--color-wht);
      background-color: var(--color-primary-default);
      text-shadow: 1px 1px 0 var(--color-primary-default), -1px -1px 0 var(--color-primary-default),
      -1px 1px 0 var(--color-primary-default), 1px -1px 0 var(--color-primary-default),
      0px 1px 0 var(--color-primary-default),  0 -1px 0 var(--color-primary-default),
      -1px 0 0 var(--color-primary-default), 1px 0 0 var(--color-primary-default);
    }
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  position: absolute;
  top: rem(98);
  left: 50%;
  z-index: 100;
  display: block;
  width: max-content;
  transform: translateX(-50%);
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  @include mq(med) {
    position: relative;
    width: max-content;
    border-top: 1px dotted var(--color-primary-default);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  &::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    width: 8px;
    height: 8px;
    margin: 0 auto;
    background-color: #0052B2;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med, min, ps) {
    background-color: var(--color-primary-default);
  }
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav li:not(:last-child) {
  @include mq(med, min, ps) {
    border-bottom: 1px solid #fff;
  }
}
.head_sub_nav a {
  display: block;
  padding: rem(8) rem(24);
  font-size: rem(16);
  word-break: keep-all;
  @include mq(med, min, ps) {
    color: #fff;
  }
  &:hover {
    @include mq(med, min, ps) {
      opacity: .5;
    }
  }
  span {
    padding-left: 0;
    padding-right: 0;
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-20);
    @include sprect(56);
    transition: var(--transit-default);
    color: var(--color-primary-default);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn {
  position: absolute;
  top: rem(32);
  right: rem(244);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  opacity: 0;
  pointer-events: none;
  transition: all .4s ease-out;
  cursor: pointer;
  @include mq(sp) {
    top: 9px;
    right: 2%;
    width: 56px;
    height: 56px;
    opacity: 1;
    pointer-events: all;
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 56px;
    height: 56px;
  }
}
.gnav_btn--lines {
  width: 45px;
  height: 26px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  @include mq(sp) {
    width: 36px;
    height: 24px;
  }
}
.gnav_btn--lines span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-primary-default);
  }
}
.gnav_btn .font-antonio {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0;
  color: var(--color-primary-default);
  @include mq(sp) {
    font-size: 18px;
  }
}

.gnav--cta {
  text-align: center;
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--contact {
  margin-left: rem(28);
}
.gnav--contact a {
  position: relative;
  padding: rem(40) rem(32) rem(24) rem(24);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: rem(234);
    height: rem(218);
    background-image: url(../images/common/gnav_btn_polygon.svg);
    background-size: cover;
  }
  img {
    position: relative;
    z-index: 1;
    width: rem(116);
    height: rem(57);
  }
}
.gnav--contact span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  mix-blend-mode: multiply;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: #0052B2;
  }
  &::before {
    right: 38%;
    bottom: rem(-122);
    width: rem(99);
    height: rem(113);
    clip-path: polygon(0 0, 30% 100%, 100% 24%);
    transform: rotate(120deg);
  }
  &::after {
    left: rem(-130);
    bottom: rem(-48);
    width: rem(62);
    height: rem(48);
    clip-path: polygon(0 0, 13% 100%, 100% 27%);
  }
}

/* hbr-nav
********************************************** */
.hbr-nav {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-default);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all .4s ease-out;
  @include mq(sp) {
    padding: sprem(64) 0 sprem(40);
    align-items: flex-start;
    overflow-y: scroll;
  }
}
.hbr-nav.active {
  opacity: 1;
  pointer-events: all;
}
.hbr-nav--top .imgarea {
  display: flex;
  flex-direction: column;
  margin-right: rem(54);
  flex: 1;
  @include mq(sp) {
    display: none;
  }
  .img {
    padding-right: rem(40);
  }
  .font-antonio {
    display: inline-block;
    margin-top: 16px;
    margin-left: auto;
    margin-right: 0;
    line-height: 1.5;
    letter-spacing: rem(4.8);
  }
}
.hbr-nav--menu {
  padding: rem(6) rem(54);
  border-left: 1px solid #fff;
  @include mq(sp) {
    padding: 0;
    border-left: none;
    &:nth-child(1) {
      margin-bottom: sprem(16);
    }
  }
}
.hbr-nav--menu > li {
  &:not(:last-child) {
    margin-bottom: rem(40);
    @include mq(sp) {
      margin-bottom: sprem(16);
    }
  }
}
.hbr-nav--menu li a {
  display: inline-block;
  padding: 6px 8px;
  line-height: 1;
  color: #fff;
  &:hover {
    background-color: #fff;
    color: var(--color-primary-default);
  }
  .font-en {
    font-size: rem(60);
    letter-spacing: rem(-0.5);
    line-height: 1;
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}
.hbr-nav--menu .sub-menu {
  margin-top: rem(8);
  li {
    margin-top: rem(16);
  }
}
.hbr-nav--menu .sub-menu a {
  position: relative;
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(16);
  }
  &::before {
    content: "…";
    margin-right: rem(8);
  }
}
.hbr-nav--btm {
  margin-top: rem(80);
  padding-top: rem(32);
  border-top: 1px solid #fff;
  @include mq(sp) {
    margin-top: sprem(40);
  }
}
.hbr-nav-subnav {
  margin-left: rem(24);
  margin-right: auto;
  @include mq(sp) {
    margin-left: 0;
    margin-bottom: 8px;
    text-align: center;
  }
}
.hbr-nav-subnav li {
  &:not(:last-child) {
    margin-right: rem(16);
  }
  a {
    color: #fff;
    font-size: rem(14);
    &:hover {
      opacity: .7;
    }
  }
}
.hbr-nav-subnav svg {
  fill: #fff;
  margin-left: rem(8);
  vertical-align: middle;
  transform: translateY(-2px);
}
.hbr-nav--entry {
  position: absolute;
  top: 0;
  right: 0;
  @include mq(sp) {
    display: none;
  }
}
.hbr-nav--entry a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(56) rem(32) rem(24) rem(24);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 14.625rem;
    height: 13.625rem;
    background-image: url(../images/common/hbr_entry_polygon.svg);
    background-size: cover;
  }
}
.hbr-nav--entry a span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &::before, 
  &::after {
    content: "";
    position: absolute;
    background-color: #FFF;
  }
  &::before {
    right: 38%;
    bottom: rem(-122);
    width: rem(99);
    height: rem(113);
    clip-path: polygon(0 0, 30% 100%, 100% 24%);
    transform: rotate(120deg);
  }
  &::after {
    left: rem(-130);
    bottom: rem(-48);
    width: rem(62);
    height: rem(48);
    clip-path: polygon(0 0, 13% 100%, 100% 27%);
  }
}
.hbr-nav--close {
  position: absolute;
  top: rem(32);
  right: rem(244);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  cursor: pointer;
  @include mq(sp) {
    top: 6px;
    right: 2%;
    width: 56px;
    height: 56px;
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 54px;
    height: 54px;
    @include mq(sp) {
      width: 56px;
      height: 56px;
    }
  }
}
.hbr-nav--close .icon {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  margin-bottom: rem(8);
  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #fff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.hbr-nav--close .font-antonio {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0;
  @include mq(sp) {
    font-size: 18px;
  }
}
.hbr-nav .txt_slide_deco {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: .2;
}

/* header-sm
********************************************** */
@include mq(med, min, ps) {
  .header-sm {
    mix-blend-mode: multiply;
  }
  .header-sm .gnav--menu {
    opacity: 0;
    pointer-events: none;
  }
  .header-sm .gnav_btn {
    opacity: 1;
    pointer-events: all;
  }
}