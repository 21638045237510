@charset "UTF-8";
:root {
  --ltr-space-default: normal;
  --line-height-default: 2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary: "Midashi Go MB31 JIS2004 AP", sans-serif;
  --font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", sans-serif;
  --font-jp: "Midashi Go MB31 JIS2004 AP", sans-serif;
  --font-en: "Anton", sans-serif;
  --font-antonio: "Antonio", sans-serif;
  --font-kaushan: "Kaushan Script", cursive;
  --font-mb101: "Gothic MB101 Bold", sans-serif;
  --color-primary-default: #0052B2;
  --color-primary-dark: #0F6593;
  --color-primary-light: #2E9AD4;
  /* ※CTA周りは、デフォルトは secondary を当てています。自由に変えてください */
  --color-secondary-default: #EB3535;
  --color-secondary-dark: #D03030;
  --color-secondary-light: #F25353;
  --color-bg-01: #F7F7F4;
  --color-bg-02: #F0EFEC;
  --color-sub-01: #51AAA6;
  --color-sub-02: #A8BF71;
  --color-sub-03: #D7A761;
  --color-sub-04: #CF727C;
  --color-sub-05: #A2689B;
  --color-sub-06: #6790B7;
  --color-mono-01: #F2F2F2;
  --color-mono-02: #DFDFE0;
  --color-mono-03: #BABDC4;
  --color-mono-04: #9599A3;
  --color-mono-05: #646B7A;
  --color-mono-06: #333841;
  --color-text-primary: #000;
  --color-text-secondary: #7A7A81;
  --color-text-placeholder: #CCC;
  --color-text-light: #F7F7F7;
  --color-wht: #fff;
  --color-blk: #000;
  --transit-default: all .4s ease-out;
}

/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: clamp(9px, 0.8333333333vw, 1rem);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    font-size: 0.875rem;
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: 0.875rem;
  }
}

body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  color: var(--color-text-primary);
  height: max-content;
  padding-top: 8.25rem;
  word-break: break-word;
}
@media print, screen and (min-width: 48em) {
  body {
    font-size: clamp(14.1666666667px, 0.8333333333vw, 1rem);
  }
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body:not(.mce-content-body) {
    min-width: 67.5rem;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    padding-top: 5.1428571429rem;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    -webkit-text-size-adjust: none;
  }
}
body.home {
  padding-top: 0;
}

* {
  letter-spacing: var(--ltr-space-default);
}

ul,
ol {
  list-style: none;
}

small {
  font-size: max(12px, 0.875rem);
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.9285714286rem;
  }
}

a,
a[href="javascript:void(0);"]:hover {
  color: var(--color-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus {
  text-decoration: none !important;
}

@media print, screen and (min-width: 48em) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}

svg {
  transition: var(--transit-default);
}
svg[preserveAspectRatio=none] {
  height: auto;
}

/* txt
**************************************** */
.txt-lg {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: 1.0714285714rem;
  }
}

.txt-sm {
  font-size: max(12px, 0.875rem);
}
@media screen and (max-width: 47.9375em) {
  .txt-sm {
    font-size: 0.9285714286rem;
  }
}

.clr-wh,
.clr-wh * {
  color: var(--color-wht);
}

.clr-primary,
.clr-primary * {
  color: var(--color-primary-default);
}

.clr-secondary,
.clr-secondary * {
  color: var(--color-secondary-default);
}

/* --- font-size --- */
.fz8 {
  font-size: max(6px, 0.5rem);
}
@media screen and (max-width: 47.9375em) {
  .fz8 {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp8 {
    font-size: 0.5714285714rem !important;
  }
}

.fz9 {
  font-size: max(7px, 0.5625rem);
}
@media screen and (max-width: 47.9375em) {
  .fz9 {
    font-size: 0.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp9 {
    font-size: 0.6428571429rem !important;
  }
}

.fz10 {
  font-size: max(8px, 0.625rem);
}
@media screen and (max-width: 47.9375em) {
  .fz10 {
    font-size: 0.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp10 {
    font-size: 0.7142857143rem !important;
  }
}

.fz11 {
  font-size: max(9px, 0.6875rem);
}
@media screen and (max-width: 47.9375em) {
  .fz11 {
    font-size: 0.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp11 {
    font-size: 0.7857142857rem !important;
  }
}

.fz12 {
  font-size: max(10px, 0.75rem);
}
@media screen and (max-width: 47.9375em) {
  .fz12 {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp12 {
    font-size: 0.8571428571rem !important;
  }
}

.fz13 {
  font-size: max(11px, 0.8125rem);
}
@media screen and (max-width: 47.9375em) {
  .fz13 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp13 {
    font-size: 0.9285714286rem !important;
  }
}

.fz14 {
  font-size: max(12px, 0.875rem);
}
@media screen and (max-width: 47.9375em) {
  .fz14 {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp14 {
    font-size: 1rem !important;
  }
}

.fz15 {
  font-size: max(13px, 0.9375rem);
}
@media screen and (max-width: 47.9375em) {
  .fz15 {
    font-size: 0.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp15 {
    font-size: 1.0714285714rem !important;
  }
}

.fz16 {
  font-size: max(14px, 1rem);
}
@media screen and (max-width: 47.9375em) {
  .fz16 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp16 {
    font-size: 1.1428571429rem !important;
  }
}

.fz17 {
  font-size: max(15px, 1.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .fz17 {
    font-size: 1.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp17 {
    font-size: 1.2142857143rem !important;
  }
}

.fz18 {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .fz18 {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp18 {
    font-size: 1.2857142857rem !important;
  }
}

.fz19 {
  font-size: max(17px, 1.1875rem);
}
@media screen and (max-width: 47.9375em) {
  .fz19 {
    font-size: 1.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp19 {
    font-size: 1.3571428571rem !important;
  }
}

.fz20 {
  font-size: max(18px, 1.25rem);
}
@media screen and (max-width: 47.9375em) {
  .fz20 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp20 {
    font-size: 1.4285714286rem !important;
  }
}

.fz21 {
  font-size: max(19px, 1.3125rem);
}
@media screen and (max-width: 47.9375em) {
  .fz21 {
    font-size: 1.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp21 {
    font-size: 1.5rem !important;
  }
}

.fz22 {
  font-size: max(20px, 1.375rem);
}
@media screen and (max-width: 47.9375em) {
  .fz22 {
    font-size: 1.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp22 {
    font-size: 1.5714285714rem !important;
  }
}

.fz23 {
  font-size: max(21px, 1.4375rem);
}
@media screen and (max-width: 47.9375em) {
  .fz23 {
    font-size: 1.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp23 {
    font-size: 1.6428571429rem !important;
  }
}

.fz24 {
  font-size: max(22px, 1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .fz24 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp24 {
    font-size: 1.7142857143rem !important;
  }
}

.fz25 {
  font-size: 1.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz25 {
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp25 {
    font-size: 1.7857142857rem !important;
  }
}

.fz26 {
  font-size: 1.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz26 {
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp26 {
    font-size: 1.8571428571rem !important;
  }
}

.fz27 {
  font-size: 1.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz27 {
    font-size: 1.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp27 {
    font-size: 1.9285714286rem !important;
  }
}

.fz28 {
  font-size: 1.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz28 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp28 {
    font-size: 2rem !important;
  }
}

.fz29 {
  font-size: 1.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz29 {
    font-size: 1.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp29 {
    font-size: 2.0714285714rem !important;
  }
}

.fz30 {
  font-size: 1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz30 {
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp30 {
    font-size: 2.1428571429rem !important;
  }
}

.fz31 {
  font-size: 1.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz31 {
    font-size: 1.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp31 {
    font-size: 2.2142857143rem !important;
  }
}

.fz32 {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .fz32 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp32 {
    font-size: 2.2857142857rem !important;
  }
}

.fz33 {
  font-size: 2.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz33 {
    font-size: 2.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp33 {
    font-size: 2.3571428571rem !important;
  }
}

.fz34 {
  font-size: 2.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz34 {
    font-size: 2.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp34 {
    font-size: 2.4285714286rem !important;
  }
}

.fz35 {
  font-size: 2.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz35 {
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp35 {
    font-size: 2.5rem !important;
  }
}

.fz36 {
  font-size: 2.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz36 {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp36 {
    font-size: 2.5714285714rem !important;
  }
}

.fz37 {
  font-size: 2.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz37 {
    font-size: 2.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp37 {
    font-size: 2.6428571429rem !important;
  }
}

.fz38 {
  font-size: 2.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz38 {
    font-size: 2.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp38 {
    font-size: 2.7142857143rem !important;
  }
}

.fz39 {
  font-size: 2.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz39 {
    font-size: 2.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp39 {
    font-size: 2.7857142857rem !important;
  }
}

.fz40 {
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz40 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp40 {
    font-size: 2.8571428571rem !important;
  }
}

.fz41 {
  font-size: 2.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz41 {
    font-size: 2.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp41 {
    font-size: 2.9285714286rem !important;
  }
}

.fz42 {
  font-size: 2.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz42 {
    font-size: 2.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp42 {
    font-size: 3rem !important;
  }
}

.fz43 {
  font-size: 2.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz43 {
    font-size: 2.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp43 {
    font-size: 3.0714285714rem !important;
  }
}

.fz44 {
  font-size: 2.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz44 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp44 {
    font-size: 3.1428571429rem !important;
  }
}

.fz45 {
  font-size: 2.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz45 {
    font-size: 2.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp45 {
    font-size: 3.2142857143rem !important;
  }
}

.fz46 {
  font-size: 2.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz46 {
    font-size: 2.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp46 {
    font-size: 3.2857142857rem !important;
  }
}

.fz47 {
  font-size: 2.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz47 {
    font-size: 2.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp47 {
    font-size: 3.3571428571rem !important;
  }
}

.fz48 {
  font-size: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .fz48 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp48 {
    font-size: 3.4285714286rem !important;
  }
}

.fz49 {
  font-size: 3.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz49 {
    font-size: 3.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp49 {
    font-size: 3.5rem !important;
  }
}

.fz50 {
  font-size: 3.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz50 {
    font-size: 3.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp50 {
    font-size: 3.5714285714rem !important;
  }
}

.fz51 {
  font-size: 3.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz51 {
    font-size: 3.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp51 {
    font-size: 3.6428571429rem !important;
  }
}

.fz52 {
  font-size: 3.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz52 {
    font-size: 3.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp52 {
    font-size: 3.7142857143rem !important;
  }
}

.fz53 {
  font-size: 3.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz53 {
    font-size: 3.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp53 {
    font-size: 3.7857142857rem !important;
  }
}

.fz54 {
  font-size: 3.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz54 {
    font-size: 3.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp54 {
    font-size: 3.8571428571rem !important;
  }
}

.fz55 {
  font-size: 3.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz55 {
    font-size: 3.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp55 {
    font-size: 3.9285714286rem !important;
  }
}

.fz56 {
  font-size: 3.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz56 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp56 {
    font-size: 4rem !important;
  }
}

.fz57 {
  font-size: 3.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz57 {
    font-size: 3.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp57 {
    font-size: 4.0714285714rem !important;
  }
}

.fz58 {
  font-size: 3.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz58 {
    font-size: 3.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp58 {
    font-size: 4.1428571429rem !important;
  }
}

.fz59 {
  font-size: 3.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz59 {
    font-size: 3.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp59 {
    font-size: 4.2142857143rem !important;
  }
}

.fz60 {
  font-size: 3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz60 {
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp60 {
    font-size: 4.2857142857rem !important;
  }
}

.fz61 {
  font-size: 3.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz61 {
    font-size: 3.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp61 {
    font-size: 4.3571428571rem !important;
  }
}

.fz62 {
  font-size: 3.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz62 {
    font-size: 3.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp62 {
    font-size: 4.4285714286rem !important;
  }
}

.fz63 {
  font-size: 3.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz63 {
    font-size: 3.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp63 {
    font-size: 4.5rem !important;
  }
}

.fz64 {
  font-size: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .fz64 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp64 {
    font-size: 4.5714285714rem !important;
  }
}

.fz65 {
  font-size: 4.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz65 {
    font-size: 4.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp65 {
    font-size: 4.6428571429rem !important;
  }
}

.fz66 {
  font-size: 4.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz66 {
    font-size: 4.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp66 {
    font-size: 4.7142857143rem !important;
  }
}

.fz67 {
  font-size: 4.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz67 {
    font-size: 4.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp67 {
    font-size: 4.7857142857rem !important;
  }
}

.fz68 {
  font-size: 4.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz68 {
    font-size: 4.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp68 {
    font-size: 4.8571428571rem !important;
  }
}

.fz69 {
  font-size: 4.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz69 {
    font-size: 4.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp69 {
    font-size: 4.9285714286rem !important;
  }
}

.fz70 {
  font-size: 4.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz70 {
    font-size: 4.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp70 {
    font-size: 5rem !important;
  }
}

.fz71 {
  font-size: 4.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz71 {
    font-size: 4.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp71 {
    font-size: 5.0714285714rem !important;
  }
}

.fz72 {
  font-size: 4.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz72 {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp72 {
    font-size: 5.1428571429rem !important;
  }
}

.fz73 {
  font-size: 4.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz73 {
    font-size: 4.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp73 {
    font-size: 5.2142857143rem !important;
  }
}

.fz74 {
  font-size: 4.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz74 {
    font-size: 4.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp74 {
    font-size: 5.2857142857rem !important;
  }
}

.fz75 {
  font-size: 4.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz75 {
    font-size: 4.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp75 {
    font-size: 5.3571428571rem !important;
  }
}

.fz76 {
  font-size: 4.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz76 {
    font-size: 4.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp76 {
    font-size: 5.4285714286rem !important;
  }
}

.fz77 {
  font-size: 4.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz77 {
    font-size: 4.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp77 {
    font-size: 5.5rem !important;
  }
}

.fz78 {
  font-size: 4.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz78 {
    font-size: 4.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp78 {
    font-size: 5.5714285714rem !important;
  }
}

.fz79 {
  font-size: 4.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz79 {
    font-size: 4.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp79 {
    font-size: 5.6428571429rem !important;
  }
}

.fz80 {
  font-size: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz80 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp80 {
    font-size: 5.7142857143rem !important;
  }
}

.fz81 {
  font-size: 5.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz81 {
    font-size: 5.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp81 {
    font-size: 5.7857142857rem !important;
  }
}

.fz82 {
  font-size: 5.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz82 {
    font-size: 5.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp82 {
    font-size: 5.8571428571rem !important;
  }
}

.fz83 {
  font-size: 5.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz83 {
    font-size: 5.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp83 {
    font-size: 5.9285714286rem !important;
  }
}

.fz84 {
  font-size: 5.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz84 {
    font-size: 5.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp84 {
    font-size: 6rem !important;
  }
}

.fz85 {
  font-size: 5.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz85 {
    font-size: 5.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp85 {
    font-size: 6.0714285714rem !important;
  }
}

.fz86 {
  font-size: 5.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz86 {
    font-size: 5.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp86 {
    font-size: 6.1428571429rem !important;
  }
}

.fz87 {
  font-size: 5.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz87 {
    font-size: 5.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp87 {
    font-size: 6.2142857143rem !important;
  }
}

.fz88 {
  font-size: 5.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz88 {
    font-size: 5.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp88 {
    font-size: 6.2857142857rem !important;
  }
}

.fz89 {
  font-size: 5.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz89 {
    font-size: 5.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp89 {
    font-size: 6.3571428571rem !important;
  }
}

.fz90 {
  font-size: 5.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz90 {
    font-size: 5.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp90 {
    font-size: 6.4285714286rem !important;
  }
}

.fz91 {
  font-size: 5.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz91 {
    font-size: 5.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp91 {
    font-size: 6.5rem !important;
  }
}

.fz92 {
  font-size: 5.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz92 {
    font-size: 5.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp92 {
    font-size: 6.5714285714rem !important;
  }
}

.fz93 {
  font-size: 5.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz93 {
    font-size: 5.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp93 {
    font-size: 6.6428571429rem !important;
  }
}

.fz94 {
  font-size: 5.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz94 {
    font-size: 5.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp94 {
    font-size: 6.7142857143rem !important;
  }
}

.fz95 {
  font-size: 5.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz95 {
    font-size: 5.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp95 {
    font-size: 6.7857142857rem !important;
  }
}

.fz96 {
  font-size: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .fz96 {
    font-size: 6rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp96 {
    font-size: 6.8571428571rem !important;
  }
}

.fz97 {
  font-size: 6.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz97 {
    font-size: 6.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp97 {
    font-size: 6.9285714286rem !important;
  }
}

.fz98 {
  font-size: 6.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz98 {
    font-size: 6.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp98 {
    font-size: 7rem !important;
  }
}

.fz99 {
  font-size: 6.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz99 {
    font-size: 6.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp99 {
    font-size: 7.0714285714rem !important;
  }
}

.fz100 {
  font-size: 6.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz100 {
    font-size: 6.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp100 {
    font-size: 7.1428571429rem !important;
  }
}

.fz101 {
  font-size: 6.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz101 {
    font-size: 6.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp101 {
    font-size: 7.2142857143rem !important;
  }
}

.fz102 {
  font-size: 6.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz102 {
    font-size: 6.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp102 {
    font-size: 7.2857142857rem !important;
  }
}

.fz103 {
  font-size: 6.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz103 {
    font-size: 6.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp103 {
    font-size: 7.3571428571rem !important;
  }
}

.fz104 {
  font-size: 6.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz104 {
    font-size: 6.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp104 {
    font-size: 7.4285714286rem !important;
  }
}

.fz105 {
  font-size: 6.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz105 {
    font-size: 6.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp105 {
    font-size: 7.5rem !important;
  }
}

.fz106 {
  font-size: 6.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz106 {
    font-size: 6.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp106 {
    font-size: 7.5714285714rem !important;
  }
}

.fz107 {
  font-size: 6.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz107 {
    font-size: 6.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp107 {
    font-size: 7.6428571429rem !important;
  }
}

.fz108 {
  font-size: 6.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz108 {
    font-size: 6.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp108 {
    font-size: 7.7142857143rem !important;
  }
}

.fz109 {
  font-size: 6.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz109 {
    font-size: 6.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp109 {
    font-size: 7.7857142857rem !important;
  }
}

.fz110 {
  font-size: 6.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz110 {
    font-size: 6.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp110 {
    font-size: 7.8571428571rem !important;
  }
}

.fz111 {
  font-size: 6.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz111 {
    font-size: 6.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp111 {
    font-size: 7.9285714286rem !important;
  }
}

.fz112 {
  font-size: 7rem;
}
@media screen and (max-width: 47.9375em) {
  .fz112 {
    font-size: 7rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp112 {
    font-size: 8rem !important;
  }
}

.fz113 {
  font-size: 7.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz113 {
    font-size: 7.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp113 {
    font-size: 8.0714285714rem !important;
  }
}

.fz114 {
  font-size: 7.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz114 {
    font-size: 7.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp114 {
    font-size: 8.1428571429rem !important;
  }
}

.fz115 {
  font-size: 7.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz115 {
    font-size: 7.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp115 {
    font-size: 8.2142857143rem !important;
  }
}

.fz116 {
  font-size: 7.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz116 {
    font-size: 7.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp116 {
    font-size: 8.2857142857rem !important;
  }
}

.fz117 {
  font-size: 7.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz117 {
    font-size: 7.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp117 {
    font-size: 8.3571428571rem !important;
  }
}

.fz118 {
  font-size: 7.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz118 {
    font-size: 7.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp118 {
    font-size: 8.4285714286rem !important;
  }
}

.fz119 {
  font-size: 7.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz119 {
    font-size: 7.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp119 {
    font-size: 8.5rem !important;
  }
}

.fz120 {
  font-size: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz120 {
    font-size: 7.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp120 {
    font-size: 8.5714285714rem !important;
  }
}

.fz121 {
  font-size: 7.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz121 {
    font-size: 7.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp121 {
    font-size: 8.6428571429rem !important;
  }
}

.fz122 {
  font-size: 7.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz122 {
    font-size: 7.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp122 {
    font-size: 8.7142857143rem !important;
  }
}

.fz123 {
  font-size: 7.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz123 {
    font-size: 7.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp123 {
    font-size: 8.7857142857rem !important;
  }
}

.fz124 {
  font-size: 7.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz124 {
    font-size: 7.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp124 {
    font-size: 8.8571428571rem !important;
  }
}

.fz125 {
  font-size: 7.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz125 {
    font-size: 7.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp125 {
    font-size: 8.9285714286rem !important;
  }
}

.fz126 {
  font-size: 7.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz126 {
    font-size: 7.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp126 {
    font-size: 9rem !important;
  }
}

.fz127 {
  font-size: 7.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz127 {
    font-size: 7.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp127 {
    font-size: 9.0714285714rem !important;
  }
}

.fz128 {
  font-size: 8rem;
}
@media screen and (max-width: 47.9375em) {
  .fz128 {
    font-size: 8rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp128 {
    font-size: 9.1428571429rem !important;
  }
}

.fz129 {
  font-size: 8.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz129 {
    font-size: 8.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp129 {
    font-size: 9.2142857143rem !important;
  }
}

.fz130 {
  font-size: 8.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz130 {
    font-size: 8.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp130 {
    font-size: 9.2857142857rem !important;
  }
}

.fz131 {
  font-size: 8.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz131 {
    font-size: 8.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp131 {
    font-size: 9.3571428571rem !important;
  }
}

.fz132 {
  font-size: 8.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz132 {
    font-size: 8.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp132 {
    font-size: 9.4285714286rem !important;
  }
}

.fz133 {
  font-size: 8.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz133 {
    font-size: 8.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp133 {
    font-size: 9.5rem !important;
  }
}

.fz134 {
  font-size: 8.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz134 {
    font-size: 8.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp134 {
    font-size: 9.5714285714rem !important;
  }
}

.fz135 {
  font-size: 8.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz135 {
    font-size: 8.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp135 {
    font-size: 9.6428571429rem !important;
  }
}

.fz136 {
  font-size: 8.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz136 {
    font-size: 8.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp136 {
    font-size: 9.7142857143rem !important;
  }
}

.fz137 {
  font-size: 8.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz137 {
    font-size: 8.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp137 {
    font-size: 9.7857142857rem !important;
  }
}

.fz138 {
  font-size: 8.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz138 {
    font-size: 8.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp138 {
    font-size: 9.8571428571rem !important;
  }
}

.fz139 {
  font-size: 8.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz139 {
    font-size: 8.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp139 {
    font-size: 9.9285714286rem !important;
  }
}

.fz140 {
  font-size: 8.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz140 {
    font-size: 8.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp140 {
    font-size: 10rem !important;
  }
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp);
}

.font-en {
  font-family: var(--font-en);
}

.font-antonio {
  font-family: var(--font-antonio);
}

.font-kaushan {
  font-family: var(--font-kaushan);
}

.font-mb101 {
  font-family: var(--font-mb101);
}

/* --- clip-txt --- */
.clip-txt1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clip-txt3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clip-txt4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
  .txt-ctr-sp {
    text-align: center;
  }
}
/* Wysiwyg editor
 **************************************** */
.mce-content-body::before, .mce-content-body::after {
  content: "";
  display: table;
}
.mce-content-body::after {
  clear: both;
}

.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  padding-left: 1.25em;
  padding-top: 0.5em;
}
.mce-content-body ul li::marker,
.mce-content-body ol li::marker {
  color: var(--color-primary-default);
  font-weight: 600;
  letter-spacing: 0.2em;
}

.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1.125em;
  left: 0;
  width: 0.625em;
  aspect-ratio: 1/1;
  border: 1px solid var(--color-primary-default);
  background-color: var(--color-primary-default);
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--color-wht);
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body ol {
  list-style-type: decimal-leading-zero;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body .wp-caption {
  width: fit-content;
  max-width: 100%;
}

.mce-content-body hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-width: 2px;
  border-color: var(--color-primary-default);
}

.mce-content-body a img {
  transition: var(--transit-default);
}

.mce-content-body a:hover img {
  opacity: 0.8;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img:not(.pc-none):not(.sp-none).aligncenter {
  display: block;
}

#tinymce img.pc-none,
#tinymce img.sp-none {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 1rem 2.5rem;
  border-radius: 0.1875rem;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--color-bg-02);
}
.mce-content-body blockquote::before, .mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}
.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 0.875rem;
  left: 0.75rem;
}
.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 0.875rem;
  right: 0.75rem;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  vertical-align: top;
  color: var(--color-text-primary);
}
.mce-content-body th *,
.mce-content-body td * {
  color: var(--color-text-primary);
}

.mce-content-body th {
  min-width: 7.5rem;
  text-align: left;
}

.mce-content-body sup {
  font-size: max(10px, 0.75rem);
  color: #555;
}

.bold {
  font-weight: bold;
  color: var(--color-primary-default);
}

.clr-wh .bold {
  color: #fff;
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 1rem;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
  /* table scroll */
  .table-sp-scroll {
    width: 50rem;
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table {
    margin: 0;
  }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: 0.25;
    font-size: max(12px, 0.875rem);
  }
}
/* AddQuicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-jp);
}
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 2em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl:not(:first-child),
  .editor-ttl2:not(:first-child),
  .editor-ttl3:not(:first-child) {
    margin-top: 1.5em;
  }
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl:not(:last-child),
  .editor-ttl2:not(:last-child),
  .editor-ttl3:not(:last-child) {
    margin-bottom: 0.75em;
  }
}

.editor-ttl {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.4285714286rem;
  }
}

.editor-ttl2 {
  font-size: max(22px, 1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.2857142857rem;
  }
}

.editor-ttl3 {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl3 {
    font-size: 1.1428571429rem;
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: max(12px, 0.875rem);
  opacity: 0.6;
}
@media screen and (max-width: 47.9375em) {
  .txt-attention {
    font-size: 0.9285714286rem;
  }
}
.txt-attention::before {
  content: "※";
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 0.5em;
}
@media screen and (max-width: 47.9375em) {
  .table1 th,
  .table1 td {
    padding: 0;
    border-bottom: 1px solid;
  }
}
.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
}

.table1 th {
  color: var(--color-primary-default);
  text-align: left;
}
@media screen and (max-width: 47.9375em) {
  .table1 th {
    padding-top: 1em;
  }
}
.table1 th::before {
  left: 0;
  border-bottom: 2px solid;
}
@media screen and (max-width: 47.9375em) {
  .table1 th::before {
    border-bottom: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .table1 td {
    padding: 0.2em 0 1em;
    border-bottom: 1px solid #D3D3D3;
  }
}
.table1 td::before {
  right: 0;
  border-bottom: 1px solid #D3D3D3;
}

.table1.table-sp-block td + td {
  padding-top: 1em;
}

.table2 {
  background-color: var(--color-wht);
}
.table2 th,
.table2 td {
  border-color: #DAEEFF;
}

.table2 tr:nth-child(odd) th {
  background-color: #DAEEFF;
}
.table2 tr:nth-child(even) th {
  background-color: #F2F9FF;
}

/* ボックス */
.box {
  padding: 1.5rem 2rem;
  background-color: #F2F9FF;
  border-radius: 0.5rem;
}
@media screen and (max-width: 47.9375em) {
  .box {
    padding: 1.5rem;
  }
}
.box:not(:first-child) {
  margin-top: 2em;
}
.box:not(:last-child) {
  margin-bottom: 2em;
}

.box *:not([class*=clr-]):not([class*=bold]):not([class*=editor-ttl]) {
  color: var(--color-text-primary);
}

.lps_sec:nth-child(even) *:not(.bg-wh) .box,
.lps_sec[style*=background-color] *:not(.bg-wh) .box {
  background-color: var(--color-wht);
}
.lps_sec:nth-child(even) *.bg-wh .box,
.lps_sec[style*=background-color] *.bg-wh .box {
  background-color: #F2F9FF;
}

.bg-wh * {
  color: #000;
}

/* リスト */
ul.check--list li {
  padding-left: 2rem;
}
ul.check--list li::before, ul.check--list li::after {
  content: "";
  position: absolute;
}
ul.check--list li::before {
  top: 0.625em;
  left: 0;
  width: 1.5em;
  aspect-ratio: 1/1;
  background-color: var(--color-primary-default);
  border-radius: 50%;
}
ul.check--list li::after {
  top: 1.0625em;
  left: 0.4375rem;
  width: 0.625em;
  height: 0.4375em;
  border-left: 2px solid var(--color-wht);
  border-bottom: 2px solid var(--color-wht);
  transform: rotate(-45deg);
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
}
.txt-decoration1 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration1 {
    flex-wrap: wrap;
  }
}
.txt-decoration1 .icon {
  width: 3.25rem;
  height: 1rem;
  margin-right: 1rem;
  background-image: url(../images/common/wave.svg);
  background-size: cover;
}
.txt-decoration1 .en {
  margin-right: 1rem;
  font-size: 1.5rem;
  color: var(--color-primary-default);
}
.txt-decoration1 .jp {
  font-size: 1.125rem;
}

.txt-decoration2 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration2 {
    display: table;
    width: 100%;
  }
}
.txt-decoration2 .num {
  margin-right: 0.875rem;
  font-size: 3.75rem;
  color: var(--color-primary-default);
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration2 .num {
    float: left;
    font-size: 3rem;
  }
}

.txt-decoration2 .txt .en {
  display: block;
  font-size: 2rem;
  line-height: 1.2;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration2 .txt .en {
    font-size: 3.15rem;
    transform: translateY(-6px);
  }
}
.txt-decoration2 .txt .jp {
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration2 .txt .jp {
    display: inline-block;
    font-size: 1.0714285714rem;
    line-height: 1.5;
  }
}

.txt-decoration3 span {
  display: inline-block;
  padding: 4px 1.5rem;
  background-color: var(--color-primary-default);
  color: var(--color-wht);
  font-size: 1.125rem;
}

.txt-decoration4 {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration4 {
    align-items: center;
  }
}
.txt-decoration4 .deco {
  margin-right: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration4 .deco {
    position: relative;
    margin-right: 0.7142857143rem;
    height: 48px;
    aspect-ratio: 1/1;
  }
}
.txt-decoration4 .deco img {
  margin: 0 !important;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration4 .deco img {
    width: 100% !important;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
}
.txt-decoration4 .txt {
  padding-bottom: 0.375rem;
  border-bottom: 1px solid;
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-decoration4 .txt {
    font-size: 1.2857142857rem;
    line-height: 1.65;
  }
}

.clr-wh .txt-decoration4 .deco {
  filter: brightness(0) invert(1);
}

.txt-decoration5 {
  display: table;
}
.txt-decoration5 .deco {
  float: left;
  margin-right: 1rem;
}
.txt-decoration5 .deco img {
  margin: 0 !important;
}
.txt-decoration5 .txt {
  border-bottom: 1px solid;
  padding-bottom: 3px;
  line-height: 1.95;
  font-size: 1.5rem;
}

/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}

.mce-content-body.wp-editor {
  font-size: 16px;
  padding: 1.5rem !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
.number_ttl {
  margin-bottom: 2.5em;
}

.number_ttl_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.number_ttl_wrap .num {
  position: relative;
  z-index: 1;
  display: inline-block;
  letter-spacing: 0.04em;
  font-size: 3rem;
  color: var(--color-primary-default);
  text-shadow: 2px 2px 0 #FFF, -2px -2px 0 #FFF, -2px 2px 0 #FFF, 2px -2px 0 #FFF, 0px 2px 0 #FFF, 0 -2px 0 #FFF, -2px 0 0 #FFF, 2px 0 0 #FFF;
  line-height: 1;
  transform: translateY(5px);
  margin-left: 0.625rem;
  margin-right: auto;
}
@media screen and (max-width: 82.5em) {
  .number_ttl_wrap .num {
    margin-left: 0;
  }
}
.number_ttl_wrap .txt {
  margin-right: 2.625rem;
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 400;
}
@media screen and (max-width: 82.5em) {
  .number_ttl_wrap .txt {
    margin-right: 0;
  }
}
.number_ttl_wrap .fukidashi {
  position: relative;
  display: inline-block;
  min-width: 230px;
  margin-inline: auto;
  padding: 8px 1rem;
  background-color: var(--color-primary-default);
  color: #fff;
  font-size: 1.75rem;
  text-align: center;
}
@media screen and (max-width: 82.5em) {
  .number_ttl_wrap .fukidashi {
    min-width: 100%;
  }
}
.number_ttl_wrap .fukidashi::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.25rem;
  margin: auto;
  width: 1.4285714286rem;
  height: 1.4285714286rem;
  background-color: var(--color-primary-default);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

/* bg
********************************************** */
.bg-primary {
  background-color: var(--color-primary-default);
}

.bg-01 {
  background-color: var(--color-bg-01);
}

.bg-02 {
  background-color: var(--color-bg-02);
}

.bg-wh {
  background-color: var(--color-wht);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trim-widescreen {
  aspect-ratio: 3/2;
}

.trim-square {
  aspect-ratio: 1/1;
}

.trim-portrait {
  aspect-ratio: 2/3;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.mgn0 {
  margin: 0rem;
}

.mgn-top0 {
  margin-top: 0rem;
}

.mgn-rgt0 {
  margin-right: 0rem;
}

.mgn-btm0 {
  margin-bottom: 0rem;
}

.mgn-lft0 {
  margin-left: 0rem;
}

.mgn1 {
  margin: 0.0625rem;
}

.mgn-top1 {
  margin-top: 0.0625rem;
}

.mgn-rgt1 {
  margin-right: 0.0625rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625rem;
}

.mgn-lft1 {
  margin-left: 0.0625rem;
}

.mgn2 {
  margin: 0.125rem;
}

.mgn-top2 {
  margin-top: 0.125rem;
}

.mgn-rgt2 {
  margin-right: 0.125rem;
}

.mgn-btm2 {
  margin-bottom: 0.125rem;
}

.mgn-lft2 {
  margin-left: 0.125rem;
}

.mgn3 {
  margin: 0.1875rem;
}

.mgn-top3 {
  margin-top: 0.1875rem;
}

.mgn-rgt3 {
  margin-right: 0.1875rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875rem;
}

.mgn-lft3 {
  margin-left: 0.1875rem;
}

.mgn4 {
  margin: 0.25rem;
}

.mgn-top4 {
  margin-top: 0.25rem;
}

.mgn-rgt4 {
  margin-right: 0.25rem;
}

.mgn-btm4 {
  margin-bottom: 0.25rem;
}

.mgn-lft4 {
  margin-left: 0.25rem;
}

.mgn5 {
  margin: 0.3125rem;
}

.mgn-top5 {
  margin-top: 0.3125rem;
}

.mgn-rgt5 {
  margin-right: 0.3125rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125rem;
}

.mgn-lft5 {
  margin-left: 0.3125rem;
}

.mgn6 {
  margin: 0.375rem;
}

.mgn-top6 {
  margin-top: 0.375rem;
}

.mgn-rgt6 {
  margin-right: 0.375rem;
}

.mgn-btm6 {
  margin-bottom: 0.375rem;
}

.mgn-lft6 {
  margin-left: 0.375rem;
}

.mgn7 {
  margin: 0.4375rem;
}

.mgn-top7 {
  margin-top: 0.4375rem;
}

.mgn-rgt7 {
  margin-right: 0.4375rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375rem;
}

.mgn-lft7 {
  margin-left: 0.4375rem;
}

.mgn8 {
  margin: 0.5rem;
}

.mgn-top8 {
  margin-top: 0.5rem;
}

.mgn-rgt8 {
  margin-right: 0.5rem;
}

.mgn-btm8 {
  margin-bottom: 0.5rem;
}

.mgn-lft8 {
  margin-left: 0.5rem;
}

.mgn9 {
  margin: 0.5625rem;
}

.mgn-top9 {
  margin-top: 0.5625rem;
}

.mgn-rgt9 {
  margin-right: 0.5625rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625rem;
}

.mgn-lft9 {
  margin-left: 0.5625rem;
}

.mgn10 {
  margin: 0.625rem;
}

.mgn-top10 {
  margin-top: 0.625rem;
}

.mgn-rgt10 {
  margin-right: 0.625rem;
}

.mgn-btm10 {
  margin-bottom: 0.625rem;
}

.mgn-lft10 {
  margin-left: 0.625rem;
}

.mgn11 {
  margin: 0.6875rem;
}

.mgn-top11 {
  margin-top: 0.6875rem;
}

.mgn-rgt11 {
  margin-right: 0.6875rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875rem;
}

.mgn-lft11 {
  margin-left: 0.6875rem;
}

.mgn12 {
  margin: 0.75rem;
}

.mgn-top12 {
  margin-top: 0.75rem;
}

.mgn-rgt12 {
  margin-right: 0.75rem;
}

.mgn-btm12 {
  margin-bottom: 0.75rem;
}

.mgn-lft12 {
  margin-left: 0.75rem;
}

.mgn13 {
  margin: 0.8125rem;
}

.mgn-top13 {
  margin-top: 0.8125rem;
}

.mgn-rgt13 {
  margin-right: 0.8125rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125rem;
}

.mgn-lft13 {
  margin-left: 0.8125rem;
}

.mgn14 {
  margin: 0.875rem;
}

.mgn-top14 {
  margin-top: 0.875rem;
}

.mgn-rgt14 {
  margin-right: 0.875rem;
}

.mgn-btm14 {
  margin-bottom: 0.875rem;
}

.mgn-lft14 {
  margin-left: 0.875rem;
}

.mgn15 {
  margin: 0.9375rem;
}

.mgn-top15 {
  margin-top: 0.9375rem;
}

.mgn-rgt15 {
  margin-right: 0.9375rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375rem;
}

.mgn-lft15 {
  margin-left: 0.9375rem;
}

.mgn16 {
  margin: 1rem;
}

.mgn-top16 {
  margin-top: 1rem;
}

.mgn-rgt16 {
  margin-right: 1rem;
}

.mgn-btm16 {
  margin-bottom: 1rem;
}

.mgn-lft16 {
  margin-left: 1rem;
}

.mgn17 {
  margin: 1.0625rem;
}

.mgn-top17 {
  margin-top: 1.0625rem;
}

.mgn-rgt17 {
  margin-right: 1.0625rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625rem;
}

.mgn-lft17 {
  margin-left: 1.0625rem;
}

.mgn18 {
  margin: 1.125rem;
}

.mgn-top18 {
  margin-top: 1.125rem;
}

.mgn-rgt18 {
  margin-right: 1.125rem;
}

.mgn-btm18 {
  margin-bottom: 1.125rem;
}

.mgn-lft18 {
  margin-left: 1.125rem;
}

.mgn19 {
  margin: 1.1875rem;
}

.mgn-top19 {
  margin-top: 1.1875rem;
}

.mgn-rgt19 {
  margin-right: 1.1875rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875rem;
}

.mgn-lft19 {
  margin-left: 1.1875rem;
}

.mgn20 {
  margin: 1.25rem;
}

.mgn-top20 {
  margin-top: 1.25rem;
}

.mgn-rgt20 {
  margin-right: 1.25rem;
}

.mgn-btm20 {
  margin-bottom: 1.25rem;
}

.mgn-lft20 {
  margin-left: 1.25rem;
}

.mgn21 {
  margin: 1.3125rem;
}

.mgn-top21 {
  margin-top: 1.3125rem;
}

.mgn-rgt21 {
  margin-right: 1.3125rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125rem;
}

.mgn-lft21 {
  margin-left: 1.3125rem;
}

.mgn22 {
  margin: 1.375rem;
}

.mgn-top22 {
  margin-top: 1.375rem;
}

.mgn-rgt22 {
  margin-right: 1.375rem;
}

.mgn-btm22 {
  margin-bottom: 1.375rem;
}

.mgn-lft22 {
  margin-left: 1.375rem;
}

.mgn23 {
  margin: 1.4375rem;
}

.mgn-top23 {
  margin-top: 1.4375rem;
}

.mgn-rgt23 {
  margin-right: 1.4375rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375rem;
}

.mgn-lft23 {
  margin-left: 1.4375rem;
}

.mgn24 {
  margin: 1.5rem;
}

.mgn-top24 {
  margin-top: 1.5rem;
}

.mgn-rgt24 {
  margin-right: 1.5rem;
}

.mgn-btm24 {
  margin-bottom: 1.5rem;
}

.mgn-lft24 {
  margin-left: 1.5rem;
}

.mgn25 {
  margin: 1.5625rem;
}

.mgn-top25 {
  margin-top: 1.5625rem;
}

.mgn-rgt25 {
  margin-right: 1.5625rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625rem;
}

.mgn-lft25 {
  margin-left: 1.5625rem;
}

.mgn26 {
  margin: 1.625rem;
}

.mgn-top26 {
  margin-top: 1.625rem;
}

.mgn-rgt26 {
  margin-right: 1.625rem;
}

.mgn-btm26 {
  margin-bottom: 1.625rem;
}

.mgn-lft26 {
  margin-left: 1.625rem;
}

.mgn27 {
  margin: 1.6875rem;
}

.mgn-top27 {
  margin-top: 1.6875rem;
}

.mgn-rgt27 {
  margin-right: 1.6875rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875rem;
}

.mgn-lft27 {
  margin-left: 1.6875rem;
}

.mgn28 {
  margin: 1.75rem;
}

.mgn-top28 {
  margin-top: 1.75rem;
}

.mgn-rgt28 {
  margin-right: 1.75rem;
}

.mgn-btm28 {
  margin-bottom: 1.75rem;
}

.mgn-lft28 {
  margin-left: 1.75rem;
}

.mgn29 {
  margin: 1.8125rem;
}

.mgn-top29 {
  margin-top: 1.8125rem;
}

.mgn-rgt29 {
  margin-right: 1.8125rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125rem;
}

.mgn-lft29 {
  margin-left: 1.8125rem;
}

.mgn30 {
  margin: 1.875rem;
}

.mgn-top30 {
  margin-top: 1.875rem;
}

.mgn-rgt30 {
  margin-right: 1.875rem;
}

.mgn-btm30 {
  margin-bottom: 1.875rem;
}

.mgn-lft30 {
  margin-left: 1.875rem;
}

.mgn31 {
  margin: 1.9375rem;
}

.mgn-top31 {
  margin-top: 1.9375rem;
}

.mgn-rgt31 {
  margin-right: 1.9375rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375rem;
}

.mgn-lft31 {
  margin-left: 1.9375rem;
}

.mgn32 {
  margin: 2rem;
}

.mgn-top32 {
  margin-top: 2rem;
}

.mgn-rgt32 {
  margin-right: 2rem;
}

.mgn-btm32 {
  margin-bottom: 2rem;
}

.mgn-lft32 {
  margin-left: 2rem;
}

.mgn33 {
  margin: 2.0625rem;
}

.mgn-top33 {
  margin-top: 2.0625rem;
}

.mgn-rgt33 {
  margin-right: 2.0625rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625rem;
}

.mgn-lft33 {
  margin-left: 2.0625rem;
}

.mgn34 {
  margin: 2.125rem;
}

.mgn-top34 {
  margin-top: 2.125rem;
}

.mgn-rgt34 {
  margin-right: 2.125rem;
}

.mgn-btm34 {
  margin-bottom: 2.125rem;
}

.mgn-lft34 {
  margin-left: 2.125rem;
}

.mgn35 {
  margin: 2.1875rem;
}

.mgn-top35 {
  margin-top: 2.1875rem;
}

.mgn-rgt35 {
  margin-right: 2.1875rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875rem;
}

.mgn-lft35 {
  margin-left: 2.1875rem;
}

.mgn36 {
  margin: 2.25rem;
}

.mgn-top36 {
  margin-top: 2.25rem;
}

.mgn-rgt36 {
  margin-right: 2.25rem;
}

.mgn-btm36 {
  margin-bottom: 2.25rem;
}

.mgn-lft36 {
  margin-left: 2.25rem;
}

.mgn37 {
  margin: 2.3125rem;
}

.mgn-top37 {
  margin-top: 2.3125rem;
}

.mgn-rgt37 {
  margin-right: 2.3125rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125rem;
}

.mgn-lft37 {
  margin-left: 2.3125rem;
}

.mgn38 {
  margin: 2.375rem;
}

.mgn-top38 {
  margin-top: 2.375rem;
}

.mgn-rgt38 {
  margin-right: 2.375rem;
}

.mgn-btm38 {
  margin-bottom: 2.375rem;
}

.mgn-lft38 {
  margin-left: 2.375rem;
}

.mgn39 {
  margin: 2.4375rem;
}

.mgn-top39 {
  margin-top: 2.4375rem;
}

.mgn-rgt39 {
  margin-right: 2.4375rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375rem;
}

.mgn-lft39 {
  margin-left: 2.4375rem;
}

.mgn40 {
  margin: 2.5rem;
}

.mgn-top40 {
  margin-top: 2.5rem;
}

.mgn-rgt40 {
  margin-right: 2.5rem;
}

.mgn-btm40 {
  margin-bottom: 2.5rem;
}

.mgn-lft40 {
  margin-left: 2.5rem;
}

.mgn41 {
  margin: 2.5625rem;
}

.mgn-top41 {
  margin-top: 2.5625rem;
}

.mgn-rgt41 {
  margin-right: 2.5625rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625rem;
}

.mgn-lft41 {
  margin-left: 2.5625rem;
}

.mgn42 {
  margin: 2.625rem;
}

.mgn-top42 {
  margin-top: 2.625rem;
}

.mgn-rgt42 {
  margin-right: 2.625rem;
}

.mgn-btm42 {
  margin-bottom: 2.625rem;
}

.mgn-lft42 {
  margin-left: 2.625rem;
}

.mgn43 {
  margin: 2.6875rem;
}

.mgn-top43 {
  margin-top: 2.6875rem;
}

.mgn-rgt43 {
  margin-right: 2.6875rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875rem;
}

.mgn-lft43 {
  margin-left: 2.6875rem;
}

.mgn44 {
  margin: 2.75rem;
}

.mgn-top44 {
  margin-top: 2.75rem;
}

.mgn-rgt44 {
  margin-right: 2.75rem;
}

.mgn-btm44 {
  margin-bottom: 2.75rem;
}

.mgn-lft44 {
  margin-left: 2.75rem;
}

.mgn45 {
  margin: 2.8125rem;
}

.mgn-top45 {
  margin-top: 2.8125rem;
}

.mgn-rgt45 {
  margin-right: 2.8125rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125rem;
}

.mgn-lft45 {
  margin-left: 2.8125rem;
}

.mgn46 {
  margin: 2.875rem;
}

.mgn-top46 {
  margin-top: 2.875rem;
}

.mgn-rgt46 {
  margin-right: 2.875rem;
}

.mgn-btm46 {
  margin-bottom: 2.875rem;
}

.mgn-lft46 {
  margin-left: 2.875rem;
}

.mgn47 {
  margin: 2.9375rem;
}

.mgn-top47 {
  margin-top: 2.9375rem;
}

.mgn-rgt47 {
  margin-right: 2.9375rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375rem;
}

.mgn-lft47 {
  margin-left: 2.9375rem;
}

.mgn48 {
  margin: 3rem;
}

.mgn-top48 {
  margin-top: 3rem;
}

.mgn-rgt48 {
  margin-right: 3rem;
}

.mgn-btm48 {
  margin-bottom: 3rem;
}

.mgn-lft48 {
  margin-left: 3rem;
}

.mgn49 {
  margin: 3.0625rem;
}

.mgn-top49 {
  margin-top: 3.0625rem;
}

.mgn-rgt49 {
  margin-right: 3.0625rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625rem;
}

.mgn-lft49 {
  margin-left: 3.0625rem;
}

.mgn50 {
  margin: 3.125rem;
}

.mgn-top50 {
  margin-top: 3.125rem;
}

.mgn-rgt50 {
  margin-right: 3.125rem;
}

.mgn-btm50 {
  margin-bottom: 3.125rem;
}

.mgn-lft50 {
  margin-left: 3.125rem;
}

.mgn51 {
  margin: 3.1875rem;
}

.mgn-top51 {
  margin-top: 3.1875rem;
}

.mgn-rgt51 {
  margin-right: 3.1875rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875rem;
}

.mgn-lft51 {
  margin-left: 3.1875rem;
}

.mgn52 {
  margin: 3.25rem;
}

.mgn-top52 {
  margin-top: 3.25rem;
}

.mgn-rgt52 {
  margin-right: 3.25rem;
}

.mgn-btm52 {
  margin-bottom: 3.25rem;
}

.mgn-lft52 {
  margin-left: 3.25rem;
}

.mgn53 {
  margin: 3.3125rem;
}

.mgn-top53 {
  margin-top: 3.3125rem;
}

.mgn-rgt53 {
  margin-right: 3.3125rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125rem;
}

.mgn-lft53 {
  margin-left: 3.3125rem;
}

.mgn54 {
  margin: 3.375rem;
}

.mgn-top54 {
  margin-top: 3.375rem;
}

.mgn-rgt54 {
  margin-right: 3.375rem;
}

.mgn-btm54 {
  margin-bottom: 3.375rem;
}

.mgn-lft54 {
  margin-left: 3.375rem;
}

.mgn55 {
  margin: 3.4375rem;
}

.mgn-top55 {
  margin-top: 3.4375rem;
}

.mgn-rgt55 {
  margin-right: 3.4375rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375rem;
}

.mgn-lft55 {
  margin-left: 3.4375rem;
}

.mgn56 {
  margin: 3.5rem;
}

.mgn-top56 {
  margin-top: 3.5rem;
}

.mgn-rgt56 {
  margin-right: 3.5rem;
}

.mgn-btm56 {
  margin-bottom: 3.5rem;
}

.mgn-lft56 {
  margin-left: 3.5rem;
}

.mgn57 {
  margin: 3.5625rem;
}

.mgn-top57 {
  margin-top: 3.5625rem;
}

.mgn-rgt57 {
  margin-right: 3.5625rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625rem;
}

.mgn-lft57 {
  margin-left: 3.5625rem;
}

.mgn58 {
  margin: 3.625rem;
}

.mgn-top58 {
  margin-top: 3.625rem;
}

.mgn-rgt58 {
  margin-right: 3.625rem;
}

.mgn-btm58 {
  margin-bottom: 3.625rem;
}

.mgn-lft58 {
  margin-left: 3.625rem;
}

.mgn59 {
  margin: 3.6875rem;
}

.mgn-top59 {
  margin-top: 3.6875rem;
}

.mgn-rgt59 {
  margin-right: 3.6875rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875rem;
}

.mgn-lft59 {
  margin-left: 3.6875rem;
}

.mgn60 {
  margin: 3.75rem;
}

.mgn-top60 {
  margin-top: 3.75rem;
}

.mgn-rgt60 {
  margin-right: 3.75rem;
}

.mgn-btm60 {
  margin-bottom: 3.75rem;
}

.mgn-lft60 {
  margin-left: 3.75rem;
}

.mgn61 {
  margin: 3.8125rem;
}

.mgn-top61 {
  margin-top: 3.8125rem;
}

.mgn-rgt61 {
  margin-right: 3.8125rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125rem;
}

.mgn-lft61 {
  margin-left: 3.8125rem;
}

.mgn62 {
  margin: 3.875rem;
}

.mgn-top62 {
  margin-top: 3.875rem;
}

.mgn-rgt62 {
  margin-right: 3.875rem;
}

.mgn-btm62 {
  margin-bottom: 3.875rem;
}

.mgn-lft62 {
  margin-left: 3.875rem;
}

.mgn63 {
  margin: 3.9375rem;
}

.mgn-top63 {
  margin-top: 3.9375rem;
}

.mgn-rgt63 {
  margin-right: 3.9375rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375rem;
}

.mgn-lft63 {
  margin-left: 3.9375rem;
}

.mgn64 {
  margin: 4rem;
}

.mgn-top64 {
  margin-top: 4rem;
}

.mgn-rgt64 {
  margin-right: 4rem;
}

.mgn-btm64 {
  margin-bottom: 4rem;
}

.mgn-lft64 {
  margin-left: 4rem;
}

.mgn65 {
  margin: 4.0625rem;
}

.mgn-top65 {
  margin-top: 4.0625rem;
}

.mgn-rgt65 {
  margin-right: 4.0625rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625rem;
}

.mgn-lft65 {
  margin-left: 4.0625rem;
}

.mgn66 {
  margin: 4.125rem;
}

.mgn-top66 {
  margin-top: 4.125rem;
}

.mgn-rgt66 {
  margin-right: 4.125rem;
}

.mgn-btm66 {
  margin-bottom: 4.125rem;
}

.mgn-lft66 {
  margin-left: 4.125rem;
}

.mgn67 {
  margin: 4.1875rem;
}

.mgn-top67 {
  margin-top: 4.1875rem;
}

.mgn-rgt67 {
  margin-right: 4.1875rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875rem;
}

.mgn-lft67 {
  margin-left: 4.1875rem;
}

.mgn68 {
  margin: 4.25rem;
}

.mgn-top68 {
  margin-top: 4.25rem;
}

.mgn-rgt68 {
  margin-right: 4.25rem;
}

.mgn-btm68 {
  margin-bottom: 4.25rem;
}

.mgn-lft68 {
  margin-left: 4.25rem;
}

.mgn69 {
  margin: 4.3125rem;
}

.mgn-top69 {
  margin-top: 4.3125rem;
}

.mgn-rgt69 {
  margin-right: 4.3125rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125rem;
}

.mgn-lft69 {
  margin-left: 4.3125rem;
}

.mgn70 {
  margin: 4.375rem;
}

.mgn-top70 {
  margin-top: 4.375rem;
}

.mgn-rgt70 {
  margin-right: 4.375rem;
}

.mgn-btm70 {
  margin-bottom: 4.375rem;
}

.mgn-lft70 {
  margin-left: 4.375rem;
}

.mgn71 {
  margin: 4.4375rem;
}

.mgn-top71 {
  margin-top: 4.4375rem;
}

.mgn-rgt71 {
  margin-right: 4.4375rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375rem;
}

.mgn-lft71 {
  margin-left: 4.4375rem;
}

.mgn72 {
  margin: 4.5rem;
}

.mgn-top72 {
  margin-top: 4.5rem;
}

.mgn-rgt72 {
  margin-right: 4.5rem;
}

.mgn-btm72 {
  margin-bottom: 4.5rem;
}

.mgn-lft72 {
  margin-left: 4.5rem;
}

.mgn73 {
  margin: 4.5625rem;
}

.mgn-top73 {
  margin-top: 4.5625rem;
}

.mgn-rgt73 {
  margin-right: 4.5625rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625rem;
}

.mgn-lft73 {
  margin-left: 4.5625rem;
}

.mgn74 {
  margin: 4.625rem;
}

.mgn-top74 {
  margin-top: 4.625rem;
}

.mgn-rgt74 {
  margin-right: 4.625rem;
}

.mgn-btm74 {
  margin-bottom: 4.625rem;
}

.mgn-lft74 {
  margin-left: 4.625rem;
}

.mgn75 {
  margin: 4.6875rem;
}

.mgn-top75 {
  margin-top: 4.6875rem;
}

.mgn-rgt75 {
  margin-right: 4.6875rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875rem;
}

.mgn-lft75 {
  margin-left: 4.6875rem;
}

.mgn76 {
  margin: 4.75rem;
}

.mgn-top76 {
  margin-top: 4.75rem;
}

.mgn-rgt76 {
  margin-right: 4.75rem;
}

.mgn-btm76 {
  margin-bottom: 4.75rem;
}

.mgn-lft76 {
  margin-left: 4.75rem;
}

.mgn77 {
  margin: 4.8125rem;
}

.mgn-top77 {
  margin-top: 4.8125rem;
}

.mgn-rgt77 {
  margin-right: 4.8125rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125rem;
}

.mgn-lft77 {
  margin-left: 4.8125rem;
}

.mgn78 {
  margin: 4.875rem;
}

.mgn-top78 {
  margin-top: 4.875rem;
}

.mgn-rgt78 {
  margin-right: 4.875rem;
}

.mgn-btm78 {
  margin-bottom: 4.875rem;
}

.mgn-lft78 {
  margin-left: 4.875rem;
}

.mgn79 {
  margin: 4.9375rem;
}

.mgn-top79 {
  margin-top: 4.9375rem;
}

.mgn-rgt79 {
  margin-right: 4.9375rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375rem;
}

.mgn-lft79 {
  margin-left: 4.9375rem;
}

.mgn80 {
  margin: 5rem;
}

.mgn-top80 {
  margin-top: 5rem;
}

.mgn-rgt80 {
  margin-right: 5rem;
}

.mgn-btm80 {
  margin-bottom: 5rem;
}

.mgn-lft80 {
  margin-left: 5rem;
}

.mgn81 {
  margin: 5.0625rem;
}

.mgn-top81 {
  margin-top: 5.0625rem;
}

.mgn-rgt81 {
  margin-right: 5.0625rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625rem;
}

.mgn-lft81 {
  margin-left: 5.0625rem;
}

.mgn82 {
  margin: 5.125rem;
}

.mgn-top82 {
  margin-top: 5.125rem;
}

.mgn-rgt82 {
  margin-right: 5.125rem;
}

.mgn-btm82 {
  margin-bottom: 5.125rem;
}

.mgn-lft82 {
  margin-left: 5.125rem;
}

.mgn83 {
  margin: 5.1875rem;
}

.mgn-top83 {
  margin-top: 5.1875rem;
}

.mgn-rgt83 {
  margin-right: 5.1875rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875rem;
}

.mgn-lft83 {
  margin-left: 5.1875rem;
}

.mgn84 {
  margin: 5.25rem;
}

.mgn-top84 {
  margin-top: 5.25rem;
}

.mgn-rgt84 {
  margin-right: 5.25rem;
}

.mgn-btm84 {
  margin-bottom: 5.25rem;
}

.mgn-lft84 {
  margin-left: 5.25rem;
}

.mgn85 {
  margin: 5.3125rem;
}

.mgn-top85 {
  margin-top: 5.3125rem;
}

.mgn-rgt85 {
  margin-right: 5.3125rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125rem;
}

.mgn-lft85 {
  margin-left: 5.3125rem;
}

.mgn86 {
  margin: 5.375rem;
}

.mgn-top86 {
  margin-top: 5.375rem;
}

.mgn-rgt86 {
  margin-right: 5.375rem;
}

.mgn-btm86 {
  margin-bottom: 5.375rem;
}

.mgn-lft86 {
  margin-left: 5.375rem;
}

.mgn87 {
  margin: 5.4375rem;
}

.mgn-top87 {
  margin-top: 5.4375rem;
}

.mgn-rgt87 {
  margin-right: 5.4375rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375rem;
}

.mgn-lft87 {
  margin-left: 5.4375rem;
}

.mgn88 {
  margin: 5.5rem;
}

.mgn-top88 {
  margin-top: 5.5rem;
}

.mgn-rgt88 {
  margin-right: 5.5rem;
}

.mgn-btm88 {
  margin-bottom: 5.5rem;
}

.mgn-lft88 {
  margin-left: 5.5rem;
}

.mgn89 {
  margin: 5.5625rem;
}

.mgn-top89 {
  margin-top: 5.5625rem;
}

.mgn-rgt89 {
  margin-right: 5.5625rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625rem;
}

.mgn-lft89 {
  margin-left: 5.5625rem;
}

.mgn90 {
  margin: 5.625rem;
}

.mgn-top90 {
  margin-top: 5.625rem;
}

.mgn-rgt90 {
  margin-right: 5.625rem;
}

.mgn-btm90 {
  margin-bottom: 5.625rem;
}

.mgn-lft90 {
  margin-left: 5.625rem;
}

.mgn91 {
  margin: 5.6875rem;
}

.mgn-top91 {
  margin-top: 5.6875rem;
}

.mgn-rgt91 {
  margin-right: 5.6875rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875rem;
}

.mgn-lft91 {
  margin-left: 5.6875rem;
}

.mgn92 {
  margin: 5.75rem;
}

.mgn-top92 {
  margin-top: 5.75rem;
}

.mgn-rgt92 {
  margin-right: 5.75rem;
}

.mgn-btm92 {
  margin-bottom: 5.75rem;
}

.mgn-lft92 {
  margin-left: 5.75rem;
}

.mgn93 {
  margin: 5.8125rem;
}

.mgn-top93 {
  margin-top: 5.8125rem;
}

.mgn-rgt93 {
  margin-right: 5.8125rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125rem;
}

.mgn-lft93 {
  margin-left: 5.8125rem;
}

.mgn94 {
  margin: 5.875rem;
}

.mgn-top94 {
  margin-top: 5.875rem;
}

.mgn-rgt94 {
  margin-right: 5.875rem;
}

.mgn-btm94 {
  margin-bottom: 5.875rem;
}

.mgn-lft94 {
  margin-left: 5.875rem;
}

.mgn95 {
  margin: 5.9375rem;
}

.mgn-top95 {
  margin-top: 5.9375rem;
}

.mgn-rgt95 {
  margin-right: 5.9375rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375rem;
}

.mgn-lft95 {
  margin-left: 5.9375rem;
}

.mgn96 {
  margin: 6rem;
}

.mgn-top96 {
  margin-top: 6rem;
}

.mgn-rgt96 {
  margin-right: 6rem;
}

.mgn-btm96 {
  margin-bottom: 6rem;
}

.mgn-lft96 {
  margin-left: 6rem;
}

.mgn97 {
  margin: 6.0625rem;
}

.mgn-top97 {
  margin-top: 6.0625rem;
}

.mgn-rgt97 {
  margin-right: 6.0625rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625rem;
}

.mgn-lft97 {
  margin-left: 6.0625rem;
}

.mgn98 {
  margin: 6.125rem;
}

.mgn-top98 {
  margin-top: 6.125rem;
}

.mgn-rgt98 {
  margin-right: 6.125rem;
}

.mgn-btm98 {
  margin-bottom: 6.125rem;
}

.mgn-lft98 {
  margin-left: 6.125rem;
}

.mgn99 {
  margin: 6.1875rem;
}

.mgn-top99 {
  margin-top: 6.1875rem;
}

.mgn-rgt99 {
  margin-right: 6.1875rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875rem;
}

.mgn-lft99 {
  margin-left: 6.1875rem;
}

.mgn100 {
  margin: 6.25rem;
}

.mgn-top100 {
  margin-top: 6.25rem;
}

.mgn-rgt100 {
  margin-right: 6.25rem;
}

.mgn-btm100 {
  margin-bottom: 6.25rem;
}

.mgn-lft100 {
  margin-left: 6.25rem;
}

.mgn101 {
  margin: 6.3125rem;
}

.mgn-top101 {
  margin-top: 6.3125rem;
}

.mgn-rgt101 {
  margin-right: 6.3125rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125rem;
}

.mgn-lft101 {
  margin-left: 6.3125rem;
}

.mgn102 {
  margin: 6.375rem;
}

.mgn-top102 {
  margin-top: 6.375rem;
}

.mgn-rgt102 {
  margin-right: 6.375rem;
}

.mgn-btm102 {
  margin-bottom: 6.375rem;
}

.mgn-lft102 {
  margin-left: 6.375rem;
}

.mgn103 {
  margin: 6.4375rem;
}

.mgn-top103 {
  margin-top: 6.4375rem;
}

.mgn-rgt103 {
  margin-right: 6.4375rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375rem;
}

.mgn-lft103 {
  margin-left: 6.4375rem;
}

.mgn104 {
  margin: 6.5rem;
}

.mgn-top104 {
  margin-top: 6.5rem;
}

.mgn-rgt104 {
  margin-right: 6.5rem;
}

.mgn-btm104 {
  margin-bottom: 6.5rem;
}

.mgn-lft104 {
  margin-left: 6.5rem;
}

.mgn105 {
  margin: 6.5625rem;
}

.mgn-top105 {
  margin-top: 6.5625rem;
}

.mgn-rgt105 {
  margin-right: 6.5625rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625rem;
}

.mgn-lft105 {
  margin-left: 6.5625rem;
}

.mgn106 {
  margin: 6.625rem;
}

.mgn-top106 {
  margin-top: 6.625rem;
}

.mgn-rgt106 {
  margin-right: 6.625rem;
}

.mgn-btm106 {
  margin-bottom: 6.625rem;
}

.mgn-lft106 {
  margin-left: 6.625rem;
}

.mgn107 {
  margin: 6.6875rem;
}

.mgn-top107 {
  margin-top: 6.6875rem;
}

.mgn-rgt107 {
  margin-right: 6.6875rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875rem;
}

.mgn-lft107 {
  margin-left: 6.6875rem;
}

.mgn108 {
  margin: 6.75rem;
}

.mgn-top108 {
  margin-top: 6.75rem;
}

.mgn-rgt108 {
  margin-right: 6.75rem;
}

.mgn-btm108 {
  margin-bottom: 6.75rem;
}

.mgn-lft108 {
  margin-left: 6.75rem;
}

.mgn109 {
  margin: 6.8125rem;
}

.mgn-top109 {
  margin-top: 6.8125rem;
}

.mgn-rgt109 {
  margin-right: 6.8125rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125rem;
}

.mgn-lft109 {
  margin-left: 6.8125rem;
}

.mgn110 {
  margin: 6.875rem;
}

.mgn-top110 {
  margin-top: 6.875rem;
}

.mgn-rgt110 {
  margin-right: 6.875rem;
}

.mgn-btm110 {
  margin-bottom: 6.875rem;
}

.mgn-lft110 {
  margin-left: 6.875rem;
}

.mgn111 {
  margin: 6.9375rem;
}

.mgn-top111 {
  margin-top: 6.9375rem;
}

.mgn-rgt111 {
  margin-right: 6.9375rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375rem;
}

.mgn-lft111 {
  margin-left: 6.9375rem;
}

.mgn112 {
  margin: 7rem;
}

.mgn-top112 {
  margin-top: 7rem;
}

.mgn-rgt112 {
  margin-right: 7rem;
}

.mgn-btm112 {
  margin-bottom: 7rem;
}

.mgn-lft112 {
  margin-left: 7rem;
}

.mgn113 {
  margin: 7.0625rem;
}

.mgn-top113 {
  margin-top: 7.0625rem;
}

.mgn-rgt113 {
  margin-right: 7.0625rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625rem;
}

.mgn-lft113 {
  margin-left: 7.0625rem;
}

.mgn114 {
  margin: 7.125rem;
}

.mgn-top114 {
  margin-top: 7.125rem;
}

.mgn-rgt114 {
  margin-right: 7.125rem;
}

.mgn-btm114 {
  margin-bottom: 7.125rem;
}

.mgn-lft114 {
  margin-left: 7.125rem;
}

.mgn115 {
  margin: 7.1875rem;
}

.mgn-top115 {
  margin-top: 7.1875rem;
}

.mgn-rgt115 {
  margin-right: 7.1875rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875rem;
}

.mgn-lft115 {
  margin-left: 7.1875rem;
}

.mgn116 {
  margin: 7.25rem;
}

.mgn-top116 {
  margin-top: 7.25rem;
}

.mgn-rgt116 {
  margin-right: 7.25rem;
}

.mgn-btm116 {
  margin-bottom: 7.25rem;
}

.mgn-lft116 {
  margin-left: 7.25rem;
}

.mgn117 {
  margin: 7.3125rem;
}

.mgn-top117 {
  margin-top: 7.3125rem;
}

.mgn-rgt117 {
  margin-right: 7.3125rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125rem;
}

.mgn-lft117 {
  margin-left: 7.3125rem;
}

.mgn118 {
  margin: 7.375rem;
}

.mgn-top118 {
  margin-top: 7.375rem;
}

.mgn-rgt118 {
  margin-right: 7.375rem;
}

.mgn-btm118 {
  margin-bottom: 7.375rem;
}

.mgn-lft118 {
  margin-left: 7.375rem;
}

.mgn119 {
  margin: 7.4375rem;
}

.mgn-top119 {
  margin-top: 7.4375rem;
}

.mgn-rgt119 {
  margin-right: 7.4375rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375rem;
}

.mgn-lft119 {
  margin-left: 7.4375rem;
}

.mgn120 {
  margin: 7.5rem;
}

.mgn-top120 {
  margin-top: 7.5rem;
}

.mgn-rgt120 {
  margin-right: 7.5rem;
}

.mgn-btm120 {
  margin-bottom: 7.5rem;
}

.mgn-lft120 {
  margin-left: 7.5rem;
}

.mgn121 {
  margin: 7.5625rem;
}

.mgn-top121 {
  margin-top: 7.5625rem;
}

.mgn-rgt121 {
  margin-right: 7.5625rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625rem;
}

.mgn-lft121 {
  margin-left: 7.5625rem;
}

.mgn122 {
  margin: 7.625rem;
}

.mgn-top122 {
  margin-top: 7.625rem;
}

.mgn-rgt122 {
  margin-right: 7.625rem;
}

.mgn-btm122 {
  margin-bottom: 7.625rem;
}

.mgn-lft122 {
  margin-left: 7.625rem;
}

.mgn123 {
  margin: 7.6875rem;
}

.mgn-top123 {
  margin-top: 7.6875rem;
}

.mgn-rgt123 {
  margin-right: 7.6875rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875rem;
}

.mgn-lft123 {
  margin-left: 7.6875rem;
}

.mgn124 {
  margin: 7.75rem;
}

.mgn-top124 {
  margin-top: 7.75rem;
}

.mgn-rgt124 {
  margin-right: 7.75rem;
}

.mgn-btm124 {
  margin-bottom: 7.75rem;
}

.mgn-lft124 {
  margin-left: 7.75rem;
}

.mgn125 {
  margin: 7.8125rem;
}

.mgn-top125 {
  margin-top: 7.8125rem;
}

.mgn-rgt125 {
  margin-right: 7.8125rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125rem;
}

.mgn-lft125 {
  margin-left: 7.8125rem;
}

.mgn126 {
  margin: 7.875rem;
}

.mgn-top126 {
  margin-top: 7.875rem;
}

.mgn-rgt126 {
  margin-right: 7.875rem;
}

.mgn-btm126 {
  margin-bottom: 7.875rem;
}

.mgn-lft126 {
  margin-left: 7.875rem;
}

.mgn127 {
  margin: 7.9375rem;
}

.mgn-top127 {
  margin-top: 7.9375rem;
}

.mgn-rgt127 {
  margin-right: 7.9375rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375rem;
}

.mgn-lft127 {
  margin-left: 7.9375rem;
}

.mgn128 {
  margin: 8rem;
}

.mgn-top128 {
  margin-top: 8rem;
}

.mgn-rgt128 {
  margin-right: 8rem;
}

.mgn-btm128 {
  margin-bottom: 8rem;
}

.mgn-lft128 {
  margin-left: 8rem;
}

.mgn129 {
  margin: 8.0625rem;
}

.mgn-top129 {
  margin-top: 8.0625rem;
}

.mgn-rgt129 {
  margin-right: 8.0625rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625rem;
}

.mgn-lft129 {
  margin-left: 8.0625rem;
}

.mgn130 {
  margin: 8.125rem;
}

.mgn-top130 {
  margin-top: 8.125rem;
}

.mgn-rgt130 {
  margin-right: 8.125rem;
}

.mgn-btm130 {
  margin-bottom: 8.125rem;
}

.mgn-lft130 {
  margin-left: 8.125rem;
}

.mgn131 {
  margin: 8.1875rem;
}

.mgn-top131 {
  margin-top: 8.1875rem;
}

.mgn-rgt131 {
  margin-right: 8.1875rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875rem;
}

.mgn-lft131 {
  margin-left: 8.1875rem;
}

.mgn132 {
  margin: 8.25rem;
}

.mgn-top132 {
  margin-top: 8.25rem;
}

.mgn-rgt132 {
  margin-right: 8.25rem;
}

.mgn-btm132 {
  margin-bottom: 8.25rem;
}

.mgn-lft132 {
  margin-left: 8.25rem;
}

.mgn133 {
  margin: 8.3125rem;
}

.mgn-top133 {
  margin-top: 8.3125rem;
}

.mgn-rgt133 {
  margin-right: 8.3125rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125rem;
}

.mgn-lft133 {
  margin-left: 8.3125rem;
}

.mgn134 {
  margin: 8.375rem;
}

.mgn-top134 {
  margin-top: 8.375rem;
}

.mgn-rgt134 {
  margin-right: 8.375rem;
}

.mgn-btm134 {
  margin-bottom: 8.375rem;
}

.mgn-lft134 {
  margin-left: 8.375rem;
}

.mgn135 {
  margin: 8.4375rem;
}

.mgn-top135 {
  margin-top: 8.4375rem;
}

.mgn-rgt135 {
  margin-right: 8.4375rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375rem;
}

.mgn-lft135 {
  margin-left: 8.4375rem;
}

.mgn136 {
  margin: 8.5rem;
}

.mgn-top136 {
  margin-top: 8.5rem;
}

.mgn-rgt136 {
  margin-right: 8.5rem;
}

.mgn-btm136 {
  margin-bottom: 8.5rem;
}

.mgn-lft136 {
  margin-left: 8.5rem;
}

.mgn137 {
  margin: 8.5625rem;
}

.mgn-top137 {
  margin-top: 8.5625rem;
}

.mgn-rgt137 {
  margin-right: 8.5625rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625rem;
}

.mgn-lft137 {
  margin-left: 8.5625rem;
}

.mgn138 {
  margin: 8.625rem;
}

.mgn-top138 {
  margin-top: 8.625rem;
}

.mgn-rgt138 {
  margin-right: 8.625rem;
}

.mgn-btm138 {
  margin-bottom: 8.625rem;
}

.mgn-lft138 {
  margin-left: 8.625rem;
}

.mgn139 {
  margin: 8.6875rem;
}

.mgn-top139 {
  margin-top: 8.6875rem;
}

.mgn-rgt139 {
  margin-right: 8.6875rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875rem;
}

.mgn-lft139 {
  margin-left: 8.6875rem;
}

.mgn140 {
  margin: 8.75rem;
}

.mgn-top140 {
  margin-top: 8.75rem;
}

.mgn-rgt140 {
  margin-right: 8.75rem;
}

.mgn-btm140 {
  margin-bottom: 8.75rem;
}

.mgn-lft140 {
  margin-left: 8.75rem;
}

.mgn141 {
  margin: 8.8125rem;
}

.mgn-top141 {
  margin-top: 8.8125rem;
}

.mgn-rgt141 {
  margin-right: 8.8125rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125rem;
}

.mgn-lft141 {
  margin-left: 8.8125rem;
}

.mgn142 {
  margin: 8.875rem;
}

.mgn-top142 {
  margin-top: 8.875rem;
}

.mgn-rgt142 {
  margin-right: 8.875rem;
}

.mgn-btm142 {
  margin-bottom: 8.875rem;
}

.mgn-lft142 {
  margin-left: 8.875rem;
}

.mgn143 {
  margin: 8.9375rem;
}

.mgn-top143 {
  margin-top: 8.9375rem;
}

.mgn-rgt143 {
  margin-right: 8.9375rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375rem;
}

.mgn-lft143 {
  margin-left: 8.9375rem;
}

.mgn144 {
  margin: 9rem;
}

.mgn-top144 {
  margin-top: 9rem;
}

.mgn-rgt144 {
  margin-right: 9rem;
}

.mgn-btm144 {
  margin-bottom: 9rem;
}

.mgn-lft144 {
  margin-left: 9rem;
}

.mgn145 {
  margin: 9.0625rem;
}

.mgn-top145 {
  margin-top: 9.0625rem;
}

.mgn-rgt145 {
  margin-right: 9.0625rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625rem;
}

.mgn-lft145 {
  margin-left: 9.0625rem;
}

.mgn146 {
  margin: 9.125rem;
}

.mgn-top146 {
  margin-top: 9.125rem;
}

.mgn-rgt146 {
  margin-right: 9.125rem;
}

.mgn-btm146 {
  margin-bottom: 9.125rem;
}

.mgn-lft146 {
  margin-left: 9.125rem;
}

.mgn147 {
  margin: 9.1875rem;
}

.mgn-top147 {
  margin-top: 9.1875rem;
}

.mgn-rgt147 {
  margin-right: 9.1875rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875rem;
}

.mgn-lft147 {
  margin-left: 9.1875rem;
}

.mgn148 {
  margin: 9.25rem;
}

.mgn-top148 {
  margin-top: 9.25rem;
}

.mgn-rgt148 {
  margin-right: 9.25rem;
}

.mgn-btm148 {
  margin-bottom: 9.25rem;
}

.mgn-lft148 {
  margin-left: 9.25rem;
}

.mgn149 {
  margin: 9.3125rem;
}

.mgn-top149 {
  margin-top: 9.3125rem;
}

.mgn-rgt149 {
  margin-right: 9.3125rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125rem;
}

.mgn-lft149 {
  margin-left: 9.3125rem;
}

.mgn150 {
  margin: 9.375rem;
}

.mgn-top150 {
  margin-top: 9.375rem;
}

.mgn-rgt150 {
  margin-right: 9.375rem;
}

.mgn-btm150 {
  margin-bottom: 9.375rem;
}

.mgn-lft150 {
  margin-left: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .mgn0 {
    margin: 0rem;
  }
  .mgn-top0 {
    margin-top: 0rem;
  }
  .mgn-rgt0 {
    margin-right: 0rem;
  }
  .mgn-btm0 {
    margin-bottom: 0rem;
  }
  .mgn-lft0 {
    margin-left: 0rem;
  }
  .mgn1 {
    margin: 0.0357142857rem;
  }
  .mgn-top1 {
    margin-top: 0.0357142857rem;
  }
  .mgn-rgt1 {
    margin-right: 0.0357142857rem;
  }
  .mgn-btm1 {
    margin-bottom: 0.0357142857rem;
  }
  .mgn-lft1 {
    margin-left: 0.0357142857rem;
  }
  .mgn2 {
    margin: 0.0714285714rem;
  }
  .mgn-top2 {
    margin-top: 0.0714285714rem;
  }
  .mgn-rgt2 {
    margin-right: 0.0714285714rem;
  }
  .mgn-btm2 {
    margin-bottom: 0.0714285714rem;
  }
  .mgn-lft2 {
    margin-left: 0.0714285714rem;
  }
  .mgn3 {
    margin: 0.1071428571rem;
  }
  .mgn-top3 {
    margin-top: 0.1071428571rem;
  }
  .mgn-rgt3 {
    margin-right: 0.1071428571rem;
  }
  .mgn-btm3 {
    margin-bottom: 0.1071428571rem;
  }
  .mgn-lft3 {
    margin-left: 0.1071428571rem;
  }
  .mgn4 {
    margin: 0.1428571429rem;
  }
  .mgn-top4 {
    margin-top: 0.1428571429rem;
  }
  .mgn-rgt4 {
    margin-right: 0.1428571429rem;
  }
  .mgn-btm4 {
    margin-bottom: 0.1428571429rem;
  }
  .mgn-lft4 {
    margin-left: 0.1428571429rem;
  }
  .mgn5 {
    margin: 0.1785714286rem;
  }
  .mgn-top5 {
    margin-top: 0.1785714286rem;
  }
  .mgn-rgt5 {
    margin-right: 0.1785714286rem;
  }
  .mgn-btm5 {
    margin-bottom: 0.1785714286rem;
  }
  .mgn-lft5 {
    margin-left: 0.1785714286rem;
  }
  .mgn6 {
    margin: 0.2142857143rem;
  }
  .mgn-top6 {
    margin-top: 0.2142857143rem;
  }
  .mgn-rgt6 {
    margin-right: 0.2142857143rem;
  }
  .mgn-btm6 {
    margin-bottom: 0.2142857143rem;
  }
  .mgn-lft6 {
    margin-left: 0.2142857143rem;
  }
  .mgn7 {
    margin: 0.25rem;
  }
  .mgn-top7 {
    margin-top: 0.25rem;
  }
  .mgn-rgt7 {
    margin-right: 0.25rem;
  }
  .mgn-btm7 {
    margin-bottom: 0.25rem;
  }
  .mgn-lft7 {
    margin-left: 0.25rem;
  }
  .mgn8 {
    margin: 0.2857142857rem;
  }
  .mgn-top8 {
    margin-top: 0.2857142857rem;
  }
  .mgn-rgt8 {
    margin-right: 0.2857142857rem;
  }
  .mgn-btm8 {
    margin-bottom: 0.2857142857rem;
  }
  .mgn-lft8 {
    margin-left: 0.2857142857rem;
  }
  .mgn9 {
    margin: 0.3214285714rem;
  }
  .mgn-top9 {
    margin-top: 0.3214285714rem;
  }
  .mgn-rgt9 {
    margin-right: 0.3214285714rem;
  }
  .mgn-btm9 {
    margin-bottom: 0.3214285714rem;
  }
  .mgn-lft9 {
    margin-left: 0.3214285714rem;
  }
  .mgn10 {
    margin: 0.3571428571rem;
  }
  .mgn-top10 {
    margin-top: 0.3571428571rem;
  }
  .mgn-rgt10 {
    margin-right: 0.3571428571rem;
  }
  .mgn-btm10 {
    margin-bottom: 0.3571428571rem;
  }
  .mgn-lft10 {
    margin-left: 0.3571428571rem;
  }
  .mgn11 {
    margin: 0.3928571429rem;
  }
  .mgn-top11 {
    margin-top: 0.3928571429rem;
  }
  .mgn-rgt11 {
    margin-right: 0.3928571429rem;
  }
  .mgn-btm11 {
    margin-bottom: 0.3928571429rem;
  }
  .mgn-lft11 {
    margin-left: 0.3928571429rem;
  }
  .mgn12 {
    margin: 0.4285714286rem;
  }
  .mgn-top12 {
    margin-top: 0.4285714286rem;
  }
  .mgn-rgt12 {
    margin-right: 0.4285714286rem;
  }
  .mgn-btm12 {
    margin-bottom: 0.4285714286rem;
  }
  .mgn-lft12 {
    margin-left: 0.4285714286rem;
  }
  .mgn13 {
    margin: 0.4642857143rem;
  }
  .mgn-top13 {
    margin-top: 0.4642857143rem;
  }
  .mgn-rgt13 {
    margin-right: 0.4642857143rem;
  }
  .mgn-btm13 {
    margin-bottom: 0.4642857143rem;
  }
  .mgn-lft13 {
    margin-left: 0.4642857143rem;
  }
  .mgn14 {
    margin: 0.5rem;
  }
  .mgn-top14 {
    margin-top: 0.5rem;
  }
  .mgn-rgt14 {
    margin-right: 0.5rem;
  }
  .mgn-btm14 {
    margin-bottom: 0.5rem;
  }
  .mgn-lft14 {
    margin-left: 0.5rem;
  }
  .mgn15 {
    margin: 0.5357142857rem;
  }
  .mgn-top15 {
    margin-top: 0.5357142857rem;
  }
  .mgn-rgt15 {
    margin-right: 0.5357142857rem;
  }
  .mgn-btm15 {
    margin-bottom: 0.5357142857rem;
  }
  .mgn-lft15 {
    margin-left: 0.5357142857rem;
  }
  .mgn16 {
    margin: 0.5714285714rem;
  }
  .mgn-top16 {
    margin-top: 0.5714285714rem;
  }
  .mgn-rgt16 {
    margin-right: 0.5714285714rem;
  }
  .mgn-btm16 {
    margin-bottom: 0.5714285714rem;
  }
  .mgn-lft16 {
    margin-left: 0.5714285714rem;
  }
  .mgn17 {
    margin: 0.6071428571rem;
  }
  .mgn-top17 {
    margin-top: 0.6071428571rem;
  }
  .mgn-rgt17 {
    margin-right: 0.6071428571rem;
  }
  .mgn-btm17 {
    margin-bottom: 0.6071428571rem;
  }
  .mgn-lft17 {
    margin-left: 0.6071428571rem;
  }
  .mgn18 {
    margin: 0.6428571429rem;
  }
  .mgn-top18 {
    margin-top: 0.6428571429rem;
  }
  .mgn-rgt18 {
    margin-right: 0.6428571429rem;
  }
  .mgn-btm18 {
    margin-bottom: 0.6428571429rem;
  }
  .mgn-lft18 {
    margin-left: 0.6428571429rem;
  }
  .mgn19 {
    margin: 0.6785714286rem;
  }
  .mgn-top19 {
    margin-top: 0.6785714286rem;
  }
  .mgn-rgt19 {
    margin-right: 0.6785714286rem;
  }
  .mgn-btm19 {
    margin-bottom: 0.6785714286rem;
  }
  .mgn-lft19 {
    margin-left: 0.6785714286rem;
  }
  .mgn20 {
    margin: 0.7142857143rem;
  }
  .mgn-top20 {
    margin-top: 0.7142857143rem;
  }
  .mgn-rgt20 {
    margin-right: 0.7142857143rem;
  }
  .mgn-btm20 {
    margin-bottom: 0.7142857143rem;
  }
  .mgn-lft20 {
    margin-left: 0.7142857143rem;
  }
  .mgn21 {
    margin: 0.75rem;
  }
  .mgn-top21 {
    margin-top: 0.75rem;
  }
  .mgn-rgt21 {
    margin-right: 0.75rem;
  }
  .mgn-btm21 {
    margin-bottom: 0.75rem;
  }
  .mgn-lft21 {
    margin-left: 0.75rem;
  }
  .mgn22 {
    margin: 0.7857142857rem;
  }
  .mgn-top22 {
    margin-top: 0.7857142857rem;
  }
  .mgn-rgt22 {
    margin-right: 0.7857142857rem;
  }
  .mgn-btm22 {
    margin-bottom: 0.7857142857rem;
  }
  .mgn-lft22 {
    margin-left: 0.7857142857rem;
  }
  .mgn23 {
    margin: 0.8214285714rem;
  }
  .mgn-top23 {
    margin-top: 0.8214285714rem;
  }
  .mgn-rgt23 {
    margin-right: 0.8214285714rem;
  }
  .mgn-btm23 {
    margin-bottom: 0.8214285714rem;
  }
  .mgn-lft23 {
    margin-left: 0.8214285714rem;
  }
  .mgn24 {
    margin: 0.8571428571rem;
  }
  .mgn-top24 {
    margin-top: 0.8571428571rem;
  }
  .mgn-rgt24 {
    margin-right: 0.8571428571rem;
  }
  .mgn-btm24 {
    margin-bottom: 0.8571428571rem;
  }
  .mgn-lft24 {
    margin-left: 0.8571428571rem;
  }
  .mgn25 {
    margin: 0.8928571429rem;
  }
  .mgn-top25 {
    margin-top: 0.8928571429rem;
  }
  .mgn-rgt25 {
    margin-right: 0.8928571429rem;
  }
  .mgn-btm25 {
    margin-bottom: 0.8928571429rem;
  }
  .mgn-lft25 {
    margin-left: 0.8928571429rem;
  }
  .mgn26 {
    margin: 0.9285714286rem;
  }
  .mgn-top26 {
    margin-top: 0.9285714286rem;
  }
  .mgn-rgt26 {
    margin-right: 0.9285714286rem;
  }
  .mgn-btm26 {
    margin-bottom: 0.9285714286rem;
  }
  .mgn-lft26 {
    margin-left: 0.9285714286rem;
  }
  .mgn27 {
    margin: 0.9642857143rem;
  }
  .mgn-top27 {
    margin-top: 0.9642857143rem;
  }
  .mgn-rgt27 {
    margin-right: 0.9642857143rem;
  }
  .mgn-btm27 {
    margin-bottom: 0.9642857143rem;
  }
  .mgn-lft27 {
    margin-left: 0.9642857143rem;
  }
  .mgn28 {
    margin: 1rem;
  }
  .mgn-top28 {
    margin-top: 1rem;
  }
  .mgn-rgt28 {
    margin-right: 1rem;
  }
  .mgn-btm28 {
    margin-bottom: 1rem;
  }
  .mgn-lft28 {
    margin-left: 1rem;
  }
  .mgn29 {
    margin: 1.0357142857rem;
  }
  .mgn-top29 {
    margin-top: 1.0357142857rem;
  }
  .mgn-rgt29 {
    margin-right: 1.0357142857rem;
  }
  .mgn-btm29 {
    margin-bottom: 1.0357142857rem;
  }
  .mgn-lft29 {
    margin-left: 1.0357142857rem;
  }
  .mgn30 {
    margin: 1.0714285714rem;
  }
  .mgn-top30 {
    margin-top: 1.0714285714rem;
  }
  .mgn-rgt30 {
    margin-right: 1.0714285714rem;
  }
  .mgn-btm30 {
    margin-bottom: 1.0714285714rem;
  }
  .mgn-lft30 {
    margin-left: 1.0714285714rem;
  }
  .mgn31 {
    margin: 1.1071428571rem;
  }
  .mgn-top31 {
    margin-top: 1.1071428571rem;
  }
  .mgn-rgt31 {
    margin-right: 1.1071428571rem;
  }
  .mgn-btm31 {
    margin-bottom: 1.1071428571rem;
  }
  .mgn-lft31 {
    margin-left: 1.1071428571rem;
  }
  .mgn32 {
    margin: 1.1428571429rem;
  }
  .mgn-top32 {
    margin-top: 1.1428571429rem;
  }
  .mgn-rgt32 {
    margin-right: 1.1428571429rem;
  }
  .mgn-btm32 {
    margin-bottom: 1.1428571429rem;
  }
  .mgn-lft32 {
    margin-left: 1.1428571429rem;
  }
  .mgn33 {
    margin: 1.1785714286rem;
  }
  .mgn-top33 {
    margin-top: 1.1785714286rem;
  }
  .mgn-rgt33 {
    margin-right: 1.1785714286rem;
  }
  .mgn-btm33 {
    margin-bottom: 1.1785714286rem;
  }
  .mgn-lft33 {
    margin-left: 1.1785714286rem;
  }
  .mgn34 {
    margin: 1.2142857143rem;
  }
  .mgn-top34 {
    margin-top: 1.2142857143rem;
  }
  .mgn-rgt34 {
    margin-right: 1.2142857143rem;
  }
  .mgn-btm34 {
    margin-bottom: 1.2142857143rem;
  }
  .mgn-lft34 {
    margin-left: 1.2142857143rem;
  }
  .mgn35 {
    margin: 1.25rem;
  }
  .mgn-top35 {
    margin-top: 1.25rem;
  }
  .mgn-rgt35 {
    margin-right: 1.25rem;
  }
  .mgn-btm35 {
    margin-bottom: 1.25rem;
  }
  .mgn-lft35 {
    margin-left: 1.25rem;
  }
  .mgn36 {
    margin: 1.2857142857rem;
  }
  .mgn-top36 {
    margin-top: 1.2857142857rem;
  }
  .mgn-rgt36 {
    margin-right: 1.2857142857rem;
  }
  .mgn-btm36 {
    margin-bottom: 1.2857142857rem;
  }
  .mgn-lft36 {
    margin-left: 1.2857142857rem;
  }
  .mgn37 {
    margin: 1.3214285714rem;
  }
  .mgn-top37 {
    margin-top: 1.3214285714rem;
  }
  .mgn-rgt37 {
    margin-right: 1.3214285714rem;
  }
  .mgn-btm37 {
    margin-bottom: 1.3214285714rem;
  }
  .mgn-lft37 {
    margin-left: 1.3214285714rem;
  }
  .mgn38 {
    margin: 1.3571428571rem;
  }
  .mgn-top38 {
    margin-top: 1.3571428571rem;
  }
  .mgn-rgt38 {
    margin-right: 1.3571428571rem;
  }
  .mgn-btm38 {
    margin-bottom: 1.3571428571rem;
  }
  .mgn-lft38 {
    margin-left: 1.3571428571rem;
  }
  .mgn39 {
    margin: 1.3928571429rem;
  }
  .mgn-top39 {
    margin-top: 1.3928571429rem;
  }
  .mgn-rgt39 {
    margin-right: 1.3928571429rem;
  }
  .mgn-btm39 {
    margin-bottom: 1.3928571429rem;
  }
  .mgn-lft39 {
    margin-left: 1.3928571429rem;
  }
  .mgn40 {
    margin: 1.4285714286rem;
  }
  .mgn-top40 {
    margin-top: 1.4285714286rem;
  }
  .mgn-rgt40 {
    margin-right: 1.4285714286rem;
  }
  .mgn-btm40 {
    margin-bottom: 1.4285714286rem;
  }
  .mgn-lft40 {
    margin-left: 1.4285714286rem;
  }
  .mgn41 {
    margin: 1.4642857143rem;
  }
  .mgn-top41 {
    margin-top: 1.4642857143rem;
  }
  .mgn-rgt41 {
    margin-right: 1.4642857143rem;
  }
  .mgn-btm41 {
    margin-bottom: 1.4642857143rem;
  }
  .mgn-lft41 {
    margin-left: 1.4642857143rem;
  }
  .mgn42 {
    margin: 1.5rem;
  }
  .mgn-top42 {
    margin-top: 1.5rem;
  }
  .mgn-rgt42 {
    margin-right: 1.5rem;
  }
  .mgn-btm42 {
    margin-bottom: 1.5rem;
  }
  .mgn-lft42 {
    margin-left: 1.5rem;
  }
  .mgn43 {
    margin: 1.5357142857rem;
  }
  .mgn-top43 {
    margin-top: 1.5357142857rem;
  }
  .mgn-rgt43 {
    margin-right: 1.5357142857rem;
  }
  .mgn-btm43 {
    margin-bottom: 1.5357142857rem;
  }
  .mgn-lft43 {
    margin-left: 1.5357142857rem;
  }
  .mgn44 {
    margin: 1.5714285714rem;
  }
  .mgn-top44 {
    margin-top: 1.5714285714rem;
  }
  .mgn-rgt44 {
    margin-right: 1.5714285714rem;
  }
  .mgn-btm44 {
    margin-bottom: 1.5714285714rem;
  }
  .mgn-lft44 {
    margin-left: 1.5714285714rem;
  }
  .mgn45 {
    margin: 1.6071428571rem;
  }
  .mgn-top45 {
    margin-top: 1.6071428571rem;
  }
  .mgn-rgt45 {
    margin-right: 1.6071428571rem;
  }
  .mgn-btm45 {
    margin-bottom: 1.6071428571rem;
  }
  .mgn-lft45 {
    margin-left: 1.6071428571rem;
  }
  .mgn46 {
    margin: 1.6428571429rem;
  }
  .mgn-top46 {
    margin-top: 1.6428571429rem;
  }
  .mgn-rgt46 {
    margin-right: 1.6428571429rem;
  }
  .mgn-btm46 {
    margin-bottom: 1.6428571429rem;
  }
  .mgn-lft46 {
    margin-left: 1.6428571429rem;
  }
  .mgn47 {
    margin: 1.6785714286rem;
  }
  .mgn-top47 {
    margin-top: 1.6785714286rem;
  }
  .mgn-rgt47 {
    margin-right: 1.6785714286rem;
  }
  .mgn-btm47 {
    margin-bottom: 1.6785714286rem;
  }
  .mgn-lft47 {
    margin-left: 1.6785714286rem;
  }
  .mgn48 {
    margin: 1.7142857143rem;
  }
  .mgn-top48 {
    margin-top: 1.7142857143rem;
  }
  .mgn-rgt48 {
    margin-right: 1.7142857143rem;
  }
  .mgn-btm48 {
    margin-bottom: 1.7142857143rem;
  }
  .mgn-lft48 {
    margin-left: 1.7142857143rem;
  }
  .mgn49 {
    margin: 1.75rem;
  }
  .mgn-top49 {
    margin-top: 1.75rem;
  }
  .mgn-rgt49 {
    margin-right: 1.75rem;
  }
  .mgn-btm49 {
    margin-bottom: 1.75rem;
  }
  .mgn-lft49 {
    margin-left: 1.75rem;
  }
  .mgn50 {
    margin: 1.7857142857rem;
  }
  .mgn-top50 {
    margin-top: 1.7857142857rem;
  }
  .mgn-rgt50 {
    margin-right: 1.7857142857rem;
  }
  .mgn-btm50 {
    margin-bottom: 1.7857142857rem;
  }
  .mgn-lft50 {
    margin-left: 1.7857142857rem;
  }
  .mgn51 {
    margin: 1.8214285714rem;
  }
  .mgn-top51 {
    margin-top: 1.8214285714rem;
  }
  .mgn-rgt51 {
    margin-right: 1.8214285714rem;
  }
  .mgn-btm51 {
    margin-bottom: 1.8214285714rem;
  }
  .mgn-lft51 {
    margin-left: 1.8214285714rem;
  }
  .mgn52 {
    margin: 1.8571428571rem;
  }
  .mgn-top52 {
    margin-top: 1.8571428571rem;
  }
  .mgn-rgt52 {
    margin-right: 1.8571428571rem;
  }
  .mgn-btm52 {
    margin-bottom: 1.8571428571rem;
  }
  .mgn-lft52 {
    margin-left: 1.8571428571rem;
  }
  .mgn53 {
    margin: 1.8928571429rem;
  }
  .mgn-top53 {
    margin-top: 1.8928571429rem;
  }
  .mgn-rgt53 {
    margin-right: 1.8928571429rem;
  }
  .mgn-btm53 {
    margin-bottom: 1.8928571429rem;
  }
  .mgn-lft53 {
    margin-left: 1.8928571429rem;
  }
  .mgn54 {
    margin: 1.9285714286rem;
  }
  .mgn-top54 {
    margin-top: 1.9285714286rem;
  }
  .mgn-rgt54 {
    margin-right: 1.9285714286rem;
  }
  .mgn-btm54 {
    margin-bottom: 1.9285714286rem;
  }
  .mgn-lft54 {
    margin-left: 1.9285714286rem;
  }
  .mgn55 {
    margin: 1.9642857143rem;
  }
  .mgn-top55 {
    margin-top: 1.9642857143rem;
  }
  .mgn-rgt55 {
    margin-right: 1.9642857143rem;
  }
  .mgn-btm55 {
    margin-bottom: 1.9642857143rem;
  }
  .mgn-lft55 {
    margin-left: 1.9642857143rem;
  }
  .mgn56 {
    margin: 2rem;
  }
  .mgn-top56 {
    margin-top: 2rem;
  }
  .mgn-rgt56 {
    margin-right: 2rem;
  }
  .mgn-btm56 {
    margin-bottom: 2rem;
  }
  .mgn-lft56 {
    margin-left: 2rem;
  }
  .mgn57 {
    margin: 2.0357142857rem;
  }
  .mgn-top57 {
    margin-top: 2.0357142857rem;
  }
  .mgn-rgt57 {
    margin-right: 2.0357142857rem;
  }
  .mgn-btm57 {
    margin-bottom: 2.0357142857rem;
  }
  .mgn-lft57 {
    margin-left: 2.0357142857rem;
  }
  .mgn58 {
    margin: 2.0714285714rem;
  }
  .mgn-top58 {
    margin-top: 2.0714285714rem;
  }
  .mgn-rgt58 {
    margin-right: 2.0714285714rem;
  }
  .mgn-btm58 {
    margin-bottom: 2.0714285714rem;
  }
  .mgn-lft58 {
    margin-left: 2.0714285714rem;
  }
  .mgn59 {
    margin: 2.1071428571rem;
  }
  .mgn-top59 {
    margin-top: 2.1071428571rem;
  }
  .mgn-rgt59 {
    margin-right: 2.1071428571rem;
  }
  .mgn-btm59 {
    margin-bottom: 2.1071428571rem;
  }
  .mgn-lft59 {
    margin-left: 2.1071428571rem;
  }
  .mgn60 {
    margin: 2.1428571429rem;
  }
  .mgn-top60 {
    margin-top: 2.1428571429rem;
  }
  .mgn-rgt60 {
    margin-right: 2.1428571429rem;
  }
  .mgn-btm60 {
    margin-bottom: 2.1428571429rem;
  }
  .mgn-lft60 {
    margin-left: 2.1428571429rem;
  }
  .mgn61 {
    margin: 2.1785714286rem;
  }
  .mgn-top61 {
    margin-top: 2.1785714286rem;
  }
  .mgn-rgt61 {
    margin-right: 2.1785714286rem;
  }
  .mgn-btm61 {
    margin-bottom: 2.1785714286rem;
  }
  .mgn-lft61 {
    margin-left: 2.1785714286rem;
  }
  .mgn62 {
    margin: 2.2142857143rem;
  }
  .mgn-top62 {
    margin-top: 2.2142857143rem;
  }
  .mgn-rgt62 {
    margin-right: 2.2142857143rem;
  }
  .mgn-btm62 {
    margin-bottom: 2.2142857143rem;
  }
  .mgn-lft62 {
    margin-left: 2.2142857143rem;
  }
  .mgn63 {
    margin: 2.25rem;
  }
  .mgn-top63 {
    margin-top: 2.25rem;
  }
  .mgn-rgt63 {
    margin-right: 2.25rem;
  }
  .mgn-btm63 {
    margin-bottom: 2.25rem;
  }
  .mgn-lft63 {
    margin-left: 2.25rem;
  }
  .mgn64 {
    margin: 2.2857142857rem;
  }
  .mgn-top64 {
    margin-top: 2.2857142857rem;
  }
  .mgn-rgt64 {
    margin-right: 2.2857142857rem;
  }
  .mgn-btm64 {
    margin-bottom: 2.2857142857rem;
  }
  .mgn-lft64 {
    margin-left: 2.2857142857rem;
  }
  .mgn65 {
    margin: 2.3214285714rem;
  }
  .mgn-top65 {
    margin-top: 2.3214285714rem;
  }
  .mgn-rgt65 {
    margin-right: 2.3214285714rem;
  }
  .mgn-btm65 {
    margin-bottom: 2.3214285714rem;
  }
  .mgn-lft65 {
    margin-left: 2.3214285714rem;
  }
  .mgn66 {
    margin: 2.3571428571rem;
  }
  .mgn-top66 {
    margin-top: 2.3571428571rem;
  }
  .mgn-rgt66 {
    margin-right: 2.3571428571rem;
  }
  .mgn-btm66 {
    margin-bottom: 2.3571428571rem;
  }
  .mgn-lft66 {
    margin-left: 2.3571428571rem;
  }
  .mgn67 {
    margin: 2.3928571429rem;
  }
  .mgn-top67 {
    margin-top: 2.3928571429rem;
  }
  .mgn-rgt67 {
    margin-right: 2.3928571429rem;
  }
  .mgn-btm67 {
    margin-bottom: 2.3928571429rem;
  }
  .mgn-lft67 {
    margin-left: 2.3928571429rem;
  }
  .mgn68 {
    margin: 2.4285714286rem;
  }
  .mgn-top68 {
    margin-top: 2.4285714286rem;
  }
  .mgn-rgt68 {
    margin-right: 2.4285714286rem;
  }
  .mgn-btm68 {
    margin-bottom: 2.4285714286rem;
  }
  .mgn-lft68 {
    margin-left: 2.4285714286rem;
  }
  .mgn69 {
    margin: 2.4642857143rem;
  }
  .mgn-top69 {
    margin-top: 2.4642857143rem;
  }
  .mgn-rgt69 {
    margin-right: 2.4642857143rem;
  }
  .mgn-btm69 {
    margin-bottom: 2.4642857143rem;
  }
  .mgn-lft69 {
    margin-left: 2.4642857143rem;
  }
  .mgn70 {
    margin: 2.5rem;
  }
  .mgn-top70 {
    margin-top: 2.5rem;
  }
  .mgn-rgt70 {
    margin-right: 2.5rem;
  }
  .mgn-btm70 {
    margin-bottom: 2.5rem;
  }
  .mgn-lft70 {
    margin-left: 2.5rem;
  }
  .mgn71 {
    margin: 2.5357142857rem;
  }
  .mgn-top71 {
    margin-top: 2.5357142857rem;
  }
  .mgn-rgt71 {
    margin-right: 2.5357142857rem;
  }
  .mgn-btm71 {
    margin-bottom: 2.5357142857rem;
  }
  .mgn-lft71 {
    margin-left: 2.5357142857rem;
  }
  .mgn72 {
    margin: 2.5714285714rem;
  }
  .mgn-top72 {
    margin-top: 2.5714285714rem;
  }
  .mgn-rgt72 {
    margin-right: 2.5714285714rem;
  }
  .mgn-btm72 {
    margin-bottom: 2.5714285714rem;
  }
  .mgn-lft72 {
    margin-left: 2.5714285714rem;
  }
  .mgn73 {
    margin: 2.6071428571rem;
  }
  .mgn-top73 {
    margin-top: 2.6071428571rem;
  }
  .mgn-rgt73 {
    margin-right: 2.6071428571rem;
  }
  .mgn-btm73 {
    margin-bottom: 2.6071428571rem;
  }
  .mgn-lft73 {
    margin-left: 2.6071428571rem;
  }
  .mgn74 {
    margin: 2.6428571429rem;
  }
  .mgn-top74 {
    margin-top: 2.6428571429rem;
  }
  .mgn-rgt74 {
    margin-right: 2.6428571429rem;
  }
  .mgn-btm74 {
    margin-bottom: 2.6428571429rem;
  }
  .mgn-lft74 {
    margin-left: 2.6428571429rem;
  }
  .mgn75 {
    margin: 2.6785714286rem;
  }
  .mgn-top75 {
    margin-top: 2.6785714286rem;
  }
  .mgn-rgt75 {
    margin-right: 2.6785714286rem;
  }
  .mgn-btm75 {
    margin-bottom: 2.6785714286rem;
  }
  .mgn-lft75 {
    margin-left: 2.6785714286rem;
  }
  .mgn76 {
    margin: 2.7142857143rem;
  }
  .mgn-top76 {
    margin-top: 2.7142857143rem;
  }
  .mgn-rgt76 {
    margin-right: 2.7142857143rem;
  }
  .mgn-btm76 {
    margin-bottom: 2.7142857143rem;
  }
  .mgn-lft76 {
    margin-left: 2.7142857143rem;
  }
  .mgn77 {
    margin: 2.75rem;
  }
  .mgn-top77 {
    margin-top: 2.75rem;
  }
  .mgn-rgt77 {
    margin-right: 2.75rem;
  }
  .mgn-btm77 {
    margin-bottom: 2.75rem;
  }
  .mgn-lft77 {
    margin-left: 2.75rem;
  }
  .mgn78 {
    margin: 2.7857142857rem;
  }
  .mgn-top78 {
    margin-top: 2.7857142857rem;
  }
  .mgn-rgt78 {
    margin-right: 2.7857142857rem;
  }
  .mgn-btm78 {
    margin-bottom: 2.7857142857rem;
  }
  .mgn-lft78 {
    margin-left: 2.7857142857rem;
  }
  .mgn79 {
    margin: 2.8214285714rem;
  }
  .mgn-top79 {
    margin-top: 2.8214285714rem;
  }
  .mgn-rgt79 {
    margin-right: 2.8214285714rem;
  }
  .mgn-btm79 {
    margin-bottom: 2.8214285714rem;
  }
  .mgn-lft79 {
    margin-left: 2.8214285714rem;
  }
  .mgn80 {
    margin: 2.8571428571rem;
  }
  .mgn-top80 {
    margin-top: 2.8571428571rem;
  }
  .mgn-rgt80 {
    margin-right: 2.8571428571rem;
  }
  .mgn-btm80 {
    margin-bottom: 2.8571428571rem;
  }
  .mgn-lft80 {
    margin-left: 2.8571428571rem;
  }
  .mgn81 {
    margin: 2.8928571429rem;
  }
  .mgn-top81 {
    margin-top: 2.8928571429rem;
  }
  .mgn-rgt81 {
    margin-right: 2.8928571429rem;
  }
  .mgn-btm81 {
    margin-bottom: 2.8928571429rem;
  }
  .mgn-lft81 {
    margin-left: 2.8928571429rem;
  }
  .mgn82 {
    margin: 2.9285714286rem;
  }
  .mgn-top82 {
    margin-top: 2.9285714286rem;
  }
  .mgn-rgt82 {
    margin-right: 2.9285714286rem;
  }
  .mgn-btm82 {
    margin-bottom: 2.9285714286rem;
  }
  .mgn-lft82 {
    margin-left: 2.9285714286rem;
  }
  .mgn83 {
    margin: 2.9642857143rem;
  }
  .mgn-top83 {
    margin-top: 2.9642857143rem;
  }
  .mgn-rgt83 {
    margin-right: 2.9642857143rem;
  }
  .mgn-btm83 {
    margin-bottom: 2.9642857143rem;
  }
  .mgn-lft83 {
    margin-left: 2.9642857143rem;
  }
  .mgn84 {
    margin: 3rem;
  }
  .mgn-top84 {
    margin-top: 3rem;
  }
  .mgn-rgt84 {
    margin-right: 3rem;
  }
  .mgn-btm84 {
    margin-bottom: 3rem;
  }
  .mgn-lft84 {
    margin-left: 3rem;
  }
  .mgn85 {
    margin: 3.0357142857rem;
  }
  .mgn-top85 {
    margin-top: 3.0357142857rem;
  }
  .mgn-rgt85 {
    margin-right: 3.0357142857rem;
  }
  .mgn-btm85 {
    margin-bottom: 3.0357142857rem;
  }
  .mgn-lft85 {
    margin-left: 3.0357142857rem;
  }
  .mgn86 {
    margin: 3.0714285714rem;
  }
  .mgn-top86 {
    margin-top: 3.0714285714rem;
  }
  .mgn-rgt86 {
    margin-right: 3.0714285714rem;
  }
  .mgn-btm86 {
    margin-bottom: 3.0714285714rem;
  }
  .mgn-lft86 {
    margin-left: 3.0714285714rem;
  }
  .mgn87 {
    margin: 3.1071428571rem;
  }
  .mgn-top87 {
    margin-top: 3.1071428571rem;
  }
  .mgn-rgt87 {
    margin-right: 3.1071428571rem;
  }
  .mgn-btm87 {
    margin-bottom: 3.1071428571rem;
  }
  .mgn-lft87 {
    margin-left: 3.1071428571rem;
  }
  .mgn88 {
    margin: 3.1428571429rem;
  }
  .mgn-top88 {
    margin-top: 3.1428571429rem;
  }
  .mgn-rgt88 {
    margin-right: 3.1428571429rem;
  }
  .mgn-btm88 {
    margin-bottom: 3.1428571429rem;
  }
  .mgn-lft88 {
    margin-left: 3.1428571429rem;
  }
  .mgn89 {
    margin: 3.1785714286rem;
  }
  .mgn-top89 {
    margin-top: 3.1785714286rem;
  }
  .mgn-rgt89 {
    margin-right: 3.1785714286rem;
  }
  .mgn-btm89 {
    margin-bottom: 3.1785714286rem;
  }
  .mgn-lft89 {
    margin-left: 3.1785714286rem;
  }
  .mgn90 {
    margin: 3.2142857143rem;
  }
  .mgn-top90 {
    margin-top: 3.2142857143rem;
  }
  .mgn-rgt90 {
    margin-right: 3.2142857143rem;
  }
  .mgn-btm90 {
    margin-bottom: 3.2142857143rem;
  }
  .mgn-lft90 {
    margin-left: 3.2142857143rem;
  }
  .mgn91 {
    margin: 3.25rem;
  }
  .mgn-top91 {
    margin-top: 3.25rem;
  }
  .mgn-rgt91 {
    margin-right: 3.25rem;
  }
  .mgn-btm91 {
    margin-bottom: 3.25rem;
  }
  .mgn-lft91 {
    margin-left: 3.25rem;
  }
  .mgn92 {
    margin: 3.2857142857rem;
  }
  .mgn-top92 {
    margin-top: 3.2857142857rem;
  }
  .mgn-rgt92 {
    margin-right: 3.2857142857rem;
  }
  .mgn-btm92 {
    margin-bottom: 3.2857142857rem;
  }
  .mgn-lft92 {
    margin-left: 3.2857142857rem;
  }
  .mgn93 {
    margin: 3.3214285714rem;
  }
  .mgn-top93 {
    margin-top: 3.3214285714rem;
  }
  .mgn-rgt93 {
    margin-right: 3.3214285714rem;
  }
  .mgn-btm93 {
    margin-bottom: 3.3214285714rem;
  }
  .mgn-lft93 {
    margin-left: 3.3214285714rem;
  }
  .mgn94 {
    margin: 3.3571428571rem;
  }
  .mgn-top94 {
    margin-top: 3.3571428571rem;
  }
  .mgn-rgt94 {
    margin-right: 3.3571428571rem;
  }
  .mgn-btm94 {
    margin-bottom: 3.3571428571rem;
  }
  .mgn-lft94 {
    margin-left: 3.3571428571rem;
  }
  .mgn95 {
    margin: 3.3928571429rem;
  }
  .mgn-top95 {
    margin-top: 3.3928571429rem;
  }
  .mgn-rgt95 {
    margin-right: 3.3928571429rem;
  }
  .mgn-btm95 {
    margin-bottom: 3.3928571429rem;
  }
  .mgn-lft95 {
    margin-left: 3.3928571429rem;
  }
  .mgn96 {
    margin: 3.4285714286rem;
  }
  .mgn-top96 {
    margin-top: 3.4285714286rem;
  }
  .mgn-rgt96 {
    margin-right: 3.4285714286rem;
  }
  .mgn-btm96 {
    margin-bottom: 3.4285714286rem;
  }
  .mgn-lft96 {
    margin-left: 3.4285714286rem;
  }
  .mgn97 {
    margin: 3.4642857143rem;
  }
  .mgn-top97 {
    margin-top: 3.4642857143rem;
  }
  .mgn-rgt97 {
    margin-right: 3.4642857143rem;
  }
  .mgn-btm97 {
    margin-bottom: 3.4642857143rem;
  }
  .mgn-lft97 {
    margin-left: 3.4642857143rem;
  }
  .mgn98 {
    margin: 3.5rem;
  }
  .mgn-top98 {
    margin-top: 3.5rem;
  }
  .mgn-rgt98 {
    margin-right: 3.5rem;
  }
  .mgn-btm98 {
    margin-bottom: 3.5rem;
  }
  .mgn-lft98 {
    margin-left: 3.5rem;
  }
  .mgn99 {
    margin: 3.5357142857rem;
  }
  .mgn-top99 {
    margin-top: 3.5357142857rem;
  }
  .mgn-rgt99 {
    margin-right: 3.5357142857rem;
  }
  .mgn-btm99 {
    margin-bottom: 3.5357142857rem;
  }
  .mgn-lft99 {
    margin-left: 3.5357142857rem;
  }
  .mgn100 {
    margin: 3.5714285714rem;
  }
  .mgn-top100 {
    margin-top: 3.5714285714rem;
  }
  .mgn-rgt100 {
    margin-right: 3.5714285714rem;
  }
  .mgn-btm100 {
    margin-bottom: 3.5714285714rem;
  }
  .mgn-lft100 {
    margin-left: 3.5714285714rem;
  }
  .mgn101 {
    margin: 3.6071428571rem;
  }
  .mgn-top101 {
    margin-top: 3.6071428571rem;
  }
  .mgn-rgt101 {
    margin-right: 3.6071428571rem;
  }
  .mgn-btm101 {
    margin-bottom: 3.6071428571rem;
  }
  .mgn-lft101 {
    margin-left: 3.6071428571rem;
  }
  .mgn102 {
    margin: 3.6428571429rem;
  }
  .mgn-top102 {
    margin-top: 3.6428571429rem;
  }
  .mgn-rgt102 {
    margin-right: 3.6428571429rem;
  }
  .mgn-btm102 {
    margin-bottom: 3.6428571429rem;
  }
  .mgn-lft102 {
    margin-left: 3.6428571429rem;
  }
  .mgn103 {
    margin: 3.6785714286rem;
  }
  .mgn-top103 {
    margin-top: 3.6785714286rem;
  }
  .mgn-rgt103 {
    margin-right: 3.6785714286rem;
  }
  .mgn-btm103 {
    margin-bottom: 3.6785714286rem;
  }
  .mgn-lft103 {
    margin-left: 3.6785714286rem;
  }
  .mgn104 {
    margin: 3.7142857143rem;
  }
  .mgn-top104 {
    margin-top: 3.7142857143rem;
  }
  .mgn-rgt104 {
    margin-right: 3.7142857143rem;
  }
  .mgn-btm104 {
    margin-bottom: 3.7142857143rem;
  }
  .mgn-lft104 {
    margin-left: 3.7142857143rem;
  }
  .mgn105 {
    margin: 3.75rem;
  }
  .mgn-top105 {
    margin-top: 3.75rem;
  }
  .mgn-rgt105 {
    margin-right: 3.75rem;
  }
  .mgn-btm105 {
    margin-bottom: 3.75rem;
  }
  .mgn-lft105 {
    margin-left: 3.75rem;
  }
  .mgn106 {
    margin: 3.7857142857rem;
  }
  .mgn-top106 {
    margin-top: 3.7857142857rem;
  }
  .mgn-rgt106 {
    margin-right: 3.7857142857rem;
  }
  .mgn-btm106 {
    margin-bottom: 3.7857142857rem;
  }
  .mgn-lft106 {
    margin-left: 3.7857142857rem;
  }
  .mgn107 {
    margin: 3.8214285714rem;
  }
  .mgn-top107 {
    margin-top: 3.8214285714rem;
  }
  .mgn-rgt107 {
    margin-right: 3.8214285714rem;
  }
  .mgn-btm107 {
    margin-bottom: 3.8214285714rem;
  }
  .mgn-lft107 {
    margin-left: 3.8214285714rem;
  }
  .mgn108 {
    margin: 3.8571428571rem;
  }
  .mgn-top108 {
    margin-top: 3.8571428571rem;
  }
  .mgn-rgt108 {
    margin-right: 3.8571428571rem;
  }
  .mgn-btm108 {
    margin-bottom: 3.8571428571rem;
  }
  .mgn-lft108 {
    margin-left: 3.8571428571rem;
  }
  .mgn109 {
    margin: 3.8928571429rem;
  }
  .mgn-top109 {
    margin-top: 3.8928571429rem;
  }
  .mgn-rgt109 {
    margin-right: 3.8928571429rem;
  }
  .mgn-btm109 {
    margin-bottom: 3.8928571429rem;
  }
  .mgn-lft109 {
    margin-left: 3.8928571429rem;
  }
  .mgn110 {
    margin: 3.9285714286rem;
  }
  .mgn-top110 {
    margin-top: 3.9285714286rem;
  }
  .mgn-rgt110 {
    margin-right: 3.9285714286rem;
  }
  .mgn-btm110 {
    margin-bottom: 3.9285714286rem;
  }
  .mgn-lft110 {
    margin-left: 3.9285714286rem;
  }
  .mgn111 {
    margin: 3.9642857143rem;
  }
  .mgn-top111 {
    margin-top: 3.9642857143rem;
  }
  .mgn-rgt111 {
    margin-right: 3.9642857143rem;
  }
  .mgn-btm111 {
    margin-bottom: 3.9642857143rem;
  }
  .mgn-lft111 {
    margin-left: 3.9642857143rem;
  }
  .mgn112 {
    margin: 4rem;
  }
  .mgn-top112 {
    margin-top: 4rem;
  }
  .mgn-rgt112 {
    margin-right: 4rem;
  }
  .mgn-btm112 {
    margin-bottom: 4rem;
  }
  .mgn-lft112 {
    margin-left: 4rem;
  }
  .mgn113 {
    margin: 4.0357142857rem;
  }
  .mgn-top113 {
    margin-top: 4.0357142857rem;
  }
  .mgn-rgt113 {
    margin-right: 4.0357142857rem;
  }
  .mgn-btm113 {
    margin-bottom: 4.0357142857rem;
  }
  .mgn-lft113 {
    margin-left: 4.0357142857rem;
  }
  .mgn114 {
    margin: 4.0714285714rem;
  }
  .mgn-top114 {
    margin-top: 4.0714285714rem;
  }
  .mgn-rgt114 {
    margin-right: 4.0714285714rem;
  }
  .mgn-btm114 {
    margin-bottom: 4.0714285714rem;
  }
  .mgn-lft114 {
    margin-left: 4.0714285714rem;
  }
  .mgn115 {
    margin: 4.1071428571rem;
  }
  .mgn-top115 {
    margin-top: 4.1071428571rem;
  }
  .mgn-rgt115 {
    margin-right: 4.1071428571rem;
  }
  .mgn-btm115 {
    margin-bottom: 4.1071428571rem;
  }
  .mgn-lft115 {
    margin-left: 4.1071428571rem;
  }
  .mgn116 {
    margin: 4.1428571429rem;
  }
  .mgn-top116 {
    margin-top: 4.1428571429rem;
  }
  .mgn-rgt116 {
    margin-right: 4.1428571429rem;
  }
  .mgn-btm116 {
    margin-bottom: 4.1428571429rem;
  }
  .mgn-lft116 {
    margin-left: 4.1428571429rem;
  }
  .mgn117 {
    margin: 4.1785714286rem;
  }
  .mgn-top117 {
    margin-top: 4.1785714286rem;
  }
  .mgn-rgt117 {
    margin-right: 4.1785714286rem;
  }
  .mgn-btm117 {
    margin-bottom: 4.1785714286rem;
  }
  .mgn-lft117 {
    margin-left: 4.1785714286rem;
  }
  .mgn118 {
    margin: 4.2142857143rem;
  }
  .mgn-top118 {
    margin-top: 4.2142857143rem;
  }
  .mgn-rgt118 {
    margin-right: 4.2142857143rem;
  }
  .mgn-btm118 {
    margin-bottom: 4.2142857143rem;
  }
  .mgn-lft118 {
    margin-left: 4.2142857143rem;
  }
  .mgn119 {
    margin: 4.25rem;
  }
  .mgn-top119 {
    margin-top: 4.25rem;
  }
  .mgn-rgt119 {
    margin-right: 4.25rem;
  }
  .mgn-btm119 {
    margin-bottom: 4.25rem;
  }
  .mgn-lft119 {
    margin-left: 4.25rem;
  }
  .mgn120 {
    margin: 4.2857142857rem;
  }
  .mgn-top120 {
    margin-top: 4.2857142857rem;
  }
  .mgn-rgt120 {
    margin-right: 4.2857142857rem;
  }
  .mgn-btm120 {
    margin-bottom: 4.2857142857rem;
  }
  .mgn-lft120 {
    margin-left: 4.2857142857rem;
  }
  .mgn121 {
    margin: 4.3214285714rem;
  }
  .mgn-top121 {
    margin-top: 4.3214285714rem;
  }
  .mgn-rgt121 {
    margin-right: 4.3214285714rem;
  }
  .mgn-btm121 {
    margin-bottom: 4.3214285714rem;
  }
  .mgn-lft121 {
    margin-left: 4.3214285714rem;
  }
  .mgn122 {
    margin: 4.3571428571rem;
  }
  .mgn-top122 {
    margin-top: 4.3571428571rem;
  }
  .mgn-rgt122 {
    margin-right: 4.3571428571rem;
  }
  .mgn-btm122 {
    margin-bottom: 4.3571428571rem;
  }
  .mgn-lft122 {
    margin-left: 4.3571428571rem;
  }
  .mgn123 {
    margin: 4.3928571429rem;
  }
  .mgn-top123 {
    margin-top: 4.3928571429rem;
  }
  .mgn-rgt123 {
    margin-right: 4.3928571429rem;
  }
  .mgn-btm123 {
    margin-bottom: 4.3928571429rem;
  }
  .mgn-lft123 {
    margin-left: 4.3928571429rem;
  }
  .mgn124 {
    margin: 4.4285714286rem;
  }
  .mgn-top124 {
    margin-top: 4.4285714286rem;
  }
  .mgn-rgt124 {
    margin-right: 4.4285714286rem;
  }
  .mgn-btm124 {
    margin-bottom: 4.4285714286rem;
  }
  .mgn-lft124 {
    margin-left: 4.4285714286rem;
  }
  .mgn125 {
    margin: 4.4642857143rem;
  }
  .mgn-top125 {
    margin-top: 4.4642857143rem;
  }
  .mgn-rgt125 {
    margin-right: 4.4642857143rem;
  }
  .mgn-btm125 {
    margin-bottom: 4.4642857143rem;
  }
  .mgn-lft125 {
    margin-left: 4.4642857143rem;
  }
  .mgn126 {
    margin: 4.5rem;
  }
  .mgn-top126 {
    margin-top: 4.5rem;
  }
  .mgn-rgt126 {
    margin-right: 4.5rem;
  }
  .mgn-btm126 {
    margin-bottom: 4.5rem;
  }
  .mgn-lft126 {
    margin-left: 4.5rem;
  }
  .mgn127 {
    margin: 4.5357142857rem;
  }
  .mgn-top127 {
    margin-top: 4.5357142857rem;
  }
  .mgn-rgt127 {
    margin-right: 4.5357142857rem;
  }
  .mgn-btm127 {
    margin-bottom: 4.5357142857rem;
  }
  .mgn-lft127 {
    margin-left: 4.5357142857rem;
  }
  .mgn128 {
    margin: 4.5714285714rem;
  }
  .mgn-top128 {
    margin-top: 4.5714285714rem;
  }
  .mgn-rgt128 {
    margin-right: 4.5714285714rem;
  }
  .mgn-btm128 {
    margin-bottom: 4.5714285714rem;
  }
  .mgn-lft128 {
    margin-left: 4.5714285714rem;
  }
  .mgn129 {
    margin: 4.6071428571rem;
  }
  .mgn-top129 {
    margin-top: 4.6071428571rem;
  }
  .mgn-rgt129 {
    margin-right: 4.6071428571rem;
  }
  .mgn-btm129 {
    margin-bottom: 4.6071428571rem;
  }
  .mgn-lft129 {
    margin-left: 4.6071428571rem;
  }
  .mgn130 {
    margin: 4.6428571429rem;
  }
  .mgn-top130 {
    margin-top: 4.6428571429rem;
  }
  .mgn-rgt130 {
    margin-right: 4.6428571429rem;
  }
  .mgn-btm130 {
    margin-bottom: 4.6428571429rem;
  }
  .mgn-lft130 {
    margin-left: 4.6428571429rem;
  }
  .mgn131 {
    margin: 4.6785714286rem;
  }
  .mgn-top131 {
    margin-top: 4.6785714286rem;
  }
  .mgn-rgt131 {
    margin-right: 4.6785714286rem;
  }
  .mgn-btm131 {
    margin-bottom: 4.6785714286rem;
  }
  .mgn-lft131 {
    margin-left: 4.6785714286rem;
  }
  .mgn132 {
    margin: 4.7142857143rem;
  }
  .mgn-top132 {
    margin-top: 4.7142857143rem;
  }
  .mgn-rgt132 {
    margin-right: 4.7142857143rem;
  }
  .mgn-btm132 {
    margin-bottom: 4.7142857143rem;
  }
  .mgn-lft132 {
    margin-left: 4.7142857143rem;
  }
  .mgn133 {
    margin: 4.75rem;
  }
  .mgn-top133 {
    margin-top: 4.75rem;
  }
  .mgn-rgt133 {
    margin-right: 4.75rem;
  }
  .mgn-btm133 {
    margin-bottom: 4.75rem;
  }
  .mgn-lft133 {
    margin-left: 4.75rem;
  }
  .mgn134 {
    margin: 4.7857142857rem;
  }
  .mgn-top134 {
    margin-top: 4.7857142857rem;
  }
  .mgn-rgt134 {
    margin-right: 4.7857142857rem;
  }
  .mgn-btm134 {
    margin-bottom: 4.7857142857rem;
  }
  .mgn-lft134 {
    margin-left: 4.7857142857rem;
  }
  .mgn135 {
    margin: 4.8214285714rem;
  }
  .mgn-top135 {
    margin-top: 4.8214285714rem;
  }
  .mgn-rgt135 {
    margin-right: 4.8214285714rem;
  }
  .mgn-btm135 {
    margin-bottom: 4.8214285714rem;
  }
  .mgn-lft135 {
    margin-left: 4.8214285714rem;
  }
  .mgn136 {
    margin: 4.8571428571rem;
  }
  .mgn-top136 {
    margin-top: 4.8571428571rem;
  }
  .mgn-rgt136 {
    margin-right: 4.8571428571rem;
  }
  .mgn-btm136 {
    margin-bottom: 4.8571428571rem;
  }
  .mgn-lft136 {
    margin-left: 4.8571428571rem;
  }
  .mgn137 {
    margin: 4.8928571429rem;
  }
  .mgn-top137 {
    margin-top: 4.8928571429rem;
  }
  .mgn-rgt137 {
    margin-right: 4.8928571429rem;
  }
  .mgn-btm137 {
    margin-bottom: 4.8928571429rem;
  }
  .mgn-lft137 {
    margin-left: 4.8928571429rem;
  }
  .mgn138 {
    margin: 4.9285714286rem;
  }
  .mgn-top138 {
    margin-top: 4.9285714286rem;
  }
  .mgn-rgt138 {
    margin-right: 4.9285714286rem;
  }
  .mgn-btm138 {
    margin-bottom: 4.9285714286rem;
  }
  .mgn-lft138 {
    margin-left: 4.9285714286rem;
  }
  .mgn139 {
    margin: 4.9642857143rem;
  }
  .mgn-top139 {
    margin-top: 4.9642857143rem;
  }
  .mgn-rgt139 {
    margin-right: 4.9642857143rem;
  }
  .mgn-btm139 {
    margin-bottom: 4.9642857143rem;
  }
  .mgn-lft139 {
    margin-left: 4.9642857143rem;
  }
  .mgn140 {
    margin: 5rem;
  }
  .mgn-top140 {
    margin-top: 5rem;
  }
  .mgn-rgt140 {
    margin-right: 5rem;
  }
  .mgn-btm140 {
    margin-bottom: 5rem;
  }
  .mgn-lft140 {
    margin-left: 5rem;
  }
  .mgn141 {
    margin: 5.0357142857rem;
  }
  .mgn-top141 {
    margin-top: 5.0357142857rem;
  }
  .mgn-rgt141 {
    margin-right: 5.0357142857rem;
  }
  .mgn-btm141 {
    margin-bottom: 5.0357142857rem;
  }
  .mgn-lft141 {
    margin-left: 5.0357142857rem;
  }
  .mgn142 {
    margin: 5.0714285714rem;
  }
  .mgn-top142 {
    margin-top: 5.0714285714rem;
  }
  .mgn-rgt142 {
    margin-right: 5.0714285714rem;
  }
  .mgn-btm142 {
    margin-bottom: 5.0714285714rem;
  }
  .mgn-lft142 {
    margin-left: 5.0714285714rem;
  }
  .mgn143 {
    margin: 5.1071428571rem;
  }
  .mgn-top143 {
    margin-top: 5.1071428571rem;
  }
  .mgn-rgt143 {
    margin-right: 5.1071428571rem;
  }
  .mgn-btm143 {
    margin-bottom: 5.1071428571rem;
  }
  .mgn-lft143 {
    margin-left: 5.1071428571rem;
  }
  .mgn144 {
    margin: 5.1428571429rem;
  }
  .mgn-top144 {
    margin-top: 5.1428571429rem;
  }
  .mgn-rgt144 {
    margin-right: 5.1428571429rem;
  }
  .mgn-btm144 {
    margin-bottom: 5.1428571429rem;
  }
  .mgn-lft144 {
    margin-left: 5.1428571429rem;
  }
  .mgn145 {
    margin: 5.1785714286rem;
  }
  .mgn-top145 {
    margin-top: 5.1785714286rem;
  }
  .mgn-rgt145 {
    margin-right: 5.1785714286rem;
  }
  .mgn-btm145 {
    margin-bottom: 5.1785714286rem;
  }
  .mgn-lft145 {
    margin-left: 5.1785714286rem;
  }
  .mgn146 {
    margin: 5.2142857143rem;
  }
  .mgn-top146 {
    margin-top: 5.2142857143rem;
  }
  .mgn-rgt146 {
    margin-right: 5.2142857143rem;
  }
  .mgn-btm146 {
    margin-bottom: 5.2142857143rem;
  }
  .mgn-lft146 {
    margin-left: 5.2142857143rem;
  }
  .mgn147 {
    margin: 5.25rem;
  }
  .mgn-top147 {
    margin-top: 5.25rem;
  }
  .mgn-rgt147 {
    margin-right: 5.25rem;
  }
  .mgn-btm147 {
    margin-bottom: 5.25rem;
  }
  .mgn-lft147 {
    margin-left: 5.25rem;
  }
  .mgn148 {
    margin: 5.2857142857rem;
  }
  .mgn-top148 {
    margin-top: 5.2857142857rem;
  }
  .mgn-rgt148 {
    margin-right: 5.2857142857rem;
  }
  .mgn-btm148 {
    margin-bottom: 5.2857142857rem;
  }
  .mgn-lft148 {
    margin-left: 5.2857142857rem;
  }
  .mgn149 {
    margin: 5.3214285714rem;
  }
  .mgn-top149 {
    margin-top: 5.3214285714rem;
  }
  .mgn-rgt149 {
    margin-right: 5.3214285714rem;
  }
  .mgn-btm149 {
    margin-bottom: 5.3214285714rem;
  }
  .mgn-lft149 {
    margin-left: 5.3214285714rem;
  }
  .mgn150 {
    margin: 5.3571428571rem;
  }
  .mgn-top150 {
    margin-top: 5.3571428571rem;
  }
  .mgn-rgt150 {
    margin-right: 5.3571428571rem;
  }
  .mgn-btm150 {
    margin-bottom: 5.3571428571rem;
  }
  .mgn-lft150 {
    margin-left: 5.3571428571rem;
  }
}
.mgn0em {
  margin: 0em;
}

.mgn-top0em {
  margin-top: 0em;
}

.mgn-rgt0em {
  margin-right: 0em;
}

.mgn-btm0em {
  margin-bottom: 0em;
}

.mgn-lft0em {
  margin-left: 0em;
}

.mgn1em {
  margin: 1em;
}

.mgn-top1em {
  margin-top: 1em;
}

.mgn-rgt1em {
  margin-right: 1em;
}

.mgn-btm1em {
  margin-bottom: 1em;
}

.mgn-lft1em {
  margin-left: 1em;
}

.mgn2em {
  margin: 2em;
}

.mgn-top2em {
  margin-top: 2em;
}

.mgn-rgt2em {
  margin-right: 2em;
}

.mgn-btm2em {
  margin-bottom: 2em;
}

.mgn-lft2em {
  margin-left: 2em;
}

.mgn3em {
  margin: 3em;
}

.mgn-top3em {
  margin-top: 3em;
}

.mgn-rgt3em {
  margin-right: 3em;
}

.mgn-btm3em {
  margin-bottom: 3em;
}

.mgn-lft3em {
  margin-left: 3em;
}

.mgn4em {
  margin: 4em;
}

.mgn-top4em {
  margin-top: 4em;
}

.mgn-rgt4em {
  margin-right: 4em;
}

.mgn-btm4em {
  margin-bottom: 4em;
}

.mgn-lft4em {
  margin-left: 4em;
}

.mgn5em {
  margin: 5em;
}

.mgn-top5em {
  margin-top: 5em;
}

.mgn-rgt5em {
  margin-right: 5em;
}

.mgn-btm5em {
  margin-bottom: 5em;
}

.mgn-lft5em {
  margin-left: 5em;
}

.mgn6em {
  margin: 6em;
}

.mgn-top6em {
  margin-top: 6em;
}

.mgn-rgt6em {
  margin-right: 6em;
}

.mgn-btm6em {
  margin-bottom: 6em;
}

.mgn-lft6em {
  margin-left: 6em;
}

.mgn7em {
  margin: 7em;
}

.mgn-top7em {
  margin-top: 7em;
}

.mgn-rgt7em {
  margin-right: 7em;
}

.mgn-btm7em {
  margin-bottom: 7em;
}

.mgn-lft7em {
  margin-left: 7em;
}

.mgn8em {
  margin: 8em;
}

.mgn-top8em {
  margin-top: 8em;
}

.mgn-rgt8em {
  margin-right: 8em;
}

.mgn-btm8em {
  margin-bottom: 8em;
}

.mgn-lft8em {
  margin-left: 8em;
}

.mgn9em {
  margin: 9em;
}

.mgn-top9em {
  margin-top: 9em;
}

.mgn-rgt9em {
  margin-right: 9em;
}

.mgn-btm9em {
  margin-bottom: 9em;
}

.mgn-lft9em {
  margin-left: 9em;
}

.mgn10em {
  margin: 10em;
}

.mgn-top10em {
  margin-top: 10em;
}

.mgn-rgt10em {
  margin-right: 10em;
}

.mgn-btm10em {
  margin-bottom: 10em;
}

.mgn-lft10em {
  margin-left: 10em;
}

.mgn11em {
  margin: 11em;
}

.mgn-top11em {
  margin-top: 11em;
}

.mgn-rgt11em {
  margin-right: 11em;
}

.mgn-btm11em {
  margin-bottom: 11em;
}

.mgn-lft11em {
  margin-left: 11em;
}

.mgn12em {
  margin: 12em;
}

.mgn-top12em {
  margin-top: 12em;
}

.mgn-rgt12em {
  margin-right: 12em;
}

.mgn-btm12em {
  margin-bottom: 12em;
}

.mgn-lft12em {
  margin-left: 12em;
}

.mgn13em {
  margin: 13em;
}

.mgn-top13em {
  margin-top: 13em;
}

.mgn-rgt13em {
  margin-right: 13em;
}

.mgn-btm13em {
  margin-bottom: 13em;
}

.mgn-lft13em {
  margin-left: 13em;
}

.mgn14em {
  margin: 14em;
}

.mgn-top14em {
  margin-top: 14em;
}

.mgn-rgt14em {
  margin-right: 14em;
}

.mgn-btm14em {
  margin-bottom: 14em;
}

.mgn-lft14em {
  margin-left: 14em;
}

.mgn15em {
  margin: 15em;
}

.mgn-top15em {
  margin-top: 15em;
}

.mgn-rgt15em {
  margin-right: 15em;
}

.mgn-btm15em {
  margin-bottom: 15em;
}

.mgn-lft15em {
  margin-left: 15em;
}

.pdg0 {
  padding: 0rem;
}

.pdg-top0 {
  padding-top: 0rem;
}

.pdg-rgt0 {
  padding-right: 0rem;
}

.pdg-btm0 {
  padding-bottom: 0rem;
}

.pdg-lft0 {
  padding-left: 0rem;
}

.pdg1 {
  padding: 0.0625rem;
}

.pdg-top1 {
  padding-top: 0.0625rem;
}

.pdg-rgt1 {
  padding-right: 0.0625rem;
}

.pdg-btm1 {
  padding-bottom: 0.0625rem;
}

.pdg-lft1 {
  padding-left: 0.0625rem;
}

.pdg2 {
  padding: 0.125rem;
}

.pdg-top2 {
  padding-top: 0.125rem;
}

.pdg-rgt2 {
  padding-right: 0.125rem;
}

.pdg-btm2 {
  padding-bottom: 0.125rem;
}

.pdg-lft2 {
  padding-left: 0.125rem;
}

.pdg3 {
  padding: 0.1875rem;
}

.pdg-top3 {
  padding-top: 0.1875rem;
}

.pdg-rgt3 {
  padding-right: 0.1875rem;
}

.pdg-btm3 {
  padding-bottom: 0.1875rem;
}

.pdg-lft3 {
  padding-left: 0.1875rem;
}

.pdg4 {
  padding: 0.25rem;
}

.pdg-top4 {
  padding-top: 0.25rem;
}

.pdg-rgt4 {
  padding-right: 0.25rem;
}

.pdg-btm4 {
  padding-bottom: 0.25rem;
}

.pdg-lft4 {
  padding-left: 0.25rem;
}

.pdg5 {
  padding: 0.3125rem;
}

.pdg-top5 {
  padding-top: 0.3125rem;
}

.pdg-rgt5 {
  padding-right: 0.3125rem;
}

.pdg-btm5 {
  padding-bottom: 0.3125rem;
}

.pdg-lft5 {
  padding-left: 0.3125rem;
}

.pdg6 {
  padding: 0.375rem;
}

.pdg-top6 {
  padding-top: 0.375rem;
}

.pdg-rgt6 {
  padding-right: 0.375rem;
}

.pdg-btm6 {
  padding-bottom: 0.375rem;
}

.pdg-lft6 {
  padding-left: 0.375rem;
}

.pdg7 {
  padding: 0.4375rem;
}

.pdg-top7 {
  padding-top: 0.4375rem;
}

.pdg-rgt7 {
  padding-right: 0.4375rem;
}

.pdg-btm7 {
  padding-bottom: 0.4375rem;
}

.pdg-lft7 {
  padding-left: 0.4375rem;
}

.pdg8 {
  padding: 0.5rem;
}

.pdg-top8 {
  padding-top: 0.5rem;
}

.pdg-rgt8 {
  padding-right: 0.5rem;
}

.pdg-btm8 {
  padding-bottom: 0.5rem;
}

.pdg-lft8 {
  padding-left: 0.5rem;
}

.pdg9 {
  padding: 0.5625rem;
}

.pdg-top9 {
  padding-top: 0.5625rem;
}

.pdg-rgt9 {
  padding-right: 0.5625rem;
}

.pdg-btm9 {
  padding-bottom: 0.5625rem;
}

.pdg-lft9 {
  padding-left: 0.5625rem;
}

.pdg10 {
  padding: 0.625rem;
}

.pdg-top10 {
  padding-top: 0.625rem;
}

.pdg-rgt10 {
  padding-right: 0.625rem;
}

.pdg-btm10 {
  padding-bottom: 0.625rem;
}

.pdg-lft10 {
  padding-left: 0.625rem;
}

.pdg11 {
  padding: 0.6875rem;
}

.pdg-top11 {
  padding-top: 0.6875rem;
}

.pdg-rgt11 {
  padding-right: 0.6875rem;
}

.pdg-btm11 {
  padding-bottom: 0.6875rem;
}

.pdg-lft11 {
  padding-left: 0.6875rem;
}

.pdg12 {
  padding: 0.75rem;
}

.pdg-top12 {
  padding-top: 0.75rem;
}

.pdg-rgt12 {
  padding-right: 0.75rem;
}

.pdg-btm12 {
  padding-bottom: 0.75rem;
}

.pdg-lft12 {
  padding-left: 0.75rem;
}

.pdg13 {
  padding: 0.8125rem;
}

.pdg-top13 {
  padding-top: 0.8125rem;
}

.pdg-rgt13 {
  padding-right: 0.8125rem;
}

.pdg-btm13 {
  padding-bottom: 0.8125rem;
}

.pdg-lft13 {
  padding-left: 0.8125rem;
}

.pdg14 {
  padding: 0.875rem;
}

.pdg-top14 {
  padding-top: 0.875rem;
}

.pdg-rgt14 {
  padding-right: 0.875rem;
}

.pdg-btm14 {
  padding-bottom: 0.875rem;
}

.pdg-lft14 {
  padding-left: 0.875rem;
}

.pdg15 {
  padding: 0.9375rem;
}

.pdg-top15 {
  padding-top: 0.9375rem;
}

.pdg-rgt15 {
  padding-right: 0.9375rem;
}

.pdg-btm15 {
  padding-bottom: 0.9375rem;
}

.pdg-lft15 {
  padding-left: 0.9375rem;
}

.pdg16 {
  padding: 1rem;
}

.pdg-top16 {
  padding-top: 1rem;
}

.pdg-rgt16 {
  padding-right: 1rem;
}

.pdg-btm16 {
  padding-bottom: 1rem;
}

.pdg-lft16 {
  padding-left: 1rem;
}

.pdg17 {
  padding: 1.0625rem;
}

.pdg-top17 {
  padding-top: 1.0625rem;
}

.pdg-rgt17 {
  padding-right: 1.0625rem;
}

.pdg-btm17 {
  padding-bottom: 1.0625rem;
}

.pdg-lft17 {
  padding-left: 1.0625rem;
}

.pdg18 {
  padding: 1.125rem;
}

.pdg-top18 {
  padding-top: 1.125rem;
}

.pdg-rgt18 {
  padding-right: 1.125rem;
}

.pdg-btm18 {
  padding-bottom: 1.125rem;
}

.pdg-lft18 {
  padding-left: 1.125rem;
}

.pdg19 {
  padding: 1.1875rem;
}

.pdg-top19 {
  padding-top: 1.1875rem;
}

.pdg-rgt19 {
  padding-right: 1.1875rem;
}

.pdg-btm19 {
  padding-bottom: 1.1875rem;
}

.pdg-lft19 {
  padding-left: 1.1875rem;
}

.pdg20 {
  padding: 1.25rem;
}

.pdg-top20 {
  padding-top: 1.25rem;
}

.pdg-rgt20 {
  padding-right: 1.25rem;
}

.pdg-btm20 {
  padding-bottom: 1.25rem;
}

.pdg-lft20 {
  padding-left: 1.25rem;
}

.pdg21 {
  padding: 1.3125rem;
}

.pdg-top21 {
  padding-top: 1.3125rem;
}

.pdg-rgt21 {
  padding-right: 1.3125rem;
}

.pdg-btm21 {
  padding-bottom: 1.3125rem;
}

.pdg-lft21 {
  padding-left: 1.3125rem;
}

.pdg22 {
  padding: 1.375rem;
}

.pdg-top22 {
  padding-top: 1.375rem;
}

.pdg-rgt22 {
  padding-right: 1.375rem;
}

.pdg-btm22 {
  padding-bottom: 1.375rem;
}

.pdg-lft22 {
  padding-left: 1.375rem;
}

.pdg23 {
  padding: 1.4375rem;
}

.pdg-top23 {
  padding-top: 1.4375rem;
}

.pdg-rgt23 {
  padding-right: 1.4375rem;
}

.pdg-btm23 {
  padding-bottom: 1.4375rem;
}

.pdg-lft23 {
  padding-left: 1.4375rem;
}

.pdg24 {
  padding: 1.5rem;
}

.pdg-top24 {
  padding-top: 1.5rem;
}

.pdg-rgt24 {
  padding-right: 1.5rem;
}

.pdg-btm24 {
  padding-bottom: 1.5rem;
}

.pdg-lft24 {
  padding-left: 1.5rem;
}

.pdg25 {
  padding: 1.5625rem;
}

.pdg-top25 {
  padding-top: 1.5625rem;
}

.pdg-rgt25 {
  padding-right: 1.5625rem;
}

.pdg-btm25 {
  padding-bottom: 1.5625rem;
}

.pdg-lft25 {
  padding-left: 1.5625rem;
}

.pdg26 {
  padding: 1.625rem;
}

.pdg-top26 {
  padding-top: 1.625rem;
}

.pdg-rgt26 {
  padding-right: 1.625rem;
}

.pdg-btm26 {
  padding-bottom: 1.625rem;
}

.pdg-lft26 {
  padding-left: 1.625rem;
}

.pdg27 {
  padding: 1.6875rem;
}

.pdg-top27 {
  padding-top: 1.6875rem;
}

.pdg-rgt27 {
  padding-right: 1.6875rem;
}

.pdg-btm27 {
  padding-bottom: 1.6875rem;
}

.pdg-lft27 {
  padding-left: 1.6875rem;
}

.pdg28 {
  padding: 1.75rem;
}

.pdg-top28 {
  padding-top: 1.75rem;
}

.pdg-rgt28 {
  padding-right: 1.75rem;
}

.pdg-btm28 {
  padding-bottom: 1.75rem;
}

.pdg-lft28 {
  padding-left: 1.75rem;
}

.pdg29 {
  padding: 1.8125rem;
}

.pdg-top29 {
  padding-top: 1.8125rem;
}

.pdg-rgt29 {
  padding-right: 1.8125rem;
}

.pdg-btm29 {
  padding-bottom: 1.8125rem;
}

.pdg-lft29 {
  padding-left: 1.8125rem;
}

.pdg30 {
  padding: 1.875rem;
}

.pdg-top30 {
  padding-top: 1.875rem;
}

.pdg-rgt30 {
  padding-right: 1.875rem;
}

.pdg-btm30 {
  padding-bottom: 1.875rem;
}

.pdg-lft30 {
  padding-left: 1.875rem;
}

.pdg31 {
  padding: 1.9375rem;
}

.pdg-top31 {
  padding-top: 1.9375rem;
}

.pdg-rgt31 {
  padding-right: 1.9375rem;
}

.pdg-btm31 {
  padding-bottom: 1.9375rem;
}

.pdg-lft31 {
  padding-left: 1.9375rem;
}

.pdg32 {
  padding: 2rem;
}

.pdg-top32 {
  padding-top: 2rem;
}

.pdg-rgt32 {
  padding-right: 2rem;
}

.pdg-btm32 {
  padding-bottom: 2rem;
}

.pdg-lft32 {
  padding-left: 2rem;
}

.pdg33 {
  padding: 2.0625rem;
}

.pdg-top33 {
  padding-top: 2.0625rem;
}

.pdg-rgt33 {
  padding-right: 2.0625rem;
}

.pdg-btm33 {
  padding-bottom: 2.0625rem;
}

.pdg-lft33 {
  padding-left: 2.0625rem;
}

.pdg34 {
  padding: 2.125rem;
}

.pdg-top34 {
  padding-top: 2.125rem;
}

.pdg-rgt34 {
  padding-right: 2.125rem;
}

.pdg-btm34 {
  padding-bottom: 2.125rem;
}

.pdg-lft34 {
  padding-left: 2.125rem;
}

.pdg35 {
  padding: 2.1875rem;
}

.pdg-top35 {
  padding-top: 2.1875rem;
}

.pdg-rgt35 {
  padding-right: 2.1875rem;
}

.pdg-btm35 {
  padding-bottom: 2.1875rem;
}

.pdg-lft35 {
  padding-left: 2.1875rem;
}

.pdg36 {
  padding: 2.25rem;
}

.pdg-top36 {
  padding-top: 2.25rem;
}

.pdg-rgt36 {
  padding-right: 2.25rem;
}

.pdg-btm36 {
  padding-bottom: 2.25rem;
}

.pdg-lft36 {
  padding-left: 2.25rem;
}

.pdg37 {
  padding: 2.3125rem;
}

.pdg-top37 {
  padding-top: 2.3125rem;
}

.pdg-rgt37 {
  padding-right: 2.3125rem;
}

.pdg-btm37 {
  padding-bottom: 2.3125rem;
}

.pdg-lft37 {
  padding-left: 2.3125rem;
}

.pdg38 {
  padding: 2.375rem;
}

.pdg-top38 {
  padding-top: 2.375rem;
}

.pdg-rgt38 {
  padding-right: 2.375rem;
}

.pdg-btm38 {
  padding-bottom: 2.375rem;
}

.pdg-lft38 {
  padding-left: 2.375rem;
}

.pdg39 {
  padding: 2.4375rem;
}

.pdg-top39 {
  padding-top: 2.4375rem;
}

.pdg-rgt39 {
  padding-right: 2.4375rem;
}

.pdg-btm39 {
  padding-bottom: 2.4375rem;
}

.pdg-lft39 {
  padding-left: 2.4375rem;
}

.pdg40 {
  padding: 2.5rem;
}

.pdg-top40 {
  padding-top: 2.5rem;
}

.pdg-rgt40 {
  padding-right: 2.5rem;
}

.pdg-btm40 {
  padding-bottom: 2.5rem;
}

.pdg-lft40 {
  padding-left: 2.5rem;
}

.pdg41 {
  padding: 2.5625rem;
}

.pdg-top41 {
  padding-top: 2.5625rem;
}

.pdg-rgt41 {
  padding-right: 2.5625rem;
}

.pdg-btm41 {
  padding-bottom: 2.5625rem;
}

.pdg-lft41 {
  padding-left: 2.5625rem;
}

.pdg42 {
  padding: 2.625rem;
}

.pdg-top42 {
  padding-top: 2.625rem;
}

.pdg-rgt42 {
  padding-right: 2.625rem;
}

.pdg-btm42 {
  padding-bottom: 2.625rem;
}

.pdg-lft42 {
  padding-left: 2.625rem;
}

.pdg43 {
  padding: 2.6875rem;
}

.pdg-top43 {
  padding-top: 2.6875rem;
}

.pdg-rgt43 {
  padding-right: 2.6875rem;
}

.pdg-btm43 {
  padding-bottom: 2.6875rem;
}

.pdg-lft43 {
  padding-left: 2.6875rem;
}

.pdg44 {
  padding: 2.75rem;
}

.pdg-top44 {
  padding-top: 2.75rem;
}

.pdg-rgt44 {
  padding-right: 2.75rem;
}

.pdg-btm44 {
  padding-bottom: 2.75rem;
}

.pdg-lft44 {
  padding-left: 2.75rem;
}

.pdg45 {
  padding: 2.8125rem;
}

.pdg-top45 {
  padding-top: 2.8125rem;
}

.pdg-rgt45 {
  padding-right: 2.8125rem;
}

.pdg-btm45 {
  padding-bottom: 2.8125rem;
}

.pdg-lft45 {
  padding-left: 2.8125rem;
}

.pdg46 {
  padding: 2.875rem;
}

.pdg-top46 {
  padding-top: 2.875rem;
}

.pdg-rgt46 {
  padding-right: 2.875rem;
}

.pdg-btm46 {
  padding-bottom: 2.875rem;
}

.pdg-lft46 {
  padding-left: 2.875rem;
}

.pdg47 {
  padding: 2.9375rem;
}

.pdg-top47 {
  padding-top: 2.9375rem;
}

.pdg-rgt47 {
  padding-right: 2.9375rem;
}

.pdg-btm47 {
  padding-bottom: 2.9375rem;
}

.pdg-lft47 {
  padding-left: 2.9375rem;
}

.pdg48 {
  padding: 3rem;
}

.pdg-top48 {
  padding-top: 3rem;
}

.pdg-rgt48 {
  padding-right: 3rem;
}

.pdg-btm48 {
  padding-bottom: 3rem;
}

.pdg-lft48 {
  padding-left: 3rem;
}

.pdg49 {
  padding: 3.0625rem;
}

.pdg-top49 {
  padding-top: 3.0625rem;
}

.pdg-rgt49 {
  padding-right: 3.0625rem;
}

.pdg-btm49 {
  padding-bottom: 3.0625rem;
}

.pdg-lft49 {
  padding-left: 3.0625rem;
}

.pdg50 {
  padding: 3.125rem;
}

.pdg-top50 {
  padding-top: 3.125rem;
}

.pdg-rgt50 {
  padding-right: 3.125rem;
}

.pdg-btm50 {
  padding-bottom: 3.125rem;
}

.pdg-lft50 {
  padding-left: 3.125rem;
}

.pdg51 {
  padding: 3.1875rem;
}

.pdg-top51 {
  padding-top: 3.1875rem;
}

.pdg-rgt51 {
  padding-right: 3.1875rem;
}

.pdg-btm51 {
  padding-bottom: 3.1875rem;
}

.pdg-lft51 {
  padding-left: 3.1875rem;
}

.pdg52 {
  padding: 3.25rem;
}

.pdg-top52 {
  padding-top: 3.25rem;
}

.pdg-rgt52 {
  padding-right: 3.25rem;
}

.pdg-btm52 {
  padding-bottom: 3.25rem;
}

.pdg-lft52 {
  padding-left: 3.25rem;
}

.pdg53 {
  padding: 3.3125rem;
}

.pdg-top53 {
  padding-top: 3.3125rem;
}

.pdg-rgt53 {
  padding-right: 3.3125rem;
}

.pdg-btm53 {
  padding-bottom: 3.3125rem;
}

.pdg-lft53 {
  padding-left: 3.3125rem;
}

.pdg54 {
  padding: 3.375rem;
}

.pdg-top54 {
  padding-top: 3.375rem;
}

.pdg-rgt54 {
  padding-right: 3.375rem;
}

.pdg-btm54 {
  padding-bottom: 3.375rem;
}

.pdg-lft54 {
  padding-left: 3.375rem;
}

.pdg55 {
  padding: 3.4375rem;
}

.pdg-top55 {
  padding-top: 3.4375rem;
}

.pdg-rgt55 {
  padding-right: 3.4375rem;
}

.pdg-btm55 {
  padding-bottom: 3.4375rem;
}

.pdg-lft55 {
  padding-left: 3.4375rem;
}

.pdg56 {
  padding: 3.5rem;
}

.pdg-top56 {
  padding-top: 3.5rem;
}

.pdg-rgt56 {
  padding-right: 3.5rem;
}

.pdg-btm56 {
  padding-bottom: 3.5rem;
}

.pdg-lft56 {
  padding-left: 3.5rem;
}

.pdg57 {
  padding: 3.5625rem;
}

.pdg-top57 {
  padding-top: 3.5625rem;
}

.pdg-rgt57 {
  padding-right: 3.5625rem;
}

.pdg-btm57 {
  padding-bottom: 3.5625rem;
}

.pdg-lft57 {
  padding-left: 3.5625rem;
}

.pdg58 {
  padding: 3.625rem;
}

.pdg-top58 {
  padding-top: 3.625rem;
}

.pdg-rgt58 {
  padding-right: 3.625rem;
}

.pdg-btm58 {
  padding-bottom: 3.625rem;
}

.pdg-lft58 {
  padding-left: 3.625rem;
}

.pdg59 {
  padding: 3.6875rem;
}

.pdg-top59 {
  padding-top: 3.6875rem;
}

.pdg-rgt59 {
  padding-right: 3.6875rem;
}

.pdg-btm59 {
  padding-bottom: 3.6875rem;
}

.pdg-lft59 {
  padding-left: 3.6875rem;
}

.pdg60 {
  padding: 3.75rem;
}

.pdg-top60 {
  padding-top: 3.75rem;
}

.pdg-rgt60 {
  padding-right: 3.75rem;
}

.pdg-btm60 {
  padding-bottom: 3.75rem;
}

.pdg-lft60 {
  padding-left: 3.75rem;
}

.pdg61 {
  padding: 3.8125rem;
}

.pdg-top61 {
  padding-top: 3.8125rem;
}

.pdg-rgt61 {
  padding-right: 3.8125rem;
}

.pdg-btm61 {
  padding-bottom: 3.8125rem;
}

.pdg-lft61 {
  padding-left: 3.8125rem;
}

.pdg62 {
  padding: 3.875rem;
}

.pdg-top62 {
  padding-top: 3.875rem;
}

.pdg-rgt62 {
  padding-right: 3.875rem;
}

.pdg-btm62 {
  padding-bottom: 3.875rem;
}

.pdg-lft62 {
  padding-left: 3.875rem;
}

.pdg63 {
  padding: 3.9375rem;
}

.pdg-top63 {
  padding-top: 3.9375rem;
}

.pdg-rgt63 {
  padding-right: 3.9375rem;
}

.pdg-btm63 {
  padding-bottom: 3.9375rem;
}

.pdg-lft63 {
  padding-left: 3.9375rem;
}

.pdg64 {
  padding: 4rem;
}

.pdg-top64 {
  padding-top: 4rem;
}

.pdg-rgt64 {
  padding-right: 4rem;
}

.pdg-btm64 {
  padding-bottom: 4rem;
}

.pdg-lft64 {
  padding-left: 4rem;
}

.pdg65 {
  padding: 4.0625rem;
}

.pdg-top65 {
  padding-top: 4.0625rem;
}

.pdg-rgt65 {
  padding-right: 4.0625rem;
}

.pdg-btm65 {
  padding-bottom: 4.0625rem;
}

.pdg-lft65 {
  padding-left: 4.0625rem;
}

.pdg66 {
  padding: 4.125rem;
}

.pdg-top66 {
  padding-top: 4.125rem;
}

.pdg-rgt66 {
  padding-right: 4.125rem;
}

.pdg-btm66 {
  padding-bottom: 4.125rem;
}

.pdg-lft66 {
  padding-left: 4.125rem;
}

.pdg67 {
  padding: 4.1875rem;
}

.pdg-top67 {
  padding-top: 4.1875rem;
}

.pdg-rgt67 {
  padding-right: 4.1875rem;
}

.pdg-btm67 {
  padding-bottom: 4.1875rem;
}

.pdg-lft67 {
  padding-left: 4.1875rem;
}

.pdg68 {
  padding: 4.25rem;
}

.pdg-top68 {
  padding-top: 4.25rem;
}

.pdg-rgt68 {
  padding-right: 4.25rem;
}

.pdg-btm68 {
  padding-bottom: 4.25rem;
}

.pdg-lft68 {
  padding-left: 4.25rem;
}

.pdg69 {
  padding: 4.3125rem;
}

.pdg-top69 {
  padding-top: 4.3125rem;
}

.pdg-rgt69 {
  padding-right: 4.3125rem;
}

.pdg-btm69 {
  padding-bottom: 4.3125rem;
}

.pdg-lft69 {
  padding-left: 4.3125rem;
}

.pdg70 {
  padding: 4.375rem;
}

.pdg-top70 {
  padding-top: 4.375rem;
}

.pdg-rgt70 {
  padding-right: 4.375rem;
}

.pdg-btm70 {
  padding-bottom: 4.375rem;
}

.pdg-lft70 {
  padding-left: 4.375rem;
}

.pdg71 {
  padding: 4.4375rem;
}

.pdg-top71 {
  padding-top: 4.4375rem;
}

.pdg-rgt71 {
  padding-right: 4.4375rem;
}

.pdg-btm71 {
  padding-bottom: 4.4375rem;
}

.pdg-lft71 {
  padding-left: 4.4375rem;
}

.pdg72 {
  padding: 4.5rem;
}

.pdg-top72 {
  padding-top: 4.5rem;
}

.pdg-rgt72 {
  padding-right: 4.5rem;
}

.pdg-btm72 {
  padding-bottom: 4.5rem;
}

.pdg-lft72 {
  padding-left: 4.5rem;
}

.pdg73 {
  padding: 4.5625rem;
}

.pdg-top73 {
  padding-top: 4.5625rem;
}

.pdg-rgt73 {
  padding-right: 4.5625rem;
}

.pdg-btm73 {
  padding-bottom: 4.5625rem;
}

.pdg-lft73 {
  padding-left: 4.5625rem;
}

.pdg74 {
  padding: 4.625rem;
}

.pdg-top74 {
  padding-top: 4.625rem;
}

.pdg-rgt74 {
  padding-right: 4.625rem;
}

.pdg-btm74 {
  padding-bottom: 4.625rem;
}

.pdg-lft74 {
  padding-left: 4.625rem;
}

.pdg75 {
  padding: 4.6875rem;
}

.pdg-top75 {
  padding-top: 4.6875rem;
}

.pdg-rgt75 {
  padding-right: 4.6875rem;
}

.pdg-btm75 {
  padding-bottom: 4.6875rem;
}

.pdg-lft75 {
  padding-left: 4.6875rem;
}

.pdg76 {
  padding: 4.75rem;
}

.pdg-top76 {
  padding-top: 4.75rem;
}

.pdg-rgt76 {
  padding-right: 4.75rem;
}

.pdg-btm76 {
  padding-bottom: 4.75rem;
}

.pdg-lft76 {
  padding-left: 4.75rem;
}

.pdg77 {
  padding: 4.8125rem;
}

.pdg-top77 {
  padding-top: 4.8125rem;
}

.pdg-rgt77 {
  padding-right: 4.8125rem;
}

.pdg-btm77 {
  padding-bottom: 4.8125rem;
}

.pdg-lft77 {
  padding-left: 4.8125rem;
}

.pdg78 {
  padding: 4.875rem;
}

.pdg-top78 {
  padding-top: 4.875rem;
}

.pdg-rgt78 {
  padding-right: 4.875rem;
}

.pdg-btm78 {
  padding-bottom: 4.875rem;
}

.pdg-lft78 {
  padding-left: 4.875rem;
}

.pdg79 {
  padding: 4.9375rem;
}

.pdg-top79 {
  padding-top: 4.9375rem;
}

.pdg-rgt79 {
  padding-right: 4.9375rem;
}

.pdg-btm79 {
  padding-bottom: 4.9375rem;
}

.pdg-lft79 {
  padding-left: 4.9375rem;
}

.pdg80 {
  padding: 5rem;
}

.pdg-top80 {
  padding-top: 5rem;
}

.pdg-rgt80 {
  padding-right: 5rem;
}

.pdg-btm80 {
  padding-bottom: 5rem;
}

.pdg-lft80 {
  padding-left: 5rem;
}

.pdg81 {
  padding: 5.0625rem;
}

.pdg-top81 {
  padding-top: 5.0625rem;
}

.pdg-rgt81 {
  padding-right: 5.0625rem;
}

.pdg-btm81 {
  padding-bottom: 5.0625rem;
}

.pdg-lft81 {
  padding-left: 5.0625rem;
}

.pdg82 {
  padding: 5.125rem;
}

.pdg-top82 {
  padding-top: 5.125rem;
}

.pdg-rgt82 {
  padding-right: 5.125rem;
}

.pdg-btm82 {
  padding-bottom: 5.125rem;
}

.pdg-lft82 {
  padding-left: 5.125rem;
}

.pdg83 {
  padding: 5.1875rem;
}

.pdg-top83 {
  padding-top: 5.1875rem;
}

.pdg-rgt83 {
  padding-right: 5.1875rem;
}

.pdg-btm83 {
  padding-bottom: 5.1875rem;
}

.pdg-lft83 {
  padding-left: 5.1875rem;
}

.pdg84 {
  padding: 5.25rem;
}

.pdg-top84 {
  padding-top: 5.25rem;
}

.pdg-rgt84 {
  padding-right: 5.25rem;
}

.pdg-btm84 {
  padding-bottom: 5.25rem;
}

.pdg-lft84 {
  padding-left: 5.25rem;
}

.pdg85 {
  padding: 5.3125rem;
}

.pdg-top85 {
  padding-top: 5.3125rem;
}

.pdg-rgt85 {
  padding-right: 5.3125rem;
}

.pdg-btm85 {
  padding-bottom: 5.3125rem;
}

.pdg-lft85 {
  padding-left: 5.3125rem;
}

.pdg86 {
  padding: 5.375rem;
}

.pdg-top86 {
  padding-top: 5.375rem;
}

.pdg-rgt86 {
  padding-right: 5.375rem;
}

.pdg-btm86 {
  padding-bottom: 5.375rem;
}

.pdg-lft86 {
  padding-left: 5.375rem;
}

.pdg87 {
  padding: 5.4375rem;
}

.pdg-top87 {
  padding-top: 5.4375rem;
}

.pdg-rgt87 {
  padding-right: 5.4375rem;
}

.pdg-btm87 {
  padding-bottom: 5.4375rem;
}

.pdg-lft87 {
  padding-left: 5.4375rem;
}

.pdg88 {
  padding: 5.5rem;
}

.pdg-top88 {
  padding-top: 5.5rem;
}

.pdg-rgt88 {
  padding-right: 5.5rem;
}

.pdg-btm88 {
  padding-bottom: 5.5rem;
}

.pdg-lft88 {
  padding-left: 5.5rem;
}

.pdg89 {
  padding: 5.5625rem;
}

.pdg-top89 {
  padding-top: 5.5625rem;
}

.pdg-rgt89 {
  padding-right: 5.5625rem;
}

.pdg-btm89 {
  padding-bottom: 5.5625rem;
}

.pdg-lft89 {
  padding-left: 5.5625rem;
}

.pdg90 {
  padding: 5.625rem;
}

.pdg-top90 {
  padding-top: 5.625rem;
}

.pdg-rgt90 {
  padding-right: 5.625rem;
}

.pdg-btm90 {
  padding-bottom: 5.625rem;
}

.pdg-lft90 {
  padding-left: 5.625rem;
}

.pdg91 {
  padding: 5.6875rem;
}

.pdg-top91 {
  padding-top: 5.6875rem;
}

.pdg-rgt91 {
  padding-right: 5.6875rem;
}

.pdg-btm91 {
  padding-bottom: 5.6875rem;
}

.pdg-lft91 {
  padding-left: 5.6875rem;
}

.pdg92 {
  padding: 5.75rem;
}

.pdg-top92 {
  padding-top: 5.75rem;
}

.pdg-rgt92 {
  padding-right: 5.75rem;
}

.pdg-btm92 {
  padding-bottom: 5.75rem;
}

.pdg-lft92 {
  padding-left: 5.75rem;
}

.pdg93 {
  padding: 5.8125rem;
}

.pdg-top93 {
  padding-top: 5.8125rem;
}

.pdg-rgt93 {
  padding-right: 5.8125rem;
}

.pdg-btm93 {
  padding-bottom: 5.8125rem;
}

.pdg-lft93 {
  padding-left: 5.8125rem;
}

.pdg94 {
  padding: 5.875rem;
}

.pdg-top94 {
  padding-top: 5.875rem;
}

.pdg-rgt94 {
  padding-right: 5.875rem;
}

.pdg-btm94 {
  padding-bottom: 5.875rem;
}

.pdg-lft94 {
  padding-left: 5.875rem;
}

.pdg95 {
  padding: 5.9375rem;
}

.pdg-top95 {
  padding-top: 5.9375rem;
}

.pdg-rgt95 {
  padding-right: 5.9375rem;
}

.pdg-btm95 {
  padding-bottom: 5.9375rem;
}

.pdg-lft95 {
  padding-left: 5.9375rem;
}

.pdg96 {
  padding: 6rem;
}

.pdg-top96 {
  padding-top: 6rem;
}

.pdg-rgt96 {
  padding-right: 6rem;
}

.pdg-btm96 {
  padding-bottom: 6rem;
}

.pdg-lft96 {
  padding-left: 6rem;
}

.pdg97 {
  padding: 6.0625rem;
}

.pdg-top97 {
  padding-top: 6.0625rem;
}

.pdg-rgt97 {
  padding-right: 6.0625rem;
}

.pdg-btm97 {
  padding-bottom: 6.0625rem;
}

.pdg-lft97 {
  padding-left: 6.0625rem;
}

.pdg98 {
  padding: 6.125rem;
}

.pdg-top98 {
  padding-top: 6.125rem;
}

.pdg-rgt98 {
  padding-right: 6.125rem;
}

.pdg-btm98 {
  padding-bottom: 6.125rem;
}

.pdg-lft98 {
  padding-left: 6.125rem;
}

.pdg99 {
  padding: 6.1875rem;
}

.pdg-top99 {
  padding-top: 6.1875rem;
}

.pdg-rgt99 {
  padding-right: 6.1875rem;
}

.pdg-btm99 {
  padding-bottom: 6.1875rem;
}

.pdg-lft99 {
  padding-left: 6.1875rem;
}

.pdg100 {
  padding: 6.25rem;
}

.pdg-top100 {
  padding-top: 6.25rem;
}

.pdg-rgt100 {
  padding-right: 6.25rem;
}

.pdg-btm100 {
  padding-bottom: 6.25rem;
}

.pdg-lft100 {
  padding-left: 6.25rem;
}

.pdg101 {
  padding: 6.3125rem;
}

.pdg-top101 {
  padding-top: 6.3125rem;
}

.pdg-rgt101 {
  padding-right: 6.3125rem;
}

.pdg-btm101 {
  padding-bottom: 6.3125rem;
}

.pdg-lft101 {
  padding-left: 6.3125rem;
}

.pdg102 {
  padding: 6.375rem;
}

.pdg-top102 {
  padding-top: 6.375rem;
}

.pdg-rgt102 {
  padding-right: 6.375rem;
}

.pdg-btm102 {
  padding-bottom: 6.375rem;
}

.pdg-lft102 {
  padding-left: 6.375rem;
}

.pdg103 {
  padding: 6.4375rem;
}

.pdg-top103 {
  padding-top: 6.4375rem;
}

.pdg-rgt103 {
  padding-right: 6.4375rem;
}

.pdg-btm103 {
  padding-bottom: 6.4375rem;
}

.pdg-lft103 {
  padding-left: 6.4375rem;
}

.pdg104 {
  padding: 6.5rem;
}

.pdg-top104 {
  padding-top: 6.5rem;
}

.pdg-rgt104 {
  padding-right: 6.5rem;
}

.pdg-btm104 {
  padding-bottom: 6.5rem;
}

.pdg-lft104 {
  padding-left: 6.5rem;
}

.pdg105 {
  padding: 6.5625rem;
}

.pdg-top105 {
  padding-top: 6.5625rem;
}

.pdg-rgt105 {
  padding-right: 6.5625rem;
}

.pdg-btm105 {
  padding-bottom: 6.5625rem;
}

.pdg-lft105 {
  padding-left: 6.5625rem;
}

.pdg106 {
  padding: 6.625rem;
}

.pdg-top106 {
  padding-top: 6.625rem;
}

.pdg-rgt106 {
  padding-right: 6.625rem;
}

.pdg-btm106 {
  padding-bottom: 6.625rem;
}

.pdg-lft106 {
  padding-left: 6.625rem;
}

.pdg107 {
  padding: 6.6875rem;
}

.pdg-top107 {
  padding-top: 6.6875rem;
}

.pdg-rgt107 {
  padding-right: 6.6875rem;
}

.pdg-btm107 {
  padding-bottom: 6.6875rem;
}

.pdg-lft107 {
  padding-left: 6.6875rem;
}

.pdg108 {
  padding: 6.75rem;
}

.pdg-top108 {
  padding-top: 6.75rem;
}

.pdg-rgt108 {
  padding-right: 6.75rem;
}

.pdg-btm108 {
  padding-bottom: 6.75rem;
}

.pdg-lft108 {
  padding-left: 6.75rem;
}

.pdg109 {
  padding: 6.8125rem;
}

.pdg-top109 {
  padding-top: 6.8125rem;
}

.pdg-rgt109 {
  padding-right: 6.8125rem;
}

.pdg-btm109 {
  padding-bottom: 6.8125rem;
}

.pdg-lft109 {
  padding-left: 6.8125rem;
}

.pdg110 {
  padding: 6.875rem;
}

.pdg-top110 {
  padding-top: 6.875rem;
}

.pdg-rgt110 {
  padding-right: 6.875rem;
}

.pdg-btm110 {
  padding-bottom: 6.875rem;
}

.pdg-lft110 {
  padding-left: 6.875rem;
}

.pdg111 {
  padding: 6.9375rem;
}

.pdg-top111 {
  padding-top: 6.9375rem;
}

.pdg-rgt111 {
  padding-right: 6.9375rem;
}

.pdg-btm111 {
  padding-bottom: 6.9375rem;
}

.pdg-lft111 {
  padding-left: 6.9375rem;
}

.pdg112 {
  padding: 7rem;
}

.pdg-top112 {
  padding-top: 7rem;
}

.pdg-rgt112 {
  padding-right: 7rem;
}

.pdg-btm112 {
  padding-bottom: 7rem;
}

.pdg-lft112 {
  padding-left: 7rem;
}

.pdg113 {
  padding: 7.0625rem;
}

.pdg-top113 {
  padding-top: 7.0625rem;
}

.pdg-rgt113 {
  padding-right: 7.0625rem;
}

.pdg-btm113 {
  padding-bottom: 7.0625rem;
}

.pdg-lft113 {
  padding-left: 7.0625rem;
}

.pdg114 {
  padding: 7.125rem;
}

.pdg-top114 {
  padding-top: 7.125rem;
}

.pdg-rgt114 {
  padding-right: 7.125rem;
}

.pdg-btm114 {
  padding-bottom: 7.125rem;
}

.pdg-lft114 {
  padding-left: 7.125rem;
}

.pdg115 {
  padding: 7.1875rem;
}

.pdg-top115 {
  padding-top: 7.1875rem;
}

.pdg-rgt115 {
  padding-right: 7.1875rem;
}

.pdg-btm115 {
  padding-bottom: 7.1875rem;
}

.pdg-lft115 {
  padding-left: 7.1875rem;
}

.pdg116 {
  padding: 7.25rem;
}

.pdg-top116 {
  padding-top: 7.25rem;
}

.pdg-rgt116 {
  padding-right: 7.25rem;
}

.pdg-btm116 {
  padding-bottom: 7.25rem;
}

.pdg-lft116 {
  padding-left: 7.25rem;
}

.pdg117 {
  padding: 7.3125rem;
}

.pdg-top117 {
  padding-top: 7.3125rem;
}

.pdg-rgt117 {
  padding-right: 7.3125rem;
}

.pdg-btm117 {
  padding-bottom: 7.3125rem;
}

.pdg-lft117 {
  padding-left: 7.3125rem;
}

.pdg118 {
  padding: 7.375rem;
}

.pdg-top118 {
  padding-top: 7.375rem;
}

.pdg-rgt118 {
  padding-right: 7.375rem;
}

.pdg-btm118 {
  padding-bottom: 7.375rem;
}

.pdg-lft118 {
  padding-left: 7.375rem;
}

.pdg119 {
  padding: 7.4375rem;
}

.pdg-top119 {
  padding-top: 7.4375rem;
}

.pdg-rgt119 {
  padding-right: 7.4375rem;
}

.pdg-btm119 {
  padding-bottom: 7.4375rem;
}

.pdg-lft119 {
  padding-left: 7.4375rem;
}

.pdg120 {
  padding: 7.5rem;
}

.pdg-top120 {
  padding-top: 7.5rem;
}

.pdg-rgt120 {
  padding-right: 7.5rem;
}

.pdg-btm120 {
  padding-bottom: 7.5rem;
}

.pdg-lft120 {
  padding-left: 7.5rem;
}

.pdg121 {
  padding: 7.5625rem;
}

.pdg-top121 {
  padding-top: 7.5625rem;
}

.pdg-rgt121 {
  padding-right: 7.5625rem;
}

.pdg-btm121 {
  padding-bottom: 7.5625rem;
}

.pdg-lft121 {
  padding-left: 7.5625rem;
}

.pdg122 {
  padding: 7.625rem;
}

.pdg-top122 {
  padding-top: 7.625rem;
}

.pdg-rgt122 {
  padding-right: 7.625rem;
}

.pdg-btm122 {
  padding-bottom: 7.625rem;
}

.pdg-lft122 {
  padding-left: 7.625rem;
}

.pdg123 {
  padding: 7.6875rem;
}

.pdg-top123 {
  padding-top: 7.6875rem;
}

.pdg-rgt123 {
  padding-right: 7.6875rem;
}

.pdg-btm123 {
  padding-bottom: 7.6875rem;
}

.pdg-lft123 {
  padding-left: 7.6875rem;
}

.pdg124 {
  padding: 7.75rem;
}

.pdg-top124 {
  padding-top: 7.75rem;
}

.pdg-rgt124 {
  padding-right: 7.75rem;
}

.pdg-btm124 {
  padding-bottom: 7.75rem;
}

.pdg-lft124 {
  padding-left: 7.75rem;
}

.pdg125 {
  padding: 7.8125rem;
}

.pdg-top125 {
  padding-top: 7.8125rem;
}

.pdg-rgt125 {
  padding-right: 7.8125rem;
}

.pdg-btm125 {
  padding-bottom: 7.8125rem;
}

.pdg-lft125 {
  padding-left: 7.8125rem;
}

.pdg126 {
  padding: 7.875rem;
}

.pdg-top126 {
  padding-top: 7.875rem;
}

.pdg-rgt126 {
  padding-right: 7.875rem;
}

.pdg-btm126 {
  padding-bottom: 7.875rem;
}

.pdg-lft126 {
  padding-left: 7.875rem;
}

.pdg127 {
  padding: 7.9375rem;
}

.pdg-top127 {
  padding-top: 7.9375rem;
}

.pdg-rgt127 {
  padding-right: 7.9375rem;
}

.pdg-btm127 {
  padding-bottom: 7.9375rem;
}

.pdg-lft127 {
  padding-left: 7.9375rem;
}

.pdg128 {
  padding: 8rem;
}

.pdg-top128 {
  padding-top: 8rem;
}

.pdg-rgt128 {
  padding-right: 8rem;
}

.pdg-btm128 {
  padding-bottom: 8rem;
}

.pdg-lft128 {
  padding-left: 8rem;
}

.pdg129 {
  padding: 8.0625rem;
}

.pdg-top129 {
  padding-top: 8.0625rem;
}

.pdg-rgt129 {
  padding-right: 8.0625rem;
}

.pdg-btm129 {
  padding-bottom: 8.0625rem;
}

.pdg-lft129 {
  padding-left: 8.0625rem;
}

.pdg130 {
  padding: 8.125rem;
}

.pdg-top130 {
  padding-top: 8.125rem;
}

.pdg-rgt130 {
  padding-right: 8.125rem;
}

.pdg-btm130 {
  padding-bottom: 8.125rem;
}

.pdg-lft130 {
  padding-left: 8.125rem;
}

.pdg131 {
  padding: 8.1875rem;
}

.pdg-top131 {
  padding-top: 8.1875rem;
}

.pdg-rgt131 {
  padding-right: 8.1875rem;
}

.pdg-btm131 {
  padding-bottom: 8.1875rem;
}

.pdg-lft131 {
  padding-left: 8.1875rem;
}

.pdg132 {
  padding: 8.25rem;
}

.pdg-top132 {
  padding-top: 8.25rem;
}

.pdg-rgt132 {
  padding-right: 8.25rem;
}

.pdg-btm132 {
  padding-bottom: 8.25rem;
}

.pdg-lft132 {
  padding-left: 8.25rem;
}

.pdg133 {
  padding: 8.3125rem;
}

.pdg-top133 {
  padding-top: 8.3125rem;
}

.pdg-rgt133 {
  padding-right: 8.3125rem;
}

.pdg-btm133 {
  padding-bottom: 8.3125rem;
}

.pdg-lft133 {
  padding-left: 8.3125rem;
}

.pdg134 {
  padding: 8.375rem;
}

.pdg-top134 {
  padding-top: 8.375rem;
}

.pdg-rgt134 {
  padding-right: 8.375rem;
}

.pdg-btm134 {
  padding-bottom: 8.375rem;
}

.pdg-lft134 {
  padding-left: 8.375rem;
}

.pdg135 {
  padding: 8.4375rem;
}

.pdg-top135 {
  padding-top: 8.4375rem;
}

.pdg-rgt135 {
  padding-right: 8.4375rem;
}

.pdg-btm135 {
  padding-bottom: 8.4375rem;
}

.pdg-lft135 {
  padding-left: 8.4375rem;
}

.pdg136 {
  padding: 8.5rem;
}

.pdg-top136 {
  padding-top: 8.5rem;
}

.pdg-rgt136 {
  padding-right: 8.5rem;
}

.pdg-btm136 {
  padding-bottom: 8.5rem;
}

.pdg-lft136 {
  padding-left: 8.5rem;
}

.pdg137 {
  padding: 8.5625rem;
}

.pdg-top137 {
  padding-top: 8.5625rem;
}

.pdg-rgt137 {
  padding-right: 8.5625rem;
}

.pdg-btm137 {
  padding-bottom: 8.5625rem;
}

.pdg-lft137 {
  padding-left: 8.5625rem;
}

.pdg138 {
  padding: 8.625rem;
}

.pdg-top138 {
  padding-top: 8.625rem;
}

.pdg-rgt138 {
  padding-right: 8.625rem;
}

.pdg-btm138 {
  padding-bottom: 8.625rem;
}

.pdg-lft138 {
  padding-left: 8.625rem;
}

.pdg139 {
  padding: 8.6875rem;
}

.pdg-top139 {
  padding-top: 8.6875rem;
}

.pdg-rgt139 {
  padding-right: 8.6875rem;
}

.pdg-btm139 {
  padding-bottom: 8.6875rem;
}

.pdg-lft139 {
  padding-left: 8.6875rem;
}

.pdg140 {
  padding: 8.75rem;
}

.pdg-top140 {
  padding-top: 8.75rem;
}

.pdg-rgt140 {
  padding-right: 8.75rem;
}

.pdg-btm140 {
  padding-bottom: 8.75rem;
}

.pdg-lft140 {
  padding-left: 8.75rem;
}

.pdg141 {
  padding: 8.8125rem;
}

.pdg-top141 {
  padding-top: 8.8125rem;
}

.pdg-rgt141 {
  padding-right: 8.8125rem;
}

.pdg-btm141 {
  padding-bottom: 8.8125rem;
}

.pdg-lft141 {
  padding-left: 8.8125rem;
}

.pdg142 {
  padding: 8.875rem;
}

.pdg-top142 {
  padding-top: 8.875rem;
}

.pdg-rgt142 {
  padding-right: 8.875rem;
}

.pdg-btm142 {
  padding-bottom: 8.875rem;
}

.pdg-lft142 {
  padding-left: 8.875rem;
}

.pdg143 {
  padding: 8.9375rem;
}

.pdg-top143 {
  padding-top: 8.9375rem;
}

.pdg-rgt143 {
  padding-right: 8.9375rem;
}

.pdg-btm143 {
  padding-bottom: 8.9375rem;
}

.pdg-lft143 {
  padding-left: 8.9375rem;
}

.pdg144 {
  padding: 9rem;
}

.pdg-top144 {
  padding-top: 9rem;
}

.pdg-rgt144 {
  padding-right: 9rem;
}

.pdg-btm144 {
  padding-bottom: 9rem;
}

.pdg-lft144 {
  padding-left: 9rem;
}

.pdg145 {
  padding: 9.0625rem;
}

.pdg-top145 {
  padding-top: 9.0625rem;
}

.pdg-rgt145 {
  padding-right: 9.0625rem;
}

.pdg-btm145 {
  padding-bottom: 9.0625rem;
}

.pdg-lft145 {
  padding-left: 9.0625rem;
}

.pdg146 {
  padding: 9.125rem;
}

.pdg-top146 {
  padding-top: 9.125rem;
}

.pdg-rgt146 {
  padding-right: 9.125rem;
}

.pdg-btm146 {
  padding-bottom: 9.125rem;
}

.pdg-lft146 {
  padding-left: 9.125rem;
}

.pdg147 {
  padding: 9.1875rem;
}

.pdg-top147 {
  padding-top: 9.1875rem;
}

.pdg-rgt147 {
  padding-right: 9.1875rem;
}

.pdg-btm147 {
  padding-bottom: 9.1875rem;
}

.pdg-lft147 {
  padding-left: 9.1875rem;
}

.pdg148 {
  padding: 9.25rem;
}

.pdg-top148 {
  padding-top: 9.25rem;
}

.pdg-rgt148 {
  padding-right: 9.25rem;
}

.pdg-btm148 {
  padding-bottom: 9.25rem;
}

.pdg-lft148 {
  padding-left: 9.25rem;
}

.pdg149 {
  padding: 9.3125rem;
}

.pdg-top149 {
  padding-top: 9.3125rem;
}

.pdg-rgt149 {
  padding-right: 9.3125rem;
}

.pdg-btm149 {
  padding-bottom: 9.3125rem;
}

.pdg-lft149 {
  padding-left: 9.3125rem;
}

.pdg150 {
  padding: 9.375rem;
}

.pdg-top150 {
  padding-top: 9.375rem;
}

.pdg-rgt150 {
  padding-right: 9.375rem;
}

.pdg-btm150 {
  padding-bottom: 9.375rem;
}

.pdg-lft150 {
  padding-left: 9.375rem;
}

/* --- position --- */
.pos-rel {
  position: relative;
}

.pos-ab {
  position: absolute;
}

.pos-fx {
  position: fixed;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

.ani-delay0 {
  animation-delay: 0ms;
}

.ani-delay100 {
  animation-delay: 100ms;
}

.ani-delay200 {
  animation-delay: 200ms;
}

.ani-delay300 {
  animation-delay: 300ms;
}

.ani-delay400 {
  animation-delay: 400ms;
}

.ani-delay500 {
  animation-delay: 500ms;
}

.ani-delay600 {
  animation-delay: 600ms;
}

.ani-delay700 {
  animation-delay: 700ms;
}

.ani-delay800 {
  animation-delay: 800ms;
}

.ani-delay900 {
  animation-delay: 900ms;
}

.ani-delay1000 {
  animation-delay: 1000ms;
}

.ani-delay1100 {
  animation-delay: 1100ms;
}

.ani-delay1200 {
  animation-delay: 1200ms;
}

.ani-delay1300 {
  animation-delay: 1300ms;
}

.ani-delay1400 {
  animation-delay: 1400ms;
}

.ani-delay1500 {
  animation-delay: 1500ms;
}

.ani-delay1600 {
  animation-delay: 1600ms;
}

.ani-delay1700 {
  animation-delay: 1700ms;
}

.ani-delay1800 {
  animation-delay: 1800ms;
}

.ani-delay1900 {
  animation-delay: 1900ms;
}

.ani-delay2000 {
  animation-delay: 2000ms;
}

.ani-delay2100 {
  animation-delay: 2100ms;
}

.ani-delay2200 {
  animation-delay: 2200ms;
}

.ani-delay2300 {
  animation-delay: 2300ms;
}

.ani-delay2400 {
  animation-delay: 2400ms;
}

.ani-delay2500 {
  animation-delay: 2500ms;
}

.ani-delay2600 {
  animation-delay: 2600ms;
}

.ani-delay2700 {
  animation-delay: 2700ms;
}

.ani-delay2800 {
  animation-delay: 2800ms;
}

.ani-delay2900 {
  animation-delay: 2900ms;
}

.ani-delay3000 {
  animation-delay: 3000ms;
}

.ani-delay3100 {
  animation-delay: 3100ms;
}

.ani-delay3200 {
  animation-delay: 3200ms;
}

.ani-delay3300 {
  animation-delay: 3300ms;
}

.ani-delay3400 {
  animation-delay: 3400ms;
}

.ani-delay3500 {
  animation-delay: 3500ms;
}

.ani-delay3600 {
  animation-delay: 3600ms;
}

.ani-delay3700 {
  animation-delay: 3700ms;
}

.ani-delay3800 {
  animation-delay: 3800ms;
}

.ani-delay3900 {
  animation-delay: 3900ms;
}

.ani-delay4000 {
  animation-delay: 4000ms;
}

.trans-delay0 {
  transition-delay: 0ms;
}

.trans-delay100 {
  transition-delay: 100ms;
}

.trans-delay200 {
  transition-delay: 200ms;
}

.trans-delay300 {
  transition-delay: 300ms;
}

.trans-delay400 {
  transition-delay: 400ms;
}

.trans-delay500 {
  transition-delay: 500ms;
}

.trans-delay600 {
  transition-delay: 600ms;
}

.trans-delay700 {
  transition-delay: 700ms;
}

.trans-delay800 {
  transition-delay: 800ms;
}

.trans-delay900 {
  transition-delay: 900ms;
}

.trans-delay1000 {
  transition-delay: 1000ms;
}

.trans-delay1100 {
  transition-delay: 1100ms;
}

.trans-delay1200 {
  transition-delay: 1200ms;
}

.trans-delay1300 {
  transition-delay: 1300ms;
}

.trans-delay1400 {
  transition-delay: 1400ms;
}

.trans-delay1500 {
  transition-delay: 1500ms;
}

.trans-delay1600 {
  transition-delay: 1600ms;
}

.trans-delay1700 {
  transition-delay: 1700ms;
}

.trans-delay1800 {
  transition-delay: 1800ms;
}

.trans-delay1900 {
  transition-delay: 1900ms;
}

.trans-delay2000 {
  transition-delay: 2000ms;
}

.trans-delay2100 {
  transition-delay: 2100ms;
}

.trans-delay2200 {
  transition-delay: 2200ms;
}

.trans-delay2300 {
  transition-delay: 2300ms;
}

.trans-delay2400 {
  transition-delay: 2400ms;
}

.trans-delay2500 {
  transition-delay: 2500ms;
}

.trans-delay2600 {
  transition-delay: 2600ms;
}

.trans-delay2700 {
  transition-delay: 2700ms;
}

.trans-delay2800 {
  transition-delay: 2800ms;
}

.trans-delay2900 {
  transition-delay: 2900ms;
}

.trans-delay3000 {
  transition-delay: 3000ms;
}

.trans-delay3100 {
  transition-delay: 3100ms;
}

.trans-delay3200 {
  transition-delay: 3200ms;
}

.trans-delay3300 {
  transition-delay: 3300ms;
}

.trans-delay3400 {
  transition-delay: 3400ms;
}

.trans-delay3500 {
  transition-delay: 3500ms;
}

.trans-delay3600 {
  transition-delay: 3600ms;
}

.trans-delay3700 {
  transition-delay: 3700ms;
}

.trans-delay3800 {
  transition-delay: 3800ms;
}

.trans-delay3900 {
  transition-delay: 3900ms;
}

.trans-delay4000 {
  transition-delay: 4000ms;
}

/* breadcrumbs
**************************************** */
.breadcrumbs .inner {
  padding: 1rem 0;
  white-space: nowrap;
}
@media print, screen and (min-width: 48em) {
  .breadcrumbs .inner {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 47.9375em) {
  .breadcrumbs .inner {
    overflow: auto;
  }
}

.breadcrumbs a:hover {
  color: var(--color-primary-default);
}

.breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: inline-block;
  width: 0.3125rem;
  height: 0.4375rem;
  clip-path: polygon(0 100%, 0 0, 100% 50%);
  background-color: #ccc;
  margin: 0.15em 0.5em 0.15em 1em;
}

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: 0.5rem;
}

.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0.875rem 2.5rem 0.875rem 2rem;
  border-radius: 9999px;
  width: fit-content;
  min-width: min(17.5rem, 100%);
  color: var(--color-primary-default);
  background-color: var(--color-wht);
  border: 1px solid;
}
@media screen and (max-width: 47.9375em) {
  .btn a {
    min-width: 100%;
  }
}
.btn a:hover {
  background-color: var(--color-primary-default);
  color: var(--color-wht);
}
.btn a:where(:not([target=_blank]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 2.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 2px solid;
  border-right: 2px solid;
  rotate: 45deg;
  transition: var(--transit-default);
}
.btn a svg {
  right: 1.5rem;
  fill: currentColor;
}

.btn.btn-ctr a {
  margin-left: auto;
  margin-right: auto;
}

.clr-wh .btn a:hover {
  background-color: #DAEEFF;
  color: var(--color-primary-default);
}
.clr-wh .btn a svg {
  color: var(--color-primary-default);
  fill: var(--color-primary-default);
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--color-primary-default);
  background-color: var(--color-wht);
}
.btn-wh a:hover {
  color: var(--color-wht);
  background-color: var(--color-primary-default);
}
.btn-wh a svg {
  fill: var(--color-primary-default);
}
.btn-wh a:hover svg {
  fill: var(--color-wht);
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--color-secondary-default);
}
.btn-cta a:hover {
  background-color: var(--color-secondary-dark);
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  margin-inline: -1em;
  padding: 1em;
}
.btn-arrow a:hover {
  color: var(--color-primary-default);
}
.btn-arrow a::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  color: var(--color-primary-default);
  transform: rotate(45deg);
  border-top: 2px solid;
  border-right: 2px solid;
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
}
.flex > .btn:first-child {
  margin-left: auto;
}
.flex > .btn:last-child {
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .flex > .btn:only-child {
    width: fit-content;
    min-width: min(17.5rem, 100%);
  }
}
.flex > .btn a {
  min-width: 100%;
  height: 100%;
}

/* ##############################################################################

  FORMY

############################################################################## */
#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  padding: 1rem;
  vertical-align: middle;
  border-bottom: solid 1px #eee;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto !important;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
}
@media print, screen and (min-width: 48em) {
  #formy_form th {
    width: 34%;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 0.1875rem;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #ccc;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: max(14px, 1rem);
  transition: var(--transit-default);
}

#formy_form textarea {
  height: 6.25rem;
}

#formy_form select {
  height: 2.5rem;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: var(--color-wht);
}

.help_text {
  font-size: max(12px, 0.875rem);
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 8.75rem;
  border: solid 1px #ccc;
  font-size: max(12px, 0.875rem);
  padding: 0.5rem 1rem;
}

.requiredIcon {
  background-color: #f55;
  color: var(--color-wht);
  margin: 0 0 0 1em;
  font-size: max(10px, 0.75rem);
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
  width: 100%;
}

#formy_btn > p {
  width: 100%;
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: var(--color-wht);
  border-radius: 0.1875rem;
  padding: 1rem 2rem;
  max-width: 400px;
  width: 100%;
  border-radius: 99px;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  transition: var(--transit-default);
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-weight: 600;
}
#formy_btn input:not(:first-child) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default);
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  border: 2px solid #788b93;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 0.625rem;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: #e75f5f;
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 0.625rem;
  height: 1.125rem;
  top: 0.25rem;
  left: 1.25rem;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-color: #4dbaff;
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 0.5rem 1.6875rem;
  min-width: auto;
  font-size: max(13px, 0.9375rem);
  border: 1px solid #aaa;
  border-radius: 2rem;
}

.formy_send {
  background-color: var(--color-primary-default);
}

#total_required {
  padding: 1rem;
  color: #f55555;
  text-align: center;
}

@media print, screen and (min-width: 48em) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: 0.5714285714rem;
  }
}
/* ##############################################################################

  FORM

############################################################################## */
.nf-form-layout form * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-content label,
.nf-form-cont .nf-field-label .nf-label-span {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .nf-form-cont .nf-form-content label,
  .nf-form-cont .nf-field-label .nf-label-span {
    font-size: 1.0714285714rem;
    margin-bottom: 6px !important;
  }
}

.nf-form-cont .nf-form-content .nf-label-span {
  font-weight: 600;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-fields-required {
  display: none !important;
}

.nf-form-cont .nf-field-element {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #ninja_forms_required_items,
  .nf-form-cont .nf-field-container {
    margin-bottom: 1.1428571429rem;
  }
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-cont .nf-form-content input:not([type=button]):not([type=submit]),
.nf-form-cont .nf-form-content textarea {
  background: var(--color-wht);
  color: var(--color-text-primary);
  border-radius: 0.1875rem;
  vertical-align: bottom;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: max(14px, 1rem);
  transition: var(--transit-default, all 0.4s ease-out);
  font-weight: 500;
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div:focus,
.nf-form-cont .nf-form-content input:not([type=button]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: var(--color-text-primary);
}

.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}

.nf-form-content input[type=checkbox]:focus + label:after,
.nf-form-content input[type=radio]:focus + label:after {
  box-shadow: none;
}

.nf-form-content input[type=radio] + label:before {
  opacity: 0;
  top: 5px !important;
  bottom: 0 !important;
}
@media screen and (max-width: 47.9375em) {
  .nf-form-content input[type=radio] + label:before {
    top: 4px !important;
  }
}

.nf-form-content input[type=radio]:checked + label:before {
  opacity: 1;
}

.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: var(--color-primary-default);
}

.nf-form-cont .ninja-forms-req-symbol {
  font-size: 0 !important;
}
.nf-form-cont .ninja-forms-req-symbol::before {
  content: "必須"; /* 必須 */
  background-color: #f55;
  color: var(--color-wht);
  margin-left: 0.5em;
  font-size: max(10px, 0.75rem);
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}

.nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
  max-width: 100%;
  width: 20rem;
  height: 4rem;
  font-size: max(16px, 1.125rem);
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--color-wht);
  background-color: var(--color-primary-default);
  border-radius: 3.125rem;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (min-width: 48em) {
  .nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
    margin-top: 1.7142857143rem;
  }
}

.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_policy {
  padding: 0;
  margin-top: 3rem;
  color: var(--color-text-primary);
  font-size: max(8px, 0.625rem);
  text-align: center;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .recaptcha_policy {
    text-align: left;
  }
}

.recaptcha_policy a {
  color: #69ABDB;
  font-size: max(10px, 0.75rem);
  text-decoration: underline;
}

.nf-field-element {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.nf-field-element input,
.nf-field-element select {
  width: 100%;
  height: 50px;
  border: 1px solid #aaa;
  font-size: 16px;
  padding: 8px 16px;
}

.nf-field-element textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #aaa;
  padding: 16px;
}

.wpcf7-acceptance .wpcf7-list-item {
  margin: 0;
}

.wpcf7-acceptance input {
  display: inline-block;
  width: 24px;
  height: 24px;
  transform: translateY(-1px);
}

.nf-field-description {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-weight: 400;
}

.wpcf7-spinner {
  display: none;
}

/* cta_box
**************************************** */
.cta_box {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .cta_box {
    flex-direction: column;
    align-items: center;
    gap: 0.7142857143rem;
  }
}

.cta_box a {
  height: 5rem;
}
@media screen and (min-width: 48em) {
  .cta_box a {
    width: 20rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .cta_box li {
    width: 100%;
  }
}
/* cta_tel
**************************************** */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.75rem;
  line-height: var(--line-height-hdr);
  border-radius: 0.25rem;
  background-color: var(--color-wht);
}

.cta_tel a > span {
  display: block;
}

.cta_tel .tel {
  font-size: max(18px, 1.25rem);
}

.cta_tel .num {
  font-size: 1.75rem;
}

.cta_tel .hours {
  font-size: max(11px, 0.8125rem);
}

/* cta_contact
**************************************** */
.cta_contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background-color: var(--color-secondary-default);
}

.cta_contact .micro_copy {
  position: absolute;
  top: calc(-1em - 0.625rem);
  inset-inline: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0.8125rem;
  color: var(--color-secondary-default);
  text-align: center;
  line-height: 1;
}
.cta_contact .micro_copy::before, .cta_contact .micro_copy::after {
  content: "";
  width: 0.125rem;
  height: 1rem;
  background-color: var(--color-secondary-default);
}
.cta_contact .micro_copy::before {
  transform: rotate(-45deg);
}
.cta_contact .micro_copy::after {
  transform: rotate(45deg);
}

/* icon
**************************************** */
.icon-mail {
  width: 1.3125rem;
  aspect-ratio: 21/16;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes txt_slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.txt_slide_deco {
  width: 100%;
  height: 12.5rem;
  overflow: hidden;
  display: flex;
  pointer-events: none;
}
@media screen and (max-width: 47.9375em) {
  .txt_slide_deco {
    height: 4rem;
  }
}

.txt_slide_deco--line {
  display: flex;
  word-break: keep-all;
  white-space: pre;
  animation: txt_slide 100s infinite linear 0.5s both;
  font-size: 12.5rem;
  line-height: 1.19;
}
@media screen and (max-width: 47.9375em) {
  .txt_slide_deco--line {
    animation: txt_slide 72s infinite linear 0.5s both;
    font-size: 4rem;
  }
}

@keyframes scale_anime {
  0% {
    scale: 1;
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    scale: 1.5;
  }
}
@keyframes bgroop {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 4469px 0;
  }
}
@keyframes sliderNav {
  0% {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/* --- section_pdg --- */
.section_pdg {
  padding-block: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-block: 2.8571428571rem;
  }
}

.section_pdg-sm {
  padding-block: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-block: 1.7142857143rem;
  }
}

/* --- blc:not(:last-child) --- */
.blc:not(:last-child) {
  margin-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .blc:not(:last-child) {
    margin-bottom: 2.8571428571rem;
  }
}

.blc-sm:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .blc-sm:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 57.5rem;
}

.inner-lg {
  max-width: 97.5rem;
}

.inner-xl {
  max-width: 110rem;
}

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -5rem;
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -4.5rem;
  }
}

/* section-contact
********************************************** */
.section-contact {
  background-color: transparent !important;
}
.section-contact .cta_tel {
  width: 100%;
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}
.section-contact .cta_tel a {
  padding: 1.5rem 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .section-contact .cta_tel a {
    display: block;
    padding: 1.1428571429rem;
  }
}

/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col1 {
  flex-wrap: wrap;
}
.flex-col1 > * {
  width: 100%;
}
.flex-col1.gap1 > * {
  width: calc(100% - 0.0625rem / 1 * 0);
}
.flex-col1.gap2 > * {
  width: calc(100% - 0.125rem / 1 * 0);
}
.flex-col1.gap3 > * {
  width: calc(100% - 0.1875rem / 1 * 0);
}
.flex-col1.gap4 > * {
  width: calc(100% - 0.25rem / 1 * 0);
}
.flex-col1.gap5 > * {
  width: calc(100% - 0.3125rem / 1 * 0);
}
.flex-col1.gap6 > * {
  width: calc(100% - 0.375rem / 1 * 0);
}
.flex-col1.gap7 > * {
  width: calc(100% - 0.4375rem / 1 * 0);
}
.flex-col1.gap8 > * {
  width: calc(100% - 0.5rem / 1 * 0);
}
.flex-col1.gap9 > * {
  width: calc(100% - 0.5625rem / 1 * 0);
}
.flex-col1.gap10 > * {
  width: calc(100% - 0.625rem / 1 * 0);
}
.flex-col1.gap11 > * {
  width: calc(100% - 0.6875rem / 1 * 0);
}
.flex-col1.gap12 > * {
  width: calc(100% - 0.75rem / 1 * 0);
}
.flex-col1.gap13 > * {
  width: calc(100% - 0.8125rem / 1 * 0);
}
.flex-col1.gap14 > * {
  width: calc(100% - 0.875rem / 1 * 0);
}
.flex-col1.gap15 > * {
  width: calc(100% - 0.9375rem / 1 * 0);
}
.flex-col1.gap16 > * {
  width: calc(100% - 1rem / 1 * 0);
}
.flex-col1.gap17 > * {
  width: calc(100% - 1.0625rem / 1 * 0);
}
.flex-col1.gap18 > * {
  width: calc(100% - 1.125rem / 1 * 0);
}
.flex-col1.gap19 > * {
  width: calc(100% - 1.1875rem / 1 * 0);
}
.flex-col1.gap20 > * {
  width: calc(100% - 1.25rem / 1 * 0);
}
.flex-col1.gap21 > * {
  width: calc(100% - 1.3125rem / 1 * 0);
}
.flex-col1.gap22 > * {
  width: calc(100% - 1.375rem / 1 * 0);
}
.flex-col1.gap23 > * {
  width: calc(100% - 1.4375rem / 1 * 0);
}
.flex-col1.gap24 > * {
  width: calc(100% - 1.5rem / 1 * 0);
}
.flex-col1.gap25 > * {
  width: calc(100% - 1.5625rem / 1 * 0);
}
.flex-col1.gap26 > * {
  width: calc(100% - 1.625rem / 1 * 0);
}
.flex-col1.gap27 > * {
  width: calc(100% - 1.6875rem / 1 * 0);
}
.flex-col1.gap28 > * {
  width: calc(100% - 1.75rem / 1 * 0);
}
.flex-col1.gap29 > * {
  width: calc(100% - 1.8125rem / 1 * 0);
}
.flex-col1.gap30 > * {
  width: calc(100% - 1.875rem / 1 * 0);
}
.flex-col1.gap31 > * {
  width: calc(100% - 1.9375rem / 1 * 0);
}
.flex-col1.gap32 > * {
  width: calc(100% - 2rem / 1 * 0);
}
.flex-col1.gap33 > * {
  width: calc(100% - 2.0625rem / 1 * 0);
}
.flex-col1.gap34 > * {
  width: calc(100% - 2.125rem / 1 * 0);
}
.flex-col1.gap35 > * {
  width: calc(100% - 2.1875rem / 1 * 0);
}
.flex-col1.gap36 > * {
  width: calc(100% - 2.25rem / 1 * 0);
}
.flex-col1.gap37 > * {
  width: calc(100% - 2.3125rem / 1 * 0);
}
.flex-col1.gap38 > * {
  width: calc(100% - 2.375rem / 1 * 0);
}
.flex-col1.gap39 > * {
  width: calc(100% - 2.4375rem / 1 * 0);
}
.flex-col1.gap40 > * {
  width: calc(100% - 2.5rem / 1 * 0);
}
.flex-col1.gap41 > * {
  width: calc(100% - 2.5625rem / 1 * 0);
}
.flex-col1.gap42 > * {
  width: calc(100% - 2.625rem / 1 * 0);
}
.flex-col1.gap43 > * {
  width: calc(100% - 2.6875rem / 1 * 0);
}
.flex-col1.gap44 > * {
  width: calc(100% - 2.75rem / 1 * 0);
}
.flex-col1.gap45 > * {
  width: calc(100% - 2.8125rem / 1 * 0);
}
.flex-col1.gap46 > * {
  width: calc(100% - 2.875rem / 1 * 0);
}
.flex-col1.gap47 > * {
  width: calc(100% - 2.9375rem / 1 * 0);
}
.flex-col1.gap48 > * {
  width: calc(100% - 3rem / 1 * 0);
}
.flex-col1.gap49 > * {
  width: calc(100% - 3.0625rem / 1 * 0);
}
.flex-col1.gap50 > * {
  width: calc(100% - 3.125rem / 1 * 0);
}
.flex-col1.gap51 > * {
  width: calc(100% - 3.1875rem / 1 * 0);
}
.flex-col1.gap52 > * {
  width: calc(100% - 3.25rem / 1 * 0);
}
.flex-col1.gap53 > * {
  width: calc(100% - 3.3125rem / 1 * 0);
}
.flex-col1.gap54 > * {
  width: calc(100% - 3.375rem / 1 * 0);
}
.flex-col1.gap55 > * {
  width: calc(100% - 3.4375rem / 1 * 0);
}
.flex-col1.gap56 > * {
  width: calc(100% - 3.5rem / 1 * 0);
}
.flex-col1.gap57 > * {
  width: calc(100% - 3.5625rem / 1 * 0);
}
.flex-col1.gap58 > * {
  width: calc(100% - 3.625rem / 1 * 0);
}
.flex-col1.gap59 > * {
  width: calc(100% - 3.6875rem / 1 * 0);
}
.flex-col1.gap60 > * {
  width: calc(100% - 3.75rem / 1 * 0);
}
.flex-col1.gap61 > * {
  width: calc(100% - 3.8125rem / 1 * 0);
}
.flex-col1.gap62 > * {
  width: calc(100% - 3.875rem / 1 * 0);
}
.flex-col1.gap63 > * {
  width: calc(100% - 3.9375rem / 1 * 0);
}
.flex-col1.gap64 > * {
  width: calc(100% - 4rem / 1 * 0);
}
.flex-col1.gap65 > * {
  width: calc(100% - 4.0625rem / 1 * 0);
}
.flex-col1.gap66 > * {
  width: calc(100% - 4.125rem / 1 * 0);
}
.flex-col1.gap67 > * {
  width: calc(100% - 4.1875rem / 1 * 0);
}
.flex-col1.gap68 > * {
  width: calc(100% - 4.25rem / 1 * 0);
}
.flex-col1.gap69 > * {
  width: calc(100% - 4.3125rem / 1 * 0);
}
.flex-col1.gap70 > * {
  width: calc(100% - 4.375rem / 1 * 0);
}
.flex-col1.gap71 > * {
  width: calc(100% - 4.4375rem / 1 * 0);
}
.flex-col1.gap72 > * {
  width: calc(100% - 4.5rem / 1 * 0);
}
.flex-col1.gap73 > * {
  width: calc(100% - 4.5625rem / 1 * 0);
}
.flex-col1.gap74 > * {
  width: calc(100% - 4.625rem / 1 * 0);
}
.flex-col1.gap75 > * {
  width: calc(100% - 4.6875rem / 1 * 0);
}
.flex-col1.gap76 > * {
  width: calc(100% - 4.75rem / 1 * 0);
}
.flex-col1.gap77 > * {
  width: calc(100% - 4.8125rem / 1 * 0);
}
.flex-col1.gap78 > * {
  width: calc(100% - 4.875rem / 1 * 0);
}
.flex-col1.gap79 > * {
  width: calc(100% - 4.9375rem / 1 * 0);
}
.flex-col1.gap80 > * {
  width: calc(100% - 5rem / 1 * 0);
}
.flex-col1.gap81 > * {
  width: calc(100% - 5.0625rem / 1 * 0);
}
.flex-col1.gap82 > * {
  width: calc(100% - 5.125rem / 1 * 0);
}
.flex-col1.gap83 > * {
  width: calc(100% - 5.1875rem / 1 * 0);
}
.flex-col1.gap84 > * {
  width: calc(100% - 5.25rem / 1 * 0);
}
.flex-col1.gap85 > * {
  width: calc(100% - 5.3125rem / 1 * 0);
}
.flex-col1.gap86 > * {
  width: calc(100% - 5.375rem / 1 * 0);
}
.flex-col1.gap87 > * {
  width: calc(100% - 5.4375rem / 1 * 0);
}
.flex-col1.gap88 > * {
  width: calc(100% - 5.5rem / 1 * 0);
}
.flex-col1.gap89 > * {
  width: calc(100% - 5.5625rem / 1 * 0);
}
.flex-col1.gap90 > * {
  width: calc(100% - 5.625rem / 1 * 0);
}
.flex-col1.gap91 > * {
  width: calc(100% - 5.6875rem / 1 * 0);
}
.flex-col1.gap92 > * {
  width: calc(100% - 5.75rem / 1 * 0);
}
.flex-col1.gap93 > * {
  width: calc(100% - 5.8125rem / 1 * 0);
}
.flex-col1.gap94 > * {
  width: calc(100% - 5.875rem / 1 * 0);
}
.flex-col1.gap95 > * {
  width: calc(100% - 5.9375rem / 1 * 0);
}
.flex-col1.gap96 > * {
  width: calc(100% - 6rem / 1 * 0);
}
.flex-col1.gap97 > * {
  width: calc(100% - 6.0625rem / 1 * 0);
}
.flex-col1.gap98 > * {
  width: calc(100% - 6.125rem / 1 * 0);
}
.flex-col1.gap99 > * {
  width: calc(100% - 6.1875rem / 1 * 0);
}
.flex-col1.gap100 > * {
  width: calc(100% - 6.25rem / 1 * 0);
}
.flex-col1.gap101 > * {
  width: calc(100% - 6.3125rem / 1 * 0);
}
.flex-col1.gap102 > * {
  width: calc(100% - 6.375rem / 1 * 0);
}
.flex-col1.gap103 > * {
  width: calc(100% - 6.4375rem / 1 * 0);
}
.flex-col1.gap104 > * {
  width: calc(100% - 6.5rem / 1 * 0);
}
.flex-col1.gap105 > * {
  width: calc(100% - 6.5625rem / 1 * 0);
}
.flex-col1.gap106 > * {
  width: calc(100% - 6.625rem / 1 * 0);
}
.flex-col1.gap107 > * {
  width: calc(100% - 6.6875rem / 1 * 0);
}
.flex-col1.gap108 > * {
  width: calc(100% - 6.75rem / 1 * 0);
}
.flex-col1.gap109 > * {
  width: calc(100% - 6.8125rem / 1 * 0);
}
.flex-col1.gap110 > * {
  width: calc(100% - 6.875rem / 1 * 0);
}
.flex-col1.gap111 > * {
  width: calc(100% - 6.9375rem / 1 * 0);
}
.flex-col1.gap112 > * {
  width: calc(100% - 7rem / 1 * 0);
}
.flex-col1.gap113 > * {
  width: calc(100% - 7.0625rem / 1 * 0);
}
.flex-col1.gap114 > * {
  width: calc(100% - 7.125rem / 1 * 0);
}
.flex-col1.gap115 > * {
  width: calc(100% - 7.1875rem / 1 * 0);
}
.flex-col1.gap116 > * {
  width: calc(100% - 7.25rem / 1 * 0);
}
.flex-col1.gap117 > * {
  width: calc(100% - 7.3125rem / 1 * 0);
}
.flex-col1.gap118 > * {
  width: calc(100% - 7.375rem / 1 * 0);
}
.flex-col1.gap119 > * {
  width: calc(100% - 7.4375rem / 1 * 0);
}
.flex-col1.gap120 > * {
  width: calc(100% - 7.5rem / 1 * 0);
}
.flex-col1.gap121 > * {
  width: calc(100% - 7.5625rem / 1 * 0);
}
.flex-col1.gap122 > * {
  width: calc(100% - 7.625rem / 1 * 0);
}
.flex-col1.gap123 > * {
  width: calc(100% - 7.6875rem / 1 * 0);
}
.flex-col1.gap124 > * {
  width: calc(100% - 7.75rem / 1 * 0);
}
.flex-col1.gap125 > * {
  width: calc(100% - 7.8125rem / 1 * 0);
}
.flex-col1.gap126 > * {
  width: calc(100% - 7.875rem / 1 * 0);
}
.flex-col1.gap127 > * {
  width: calc(100% - 7.9375rem / 1 * 0);
}
.flex-col1.gap128 > * {
  width: calc(100% - 8rem / 1 * 0);
}
.flex-col1.gap129 > * {
  width: calc(100% - 8.0625rem / 1 * 0);
}
.flex-col1.gap130 > * {
  width: calc(100% - 8.125rem / 1 * 0);
}
.flex-col1.gap131 > * {
  width: calc(100% - 8.1875rem / 1 * 0);
}
.flex-col1.gap132 > * {
  width: calc(100% - 8.25rem / 1 * 0);
}
.flex-col1.gap133 > * {
  width: calc(100% - 8.3125rem / 1 * 0);
}
.flex-col1.gap134 > * {
  width: calc(100% - 8.375rem / 1 * 0);
}
.flex-col1.gap135 > * {
  width: calc(100% - 8.4375rem / 1 * 0);
}
.flex-col1.gap136 > * {
  width: calc(100% - 8.5rem / 1 * 0);
}
.flex-col1.gap137 > * {
  width: calc(100% - 8.5625rem / 1 * 0);
}
.flex-col1.gap138 > * {
  width: calc(100% - 8.625rem / 1 * 0);
}
.flex-col1.gap139 > * {
  width: calc(100% - 8.6875rem / 1 * 0);
}
.flex-col1.gap140 > * {
  width: calc(100% - 8.75rem / 1 * 0);
}
.flex-col1.gap141 > * {
  width: calc(100% - 8.8125rem / 1 * 0);
}
.flex-col1.gap142 > * {
  width: calc(100% - 8.875rem / 1 * 0);
}
.flex-col1.gap143 > * {
  width: calc(100% - 8.9375rem / 1 * 0);
}
.flex-col1.gap144 > * {
  width: calc(100% - 9rem / 1 * 0);
}
.flex-col1.gap145 > * {
  width: calc(100% - 9.0625rem / 1 * 0);
}
.flex-col1.gap146 > * {
  width: calc(100% - 9.125rem / 1 * 0);
}
.flex-col1.gap147 > * {
  width: calc(100% - 9.1875rem / 1 * 0);
}
.flex-col1.gap148 > * {
  width: calc(100% - 9.25rem / 1 * 0);
}
.flex-col1.gap149 > * {
  width: calc(100% - 9.3125rem / 1 * 0);
}
.flex-col1.gap150 > * {
  width: calc(100% - 9.375rem / 1 * 0);
}

.flex-col2 {
  flex-wrap: wrap;
}
.flex-col2 > * {
  width: 50%;
}
.flex-col2.gap1 > * {
  width: calc(50% - 0.0625rem / 2 * 1);
}
.flex-col2.gap2 > * {
  width: calc(50% - 0.125rem / 2 * 1);
}
.flex-col2.gap3 > * {
  width: calc(50% - 0.1875rem / 2 * 1);
}
.flex-col2.gap4 > * {
  width: calc(50% - 0.25rem / 2 * 1);
}
.flex-col2.gap5 > * {
  width: calc(50% - 0.3125rem / 2 * 1);
}
.flex-col2.gap6 > * {
  width: calc(50% - 0.375rem / 2 * 1);
}
.flex-col2.gap7 > * {
  width: calc(50% - 0.4375rem / 2 * 1);
}
.flex-col2.gap8 > * {
  width: calc(50% - 0.5rem / 2 * 1);
}
.flex-col2.gap9 > * {
  width: calc(50% - 0.5625rem / 2 * 1);
}
.flex-col2.gap10 > * {
  width: calc(50% - 0.625rem / 2 * 1);
}
.flex-col2.gap11 > * {
  width: calc(50% - 0.6875rem / 2 * 1);
}
.flex-col2.gap12 > * {
  width: calc(50% - 0.75rem / 2 * 1);
}
.flex-col2.gap13 > * {
  width: calc(50% - 0.8125rem / 2 * 1);
}
.flex-col2.gap14 > * {
  width: calc(50% - 0.875rem / 2 * 1);
}
.flex-col2.gap15 > * {
  width: calc(50% - 0.9375rem / 2 * 1);
}
.flex-col2.gap16 > * {
  width: calc(50% - 1rem / 2 * 1);
}
.flex-col2.gap17 > * {
  width: calc(50% - 1.0625rem / 2 * 1);
}
.flex-col2.gap18 > * {
  width: calc(50% - 1.125rem / 2 * 1);
}
.flex-col2.gap19 > * {
  width: calc(50% - 1.1875rem / 2 * 1);
}
.flex-col2.gap20 > * {
  width: calc(50% - 1.25rem / 2 * 1);
}
.flex-col2.gap21 > * {
  width: calc(50% - 1.3125rem / 2 * 1);
}
.flex-col2.gap22 > * {
  width: calc(50% - 1.375rem / 2 * 1);
}
.flex-col2.gap23 > * {
  width: calc(50% - 1.4375rem / 2 * 1);
}
.flex-col2.gap24 > * {
  width: calc(50% - 1.5rem / 2 * 1);
}
.flex-col2.gap25 > * {
  width: calc(50% - 1.5625rem / 2 * 1);
}
.flex-col2.gap26 > * {
  width: calc(50% - 1.625rem / 2 * 1);
}
.flex-col2.gap27 > * {
  width: calc(50% - 1.6875rem / 2 * 1);
}
.flex-col2.gap28 > * {
  width: calc(50% - 1.75rem / 2 * 1);
}
.flex-col2.gap29 > * {
  width: calc(50% - 1.8125rem / 2 * 1);
}
.flex-col2.gap30 > * {
  width: calc(50% - 1.875rem / 2 * 1);
}
.flex-col2.gap31 > * {
  width: calc(50% - 1.9375rem / 2 * 1);
}
.flex-col2.gap32 > * {
  width: calc(50% - 2rem / 2 * 1);
}
.flex-col2.gap33 > * {
  width: calc(50% - 2.0625rem / 2 * 1);
}
.flex-col2.gap34 > * {
  width: calc(50% - 2.125rem / 2 * 1);
}
.flex-col2.gap35 > * {
  width: calc(50% - 2.1875rem / 2 * 1);
}
.flex-col2.gap36 > * {
  width: calc(50% - 2.25rem / 2 * 1);
}
.flex-col2.gap37 > * {
  width: calc(50% - 2.3125rem / 2 * 1);
}
.flex-col2.gap38 > * {
  width: calc(50% - 2.375rem / 2 * 1);
}
.flex-col2.gap39 > * {
  width: calc(50% - 2.4375rem / 2 * 1);
}
.flex-col2.gap40 > * {
  width: calc(50% - 2.5rem / 2 * 1);
}
.flex-col2.gap41 > * {
  width: calc(50% - 2.5625rem / 2 * 1);
}
.flex-col2.gap42 > * {
  width: calc(50% - 2.625rem / 2 * 1);
}
.flex-col2.gap43 > * {
  width: calc(50% - 2.6875rem / 2 * 1);
}
.flex-col2.gap44 > * {
  width: calc(50% - 2.75rem / 2 * 1);
}
.flex-col2.gap45 > * {
  width: calc(50% - 2.8125rem / 2 * 1);
}
.flex-col2.gap46 > * {
  width: calc(50% - 2.875rem / 2 * 1);
}
.flex-col2.gap47 > * {
  width: calc(50% - 2.9375rem / 2 * 1);
}
.flex-col2.gap48 > * {
  width: calc(50% - 3rem / 2 * 1);
}
.flex-col2.gap49 > * {
  width: calc(50% - 3.0625rem / 2 * 1);
}
.flex-col2.gap50 > * {
  width: calc(50% - 3.125rem / 2 * 1);
}
.flex-col2.gap51 > * {
  width: calc(50% - 3.1875rem / 2 * 1);
}
.flex-col2.gap52 > * {
  width: calc(50% - 3.25rem / 2 * 1);
}
.flex-col2.gap53 > * {
  width: calc(50% - 3.3125rem / 2 * 1);
}
.flex-col2.gap54 > * {
  width: calc(50% - 3.375rem / 2 * 1);
}
.flex-col2.gap55 > * {
  width: calc(50% - 3.4375rem / 2 * 1);
}
.flex-col2.gap56 > * {
  width: calc(50% - 3.5rem / 2 * 1);
}
.flex-col2.gap57 > * {
  width: calc(50% - 3.5625rem / 2 * 1);
}
.flex-col2.gap58 > * {
  width: calc(50% - 3.625rem / 2 * 1);
}
.flex-col2.gap59 > * {
  width: calc(50% - 3.6875rem / 2 * 1);
}
.flex-col2.gap60 > * {
  width: calc(50% - 3.75rem / 2 * 1);
}
.flex-col2.gap61 > * {
  width: calc(50% - 3.8125rem / 2 * 1);
}
.flex-col2.gap62 > * {
  width: calc(50% - 3.875rem / 2 * 1);
}
.flex-col2.gap63 > * {
  width: calc(50% - 3.9375rem / 2 * 1);
}
.flex-col2.gap64 > * {
  width: calc(50% - 4rem / 2 * 1);
}
.flex-col2.gap65 > * {
  width: calc(50% - 4.0625rem / 2 * 1);
}
.flex-col2.gap66 > * {
  width: calc(50% - 4.125rem / 2 * 1);
}
.flex-col2.gap67 > * {
  width: calc(50% - 4.1875rem / 2 * 1);
}
.flex-col2.gap68 > * {
  width: calc(50% - 4.25rem / 2 * 1);
}
.flex-col2.gap69 > * {
  width: calc(50% - 4.3125rem / 2 * 1);
}
.flex-col2.gap70 > * {
  width: calc(50% - 4.375rem / 2 * 1);
}
.flex-col2.gap71 > * {
  width: calc(50% - 4.4375rem / 2 * 1);
}
.flex-col2.gap72 > * {
  width: calc(50% - 4.5rem / 2 * 1);
}
.flex-col2.gap73 > * {
  width: calc(50% - 4.5625rem / 2 * 1);
}
.flex-col2.gap74 > * {
  width: calc(50% - 4.625rem / 2 * 1);
}
.flex-col2.gap75 > * {
  width: calc(50% - 4.6875rem / 2 * 1);
}
.flex-col2.gap76 > * {
  width: calc(50% - 4.75rem / 2 * 1);
}
.flex-col2.gap77 > * {
  width: calc(50% - 4.8125rem / 2 * 1);
}
.flex-col2.gap78 > * {
  width: calc(50% - 4.875rem / 2 * 1);
}
.flex-col2.gap79 > * {
  width: calc(50% - 4.9375rem / 2 * 1);
}
.flex-col2.gap80 > * {
  width: calc(50% - 5rem / 2 * 1);
}
.flex-col2.gap81 > * {
  width: calc(50% - 5.0625rem / 2 * 1);
}
.flex-col2.gap82 > * {
  width: calc(50% - 5.125rem / 2 * 1);
}
.flex-col2.gap83 > * {
  width: calc(50% - 5.1875rem / 2 * 1);
}
.flex-col2.gap84 > * {
  width: calc(50% - 5.25rem / 2 * 1);
}
.flex-col2.gap85 > * {
  width: calc(50% - 5.3125rem / 2 * 1);
}
.flex-col2.gap86 > * {
  width: calc(50% - 5.375rem / 2 * 1);
}
.flex-col2.gap87 > * {
  width: calc(50% - 5.4375rem / 2 * 1);
}
.flex-col2.gap88 > * {
  width: calc(50% - 5.5rem / 2 * 1);
}
.flex-col2.gap89 > * {
  width: calc(50% - 5.5625rem / 2 * 1);
}
.flex-col2.gap90 > * {
  width: calc(50% - 5.625rem / 2 * 1);
}
.flex-col2.gap91 > * {
  width: calc(50% - 5.6875rem / 2 * 1);
}
.flex-col2.gap92 > * {
  width: calc(50% - 5.75rem / 2 * 1);
}
.flex-col2.gap93 > * {
  width: calc(50% - 5.8125rem / 2 * 1);
}
.flex-col2.gap94 > * {
  width: calc(50% - 5.875rem / 2 * 1);
}
.flex-col2.gap95 > * {
  width: calc(50% - 5.9375rem / 2 * 1);
}
.flex-col2.gap96 > * {
  width: calc(50% - 6rem / 2 * 1);
}
.flex-col2.gap97 > * {
  width: calc(50% - 6.0625rem / 2 * 1);
}
.flex-col2.gap98 > * {
  width: calc(50% - 6.125rem / 2 * 1);
}
.flex-col2.gap99 > * {
  width: calc(50% - 6.1875rem / 2 * 1);
}
.flex-col2.gap100 > * {
  width: calc(50% - 6.25rem / 2 * 1);
}
.flex-col2.gap101 > * {
  width: calc(50% - 6.3125rem / 2 * 1);
}
.flex-col2.gap102 > * {
  width: calc(50% - 6.375rem / 2 * 1);
}
.flex-col2.gap103 > * {
  width: calc(50% - 6.4375rem / 2 * 1);
}
.flex-col2.gap104 > * {
  width: calc(50% - 6.5rem / 2 * 1);
}
.flex-col2.gap105 > * {
  width: calc(50% - 6.5625rem / 2 * 1);
}
.flex-col2.gap106 > * {
  width: calc(50% - 6.625rem / 2 * 1);
}
.flex-col2.gap107 > * {
  width: calc(50% - 6.6875rem / 2 * 1);
}
.flex-col2.gap108 > * {
  width: calc(50% - 6.75rem / 2 * 1);
}
.flex-col2.gap109 > * {
  width: calc(50% - 6.8125rem / 2 * 1);
}
.flex-col2.gap110 > * {
  width: calc(50% - 6.875rem / 2 * 1);
}
.flex-col2.gap111 > * {
  width: calc(50% - 6.9375rem / 2 * 1);
}
.flex-col2.gap112 > * {
  width: calc(50% - 7rem / 2 * 1);
}
.flex-col2.gap113 > * {
  width: calc(50% - 7.0625rem / 2 * 1);
}
.flex-col2.gap114 > * {
  width: calc(50% - 7.125rem / 2 * 1);
}
.flex-col2.gap115 > * {
  width: calc(50% - 7.1875rem / 2 * 1);
}
.flex-col2.gap116 > * {
  width: calc(50% - 7.25rem / 2 * 1);
}
.flex-col2.gap117 > * {
  width: calc(50% - 7.3125rem / 2 * 1);
}
.flex-col2.gap118 > * {
  width: calc(50% - 7.375rem / 2 * 1);
}
.flex-col2.gap119 > * {
  width: calc(50% - 7.4375rem / 2 * 1);
}
.flex-col2.gap120 > * {
  width: calc(50% - 7.5rem / 2 * 1);
}
.flex-col2.gap121 > * {
  width: calc(50% - 7.5625rem / 2 * 1);
}
.flex-col2.gap122 > * {
  width: calc(50% - 7.625rem / 2 * 1);
}
.flex-col2.gap123 > * {
  width: calc(50% - 7.6875rem / 2 * 1);
}
.flex-col2.gap124 > * {
  width: calc(50% - 7.75rem / 2 * 1);
}
.flex-col2.gap125 > * {
  width: calc(50% - 7.8125rem / 2 * 1);
}
.flex-col2.gap126 > * {
  width: calc(50% - 7.875rem / 2 * 1);
}
.flex-col2.gap127 > * {
  width: calc(50% - 7.9375rem / 2 * 1);
}
.flex-col2.gap128 > * {
  width: calc(50% - 8rem / 2 * 1);
}
.flex-col2.gap129 > * {
  width: calc(50% - 8.0625rem / 2 * 1);
}
.flex-col2.gap130 > * {
  width: calc(50% - 8.125rem / 2 * 1);
}
.flex-col2.gap131 > * {
  width: calc(50% - 8.1875rem / 2 * 1);
}
.flex-col2.gap132 > * {
  width: calc(50% - 8.25rem / 2 * 1);
}
.flex-col2.gap133 > * {
  width: calc(50% - 8.3125rem / 2 * 1);
}
.flex-col2.gap134 > * {
  width: calc(50% - 8.375rem / 2 * 1);
}
.flex-col2.gap135 > * {
  width: calc(50% - 8.4375rem / 2 * 1);
}
.flex-col2.gap136 > * {
  width: calc(50% - 8.5rem / 2 * 1);
}
.flex-col2.gap137 > * {
  width: calc(50% - 8.5625rem / 2 * 1);
}
.flex-col2.gap138 > * {
  width: calc(50% - 8.625rem / 2 * 1);
}
.flex-col2.gap139 > * {
  width: calc(50% - 8.6875rem / 2 * 1);
}
.flex-col2.gap140 > * {
  width: calc(50% - 8.75rem / 2 * 1);
}
.flex-col2.gap141 > * {
  width: calc(50% - 8.8125rem / 2 * 1);
}
.flex-col2.gap142 > * {
  width: calc(50% - 8.875rem / 2 * 1);
}
.flex-col2.gap143 > * {
  width: calc(50% - 8.9375rem / 2 * 1);
}
.flex-col2.gap144 > * {
  width: calc(50% - 9rem / 2 * 1);
}
.flex-col2.gap145 > * {
  width: calc(50% - 9.0625rem / 2 * 1);
}
.flex-col2.gap146 > * {
  width: calc(50% - 9.125rem / 2 * 1);
}
.flex-col2.gap147 > * {
  width: calc(50% - 9.1875rem / 2 * 1);
}
.flex-col2.gap148 > * {
  width: calc(50% - 9.25rem / 2 * 1);
}
.flex-col2.gap149 > * {
  width: calc(50% - 9.3125rem / 2 * 1);
}
.flex-col2.gap150 > * {
  width: calc(50% - 9.375rem / 2 * 1);
}

.flex-col3 {
  flex-wrap: wrap;
}
.flex-col3 > * {
  width: 33.3333333333%;
}
.flex-col3.gap1 > * {
  width: calc(33.3333333333% - 0.0625rem / 3 * 2);
}
.flex-col3.gap2 > * {
  width: calc(33.3333333333% - 0.125rem / 3 * 2);
}
.flex-col3.gap3 > * {
  width: calc(33.3333333333% - 0.1875rem / 3 * 2);
}
.flex-col3.gap4 > * {
  width: calc(33.3333333333% - 0.25rem / 3 * 2);
}
.flex-col3.gap5 > * {
  width: calc(33.3333333333% - 0.3125rem / 3 * 2);
}
.flex-col3.gap6 > * {
  width: calc(33.3333333333% - 0.375rem / 3 * 2);
}
.flex-col3.gap7 > * {
  width: calc(33.3333333333% - 0.4375rem / 3 * 2);
}
.flex-col3.gap8 > * {
  width: calc(33.3333333333% - 0.5rem / 3 * 2);
}
.flex-col3.gap9 > * {
  width: calc(33.3333333333% - 0.5625rem / 3 * 2);
}
.flex-col3.gap10 > * {
  width: calc(33.3333333333% - 0.625rem / 3 * 2);
}
.flex-col3.gap11 > * {
  width: calc(33.3333333333% - 0.6875rem / 3 * 2);
}
.flex-col3.gap12 > * {
  width: calc(33.3333333333% - 0.75rem / 3 * 2);
}
.flex-col3.gap13 > * {
  width: calc(33.3333333333% - 0.8125rem / 3 * 2);
}
.flex-col3.gap14 > * {
  width: calc(33.3333333333% - 0.875rem / 3 * 2);
}
.flex-col3.gap15 > * {
  width: calc(33.3333333333% - 0.9375rem / 3 * 2);
}
.flex-col3.gap16 > * {
  width: calc(33.3333333333% - 1rem / 3 * 2);
}
.flex-col3.gap17 > * {
  width: calc(33.3333333333% - 1.0625rem / 3 * 2);
}
.flex-col3.gap18 > * {
  width: calc(33.3333333333% - 1.125rem / 3 * 2);
}
.flex-col3.gap19 > * {
  width: calc(33.3333333333% - 1.1875rem / 3 * 2);
}
.flex-col3.gap20 > * {
  width: calc(33.3333333333% - 1.25rem / 3 * 2);
}
.flex-col3.gap21 > * {
  width: calc(33.3333333333% - 1.3125rem / 3 * 2);
}
.flex-col3.gap22 > * {
  width: calc(33.3333333333% - 1.375rem / 3 * 2);
}
.flex-col3.gap23 > * {
  width: calc(33.3333333333% - 1.4375rem / 3 * 2);
}
.flex-col3.gap24 > * {
  width: calc(33.3333333333% - 1.5rem / 3 * 2);
}
.flex-col3.gap25 > * {
  width: calc(33.3333333333% - 1.5625rem / 3 * 2);
}
.flex-col3.gap26 > * {
  width: calc(33.3333333333% - 1.625rem / 3 * 2);
}
.flex-col3.gap27 > * {
  width: calc(33.3333333333% - 1.6875rem / 3 * 2);
}
.flex-col3.gap28 > * {
  width: calc(33.3333333333% - 1.75rem / 3 * 2);
}
.flex-col3.gap29 > * {
  width: calc(33.3333333333% - 1.8125rem / 3 * 2);
}
.flex-col3.gap30 > * {
  width: calc(33.3333333333% - 1.875rem / 3 * 2);
}
.flex-col3.gap31 > * {
  width: calc(33.3333333333% - 1.9375rem / 3 * 2);
}
.flex-col3.gap32 > * {
  width: calc(33.3333333333% - 2rem / 3 * 2);
}
.flex-col3.gap33 > * {
  width: calc(33.3333333333% - 2.0625rem / 3 * 2);
}
.flex-col3.gap34 > * {
  width: calc(33.3333333333% - 2.125rem / 3 * 2);
}
.flex-col3.gap35 > * {
  width: calc(33.3333333333% - 2.1875rem / 3 * 2);
}
.flex-col3.gap36 > * {
  width: calc(33.3333333333% - 2.25rem / 3 * 2);
}
.flex-col3.gap37 > * {
  width: calc(33.3333333333% - 2.3125rem / 3 * 2);
}
.flex-col3.gap38 > * {
  width: calc(33.3333333333% - 2.375rem / 3 * 2);
}
.flex-col3.gap39 > * {
  width: calc(33.3333333333% - 2.4375rem / 3 * 2);
}
.flex-col3.gap40 > * {
  width: calc(33.3333333333% - 2.5rem / 3 * 2);
}
.flex-col3.gap41 > * {
  width: calc(33.3333333333% - 2.5625rem / 3 * 2);
}
.flex-col3.gap42 > * {
  width: calc(33.3333333333% - 2.625rem / 3 * 2);
}
.flex-col3.gap43 > * {
  width: calc(33.3333333333% - 2.6875rem / 3 * 2);
}
.flex-col3.gap44 > * {
  width: calc(33.3333333333% - 2.75rem / 3 * 2);
}
.flex-col3.gap45 > * {
  width: calc(33.3333333333% - 2.8125rem / 3 * 2);
}
.flex-col3.gap46 > * {
  width: calc(33.3333333333% - 2.875rem / 3 * 2);
}
.flex-col3.gap47 > * {
  width: calc(33.3333333333% - 2.9375rem / 3 * 2);
}
.flex-col3.gap48 > * {
  width: calc(33.3333333333% - 3rem / 3 * 2);
}
.flex-col3.gap49 > * {
  width: calc(33.3333333333% - 3.0625rem / 3 * 2);
}
.flex-col3.gap50 > * {
  width: calc(33.3333333333% - 3.125rem / 3 * 2);
}
.flex-col3.gap51 > * {
  width: calc(33.3333333333% - 3.1875rem / 3 * 2);
}
.flex-col3.gap52 > * {
  width: calc(33.3333333333% - 3.25rem / 3 * 2);
}
.flex-col3.gap53 > * {
  width: calc(33.3333333333% - 3.3125rem / 3 * 2);
}
.flex-col3.gap54 > * {
  width: calc(33.3333333333% - 3.375rem / 3 * 2);
}
.flex-col3.gap55 > * {
  width: calc(33.3333333333% - 3.4375rem / 3 * 2);
}
.flex-col3.gap56 > * {
  width: calc(33.3333333333% - 3.5rem / 3 * 2);
}
.flex-col3.gap57 > * {
  width: calc(33.3333333333% - 3.5625rem / 3 * 2);
}
.flex-col3.gap58 > * {
  width: calc(33.3333333333% - 3.625rem / 3 * 2);
}
.flex-col3.gap59 > * {
  width: calc(33.3333333333% - 3.6875rem / 3 * 2);
}
.flex-col3.gap60 > * {
  width: calc(33.3333333333% - 3.75rem / 3 * 2);
}
.flex-col3.gap61 > * {
  width: calc(33.3333333333% - 3.8125rem / 3 * 2);
}
.flex-col3.gap62 > * {
  width: calc(33.3333333333% - 3.875rem / 3 * 2);
}
.flex-col3.gap63 > * {
  width: calc(33.3333333333% - 3.9375rem / 3 * 2);
}
.flex-col3.gap64 > * {
  width: calc(33.3333333333% - 4rem / 3 * 2);
}
.flex-col3.gap65 > * {
  width: calc(33.3333333333% - 4.0625rem / 3 * 2);
}
.flex-col3.gap66 > * {
  width: calc(33.3333333333% - 4.125rem / 3 * 2);
}
.flex-col3.gap67 > * {
  width: calc(33.3333333333% - 4.1875rem / 3 * 2);
}
.flex-col3.gap68 > * {
  width: calc(33.3333333333% - 4.25rem / 3 * 2);
}
.flex-col3.gap69 > * {
  width: calc(33.3333333333% - 4.3125rem / 3 * 2);
}
.flex-col3.gap70 > * {
  width: calc(33.3333333333% - 4.375rem / 3 * 2);
}
.flex-col3.gap71 > * {
  width: calc(33.3333333333% - 4.4375rem / 3 * 2);
}
.flex-col3.gap72 > * {
  width: calc(33.3333333333% - 4.5rem / 3 * 2);
}
.flex-col3.gap73 > * {
  width: calc(33.3333333333% - 4.5625rem / 3 * 2);
}
.flex-col3.gap74 > * {
  width: calc(33.3333333333% - 4.625rem / 3 * 2);
}
.flex-col3.gap75 > * {
  width: calc(33.3333333333% - 4.6875rem / 3 * 2);
}
.flex-col3.gap76 > * {
  width: calc(33.3333333333% - 4.75rem / 3 * 2);
}
.flex-col3.gap77 > * {
  width: calc(33.3333333333% - 4.8125rem / 3 * 2);
}
.flex-col3.gap78 > * {
  width: calc(33.3333333333% - 4.875rem / 3 * 2);
}
.flex-col3.gap79 > * {
  width: calc(33.3333333333% - 4.9375rem / 3 * 2);
}
.flex-col3.gap80 > * {
  width: calc(33.3333333333% - 5rem / 3 * 2);
}
.flex-col3.gap81 > * {
  width: calc(33.3333333333% - 5.0625rem / 3 * 2);
}
.flex-col3.gap82 > * {
  width: calc(33.3333333333% - 5.125rem / 3 * 2);
}
.flex-col3.gap83 > * {
  width: calc(33.3333333333% - 5.1875rem / 3 * 2);
}
.flex-col3.gap84 > * {
  width: calc(33.3333333333% - 5.25rem / 3 * 2);
}
.flex-col3.gap85 > * {
  width: calc(33.3333333333% - 5.3125rem / 3 * 2);
}
.flex-col3.gap86 > * {
  width: calc(33.3333333333% - 5.375rem / 3 * 2);
}
.flex-col3.gap87 > * {
  width: calc(33.3333333333% - 5.4375rem / 3 * 2);
}
.flex-col3.gap88 > * {
  width: calc(33.3333333333% - 5.5rem / 3 * 2);
}
.flex-col3.gap89 > * {
  width: calc(33.3333333333% - 5.5625rem / 3 * 2);
}
.flex-col3.gap90 > * {
  width: calc(33.3333333333% - 5.625rem / 3 * 2);
}
.flex-col3.gap91 > * {
  width: calc(33.3333333333% - 5.6875rem / 3 * 2);
}
.flex-col3.gap92 > * {
  width: calc(33.3333333333% - 5.75rem / 3 * 2);
}
.flex-col3.gap93 > * {
  width: calc(33.3333333333% - 5.8125rem / 3 * 2);
}
.flex-col3.gap94 > * {
  width: calc(33.3333333333% - 5.875rem / 3 * 2);
}
.flex-col3.gap95 > * {
  width: calc(33.3333333333% - 5.9375rem / 3 * 2);
}
.flex-col3.gap96 > * {
  width: calc(33.3333333333% - 6rem / 3 * 2);
}
.flex-col3.gap97 > * {
  width: calc(33.3333333333% - 6.0625rem / 3 * 2);
}
.flex-col3.gap98 > * {
  width: calc(33.3333333333% - 6.125rem / 3 * 2);
}
.flex-col3.gap99 > * {
  width: calc(33.3333333333% - 6.1875rem / 3 * 2);
}
.flex-col3.gap100 > * {
  width: calc(33.3333333333% - 6.25rem / 3 * 2);
}
.flex-col3.gap101 > * {
  width: calc(33.3333333333% - 6.3125rem / 3 * 2);
}
.flex-col3.gap102 > * {
  width: calc(33.3333333333% - 6.375rem / 3 * 2);
}
.flex-col3.gap103 > * {
  width: calc(33.3333333333% - 6.4375rem / 3 * 2);
}
.flex-col3.gap104 > * {
  width: calc(33.3333333333% - 6.5rem / 3 * 2);
}
.flex-col3.gap105 > * {
  width: calc(33.3333333333% - 6.5625rem / 3 * 2);
}
.flex-col3.gap106 > * {
  width: calc(33.3333333333% - 6.625rem / 3 * 2);
}
.flex-col3.gap107 > * {
  width: calc(33.3333333333% - 6.6875rem / 3 * 2);
}
.flex-col3.gap108 > * {
  width: calc(33.3333333333% - 6.75rem / 3 * 2);
}
.flex-col3.gap109 > * {
  width: calc(33.3333333333% - 6.8125rem / 3 * 2);
}
.flex-col3.gap110 > * {
  width: calc(33.3333333333% - 6.875rem / 3 * 2);
}
.flex-col3.gap111 > * {
  width: calc(33.3333333333% - 6.9375rem / 3 * 2);
}
.flex-col3.gap112 > * {
  width: calc(33.3333333333% - 7rem / 3 * 2);
}
.flex-col3.gap113 > * {
  width: calc(33.3333333333% - 7.0625rem / 3 * 2);
}
.flex-col3.gap114 > * {
  width: calc(33.3333333333% - 7.125rem / 3 * 2);
}
.flex-col3.gap115 > * {
  width: calc(33.3333333333% - 7.1875rem / 3 * 2);
}
.flex-col3.gap116 > * {
  width: calc(33.3333333333% - 7.25rem / 3 * 2);
}
.flex-col3.gap117 > * {
  width: calc(33.3333333333% - 7.3125rem / 3 * 2);
}
.flex-col3.gap118 > * {
  width: calc(33.3333333333% - 7.375rem / 3 * 2);
}
.flex-col3.gap119 > * {
  width: calc(33.3333333333% - 7.4375rem / 3 * 2);
}
.flex-col3.gap120 > * {
  width: calc(33.3333333333% - 7.5rem / 3 * 2);
}
.flex-col3.gap121 > * {
  width: calc(33.3333333333% - 7.5625rem / 3 * 2);
}
.flex-col3.gap122 > * {
  width: calc(33.3333333333% - 7.625rem / 3 * 2);
}
.flex-col3.gap123 > * {
  width: calc(33.3333333333% - 7.6875rem / 3 * 2);
}
.flex-col3.gap124 > * {
  width: calc(33.3333333333% - 7.75rem / 3 * 2);
}
.flex-col3.gap125 > * {
  width: calc(33.3333333333% - 7.8125rem / 3 * 2);
}
.flex-col3.gap126 > * {
  width: calc(33.3333333333% - 7.875rem / 3 * 2);
}
.flex-col3.gap127 > * {
  width: calc(33.3333333333% - 7.9375rem / 3 * 2);
}
.flex-col3.gap128 > * {
  width: calc(33.3333333333% - 8rem / 3 * 2);
}
.flex-col3.gap129 > * {
  width: calc(33.3333333333% - 8.0625rem / 3 * 2);
}
.flex-col3.gap130 > * {
  width: calc(33.3333333333% - 8.125rem / 3 * 2);
}
.flex-col3.gap131 > * {
  width: calc(33.3333333333% - 8.1875rem / 3 * 2);
}
.flex-col3.gap132 > * {
  width: calc(33.3333333333% - 8.25rem / 3 * 2);
}
.flex-col3.gap133 > * {
  width: calc(33.3333333333% - 8.3125rem / 3 * 2);
}
.flex-col3.gap134 > * {
  width: calc(33.3333333333% - 8.375rem / 3 * 2);
}
.flex-col3.gap135 > * {
  width: calc(33.3333333333% - 8.4375rem / 3 * 2);
}
.flex-col3.gap136 > * {
  width: calc(33.3333333333% - 8.5rem / 3 * 2);
}
.flex-col3.gap137 > * {
  width: calc(33.3333333333% - 8.5625rem / 3 * 2);
}
.flex-col3.gap138 > * {
  width: calc(33.3333333333% - 8.625rem / 3 * 2);
}
.flex-col3.gap139 > * {
  width: calc(33.3333333333% - 8.6875rem / 3 * 2);
}
.flex-col3.gap140 > * {
  width: calc(33.3333333333% - 8.75rem / 3 * 2);
}
.flex-col3.gap141 > * {
  width: calc(33.3333333333% - 8.8125rem / 3 * 2);
}
.flex-col3.gap142 > * {
  width: calc(33.3333333333% - 8.875rem / 3 * 2);
}
.flex-col3.gap143 > * {
  width: calc(33.3333333333% - 8.9375rem / 3 * 2);
}
.flex-col3.gap144 > * {
  width: calc(33.3333333333% - 9rem / 3 * 2);
}
.flex-col3.gap145 > * {
  width: calc(33.3333333333% - 9.0625rem / 3 * 2);
}
.flex-col3.gap146 > * {
  width: calc(33.3333333333% - 9.125rem / 3 * 2);
}
.flex-col3.gap147 > * {
  width: calc(33.3333333333% - 9.1875rem / 3 * 2);
}
.flex-col3.gap148 > * {
  width: calc(33.3333333333% - 9.25rem / 3 * 2);
}
.flex-col3.gap149 > * {
  width: calc(33.3333333333% - 9.3125rem / 3 * 2);
}
.flex-col3.gap150 > * {
  width: calc(33.3333333333% - 9.375rem / 3 * 2);
}

.flex-col4 {
  flex-wrap: wrap;
}
.flex-col4 > * {
  width: 25%;
}
.flex-col4.gap1 > * {
  width: calc(25% - 0.0625rem / 4 * 3);
}
.flex-col4.gap2 > * {
  width: calc(25% - 0.125rem / 4 * 3);
}
.flex-col4.gap3 > * {
  width: calc(25% - 0.1875rem / 4 * 3);
}
.flex-col4.gap4 > * {
  width: calc(25% - 0.25rem / 4 * 3);
}
.flex-col4.gap5 > * {
  width: calc(25% - 0.3125rem / 4 * 3);
}
.flex-col4.gap6 > * {
  width: calc(25% - 0.375rem / 4 * 3);
}
.flex-col4.gap7 > * {
  width: calc(25% - 0.4375rem / 4 * 3);
}
.flex-col4.gap8 > * {
  width: calc(25% - 0.5rem / 4 * 3);
}
.flex-col4.gap9 > * {
  width: calc(25% - 0.5625rem / 4 * 3);
}
.flex-col4.gap10 > * {
  width: calc(25% - 0.625rem / 4 * 3);
}
.flex-col4.gap11 > * {
  width: calc(25% - 0.6875rem / 4 * 3);
}
.flex-col4.gap12 > * {
  width: calc(25% - 0.75rem / 4 * 3);
}
.flex-col4.gap13 > * {
  width: calc(25% - 0.8125rem / 4 * 3);
}
.flex-col4.gap14 > * {
  width: calc(25% - 0.875rem / 4 * 3);
}
.flex-col4.gap15 > * {
  width: calc(25% - 0.9375rem / 4 * 3);
}
.flex-col4.gap16 > * {
  width: calc(25% - 1rem / 4 * 3);
}
.flex-col4.gap17 > * {
  width: calc(25% - 1.0625rem / 4 * 3);
}
.flex-col4.gap18 > * {
  width: calc(25% - 1.125rem / 4 * 3);
}
.flex-col4.gap19 > * {
  width: calc(25% - 1.1875rem / 4 * 3);
}
.flex-col4.gap20 > * {
  width: calc(25% - 1.25rem / 4 * 3);
}
.flex-col4.gap21 > * {
  width: calc(25% - 1.3125rem / 4 * 3);
}
.flex-col4.gap22 > * {
  width: calc(25% - 1.375rem / 4 * 3);
}
.flex-col4.gap23 > * {
  width: calc(25% - 1.4375rem / 4 * 3);
}
.flex-col4.gap24 > * {
  width: calc(25% - 1.5rem / 4 * 3);
}
.flex-col4.gap25 > * {
  width: calc(25% - 1.5625rem / 4 * 3);
}
.flex-col4.gap26 > * {
  width: calc(25% - 1.625rem / 4 * 3);
}
.flex-col4.gap27 > * {
  width: calc(25% - 1.6875rem / 4 * 3);
}
.flex-col4.gap28 > * {
  width: calc(25% - 1.75rem / 4 * 3);
}
.flex-col4.gap29 > * {
  width: calc(25% - 1.8125rem / 4 * 3);
}
.flex-col4.gap30 > * {
  width: calc(25% - 1.875rem / 4 * 3);
}
.flex-col4.gap31 > * {
  width: calc(25% - 1.9375rem / 4 * 3);
}
.flex-col4.gap32 > * {
  width: calc(25% - 2rem / 4 * 3);
}
.flex-col4.gap33 > * {
  width: calc(25% - 2.0625rem / 4 * 3);
}
.flex-col4.gap34 > * {
  width: calc(25% - 2.125rem / 4 * 3);
}
.flex-col4.gap35 > * {
  width: calc(25% - 2.1875rem / 4 * 3);
}
.flex-col4.gap36 > * {
  width: calc(25% - 2.25rem / 4 * 3);
}
.flex-col4.gap37 > * {
  width: calc(25% - 2.3125rem / 4 * 3);
}
.flex-col4.gap38 > * {
  width: calc(25% - 2.375rem / 4 * 3);
}
.flex-col4.gap39 > * {
  width: calc(25% - 2.4375rem / 4 * 3);
}
.flex-col4.gap40 > * {
  width: calc(25% - 2.5rem / 4 * 3);
}
.flex-col4.gap41 > * {
  width: calc(25% - 2.5625rem / 4 * 3);
}
.flex-col4.gap42 > * {
  width: calc(25% - 2.625rem / 4 * 3);
}
.flex-col4.gap43 > * {
  width: calc(25% - 2.6875rem / 4 * 3);
}
.flex-col4.gap44 > * {
  width: calc(25% - 2.75rem / 4 * 3);
}
.flex-col4.gap45 > * {
  width: calc(25% - 2.8125rem / 4 * 3);
}
.flex-col4.gap46 > * {
  width: calc(25% - 2.875rem / 4 * 3);
}
.flex-col4.gap47 > * {
  width: calc(25% - 2.9375rem / 4 * 3);
}
.flex-col4.gap48 > * {
  width: calc(25% - 3rem / 4 * 3);
}
.flex-col4.gap49 > * {
  width: calc(25% - 3.0625rem / 4 * 3);
}
.flex-col4.gap50 > * {
  width: calc(25% - 3.125rem / 4 * 3);
}
.flex-col4.gap51 > * {
  width: calc(25% - 3.1875rem / 4 * 3);
}
.flex-col4.gap52 > * {
  width: calc(25% - 3.25rem / 4 * 3);
}
.flex-col4.gap53 > * {
  width: calc(25% - 3.3125rem / 4 * 3);
}
.flex-col4.gap54 > * {
  width: calc(25% - 3.375rem / 4 * 3);
}
.flex-col4.gap55 > * {
  width: calc(25% - 3.4375rem / 4 * 3);
}
.flex-col4.gap56 > * {
  width: calc(25% - 3.5rem / 4 * 3);
}
.flex-col4.gap57 > * {
  width: calc(25% - 3.5625rem / 4 * 3);
}
.flex-col4.gap58 > * {
  width: calc(25% - 3.625rem / 4 * 3);
}
.flex-col4.gap59 > * {
  width: calc(25% - 3.6875rem / 4 * 3);
}
.flex-col4.gap60 > * {
  width: calc(25% - 3.75rem / 4 * 3);
}
.flex-col4.gap61 > * {
  width: calc(25% - 3.8125rem / 4 * 3);
}
.flex-col4.gap62 > * {
  width: calc(25% - 3.875rem / 4 * 3);
}
.flex-col4.gap63 > * {
  width: calc(25% - 3.9375rem / 4 * 3);
}
.flex-col4.gap64 > * {
  width: calc(25% - 4rem / 4 * 3);
}
.flex-col4.gap65 > * {
  width: calc(25% - 4.0625rem / 4 * 3);
}
.flex-col4.gap66 > * {
  width: calc(25% - 4.125rem / 4 * 3);
}
.flex-col4.gap67 > * {
  width: calc(25% - 4.1875rem / 4 * 3);
}
.flex-col4.gap68 > * {
  width: calc(25% - 4.25rem / 4 * 3);
}
.flex-col4.gap69 > * {
  width: calc(25% - 4.3125rem / 4 * 3);
}
.flex-col4.gap70 > * {
  width: calc(25% - 4.375rem / 4 * 3);
}
.flex-col4.gap71 > * {
  width: calc(25% - 4.4375rem / 4 * 3);
}
.flex-col4.gap72 > * {
  width: calc(25% - 4.5rem / 4 * 3);
}
.flex-col4.gap73 > * {
  width: calc(25% - 4.5625rem / 4 * 3);
}
.flex-col4.gap74 > * {
  width: calc(25% - 4.625rem / 4 * 3);
}
.flex-col4.gap75 > * {
  width: calc(25% - 4.6875rem / 4 * 3);
}
.flex-col4.gap76 > * {
  width: calc(25% - 4.75rem / 4 * 3);
}
.flex-col4.gap77 > * {
  width: calc(25% - 4.8125rem / 4 * 3);
}
.flex-col4.gap78 > * {
  width: calc(25% - 4.875rem / 4 * 3);
}
.flex-col4.gap79 > * {
  width: calc(25% - 4.9375rem / 4 * 3);
}
.flex-col4.gap80 > * {
  width: calc(25% - 5rem / 4 * 3);
}
.flex-col4.gap81 > * {
  width: calc(25% - 5.0625rem / 4 * 3);
}
.flex-col4.gap82 > * {
  width: calc(25% - 5.125rem / 4 * 3);
}
.flex-col4.gap83 > * {
  width: calc(25% - 5.1875rem / 4 * 3);
}
.flex-col4.gap84 > * {
  width: calc(25% - 5.25rem / 4 * 3);
}
.flex-col4.gap85 > * {
  width: calc(25% - 5.3125rem / 4 * 3);
}
.flex-col4.gap86 > * {
  width: calc(25% - 5.375rem / 4 * 3);
}
.flex-col4.gap87 > * {
  width: calc(25% - 5.4375rem / 4 * 3);
}
.flex-col4.gap88 > * {
  width: calc(25% - 5.5rem / 4 * 3);
}
.flex-col4.gap89 > * {
  width: calc(25% - 5.5625rem / 4 * 3);
}
.flex-col4.gap90 > * {
  width: calc(25% - 5.625rem / 4 * 3);
}
.flex-col4.gap91 > * {
  width: calc(25% - 5.6875rem / 4 * 3);
}
.flex-col4.gap92 > * {
  width: calc(25% - 5.75rem / 4 * 3);
}
.flex-col4.gap93 > * {
  width: calc(25% - 5.8125rem / 4 * 3);
}
.flex-col4.gap94 > * {
  width: calc(25% - 5.875rem / 4 * 3);
}
.flex-col4.gap95 > * {
  width: calc(25% - 5.9375rem / 4 * 3);
}
.flex-col4.gap96 > * {
  width: calc(25% - 6rem / 4 * 3);
}
.flex-col4.gap97 > * {
  width: calc(25% - 6.0625rem / 4 * 3);
}
.flex-col4.gap98 > * {
  width: calc(25% - 6.125rem / 4 * 3);
}
.flex-col4.gap99 > * {
  width: calc(25% - 6.1875rem / 4 * 3);
}
.flex-col4.gap100 > * {
  width: calc(25% - 6.25rem / 4 * 3);
}
.flex-col4.gap101 > * {
  width: calc(25% - 6.3125rem / 4 * 3);
}
.flex-col4.gap102 > * {
  width: calc(25% - 6.375rem / 4 * 3);
}
.flex-col4.gap103 > * {
  width: calc(25% - 6.4375rem / 4 * 3);
}
.flex-col4.gap104 > * {
  width: calc(25% - 6.5rem / 4 * 3);
}
.flex-col4.gap105 > * {
  width: calc(25% - 6.5625rem / 4 * 3);
}
.flex-col4.gap106 > * {
  width: calc(25% - 6.625rem / 4 * 3);
}
.flex-col4.gap107 > * {
  width: calc(25% - 6.6875rem / 4 * 3);
}
.flex-col4.gap108 > * {
  width: calc(25% - 6.75rem / 4 * 3);
}
.flex-col4.gap109 > * {
  width: calc(25% - 6.8125rem / 4 * 3);
}
.flex-col4.gap110 > * {
  width: calc(25% - 6.875rem / 4 * 3);
}
.flex-col4.gap111 > * {
  width: calc(25% - 6.9375rem / 4 * 3);
}
.flex-col4.gap112 > * {
  width: calc(25% - 7rem / 4 * 3);
}
.flex-col4.gap113 > * {
  width: calc(25% - 7.0625rem / 4 * 3);
}
.flex-col4.gap114 > * {
  width: calc(25% - 7.125rem / 4 * 3);
}
.flex-col4.gap115 > * {
  width: calc(25% - 7.1875rem / 4 * 3);
}
.flex-col4.gap116 > * {
  width: calc(25% - 7.25rem / 4 * 3);
}
.flex-col4.gap117 > * {
  width: calc(25% - 7.3125rem / 4 * 3);
}
.flex-col4.gap118 > * {
  width: calc(25% - 7.375rem / 4 * 3);
}
.flex-col4.gap119 > * {
  width: calc(25% - 7.4375rem / 4 * 3);
}
.flex-col4.gap120 > * {
  width: calc(25% - 7.5rem / 4 * 3);
}
.flex-col4.gap121 > * {
  width: calc(25% - 7.5625rem / 4 * 3);
}
.flex-col4.gap122 > * {
  width: calc(25% - 7.625rem / 4 * 3);
}
.flex-col4.gap123 > * {
  width: calc(25% - 7.6875rem / 4 * 3);
}
.flex-col4.gap124 > * {
  width: calc(25% - 7.75rem / 4 * 3);
}
.flex-col4.gap125 > * {
  width: calc(25% - 7.8125rem / 4 * 3);
}
.flex-col4.gap126 > * {
  width: calc(25% - 7.875rem / 4 * 3);
}
.flex-col4.gap127 > * {
  width: calc(25% - 7.9375rem / 4 * 3);
}
.flex-col4.gap128 > * {
  width: calc(25% - 8rem / 4 * 3);
}
.flex-col4.gap129 > * {
  width: calc(25% - 8.0625rem / 4 * 3);
}
.flex-col4.gap130 > * {
  width: calc(25% - 8.125rem / 4 * 3);
}
.flex-col4.gap131 > * {
  width: calc(25% - 8.1875rem / 4 * 3);
}
.flex-col4.gap132 > * {
  width: calc(25% - 8.25rem / 4 * 3);
}
.flex-col4.gap133 > * {
  width: calc(25% - 8.3125rem / 4 * 3);
}
.flex-col4.gap134 > * {
  width: calc(25% - 8.375rem / 4 * 3);
}
.flex-col4.gap135 > * {
  width: calc(25% - 8.4375rem / 4 * 3);
}
.flex-col4.gap136 > * {
  width: calc(25% - 8.5rem / 4 * 3);
}
.flex-col4.gap137 > * {
  width: calc(25% - 8.5625rem / 4 * 3);
}
.flex-col4.gap138 > * {
  width: calc(25% - 8.625rem / 4 * 3);
}
.flex-col4.gap139 > * {
  width: calc(25% - 8.6875rem / 4 * 3);
}
.flex-col4.gap140 > * {
  width: calc(25% - 8.75rem / 4 * 3);
}
.flex-col4.gap141 > * {
  width: calc(25% - 8.8125rem / 4 * 3);
}
.flex-col4.gap142 > * {
  width: calc(25% - 8.875rem / 4 * 3);
}
.flex-col4.gap143 > * {
  width: calc(25% - 8.9375rem / 4 * 3);
}
.flex-col4.gap144 > * {
  width: calc(25% - 9rem / 4 * 3);
}
.flex-col4.gap145 > * {
  width: calc(25% - 9.0625rem / 4 * 3);
}
.flex-col4.gap146 > * {
  width: calc(25% - 9.125rem / 4 * 3);
}
.flex-col4.gap147 > * {
  width: calc(25% - 9.1875rem / 4 * 3);
}
.flex-col4.gap148 > * {
  width: calc(25% - 9.25rem / 4 * 3);
}
.flex-col4.gap149 > * {
  width: calc(25% - 9.3125rem / 4 * 3);
}
.flex-col4.gap150 > * {
  width: calc(25% - 9.375rem / 4 * 3);
}

.flex-col5 {
  flex-wrap: wrap;
}
.flex-col5 > * {
  width: 20%;
}
.flex-col5.gap1 > * {
  width: calc(20% - 0.0625rem / 5 * 4);
}
.flex-col5.gap2 > * {
  width: calc(20% - 0.125rem / 5 * 4);
}
.flex-col5.gap3 > * {
  width: calc(20% - 0.1875rem / 5 * 4);
}
.flex-col5.gap4 > * {
  width: calc(20% - 0.25rem / 5 * 4);
}
.flex-col5.gap5 > * {
  width: calc(20% - 0.3125rem / 5 * 4);
}
.flex-col5.gap6 > * {
  width: calc(20% - 0.375rem / 5 * 4);
}
.flex-col5.gap7 > * {
  width: calc(20% - 0.4375rem / 5 * 4);
}
.flex-col5.gap8 > * {
  width: calc(20% - 0.5rem / 5 * 4);
}
.flex-col5.gap9 > * {
  width: calc(20% - 0.5625rem / 5 * 4);
}
.flex-col5.gap10 > * {
  width: calc(20% - 0.625rem / 5 * 4);
}
.flex-col5.gap11 > * {
  width: calc(20% - 0.6875rem / 5 * 4);
}
.flex-col5.gap12 > * {
  width: calc(20% - 0.75rem / 5 * 4);
}
.flex-col5.gap13 > * {
  width: calc(20% - 0.8125rem / 5 * 4);
}
.flex-col5.gap14 > * {
  width: calc(20% - 0.875rem / 5 * 4);
}
.flex-col5.gap15 > * {
  width: calc(20% - 0.9375rem / 5 * 4);
}
.flex-col5.gap16 > * {
  width: calc(20% - 1rem / 5 * 4);
}
.flex-col5.gap17 > * {
  width: calc(20% - 1.0625rem / 5 * 4);
}
.flex-col5.gap18 > * {
  width: calc(20% - 1.125rem / 5 * 4);
}
.flex-col5.gap19 > * {
  width: calc(20% - 1.1875rem / 5 * 4);
}
.flex-col5.gap20 > * {
  width: calc(20% - 1.25rem / 5 * 4);
}
.flex-col5.gap21 > * {
  width: calc(20% - 1.3125rem / 5 * 4);
}
.flex-col5.gap22 > * {
  width: calc(20% - 1.375rem / 5 * 4);
}
.flex-col5.gap23 > * {
  width: calc(20% - 1.4375rem / 5 * 4);
}
.flex-col5.gap24 > * {
  width: calc(20% - 1.5rem / 5 * 4);
}
.flex-col5.gap25 > * {
  width: calc(20% - 1.5625rem / 5 * 4);
}
.flex-col5.gap26 > * {
  width: calc(20% - 1.625rem / 5 * 4);
}
.flex-col5.gap27 > * {
  width: calc(20% - 1.6875rem / 5 * 4);
}
.flex-col5.gap28 > * {
  width: calc(20% - 1.75rem / 5 * 4);
}
.flex-col5.gap29 > * {
  width: calc(20% - 1.8125rem / 5 * 4);
}
.flex-col5.gap30 > * {
  width: calc(20% - 1.875rem / 5 * 4);
}
.flex-col5.gap31 > * {
  width: calc(20% - 1.9375rem / 5 * 4);
}
.flex-col5.gap32 > * {
  width: calc(20% - 2rem / 5 * 4);
}
.flex-col5.gap33 > * {
  width: calc(20% - 2.0625rem / 5 * 4);
}
.flex-col5.gap34 > * {
  width: calc(20% - 2.125rem / 5 * 4);
}
.flex-col5.gap35 > * {
  width: calc(20% - 2.1875rem / 5 * 4);
}
.flex-col5.gap36 > * {
  width: calc(20% - 2.25rem / 5 * 4);
}
.flex-col5.gap37 > * {
  width: calc(20% - 2.3125rem / 5 * 4);
}
.flex-col5.gap38 > * {
  width: calc(20% - 2.375rem / 5 * 4);
}
.flex-col5.gap39 > * {
  width: calc(20% - 2.4375rem / 5 * 4);
}
.flex-col5.gap40 > * {
  width: calc(20% - 2.5rem / 5 * 4);
}
.flex-col5.gap41 > * {
  width: calc(20% - 2.5625rem / 5 * 4);
}
.flex-col5.gap42 > * {
  width: calc(20% - 2.625rem / 5 * 4);
}
.flex-col5.gap43 > * {
  width: calc(20% - 2.6875rem / 5 * 4);
}
.flex-col5.gap44 > * {
  width: calc(20% - 2.75rem / 5 * 4);
}
.flex-col5.gap45 > * {
  width: calc(20% - 2.8125rem / 5 * 4);
}
.flex-col5.gap46 > * {
  width: calc(20% - 2.875rem / 5 * 4);
}
.flex-col5.gap47 > * {
  width: calc(20% - 2.9375rem / 5 * 4);
}
.flex-col5.gap48 > * {
  width: calc(20% - 3rem / 5 * 4);
}
.flex-col5.gap49 > * {
  width: calc(20% - 3.0625rem / 5 * 4);
}
.flex-col5.gap50 > * {
  width: calc(20% - 3.125rem / 5 * 4);
}
.flex-col5.gap51 > * {
  width: calc(20% - 3.1875rem / 5 * 4);
}
.flex-col5.gap52 > * {
  width: calc(20% - 3.25rem / 5 * 4);
}
.flex-col5.gap53 > * {
  width: calc(20% - 3.3125rem / 5 * 4);
}
.flex-col5.gap54 > * {
  width: calc(20% - 3.375rem / 5 * 4);
}
.flex-col5.gap55 > * {
  width: calc(20% - 3.4375rem / 5 * 4);
}
.flex-col5.gap56 > * {
  width: calc(20% - 3.5rem / 5 * 4);
}
.flex-col5.gap57 > * {
  width: calc(20% - 3.5625rem / 5 * 4);
}
.flex-col5.gap58 > * {
  width: calc(20% - 3.625rem / 5 * 4);
}
.flex-col5.gap59 > * {
  width: calc(20% - 3.6875rem / 5 * 4);
}
.flex-col5.gap60 > * {
  width: calc(20% - 3.75rem / 5 * 4);
}
.flex-col5.gap61 > * {
  width: calc(20% - 3.8125rem / 5 * 4);
}
.flex-col5.gap62 > * {
  width: calc(20% - 3.875rem / 5 * 4);
}
.flex-col5.gap63 > * {
  width: calc(20% - 3.9375rem / 5 * 4);
}
.flex-col5.gap64 > * {
  width: calc(20% - 4rem / 5 * 4);
}
.flex-col5.gap65 > * {
  width: calc(20% - 4.0625rem / 5 * 4);
}
.flex-col5.gap66 > * {
  width: calc(20% - 4.125rem / 5 * 4);
}
.flex-col5.gap67 > * {
  width: calc(20% - 4.1875rem / 5 * 4);
}
.flex-col5.gap68 > * {
  width: calc(20% - 4.25rem / 5 * 4);
}
.flex-col5.gap69 > * {
  width: calc(20% - 4.3125rem / 5 * 4);
}
.flex-col5.gap70 > * {
  width: calc(20% - 4.375rem / 5 * 4);
}
.flex-col5.gap71 > * {
  width: calc(20% - 4.4375rem / 5 * 4);
}
.flex-col5.gap72 > * {
  width: calc(20% - 4.5rem / 5 * 4);
}
.flex-col5.gap73 > * {
  width: calc(20% - 4.5625rem / 5 * 4);
}
.flex-col5.gap74 > * {
  width: calc(20% - 4.625rem / 5 * 4);
}
.flex-col5.gap75 > * {
  width: calc(20% - 4.6875rem / 5 * 4);
}
.flex-col5.gap76 > * {
  width: calc(20% - 4.75rem / 5 * 4);
}
.flex-col5.gap77 > * {
  width: calc(20% - 4.8125rem / 5 * 4);
}
.flex-col5.gap78 > * {
  width: calc(20% - 4.875rem / 5 * 4);
}
.flex-col5.gap79 > * {
  width: calc(20% - 4.9375rem / 5 * 4);
}
.flex-col5.gap80 > * {
  width: calc(20% - 5rem / 5 * 4);
}
.flex-col5.gap81 > * {
  width: calc(20% - 5.0625rem / 5 * 4);
}
.flex-col5.gap82 > * {
  width: calc(20% - 5.125rem / 5 * 4);
}
.flex-col5.gap83 > * {
  width: calc(20% - 5.1875rem / 5 * 4);
}
.flex-col5.gap84 > * {
  width: calc(20% - 5.25rem / 5 * 4);
}
.flex-col5.gap85 > * {
  width: calc(20% - 5.3125rem / 5 * 4);
}
.flex-col5.gap86 > * {
  width: calc(20% - 5.375rem / 5 * 4);
}
.flex-col5.gap87 > * {
  width: calc(20% - 5.4375rem / 5 * 4);
}
.flex-col5.gap88 > * {
  width: calc(20% - 5.5rem / 5 * 4);
}
.flex-col5.gap89 > * {
  width: calc(20% - 5.5625rem / 5 * 4);
}
.flex-col5.gap90 > * {
  width: calc(20% - 5.625rem / 5 * 4);
}
.flex-col5.gap91 > * {
  width: calc(20% - 5.6875rem / 5 * 4);
}
.flex-col5.gap92 > * {
  width: calc(20% - 5.75rem / 5 * 4);
}
.flex-col5.gap93 > * {
  width: calc(20% - 5.8125rem / 5 * 4);
}
.flex-col5.gap94 > * {
  width: calc(20% - 5.875rem / 5 * 4);
}
.flex-col5.gap95 > * {
  width: calc(20% - 5.9375rem / 5 * 4);
}
.flex-col5.gap96 > * {
  width: calc(20% - 6rem / 5 * 4);
}
.flex-col5.gap97 > * {
  width: calc(20% - 6.0625rem / 5 * 4);
}
.flex-col5.gap98 > * {
  width: calc(20% - 6.125rem / 5 * 4);
}
.flex-col5.gap99 > * {
  width: calc(20% - 6.1875rem / 5 * 4);
}
.flex-col5.gap100 > * {
  width: calc(20% - 6.25rem / 5 * 4);
}
.flex-col5.gap101 > * {
  width: calc(20% - 6.3125rem / 5 * 4);
}
.flex-col5.gap102 > * {
  width: calc(20% - 6.375rem / 5 * 4);
}
.flex-col5.gap103 > * {
  width: calc(20% - 6.4375rem / 5 * 4);
}
.flex-col5.gap104 > * {
  width: calc(20% - 6.5rem / 5 * 4);
}
.flex-col5.gap105 > * {
  width: calc(20% - 6.5625rem / 5 * 4);
}
.flex-col5.gap106 > * {
  width: calc(20% - 6.625rem / 5 * 4);
}
.flex-col5.gap107 > * {
  width: calc(20% - 6.6875rem / 5 * 4);
}
.flex-col5.gap108 > * {
  width: calc(20% - 6.75rem / 5 * 4);
}
.flex-col5.gap109 > * {
  width: calc(20% - 6.8125rem / 5 * 4);
}
.flex-col5.gap110 > * {
  width: calc(20% - 6.875rem / 5 * 4);
}
.flex-col5.gap111 > * {
  width: calc(20% - 6.9375rem / 5 * 4);
}
.flex-col5.gap112 > * {
  width: calc(20% - 7rem / 5 * 4);
}
.flex-col5.gap113 > * {
  width: calc(20% - 7.0625rem / 5 * 4);
}
.flex-col5.gap114 > * {
  width: calc(20% - 7.125rem / 5 * 4);
}
.flex-col5.gap115 > * {
  width: calc(20% - 7.1875rem / 5 * 4);
}
.flex-col5.gap116 > * {
  width: calc(20% - 7.25rem / 5 * 4);
}
.flex-col5.gap117 > * {
  width: calc(20% - 7.3125rem / 5 * 4);
}
.flex-col5.gap118 > * {
  width: calc(20% - 7.375rem / 5 * 4);
}
.flex-col5.gap119 > * {
  width: calc(20% - 7.4375rem / 5 * 4);
}
.flex-col5.gap120 > * {
  width: calc(20% - 7.5rem / 5 * 4);
}
.flex-col5.gap121 > * {
  width: calc(20% - 7.5625rem / 5 * 4);
}
.flex-col5.gap122 > * {
  width: calc(20% - 7.625rem / 5 * 4);
}
.flex-col5.gap123 > * {
  width: calc(20% - 7.6875rem / 5 * 4);
}
.flex-col5.gap124 > * {
  width: calc(20% - 7.75rem / 5 * 4);
}
.flex-col5.gap125 > * {
  width: calc(20% - 7.8125rem / 5 * 4);
}
.flex-col5.gap126 > * {
  width: calc(20% - 7.875rem / 5 * 4);
}
.flex-col5.gap127 > * {
  width: calc(20% - 7.9375rem / 5 * 4);
}
.flex-col5.gap128 > * {
  width: calc(20% - 8rem / 5 * 4);
}
.flex-col5.gap129 > * {
  width: calc(20% - 8.0625rem / 5 * 4);
}
.flex-col5.gap130 > * {
  width: calc(20% - 8.125rem / 5 * 4);
}
.flex-col5.gap131 > * {
  width: calc(20% - 8.1875rem / 5 * 4);
}
.flex-col5.gap132 > * {
  width: calc(20% - 8.25rem / 5 * 4);
}
.flex-col5.gap133 > * {
  width: calc(20% - 8.3125rem / 5 * 4);
}
.flex-col5.gap134 > * {
  width: calc(20% - 8.375rem / 5 * 4);
}
.flex-col5.gap135 > * {
  width: calc(20% - 8.4375rem / 5 * 4);
}
.flex-col5.gap136 > * {
  width: calc(20% - 8.5rem / 5 * 4);
}
.flex-col5.gap137 > * {
  width: calc(20% - 8.5625rem / 5 * 4);
}
.flex-col5.gap138 > * {
  width: calc(20% - 8.625rem / 5 * 4);
}
.flex-col5.gap139 > * {
  width: calc(20% - 8.6875rem / 5 * 4);
}
.flex-col5.gap140 > * {
  width: calc(20% - 8.75rem / 5 * 4);
}
.flex-col5.gap141 > * {
  width: calc(20% - 8.8125rem / 5 * 4);
}
.flex-col5.gap142 > * {
  width: calc(20% - 8.875rem / 5 * 4);
}
.flex-col5.gap143 > * {
  width: calc(20% - 8.9375rem / 5 * 4);
}
.flex-col5.gap144 > * {
  width: calc(20% - 9rem / 5 * 4);
}
.flex-col5.gap145 > * {
  width: calc(20% - 9.0625rem / 5 * 4);
}
.flex-col5.gap146 > * {
  width: calc(20% - 9.125rem / 5 * 4);
}
.flex-col5.gap147 > * {
  width: calc(20% - 9.1875rem / 5 * 4);
}
.flex-col5.gap148 > * {
  width: calc(20% - 9.25rem / 5 * 4);
}
.flex-col5.gap149 > * {
  width: calc(20% - 9.3125rem / 5 * 4);
}
.flex-col5.gap150 > * {
  width: calc(20% - 9.375rem / 5 * 4);
}

.flex-col6 {
  flex-wrap: wrap;
}
.flex-col6 > * {
  width: 16.6666666667%;
}
.flex-col6.gap1 > * {
  width: calc(16.6666666667% - 0.0625rem / 6 * 5);
}
.flex-col6.gap2 > * {
  width: calc(16.6666666667% - 0.125rem / 6 * 5);
}
.flex-col6.gap3 > * {
  width: calc(16.6666666667% - 0.1875rem / 6 * 5);
}
.flex-col6.gap4 > * {
  width: calc(16.6666666667% - 0.25rem / 6 * 5);
}
.flex-col6.gap5 > * {
  width: calc(16.6666666667% - 0.3125rem / 6 * 5);
}
.flex-col6.gap6 > * {
  width: calc(16.6666666667% - 0.375rem / 6 * 5);
}
.flex-col6.gap7 > * {
  width: calc(16.6666666667% - 0.4375rem / 6 * 5);
}
.flex-col6.gap8 > * {
  width: calc(16.6666666667% - 0.5rem / 6 * 5);
}
.flex-col6.gap9 > * {
  width: calc(16.6666666667% - 0.5625rem / 6 * 5);
}
.flex-col6.gap10 > * {
  width: calc(16.6666666667% - 0.625rem / 6 * 5);
}
.flex-col6.gap11 > * {
  width: calc(16.6666666667% - 0.6875rem / 6 * 5);
}
.flex-col6.gap12 > * {
  width: calc(16.6666666667% - 0.75rem / 6 * 5);
}
.flex-col6.gap13 > * {
  width: calc(16.6666666667% - 0.8125rem / 6 * 5);
}
.flex-col6.gap14 > * {
  width: calc(16.6666666667% - 0.875rem / 6 * 5);
}
.flex-col6.gap15 > * {
  width: calc(16.6666666667% - 0.9375rem / 6 * 5);
}
.flex-col6.gap16 > * {
  width: calc(16.6666666667% - 1rem / 6 * 5);
}
.flex-col6.gap17 > * {
  width: calc(16.6666666667% - 1.0625rem / 6 * 5);
}
.flex-col6.gap18 > * {
  width: calc(16.6666666667% - 1.125rem / 6 * 5);
}
.flex-col6.gap19 > * {
  width: calc(16.6666666667% - 1.1875rem / 6 * 5);
}
.flex-col6.gap20 > * {
  width: calc(16.6666666667% - 1.25rem / 6 * 5);
}
.flex-col6.gap21 > * {
  width: calc(16.6666666667% - 1.3125rem / 6 * 5);
}
.flex-col6.gap22 > * {
  width: calc(16.6666666667% - 1.375rem / 6 * 5);
}
.flex-col6.gap23 > * {
  width: calc(16.6666666667% - 1.4375rem / 6 * 5);
}
.flex-col6.gap24 > * {
  width: calc(16.6666666667% - 1.5rem / 6 * 5);
}
.flex-col6.gap25 > * {
  width: calc(16.6666666667% - 1.5625rem / 6 * 5);
}
.flex-col6.gap26 > * {
  width: calc(16.6666666667% - 1.625rem / 6 * 5);
}
.flex-col6.gap27 > * {
  width: calc(16.6666666667% - 1.6875rem / 6 * 5);
}
.flex-col6.gap28 > * {
  width: calc(16.6666666667% - 1.75rem / 6 * 5);
}
.flex-col6.gap29 > * {
  width: calc(16.6666666667% - 1.8125rem / 6 * 5);
}
.flex-col6.gap30 > * {
  width: calc(16.6666666667% - 1.875rem / 6 * 5);
}
.flex-col6.gap31 > * {
  width: calc(16.6666666667% - 1.9375rem / 6 * 5);
}
.flex-col6.gap32 > * {
  width: calc(16.6666666667% - 2rem / 6 * 5);
}
.flex-col6.gap33 > * {
  width: calc(16.6666666667% - 2.0625rem / 6 * 5);
}
.flex-col6.gap34 > * {
  width: calc(16.6666666667% - 2.125rem / 6 * 5);
}
.flex-col6.gap35 > * {
  width: calc(16.6666666667% - 2.1875rem / 6 * 5);
}
.flex-col6.gap36 > * {
  width: calc(16.6666666667% - 2.25rem / 6 * 5);
}
.flex-col6.gap37 > * {
  width: calc(16.6666666667% - 2.3125rem / 6 * 5);
}
.flex-col6.gap38 > * {
  width: calc(16.6666666667% - 2.375rem / 6 * 5);
}
.flex-col6.gap39 > * {
  width: calc(16.6666666667% - 2.4375rem / 6 * 5);
}
.flex-col6.gap40 > * {
  width: calc(16.6666666667% - 2.5rem / 6 * 5);
}
.flex-col6.gap41 > * {
  width: calc(16.6666666667% - 2.5625rem / 6 * 5);
}
.flex-col6.gap42 > * {
  width: calc(16.6666666667% - 2.625rem / 6 * 5);
}
.flex-col6.gap43 > * {
  width: calc(16.6666666667% - 2.6875rem / 6 * 5);
}
.flex-col6.gap44 > * {
  width: calc(16.6666666667% - 2.75rem / 6 * 5);
}
.flex-col6.gap45 > * {
  width: calc(16.6666666667% - 2.8125rem / 6 * 5);
}
.flex-col6.gap46 > * {
  width: calc(16.6666666667% - 2.875rem / 6 * 5);
}
.flex-col6.gap47 > * {
  width: calc(16.6666666667% - 2.9375rem / 6 * 5);
}
.flex-col6.gap48 > * {
  width: calc(16.6666666667% - 3rem / 6 * 5);
}
.flex-col6.gap49 > * {
  width: calc(16.6666666667% - 3.0625rem / 6 * 5);
}
.flex-col6.gap50 > * {
  width: calc(16.6666666667% - 3.125rem / 6 * 5);
}
.flex-col6.gap51 > * {
  width: calc(16.6666666667% - 3.1875rem / 6 * 5);
}
.flex-col6.gap52 > * {
  width: calc(16.6666666667% - 3.25rem / 6 * 5);
}
.flex-col6.gap53 > * {
  width: calc(16.6666666667% - 3.3125rem / 6 * 5);
}
.flex-col6.gap54 > * {
  width: calc(16.6666666667% - 3.375rem / 6 * 5);
}
.flex-col6.gap55 > * {
  width: calc(16.6666666667% - 3.4375rem / 6 * 5);
}
.flex-col6.gap56 > * {
  width: calc(16.6666666667% - 3.5rem / 6 * 5);
}
.flex-col6.gap57 > * {
  width: calc(16.6666666667% - 3.5625rem / 6 * 5);
}
.flex-col6.gap58 > * {
  width: calc(16.6666666667% - 3.625rem / 6 * 5);
}
.flex-col6.gap59 > * {
  width: calc(16.6666666667% - 3.6875rem / 6 * 5);
}
.flex-col6.gap60 > * {
  width: calc(16.6666666667% - 3.75rem / 6 * 5);
}
.flex-col6.gap61 > * {
  width: calc(16.6666666667% - 3.8125rem / 6 * 5);
}
.flex-col6.gap62 > * {
  width: calc(16.6666666667% - 3.875rem / 6 * 5);
}
.flex-col6.gap63 > * {
  width: calc(16.6666666667% - 3.9375rem / 6 * 5);
}
.flex-col6.gap64 > * {
  width: calc(16.6666666667% - 4rem / 6 * 5);
}
.flex-col6.gap65 > * {
  width: calc(16.6666666667% - 4.0625rem / 6 * 5);
}
.flex-col6.gap66 > * {
  width: calc(16.6666666667% - 4.125rem / 6 * 5);
}
.flex-col6.gap67 > * {
  width: calc(16.6666666667% - 4.1875rem / 6 * 5);
}
.flex-col6.gap68 > * {
  width: calc(16.6666666667% - 4.25rem / 6 * 5);
}
.flex-col6.gap69 > * {
  width: calc(16.6666666667% - 4.3125rem / 6 * 5);
}
.flex-col6.gap70 > * {
  width: calc(16.6666666667% - 4.375rem / 6 * 5);
}
.flex-col6.gap71 > * {
  width: calc(16.6666666667% - 4.4375rem / 6 * 5);
}
.flex-col6.gap72 > * {
  width: calc(16.6666666667% - 4.5rem / 6 * 5);
}
.flex-col6.gap73 > * {
  width: calc(16.6666666667% - 4.5625rem / 6 * 5);
}
.flex-col6.gap74 > * {
  width: calc(16.6666666667% - 4.625rem / 6 * 5);
}
.flex-col6.gap75 > * {
  width: calc(16.6666666667% - 4.6875rem / 6 * 5);
}
.flex-col6.gap76 > * {
  width: calc(16.6666666667% - 4.75rem / 6 * 5);
}
.flex-col6.gap77 > * {
  width: calc(16.6666666667% - 4.8125rem / 6 * 5);
}
.flex-col6.gap78 > * {
  width: calc(16.6666666667% - 4.875rem / 6 * 5);
}
.flex-col6.gap79 > * {
  width: calc(16.6666666667% - 4.9375rem / 6 * 5);
}
.flex-col6.gap80 > * {
  width: calc(16.6666666667% - 5rem / 6 * 5);
}
.flex-col6.gap81 > * {
  width: calc(16.6666666667% - 5.0625rem / 6 * 5);
}
.flex-col6.gap82 > * {
  width: calc(16.6666666667% - 5.125rem / 6 * 5);
}
.flex-col6.gap83 > * {
  width: calc(16.6666666667% - 5.1875rem / 6 * 5);
}
.flex-col6.gap84 > * {
  width: calc(16.6666666667% - 5.25rem / 6 * 5);
}
.flex-col6.gap85 > * {
  width: calc(16.6666666667% - 5.3125rem / 6 * 5);
}
.flex-col6.gap86 > * {
  width: calc(16.6666666667% - 5.375rem / 6 * 5);
}
.flex-col6.gap87 > * {
  width: calc(16.6666666667% - 5.4375rem / 6 * 5);
}
.flex-col6.gap88 > * {
  width: calc(16.6666666667% - 5.5rem / 6 * 5);
}
.flex-col6.gap89 > * {
  width: calc(16.6666666667% - 5.5625rem / 6 * 5);
}
.flex-col6.gap90 > * {
  width: calc(16.6666666667% - 5.625rem / 6 * 5);
}
.flex-col6.gap91 > * {
  width: calc(16.6666666667% - 5.6875rem / 6 * 5);
}
.flex-col6.gap92 > * {
  width: calc(16.6666666667% - 5.75rem / 6 * 5);
}
.flex-col6.gap93 > * {
  width: calc(16.6666666667% - 5.8125rem / 6 * 5);
}
.flex-col6.gap94 > * {
  width: calc(16.6666666667% - 5.875rem / 6 * 5);
}
.flex-col6.gap95 > * {
  width: calc(16.6666666667% - 5.9375rem / 6 * 5);
}
.flex-col6.gap96 > * {
  width: calc(16.6666666667% - 6rem / 6 * 5);
}
.flex-col6.gap97 > * {
  width: calc(16.6666666667% - 6.0625rem / 6 * 5);
}
.flex-col6.gap98 > * {
  width: calc(16.6666666667% - 6.125rem / 6 * 5);
}
.flex-col6.gap99 > * {
  width: calc(16.6666666667% - 6.1875rem / 6 * 5);
}
.flex-col6.gap100 > * {
  width: calc(16.6666666667% - 6.25rem / 6 * 5);
}
.flex-col6.gap101 > * {
  width: calc(16.6666666667% - 6.3125rem / 6 * 5);
}
.flex-col6.gap102 > * {
  width: calc(16.6666666667% - 6.375rem / 6 * 5);
}
.flex-col6.gap103 > * {
  width: calc(16.6666666667% - 6.4375rem / 6 * 5);
}
.flex-col6.gap104 > * {
  width: calc(16.6666666667% - 6.5rem / 6 * 5);
}
.flex-col6.gap105 > * {
  width: calc(16.6666666667% - 6.5625rem / 6 * 5);
}
.flex-col6.gap106 > * {
  width: calc(16.6666666667% - 6.625rem / 6 * 5);
}
.flex-col6.gap107 > * {
  width: calc(16.6666666667% - 6.6875rem / 6 * 5);
}
.flex-col6.gap108 > * {
  width: calc(16.6666666667% - 6.75rem / 6 * 5);
}
.flex-col6.gap109 > * {
  width: calc(16.6666666667% - 6.8125rem / 6 * 5);
}
.flex-col6.gap110 > * {
  width: calc(16.6666666667% - 6.875rem / 6 * 5);
}
.flex-col6.gap111 > * {
  width: calc(16.6666666667% - 6.9375rem / 6 * 5);
}
.flex-col6.gap112 > * {
  width: calc(16.6666666667% - 7rem / 6 * 5);
}
.flex-col6.gap113 > * {
  width: calc(16.6666666667% - 7.0625rem / 6 * 5);
}
.flex-col6.gap114 > * {
  width: calc(16.6666666667% - 7.125rem / 6 * 5);
}
.flex-col6.gap115 > * {
  width: calc(16.6666666667% - 7.1875rem / 6 * 5);
}
.flex-col6.gap116 > * {
  width: calc(16.6666666667% - 7.25rem / 6 * 5);
}
.flex-col6.gap117 > * {
  width: calc(16.6666666667% - 7.3125rem / 6 * 5);
}
.flex-col6.gap118 > * {
  width: calc(16.6666666667% - 7.375rem / 6 * 5);
}
.flex-col6.gap119 > * {
  width: calc(16.6666666667% - 7.4375rem / 6 * 5);
}
.flex-col6.gap120 > * {
  width: calc(16.6666666667% - 7.5rem / 6 * 5);
}
.flex-col6.gap121 > * {
  width: calc(16.6666666667% - 7.5625rem / 6 * 5);
}
.flex-col6.gap122 > * {
  width: calc(16.6666666667% - 7.625rem / 6 * 5);
}
.flex-col6.gap123 > * {
  width: calc(16.6666666667% - 7.6875rem / 6 * 5);
}
.flex-col6.gap124 > * {
  width: calc(16.6666666667% - 7.75rem / 6 * 5);
}
.flex-col6.gap125 > * {
  width: calc(16.6666666667% - 7.8125rem / 6 * 5);
}
.flex-col6.gap126 > * {
  width: calc(16.6666666667% - 7.875rem / 6 * 5);
}
.flex-col6.gap127 > * {
  width: calc(16.6666666667% - 7.9375rem / 6 * 5);
}
.flex-col6.gap128 > * {
  width: calc(16.6666666667% - 8rem / 6 * 5);
}
.flex-col6.gap129 > * {
  width: calc(16.6666666667% - 8.0625rem / 6 * 5);
}
.flex-col6.gap130 > * {
  width: calc(16.6666666667% - 8.125rem / 6 * 5);
}
.flex-col6.gap131 > * {
  width: calc(16.6666666667% - 8.1875rem / 6 * 5);
}
.flex-col6.gap132 > * {
  width: calc(16.6666666667% - 8.25rem / 6 * 5);
}
.flex-col6.gap133 > * {
  width: calc(16.6666666667% - 8.3125rem / 6 * 5);
}
.flex-col6.gap134 > * {
  width: calc(16.6666666667% - 8.375rem / 6 * 5);
}
.flex-col6.gap135 > * {
  width: calc(16.6666666667% - 8.4375rem / 6 * 5);
}
.flex-col6.gap136 > * {
  width: calc(16.6666666667% - 8.5rem / 6 * 5);
}
.flex-col6.gap137 > * {
  width: calc(16.6666666667% - 8.5625rem / 6 * 5);
}
.flex-col6.gap138 > * {
  width: calc(16.6666666667% - 8.625rem / 6 * 5);
}
.flex-col6.gap139 > * {
  width: calc(16.6666666667% - 8.6875rem / 6 * 5);
}
.flex-col6.gap140 > * {
  width: calc(16.6666666667% - 8.75rem / 6 * 5);
}
.flex-col6.gap141 > * {
  width: calc(16.6666666667% - 8.8125rem / 6 * 5);
}
.flex-col6.gap142 > * {
  width: calc(16.6666666667% - 8.875rem / 6 * 5);
}
.flex-col6.gap143 > * {
  width: calc(16.6666666667% - 8.9375rem / 6 * 5);
}
.flex-col6.gap144 > * {
  width: calc(16.6666666667% - 9rem / 6 * 5);
}
.flex-col6.gap145 > * {
  width: calc(16.6666666667% - 9.0625rem / 6 * 5);
}
.flex-col6.gap146 > * {
  width: calc(16.6666666667% - 9.125rem / 6 * 5);
}
.flex-col6.gap147 > * {
  width: calc(16.6666666667% - 9.1875rem / 6 * 5);
}
.flex-col6.gap148 > * {
  width: calc(16.6666666667% - 9.25rem / 6 * 5);
}
.flex-col6.gap149 > * {
  width: calc(16.6666666667% - 9.3125rem / 6 * 5);
}
.flex-col6.gap150 > * {
  width: calc(16.6666666667% - 9.375rem / 6 * 5);
}

.flex-col7 {
  flex-wrap: wrap;
}
.flex-col7 > * {
  width: 14.2857142857%;
}
.flex-col7.gap1 > * {
  width: calc(14.2857142857% - 0.0625rem / 7 * 6);
}
.flex-col7.gap2 > * {
  width: calc(14.2857142857% - 0.125rem / 7 * 6);
}
.flex-col7.gap3 > * {
  width: calc(14.2857142857% - 0.1875rem / 7 * 6);
}
.flex-col7.gap4 > * {
  width: calc(14.2857142857% - 0.25rem / 7 * 6);
}
.flex-col7.gap5 > * {
  width: calc(14.2857142857% - 0.3125rem / 7 * 6);
}
.flex-col7.gap6 > * {
  width: calc(14.2857142857% - 0.375rem / 7 * 6);
}
.flex-col7.gap7 > * {
  width: calc(14.2857142857% - 0.4375rem / 7 * 6);
}
.flex-col7.gap8 > * {
  width: calc(14.2857142857% - 0.5rem / 7 * 6);
}
.flex-col7.gap9 > * {
  width: calc(14.2857142857% - 0.5625rem / 7 * 6);
}
.flex-col7.gap10 > * {
  width: calc(14.2857142857% - 0.625rem / 7 * 6);
}
.flex-col7.gap11 > * {
  width: calc(14.2857142857% - 0.6875rem / 7 * 6);
}
.flex-col7.gap12 > * {
  width: calc(14.2857142857% - 0.75rem / 7 * 6);
}
.flex-col7.gap13 > * {
  width: calc(14.2857142857% - 0.8125rem / 7 * 6);
}
.flex-col7.gap14 > * {
  width: calc(14.2857142857% - 0.875rem / 7 * 6);
}
.flex-col7.gap15 > * {
  width: calc(14.2857142857% - 0.9375rem / 7 * 6);
}
.flex-col7.gap16 > * {
  width: calc(14.2857142857% - 1rem / 7 * 6);
}
.flex-col7.gap17 > * {
  width: calc(14.2857142857% - 1.0625rem / 7 * 6);
}
.flex-col7.gap18 > * {
  width: calc(14.2857142857% - 1.125rem / 7 * 6);
}
.flex-col7.gap19 > * {
  width: calc(14.2857142857% - 1.1875rem / 7 * 6);
}
.flex-col7.gap20 > * {
  width: calc(14.2857142857% - 1.25rem / 7 * 6);
}
.flex-col7.gap21 > * {
  width: calc(14.2857142857% - 1.3125rem / 7 * 6);
}
.flex-col7.gap22 > * {
  width: calc(14.2857142857% - 1.375rem / 7 * 6);
}
.flex-col7.gap23 > * {
  width: calc(14.2857142857% - 1.4375rem / 7 * 6);
}
.flex-col7.gap24 > * {
  width: calc(14.2857142857% - 1.5rem / 7 * 6);
}
.flex-col7.gap25 > * {
  width: calc(14.2857142857% - 1.5625rem / 7 * 6);
}
.flex-col7.gap26 > * {
  width: calc(14.2857142857% - 1.625rem / 7 * 6);
}
.flex-col7.gap27 > * {
  width: calc(14.2857142857% - 1.6875rem / 7 * 6);
}
.flex-col7.gap28 > * {
  width: calc(14.2857142857% - 1.75rem / 7 * 6);
}
.flex-col7.gap29 > * {
  width: calc(14.2857142857% - 1.8125rem / 7 * 6);
}
.flex-col7.gap30 > * {
  width: calc(14.2857142857% - 1.875rem / 7 * 6);
}
.flex-col7.gap31 > * {
  width: calc(14.2857142857% - 1.9375rem / 7 * 6);
}
.flex-col7.gap32 > * {
  width: calc(14.2857142857% - 2rem / 7 * 6);
}
.flex-col7.gap33 > * {
  width: calc(14.2857142857% - 2.0625rem / 7 * 6);
}
.flex-col7.gap34 > * {
  width: calc(14.2857142857% - 2.125rem / 7 * 6);
}
.flex-col7.gap35 > * {
  width: calc(14.2857142857% - 2.1875rem / 7 * 6);
}
.flex-col7.gap36 > * {
  width: calc(14.2857142857% - 2.25rem / 7 * 6);
}
.flex-col7.gap37 > * {
  width: calc(14.2857142857% - 2.3125rem / 7 * 6);
}
.flex-col7.gap38 > * {
  width: calc(14.2857142857% - 2.375rem / 7 * 6);
}
.flex-col7.gap39 > * {
  width: calc(14.2857142857% - 2.4375rem / 7 * 6);
}
.flex-col7.gap40 > * {
  width: calc(14.2857142857% - 2.5rem / 7 * 6);
}
.flex-col7.gap41 > * {
  width: calc(14.2857142857% - 2.5625rem / 7 * 6);
}
.flex-col7.gap42 > * {
  width: calc(14.2857142857% - 2.625rem / 7 * 6);
}
.flex-col7.gap43 > * {
  width: calc(14.2857142857% - 2.6875rem / 7 * 6);
}
.flex-col7.gap44 > * {
  width: calc(14.2857142857% - 2.75rem / 7 * 6);
}
.flex-col7.gap45 > * {
  width: calc(14.2857142857% - 2.8125rem / 7 * 6);
}
.flex-col7.gap46 > * {
  width: calc(14.2857142857% - 2.875rem / 7 * 6);
}
.flex-col7.gap47 > * {
  width: calc(14.2857142857% - 2.9375rem / 7 * 6);
}
.flex-col7.gap48 > * {
  width: calc(14.2857142857% - 3rem / 7 * 6);
}
.flex-col7.gap49 > * {
  width: calc(14.2857142857% - 3.0625rem / 7 * 6);
}
.flex-col7.gap50 > * {
  width: calc(14.2857142857% - 3.125rem / 7 * 6);
}
.flex-col7.gap51 > * {
  width: calc(14.2857142857% - 3.1875rem / 7 * 6);
}
.flex-col7.gap52 > * {
  width: calc(14.2857142857% - 3.25rem / 7 * 6);
}
.flex-col7.gap53 > * {
  width: calc(14.2857142857% - 3.3125rem / 7 * 6);
}
.flex-col7.gap54 > * {
  width: calc(14.2857142857% - 3.375rem / 7 * 6);
}
.flex-col7.gap55 > * {
  width: calc(14.2857142857% - 3.4375rem / 7 * 6);
}
.flex-col7.gap56 > * {
  width: calc(14.2857142857% - 3.5rem / 7 * 6);
}
.flex-col7.gap57 > * {
  width: calc(14.2857142857% - 3.5625rem / 7 * 6);
}
.flex-col7.gap58 > * {
  width: calc(14.2857142857% - 3.625rem / 7 * 6);
}
.flex-col7.gap59 > * {
  width: calc(14.2857142857% - 3.6875rem / 7 * 6);
}
.flex-col7.gap60 > * {
  width: calc(14.2857142857% - 3.75rem / 7 * 6);
}
.flex-col7.gap61 > * {
  width: calc(14.2857142857% - 3.8125rem / 7 * 6);
}
.flex-col7.gap62 > * {
  width: calc(14.2857142857% - 3.875rem / 7 * 6);
}
.flex-col7.gap63 > * {
  width: calc(14.2857142857% - 3.9375rem / 7 * 6);
}
.flex-col7.gap64 > * {
  width: calc(14.2857142857% - 4rem / 7 * 6);
}
.flex-col7.gap65 > * {
  width: calc(14.2857142857% - 4.0625rem / 7 * 6);
}
.flex-col7.gap66 > * {
  width: calc(14.2857142857% - 4.125rem / 7 * 6);
}
.flex-col7.gap67 > * {
  width: calc(14.2857142857% - 4.1875rem / 7 * 6);
}
.flex-col7.gap68 > * {
  width: calc(14.2857142857% - 4.25rem / 7 * 6);
}
.flex-col7.gap69 > * {
  width: calc(14.2857142857% - 4.3125rem / 7 * 6);
}
.flex-col7.gap70 > * {
  width: calc(14.2857142857% - 4.375rem / 7 * 6);
}
.flex-col7.gap71 > * {
  width: calc(14.2857142857% - 4.4375rem / 7 * 6);
}
.flex-col7.gap72 > * {
  width: calc(14.2857142857% - 4.5rem / 7 * 6);
}
.flex-col7.gap73 > * {
  width: calc(14.2857142857% - 4.5625rem / 7 * 6);
}
.flex-col7.gap74 > * {
  width: calc(14.2857142857% - 4.625rem / 7 * 6);
}
.flex-col7.gap75 > * {
  width: calc(14.2857142857% - 4.6875rem / 7 * 6);
}
.flex-col7.gap76 > * {
  width: calc(14.2857142857% - 4.75rem / 7 * 6);
}
.flex-col7.gap77 > * {
  width: calc(14.2857142857% - 4.8125rem / 7 * 6);
}
.flex-col7.gap78 > * {
  width: calc(14.2857142857% - 4.875rem / 7 * 6);
}
.flex-col7.gap79 > * {
  width: calc(14.2857142857% - 4.9375rem / 7 * 6);
}
.flex-col7.gap80 > * {
  width: calc(14.2857142857% - 5rem / 7 * 6);
}
.flex-col7.gap81 > * {
  width: calc(14.2857142857% - 5.0625rem / 7 * 6);
}
.flex-col7.gap82 > * {
  width: calc(14.2857142857% - 5.125rem / 7 * 6);
}
.flex-col7.gap83 > * {
  width: calc(14.2857142857% - 5.1875rem / 7 * 6);
}
.flex-col7.gap84 > * {
  width: calc(14.2857142857% - 5.25rem / 7 * 6);
}
.flex-col7.gap85 > * {
  width: calc(14.2857142857% - 5.3125rem / 7 * 6);
}
.flex-col7.gap86 > * {
  width: calc(14.2857142857% - 5.375rem / 7 * 6);
}
.flex-col7.gap87 > * {
  width: calc(14.2857142857% - 5.4375rem / 7 * 6);
}
.flex-col7.gap88 > * {
  width: calc(14.2857142857% - 5.5rem / 7 * 6);
}
.flex-col7.gap89 > * {
  width: calc(14.2857142857% - 5.5625rem / 7 * 6);
}
.flex-col7.gap90 > * {
  width: calc(14.2857142857% - 5.625rem / 7 * 6);
}
.flex-col7.gap91 > * {
  width: calc(14.2857142857% - 5.6875rem / 7 * 6);
}
.flex-col7.gap92 > * {
  width: calc(14.2857142857% - 5.75rem / 7 * 6);
}
.flex-col7.gap93 > * {
  width: calc(14.2857142857% - 5.8125rem / 7 * 6);
}
.flex-col7.gap94 > * {
  width: calc(14.2857142857% - 5.875rem / 7 * 6);
}
.flex-col7.gap95 > * {
  width: calc(14.2857142857% - 5.9375rem / 7 * 6);
}
.flex-col7.gap96 > * {
  width: calc(14.2857142857% - 6rem / 7 * 6);
}
.flex-col7.gap97 > * {
  width: calc(14.2857142857% - 6.0625rem / 7 * 6);
}
.flex-col7.gap98 > * {
  width: calc(14.2857142857% - 6.125rem / 7 * 6);
}
.flex-col7.gap99 > * {
  width: calc(14.2857142857% - 6.1875rem / 7 * 6);
}
.flex-col7.gap100 > * {
  width: calc(14.2857142857% - 6.25rem / 7 * 6);
}
.flex-col7.gap101 > * {
  width: calc(14.2857142857% - 6.3125rem / 7 * 6);
}
.flex-col7.gap102 > * {
  width: calc(14.2857142857% - 6.375rem / 7 * 6);
}
.flex-col7.gap103 > * {
  width: calc(14.2857142857% - 6.4375rem / 7 * 6);
}
.flex-col7.gap104 > * {
  width: calc(14.2857142857% - 6.5rem / 7 * 6);
}
.flex-col7.gap105 > * {
  width: calc(14.2857142857% - 6.5625rem / 7 * 6);
}
.flex-col7.gap106 > * {
  width: calc(14.2857142857% - 6.625rem / 7 * 6);
}
.flex-col7.gap107 > * {
  width: calc(14.2857142857% - 6.6875rem / 7 * 6);
}
.flex-col7.gap108 > * {
  width: calc(14.2857142857% - 6.75rem / 7 * 6);
}
.flex-col7.gap109 > * {
  width: calc(14.2857142857% - 6.8125rem / 7 * 6);
}
.flex-col7.gap110 > * {
  width: calc(14.2857142857% - 6.875rem / 7 * 6);
}
.flex-col7.gap111 > * {
  width: calc(14.2857142857% - 6.9375rem / 7 * 6);
}
.flex-col7.gap112 > * {
  width: calc(14.2857142857% - 7rem / 7 * 6);
}
.flex-col7.gap113 > * {
  width: calc(14.2857142857% - 7.0625rem / 7 * 6);
}
.flex-col7.gap114 > * {
  width: calc(14.2857142857% - 7.125rem / 7 * 6);
}
.flex-col7.gap115 > * {
  width: calc(14.2857142857% - 7.1875rem / 7 * 6);
}
.flex-col7.gap116 > * {
  width: calc(14.2857142857% - 7.25rem / 7 * 6);
}
.flex-col7.gap117 > * {
  width: calc(14.2857142857% - 7.3125rem / 7 * 6);
}
.flex-col7.gap118 > * {
  width: calc(14.2857142857% - 7.375rem / 7 * 6);
}
.flex-col7.gap119 > * {
  width: calc(14.2857142857% - 7.4375rem / 7 * 6);
}
.flex-col7.gap120 > * {
  width: calc(14.2857142857% - 7.5rem / 7 * 6);
}
.flex-col7.gap121 > * {
  width: calc(14.2857142857% - 7.5625rem / 7 * 6);
}
.flex-col7.gap122 > * {
  width: calc(14.2857142857% - 7.625rem / 7 * 6);
}
.flex-col7.gap123 > * {
  width: calc(14.2857142857% - 7.6875rem / 7 * 6);
}
.flex-col7.gap124 > * {
  width: calc(14.2857142857% - 7.75rem / 7 * 6);
}
.flex-col7.gap125 > * {
  width: calc(14.2857142857% - 7.8125rem / 7 * 6);
}
.flex-col7.gap126 > * {
  width: calc(14.2857142857% - 7.875rem / 7 * 6);
}
.flex-col7.gap127 > * {
  width: calc(14.2857142857% - 7.9375rem / 7 * 6);
}
.flex-col7.gap128 > * {
  width: calc(14.2857142857% - 8rem / 7 * 6);
}
.flex-col7.gap129 > * {
  width: calc(14.2857142857% - 8.0625rem / 7 * 6);
}
.flex-col7.gap130 > * {
  width: calc(14.2857142857% - 8.125rem / 7 * 6);
}
.flex-col7.gap131 > * {
  width: calc(14.2857142857% - 8.1875rem / 7 * 6);
}
.flex-col7.gap132 > * {
  width: calc(14.2857142857% - 8.25rem / 7 * 6);
}
.flex-col7.gap133 > * {
  width: calc(14.2857142857% - 8.3125rem / 7 * 6);
}
.flex-col7.gap134 > * {
  width: calc(14.2857142857% - 8.375rem / 7 * 6);
}
.flex-col7.gap135 > * {
  width: calc(14.2857142857% - 8.4375rem / 7 * 6);
}
.flex-col7.gap136 > * {
  width: calc(14.2857142857% - 8.5rem / 7 * 6);
}
.flex-col7.gap137 > * {
  width: calc(14.2857142857% - 8.5625rem / 7 * 6);
}
.flex-col7.gap138 > * {
  width: calc(14.2857142857% - 8.625rem / 7 * 6);
}
.flex-col7.gap139 > * {
  width: calc(14.2857142857% - 8.6875rem / 7 * 6);
}
.flex-col7.gap140 > * {
  width: calc(14.2857142857% - 8.75rem / 7 * 6);
}
.flex-col7.gap141 > * {
  width: calc(14.2857142857% - 8.8125rem / 7 * 6);
}
.flex-col7.gap142 > * {
  width: calc(14.2857142857% - 8.875rem / 7 * 6);
}
.flex-col7.gap143 > * {
  width: calc(14.2857142857% - 8.9375rem / 7 * 6);
}
.flex-col7.gap144 > * {
  width: calc(14.2857142857% - 9rem / 7 * 6);
}
.flex-col7.gap145 > * {
  width: calc(14.2857142857% - 9.0625rem / 7 * 6);
}
.flex-col7.gap146 > * {
  width: calc(14.2857142857% - 9.125rem / 7 * 6);
}
.flex-col7.gap147 > * {
  width: calc(14.2857142857% - 9.1875rem / 7 * 6);
}
.flex-col7.gap148 > * {
  width: calc(14.2857142857% - 9.25rem / 7 * 6);
}
.flex-col7.gap149 > * {
  width: calc(14.2857142857% - 9.3125rem / 7 * 6);
}
.flex-col7.gap150 > * {
  width: calc(14.2857142857% - 9.375rem / 7 * 6);
}

.flex-col8 {
  flex-wrap: wrap;
}
.flex-col8 > * {
  width: 12.5%;
}
.flex-col8.gap1 > * {
  width: calc(12.5% - 0.0625rem / 8 * 7);
}
.flex-col8.gap2 > * {
  width: calc(12.5% - 0.125rem / 8 * 7);
}
.flex-col8.gap3 > * {
  width: calc(12.5% - 0.1875rem / 8 * 7);
}
.flex-col8.gap4 > * {
  width: calc(12.5% - 0.25rem / 8 * 7);
}
.flex-col8.gap5 > * {
  width: calc(12.5% - 0.3125rem / 8 * 7);
}
.flex-col8.gap6 > * {
  width: calc(12.5% - 0.375rem / 8 * 7);
}
.flex-col8.gap7 > * {
  width: calc(12.5% - 0.4375rem / 8 * 7);
}
.flex-col8.gap8 > * {
  width: calc(12.5% - 0.5rem / 8 * 7);
}
.flex-col8.gap9 > * {
  width: calc(12.5% - 0.5625rem / 8 * 7);
}
.flex-col8.gap10 > * {
  width: calc(12.5% - 0.625rem / 8 * 7);
}
.flex-col8.gap11 > * {
  width: calc(12.5% - 0.6875rem / 8 * 7);
}
.flex-col8.gap12 > * {
  width: calc(12.5% - 0.75rem / 8 * 7);
}
.flex-col8.gap13 > * {
  width: calc(12.5% - 0.8125rem / 8 * 7);
}
.flex-col8.gap14 > * {
  width: calc(12.5% - 0.875rem / 8 * 7);
}
.flex-col8.gap15 > * {
  width: calc(12.5% - 0.9375rem / 8 * 7);
}
.flex-col8.gap16 > * {
  width: calc(12.5% - 1rem / 8 * 7);
}
.flex-col8.gap17 > * {
  width: calc(12.5% - 1.0625rem / 8 * 7);
}
.flex-col8.gap18 > * {
  width: calc(12.5% - 1.125rem / 8 * 7);
}
.flex-col8.gap19 > * {
  width: calc(12.5% - 1.1875rem / 8 * 7);
}
.flex-col8.gap20 > * {
  width: calc(12.5% - 1.25rem / 8 * 7);
}
.flex-col8.gap21 > * {
  width: calc(12.5% - 1.3125rem / 8 * 7);
}
.flex-col8.gap22 > * {
  width: calc(12.5% - 1.375rem / 8 * 7);
}
.flex-col8.gap23 > * {
  width: calc(12.5% - 1.4375rem / 8 * 7);
}
.flex-col8.gap24 > * {
  width: calc(12.5% - 1.5rem / 8 * 7);
}
.flex-col8.gap25 > * {
  width: calc(12.5% - 1.5625rem / 8 * 7);
}
.flex-col8.gap26 > * {
  width: calc(12.5% - 1.625rem / 8 * 7);
}
.flex-col8.gap27 > * {
  width: calc(12.5% - 1.6875rem / 8 * 7);
}
.flex-col8.gap28 > * {
  width: calc(12.5% - 1.75rem / 8 * 7);
}
.flex-col8.gap29 > * {
  width: calc(12.5% - 1.8125rem / 8 * 7);
}
.flex-col8.gap30 > * {
  width: calc(12.5% - 1.875rem / 8 * 7);
}
.flex-col8.gap31 > * {
  width: calc(12.5% - 1.9375rem / 8 * 7);
}
.flex-col8.gap32 > * {
  width: calc(12.5% - 2rem / 8 * 7);
}
.flex-col8.gap33 > * {
  width: calc(12.5% - 2.0625rem / 8 * 7);
}
.flex-col8.gap34 > * {
  width: calc(12.5% - 2.125rem / 8 * 7);
}
.flex-col8.gap35 > * {
  width: calc(12.5% - 2.1875rem / 8 * 7);
}
.flex-col8.gap36 > * {
  width: calc(12.5% - 2.25rem / 8 * 7);
}
.flex-col8.gap37 > * {
  width: calc(12.5% - 2.3125rem / 8 * 7);
}
.flex-col8.gap38 > * {
  width: calc(12.5% - 2.375rem / 8 * 7);
}
.flex-col8.gap39 > * {
  width: calc(12.5% - 2.4375rem / 8 * 7);
}
.flex-col8.gap40 > * {
  width: calc(12.5% - 2.5rem / 8 * 7);
}
.flex-col8.gap41 > * {
  width: calc(12.5% - 2.5625rem / 8 * 7);
}
.flex-col8.gap42 > * {
  width: calc(12.5% - 2.625rem / 8 * 7);
}
.flex-col8.gap43 > * {
  width: calc(12.5% - 2.6875rem / 8 * 7);
}
.flex-col8.gap44 > * {
  width: calc(12.5% - 2.75rem / 8 * 7);
}
.flex-col8.gap45 > * {
  width: calc(12.5% - 2.8125rem / 8 * 7);
}
.flex-col8.gap46 > * {
  width: calc(12.5% - 2.875rem / 8 * 7);
}
.flex-col8.gap47 > * {
  width: calc(12.5% - 2.9375rem / 8 * 7);
}
.flex-col8.gap48 > * {
  width: calc(12.5% - 3rem / 8 * 7);
}
.flex-col8.gap49 > * {
  width: calc(12.5% - 3.0625rem / 8 * 7);
}
.flex-col8.gap50 > * {
  width: calc(12.5% - 3.125rem / 8 * 7);
}
.flex-col8.gap51 > * {
  width: calc(12.5% - 3.1875rem / 8 * 7);
}
.flex-col8.gap52 > * {
  width: calc(12.5% - 3.25rem / 8 * 7);
}
.flex-col8.gap53 > * {
  width: calc(12.5% - 3.3125rem / 8 * 7);
}
.flex-col8.gap54 > * {
  width: calc(12.5% - 3.375rem / 8 * 7);
}
.flex-col8.gap55 > * {
  width: calc(12.5% - 3.4375rem / 8 * 7);
}
.flex-col8.gap56 > * {
  width: calc(12.5% - 3.5rem / 8 * 7);
}
.flex-col8.gap57 > * {
  width: calc(12.5% - 3.5625rem / 8 * 7);
}
.flex-col8.gap58 > * {
  width: calc(12.5% - 3.625rem / 8 * 7);
}
.flex-col8.gap59 > * {
  width: calc(12.5% - 3.6875rem / 8 * 7);
}
.flex-col8.gap60 > * {
  width: calc(12.5% - 3.75rem / 8 * 7);
}
.flex-col8.gap61 > * {
  width: calc(12.5% - 3.8125rem / 8 * 7);
}
.flex-col8.gap62 > * {
  width: calc(12.5% - 3.875rem / 8 * 7);
}
.flex-col8.gap63 > * {
  width: calc(12.5% - 3.9375rem / 8 * 7);
}
.flex-col8.gap64 > * {
  width: calc(12.5% - 4rem / 8 * 7);
}
.flex-col8.gap65 > * {
  width: calc(12.5% - 4.0625rem / 8 * 7);
}
.flex-col8.gap66 > * {
  width: calc(12.5% - 4.125rem / 8 * 7);
}
.flex-col8.gap67 > * {
  width: calc(12.5% - 4.1875rem / 8 * 7);
}
.flex-col8.gap68 > * {
  width: calc(12.5% - 4.25rem / 8 * 7);
}
.flex-col8.gap69 > * {
  width: calc(12.5% - 4.3125rem / 8 * 7);
}
.flex-col8.gap70 > * {
  width: calc(12.5% - 4.375rem / 8 * 7);
}
.flex-col8.gap71 > * {
  width: calc(12.5% - 4.4375rem / 8 * 7);
}
.flex-col8.gap72 > * {
  width: calc(12.5% - 4.5rem / 8 * 7);
}
.flex-col8.gap73 > * {
  width: calc(12.5% - 4.5625rem / 8 * 7);
}
.flex-col8.gap74 > * {
  width: calc(12.5% - 4.625rem / 8 * 7);
}
.flex-col8.gap75 > * {
  width: calc(12.5% - 4.6875rem / 8 * 7);
}
.flex-col8.gap76 > * {
  width: calc(12.5% - 4.75rem / 8 * 7);
}
.flex-col8.gap77 > * {
  width: calc(12.5% - 4.8125rem / 8 * 7);
}
.flex-col8.gap78 > * {
  width: calc(12.5% - 4.875rem / 8 * 7);
}
.flex-col8.gap79 > * {
  width: calc(12.5% - 4.9375rem / 8 * 7);
}
.flex-col8.gap80 > * {
  width: calc(12.5% - 5rem / 8 * 7);
}
.flex-col8.gap81 > * {
  width: calc(12.5% - 5.0625rem / 8 * 7);
}
.flex-col8.gap82 > * {
  width: calc(12.5% - 5.125rem / 8 * 7);
}
.flex-col8.gap83 > * {
  width: calc(12.5% - 5.1875rem / 8 * 7);
}
.flex-col8.gap84 > * {
  width: calc(12.5% - 5.25rem / 8 * 7);
}
.flex-col8.gap85 > * {
  width: calc(12.5% - 5.3125rem / 8 * 7);
}
.flex-col8.gap86 > * {
  width: calc(12.5% - 5.375rem / 8 * 7);
}
.flex-col8.gap87 > * {
  width: calc(12.5% - 5.4375rem / 8 * 7);
}
.flex-col8.gap88 > * {
  width: calc(12.5% - 5.5rem / 8 * 7);
}
.flex-col8.gap89 > * {
  width: calc(12.5% - 5.5625rem / 8 * 7);
}
.flex-col8.gap90 > * {
  width: calc(12.5% - 5.625rem / 8 * 7);
}
.flex-col8.gap91 > * {
  width: calc(12.5% - 5.6875rem / 8 * 7);
}
.flex-col8.gap92 > * {
  width: calc(12.5% - 5.75rem / 8 * 7);
}
.flex-col8.gap93 > * {
  width: calc(12.5% - 5.8125rem / 8 * 7);
}
.flex-col8.gap94 > * {
  width: calc(12.5% - 5.875rem / 8 * 7);
}
.flex-col8.gap95 > * {
  width: calc(12.5% - 5.9375rem / 8 * 7);
}
.flex-col8.gap96 > * {
  width: calc(12.5% - 6rem / 8 * 7);
}
.flex-col8.gap97 > * {
  width: calc(12.5% - 6.0625rem / 8 * 7);
}
.flex-col8.gap98 > * {
  width: calc(12.5% - 6.125rem / 8 * 7);
}
.flex-col8.gap99 > * {
  width: calc(12.5% - 6.1875rem / 8 * 7);
}
.flex-col8.gap100 > * {
  width: calc(12.5% - 6.25rem / 8 * 7);
}
.flex-col8.gap101 > * {
  width: calc(12.5% - 6.3125rem / 8 * 7);
}
.flex-col8.gap102 > * {
  width: calc(12.5% - 6.375rem / 8 * 7);
}
.flex-col8.gap103 > * {
  width: calc(12.5% - 6.4375rem / 8 * 7);
}
.flex-col8.gap104 > * {
  width: calc(12.5% - 6.5rem / 8 * 7);
}
.flex-col8.gap105 > * {
  width: calc(12.5% - 6.5625rem / 8 * 7);
}
.flex-col8.gap106 > * {
  width: calc(12.5% - 6.625rem / 8 * 7);
}
.flex-col8.gap107 > * {
  width: calc(12.5% - 6.6875rem / 8 * 7);
}
.flex-col8.gap108 > * {
  width: calc(12.5% - 6.75rem / 8 * 7);
}
.flex-col8.gap109 > * {
  width: calc(12.5% - 6.8125rem / 8 * 7);
}
.flex-col8.gap110 > * {
  width: calc(12.5% - 6.875rem / 8 * 7);
}
.flex-col8.gap111 > * {
  width: calc(12.5% - 6.9375rem / 8 * 7);
}
.flex-col8.gap112 > * {
  width: calc(12.5% - 7rem / 8 * 7);
}
.flex-col8.gap113 > * {
  width: calc(12.5% - 7.0625rem / 8 * 7);
}
.flex-col8.gap114 > * {
  width: calc(12.5% - 7.125rem / 8 * 7);
}
.flex-col8.gap115 > * {
  width: calc(12.5% - 7.1875rem / 8 * 7);
}
.flex-col8.gap116 > * {
  width: calc(12.5% - 7.25rem / 8 * 7);
}
.flex-col8.gap117 > * {
  width: calc(12.5% - 7.3125rem / 8 * 7);
}
.flex-col8.gap118 > * {
  width: calc(12.5% - 7.375rem / 8 * 7);
}
.flex-col8.gap119 > * {
  width: calc(12.5% - 7.4375rem / 8 * 7);
}
.flex-col8.gap120 > * {
  width: calc(12.5% - 7.5rem / 8 * 7);
}
.flex-col8.gap121 > * {
  width: calc(12.5% - 7.5625rem / 8 * 7);
}
.flex-col8.gap122 > * {
  width: calc(12.5% - 7.625rem / 8 * 7);
}
.flex-col8.gap123 > * {
  width: calc(12.5% - 7.6875rem / 8 * 7);
}
.flex-col8.gap124 > * {
  width: calc(12.5% - 7.75rem / 8 * 7);
}
.flex-col8.gap125 > * {
  width: calc(12.5% - 7.8125rem / 8 * 7);
}
.flex-col8.gap126 > * {
  width: calc(12.5% - 7.875rem / 8 * 7);
}
.flex-col8.gap127 > * {
  width: calc(12.5% - 7.9375rem / 8 * 7);
}
.flex-col8.gap128 > * {
  width: calc(12.5% - 8rem / 8 * 7);
}
.flex-col8.gap129 > * {
  width: calc(12.5% - 8.0625rem / 8 * 7);
}
.flex-col8.gap130 > * {
  width: calc(12.5% - 8.125rem / 8 * 7);
}
.flex-col8.gap131 > * {
  width: calc(12.5% - 8.1875rem / 8 * 7);
}
.flex-col8.gap132 > * {
  width: calc(12.5% - 8.25rem / 8 * 7);
}
.flex-col8.gap133 > * {
  width: calc(12.5% - 8.3125rem / 8 * 7);
}
.flex-col8.gap134 > * {
  width: calc(12.5% - 8.375rem / 8 * 7);
}
.flex-col8.gap135 > * {
  width: calc(12.5% - 8.4375rem / 8 * 7);
}
.flex-col8.gap136 > * {
  width: calc(12.5% - 8.5rem / 8 * 7);
}
.flex-col8.gap137 > * {
  width: calc(12.5% - 8.5625rem / 8 * 7);
}
.flex-col8.gap138 > * {
  width: calc(12.5% - 8.625rem / 8 * 7);
}
.flex-col8.gap139 > * {
  width: calc(12.5% - 8.6875rem / 8 * 7);
}
.flex-col8.gap140 > * {
  width: calc(12.5% - 8.75rem / 8 * 7);
}
.flex-col8.gap141 > * {
  width: calc(12.5% - 8.8125rem / 8 * 7);
}
.flex-col8.gap142 > * {
  width: calc(12.5% - 8.875rem / 8 * 7);
}
.flex-col8.gap143 > * {
  width: calc(12.5% - 8.9375rem / 8 * 7);
}
.flex-col8.gap144 > * {
  width: calc(12.5% - 9rem / 8 * 7);
}
.flex-col8.gap145 > * {
  width: calc(12.5% - 9.0625rem / 8 * 7);
}
.flex-col8.gap146 > * {
  width: calc(12.5% - 9.125rem / 8 * 7);
}
.flex-col8.gap147 > * {
  width: calc(12.5% - 9.1875rem / 8 * 7);
}
.flex-col8.gap148 > * {
  width: calc(12.5% - 9.25rem / 8 * 7);
}
.flex-col8.gap149 > * {
  width: calc(12.5% - 9.3125rem / 8 * 7);
}
.flex-col8.gap150 > * {
  width: calc(12.5% - 9.375rem / 8 * 7);
}

@media screen and (max-width: 47.9375em) {
  .flex-sp-col1 {
    flex-wrap: wrap;
  }
  .flex-sp-col1 > * {
    width: 100%;
  }
  .flex-sp-col1.gap1 > * {
    width: calc(100% - 0.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap2 > * {
    width: calc(100% - 0.125rem / 1 * 0);
  }
  .flex-sp-col1.gap3 > * {
    width: calc(100% - 0.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap4 > * {
    width: calc(100% - 0.25rem / 1 * 0);
  }
  .flex-sp-col1.gap5 > * {
    width: calc(100% - 0.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap6 > * {
    width: calc(100% - 0.375rem / 1 * 0);
  }
  .flex-sp-col1.gap7 > * {
    width: calc(100% - 0.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap8 > * {
    width: calc(100% - 0.5rem / 1 * 0);
  }
  .flex-sp-col1.gap9 > * {
    width: calc(100% - 0.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap10 > * {
    width: calc(100% - 0.625rem / 1 * 0);
  }
  .flex-sp-col1.gap11 > * {
    width: calc(100% - 0.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap12 > * {
    width: calc(100% - 0.75rem / 1 * 0);
  }
  .flex-sp-col1.gap13 > * {
    width: calc(100% - 0.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap14 > * {
    width: calc(100% - 0.875rem / 1 * 0);
  }
  .flex-sp-col1.gap15 > * {
    width: calc(100% - 0.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap16 > * {
    width: calc(100% - 1rem / 1 * 0);
  }
  .flex-sp-col1.gap17 > * {
    width: calc(100% - 1.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap18 > * {
    width: calc(100% - 1.125rem / 1 * 0);
  }
  .flex-sp-col1.gap19 > * {
    width: calc(100% - 1.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap20 > * {
    width: calc(100% - 1.25rem / 1 * 0);
  }
  .flex-sp-col1.gap21 > * {
    width: calc(100% - 1.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap22 > * {
    width: calc(100% - 1.375rem / 1 * 0);
  }
  .flex-sp-col1.gap23 > * {
    width: calc(100% - 1.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap24 > * {
    width: calc(100% - 1.5rem / 1 * 0);
  }
  .flex-sp-col1.gap25 > * {
    width: calc(100% - 1.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap26 > * {
    width: calc(100% - 1.625rem / 1 * 0);
  }
  .flex-sp-col1.gap27 > * {
    width: calc(100% - 1.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap28 > * {
    width: calc(100% - 1.75rem / 1 * 0);
  }
  .flex-sp-col1.gap29 > * {
    width: calc(100% - 1.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap30 > * {
    width: calc(100% - 1.875rem / 1 * 0);
  }
  .flex-sp-col1.gap31 > * {
    width: calc(100% - 1.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap32 > * {
    width: calc(100% - 2rem / 1 * 0);
  }
  .flex-sp-col1.gap33 > * {
    width: calc(100% - 2.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap34 > * {
    width: calc(100% - 2.125rem / 1 * 0);
  }
  .flex-sp-col1.gap35 > * {
    width: calc(100% - 2.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap36 > * {
    width: calc(100% - 2.25rem / 1 * 0);
  }
  .flex-sp-col1.gap37 > * {
    width: calc(100% - 2.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap38 > * {
    width: calc(100% - 2.375rem / 1 * 0);
  }
  .flex-sp-col1.gap39 > * {
    width: calc(100% - 2.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap40 > * {
    width: calc(100% - 2.5rem / 1 * 0);
  }
  .flex-sp-col1.gap41 > * {
    width: calc(100% - 2.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap42 > * {
    width: calc(100% - 2.625rem / 1 * 0);
  }
  .flex-sp-col1.gap43 > * {
    width: calc(100% - 2.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap44 > * {
    width: calc(100% - 2.75rem / 1 * 0);
  }
  .flex-sp-col1.gap45 > * {
    width: calc(100% - 2.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap46 > * {
    width: calc(100% - 2.875rem / 1 * 0);
  }
  .flex-sp-col1.gap47 > * {
    width: calc(100% - 2.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap48 > * {
    width: calc(100% - 3rem / 1 * 0);
  }
  .flex-sp-col1.gap49 > * {
    width: calc(100% - 3.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap50 > * {
    width: calc(100% - 3.125rem / 1 * 0);
  }
  .flex-sp-col1.gap51 > * {
    width: calc(100% - 3.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap52 > * {
    width: calc(100% - 3.25rem / 1 * 0);
  }
  .flex-sp-col1.gap53 > * {
    width: calc(100% - 3.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap54 > * {
    width: calc(100% - 3.375rem / 1 * 0);
  }
  .flex-sp-col1.gap55 > * {
    width: calc(100% - 3.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap56 > * {
    width: calc(100% - 3.5rem / 1 * 0);
  }
  .flex-sp-col1.gap57 > * {
    width: calc(100% - 3.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap58 > * {
    width: calc(100% - 3.625rem / 1 * 0);
  }
  .flex-sp-col1.gap59 > * {
    width: calc(100% - 3.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap60 > * {
    width: calc(100% - 3.75rem / 1 * 0);
  }
  .flex-sp-col1.gap61 > * {
    width: calc(100% - 3.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap62 > * {
    width: calc(100% - 3.875rem / 1 * 0);
  }
  .flex-sp-col1.gap63 > * {
    width: calc(100% - 3.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap64 > * {
    width: calc(100% - 4rem / 1 * 0);
  }
  .flex-sp-col1.gap65 > * {
    width: calc(100% - 4.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap66 > * {
    width: calc(100% - 4.125rem / 1 * 0);
  }
  .flex-sp-col1.gap67 > * {
    width: calc(100% - 4.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap68 > * {
    width: calc(100% - 4.25rem / 1 * 0);
  }
  .flex-sp-col1.gap69 > * {
    width: calc(100% - 4.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap70 > * {
    width: calc(100% - 4.375rem / 1 * 0);
  }
  .flex-sp-col1.gap71 > * {
    width: calc(100% - 4.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap72 > * {
    width: calc(100% - 4.5rem / 1 * 0);
  }
  .flex-sp-col1.gap73 > * {
    width: calc(100% - 4.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap74 > * {
    width: calc(100% - 4.625rem / 1 * 0);
  }
  .flex-sp-col1.gap75 > * {
    width: calc(100% - 4.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap76 > * {
    width: calc(100% - 4.75rem / 1 * 0);
  }
  .flex-sp-col1.gap77 > * {
    width: calc(100% - 4.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap78 > * {
    width: calc(100% - 4.875rem / 1 * 0);
  }
  .flex-sp-col1.gap79 > * {
    width: calc(100% - 4.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap80 > * {
    width: calc(100% - 5rem / 1 * 0);
  }
  .flex-sp-col1.gap81 > * {
    width: calc(100% - 5.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap82 > * {
    width: calc(100% - 5.125rem / 1 * 0);
  }
  .flex-sp-col1.gap83 > * {
    width: calc(100% - 5.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap84 > * {
    width: calc(100% - 5.25rem / 1 * 0);
  }
  .flex-sp-col1.gap85 > * {
    width: calc(100% - 5.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap86 > * {
    width: calc(100% - 5.375rem / 1 * 0);
  }
  .flex-sp-col1.gap87 > * {
    width: calc(100% - 5.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap88 > * {
    width: calc(100% - 5.5rem / 1 * 0);
  }
  .flex-sp-col1.gap89 > * {
    width: calc(100% - 5.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap90 > * {
    width: calc(100% - 5.625rem / 1 * 0);
  }
  .flex-sp-col1.gap91 > * {
    width: calc(100% - 5.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap92 > * {
    width: calc(100% - 5.75rem / 1 * 0);
  }
  .flex-sp-col1.gap93 > * {
    width: calc(100% - 5.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap94 > * {
    width: calc(100% - 5.875rem / 1 * 0);
  }
  .flex-sp-col1.gap95 > * {
    width: calc(100% - 5.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap96 > * {
    width: calc(100% - 6rem / 1 * 0);
  }
  .flex-sp-col1.gap97 > * {
    width: calc(100% - 6.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap98 > * {
    width: calc(100% - 6.125rem / 1 * 0);
  }
  .flex-sp-col1.gap99 > * {
    width: calc(100% - 6.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap100 > * {
    width: calc(100% - 6.25rem / 1 * 0);
  }
  .flex-sp-col1.gap101 > * {
    width: calc(100% - 6.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap102 > * {
    width: calc(100% - 6.375rem / 1 * 0);
  }
  .flex-sp-col1.gap103 > * {
    width: calc(100% - 6.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap104 > * {
    width: calc(100% - 6.5rem / 1 * 0);
  }
  .flex-sp-col1.gap105 > * {
    width: calc(100% - 6.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap106 > * {
    width: calc(100% - 6.625rem / 1 * 0);
  }
  .flex-sp-col1.gap107 > * {
    width: calc(100% - 6.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap108 > * {
    width: calc(100% - 6.75rem / 1 * 0);
  }
  .flex-sp-col1.gap109 > * {
    width: calc(100% - 6.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap110 > * {
    width: calc(100% - 6.875rem / 1 * 0);
  }
  .flex-sp-col1.gap111 > * {
    width: calc(100% - 6.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap112 > * {
    width: calc(100% - 7rem / 1 * 0);
  }
  .flex-sp-col1.gap113 > * {
    width: calc(100% - 7.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap114 > * {
    width: calc(100% - 7.125rem / 1 * 0);
  }
  .flex-sp-col1.gap115 > * {
    width: calc(100% - 7.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap116 > * {
    width: calc(100% - 7.25rem / 1 * 0);
  }
  .flex-sp-col1.gap117 > * {
    width: calc(100% - 7.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap118 > * {
    width: calc(100% - 7.375rem / 1 * 0);
  }
  .flex-sp-col1.gap119 > * {
    width: calc(100% - 7.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap120 > * {
    width: calc(100% - 7.5rem / 1 * 0);
  }
  .flex-sp-col1.gap121 > * {
    width: calc(100% - 7.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap122 > * {
    width: calc(100% - 7.625rem / 1 * 0);
  }
  .flex-sp-col1.gap123 > * {
    width: calc(100% - 7.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap124 > * {
    width: calc(100% - 7.75rem / 1 * 0);
  }
  .flex-sp-col1.gap125 > * {
    width: calc(100% - 7.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap126 > * {
    width: calc(100% - 7.875rem / 1 * 0);
  }
  .flex-sp-col1.gap127 > * {
    width: calc(100% - 7.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap128 > * {
    width: calc(100% - 8rem / 1 * 0);
  }
  .flex-sp-col1.gap129 > * {
    width: calc(100% - 8.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap130 > * {
    width: calc(100% - 8.125rem / 1 * 0);
  }
  .flex-sp-col1.gap131 > * {
    width: calc(100% - 8.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap132 > * {
    width: calc(100% - 8.25rem / 1 * 0);
  }
  .flex-sp-col1.gap133 > * {
    width: calc(100% - 8.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap134 > * {
    width: calc(100% - 8.375rem / 1 * 0);
  }
  .flex-sp-col1.gap135 > * {
    width: calc(100% - 8.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap136 > * {
    width: calc(100% - 8.5rem / 1 * 0);
  }
  .flex-sp-col1.gap137 > * {
    width: calc(100% - 8.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap138 > * {
    width: calc(100% - 8.625rem / 1 * 0);
  }
  .flex-sp-col1.gap139 > * {
    width: calc(100% - 8.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap140 > * {
    width: calc(100% - 8.75rem / 1 * 0);
  }
  .flex-sp-col1.gap141 > * {
    width: calc(100% - 8.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap142 > * {
    width: calc(100% - 8.875rem / 1 * 0);
  }
  .flex-sp-col1.gap143 > * {
    width: calc(100% - 8.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap144 > * {
    width: calc(100% - 9rem / 1 * 0);
  }
  .flex-sp-col1.gap145 > * {
    width: calc(100% - 9.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap146 > * {
    width: calc(100% - 9.125rem / 1 * 0);
  }
  .flex-sp-col1.gap147 > * {
    width: calc(100% - 9.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap148 > * {
    width: calc(100% - 9.25rem / 1 * 0);
  }
  .flex-sp-col1.gap149 > * {
    width: calc(100% - 9.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap150 > * {
    width: calc(100% - 9.375rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp1 > * {
    width: calc(100% - 0.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp2 > * {
    width: calc(100% - 0.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp3 > * {
    width: calc(100% - 0.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp4 > * {
    width: calc(100% - 0.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp5 > * {
    width: calc(100% - 0.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp6 > * {
    width: calc(100% - 0.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp7 > * {
    width: calc(100% - 0.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp8 > * {
    width: calc(100% - 0.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp9 > * {
    width: calc(100% - 0.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp10 > * {
    width: calc(100% - 0.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp11 > * {
    width: calc(100% - 0.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp12 > * {
    width: calc(100% - 0.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp13 > * {
    width: calc(100% - 0.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp14 > * {
    width: calc(100% - 1rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp15 > * {
    width: calc(100% - 1.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp16 > * {
    width: calc(100% - 1.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp17 > * {
    width: calc(100% - 1.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp18 > * {
    width: calc(100% - 1.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp19 > * {
    width: calc(100% - 1.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp20 > * {
    width: calc(100% - 1.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp21 > * {
    width: calc(100% - 1.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp22 > * {
    width: calc(100% - 1.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp23 > * {
    width: calc(100% - 1.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp24 > * {
    width: calc(100% - 1.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp25 > * {
    width: calc(100% - 1.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp26 > * {
    width: calc(100% - 1.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp27 > * {
    width: calc(100% - 1.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp28 > * {
    width: calc(100% - 2rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp29 > * {
    width: calc(100% - 2.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp30 > * {
    width: calc(100% - 2.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp31 > * {
    width: calc(100% - 2.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp32 > * {
    width: calc(100% - 2.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp33 > * {
    width: calc(100% - 2.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp34 > * {
    width: calc(100% - 2.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp35 > * {
    width: calc(100% - 2.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp36 > * {
    width: calc(100% - 2.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp37 > * {
    width: calc(100% - 2.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp38 > * {
    width: calc(100% - 2.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp39 > * {
    width: calc(100% - 2.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp40 > * {
    width: calc(100% - 2.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp41 > * {
    width: calc(100% - 2.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp42 > * {
    width: calc(100% - 3rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp43 > * {
    width: calc(100% - 3.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp44 > * {
    width: calc(100% - 3.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp45 > * {
    width: calc(100% - 3.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp46 > * {
    width: calc(100% - 3.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp47 > * {
    width: calc(100% - 3.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp48 > * {
    width: calc(100% - 3.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp49 > * {
    width: calc(100% - 3.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp50 > * {
    width: calc(100% - 3.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp51 > * {
    width: calc(100% - 3.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp52 > * {
    width: calc(100% - 3.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp53 > * {
    width: calc(100% - 3.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp54 > * {
    width: calc(100% - 3.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp55 > * {
    width: calc(100% - 3.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp56 > * {
    width: calc(100% - 4rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp57 > * {
    width: calc(100% - 4.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp58 > * {
    width: calc(100% - 4.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp59 > * {
    width: calc(100% - 4.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp60 > * {
    width: calc(100% - 4.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp61 > * {
    width: calc(100% - 4.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp62 > * {
    width: calc(100% - 4.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp63 > * {
    width: calc(100% - 4.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp64 > * {
    width: calc(100% - 4.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp65 > * {
    width: calc(100% - 4.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp66 > * {
    width: calc(100% - 4.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp67 > * {
    width: calc(100% - 4.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp68 > * {
    width: calc(100% - 4.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp69 > * {
    width: calc(100% - 4.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp70 > * {
    width: calc(100% - 5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp71 > * {
    width: calc(100% - 5.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp72 > * {
    width: calc(100% - 5.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp73 > * {
    width: calc(100% - 5.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp74 > * {
    width: calc(100% - 5.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp75 > * {
    width: calc(100% - 5.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp76 > * {
    width: calc(100% - 5.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp77 > * {
    width: calc(100% - 5.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp78 > * {
    width: calc(100% - 5.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp79 > * {
    width: calc(100% - 5.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp80 > * {
    width: calc(100% - 5.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp81 > * {
    width: calc(100% - 5.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp82 > * {
    width: calc(100% - 5.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp83 > * {
    width: calc(100% - 5.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp84 > * {
    width: calc(100% - 6rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp85 > * {
    width: calc(100% - 6.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp86 > * {
    width: calc(100% - 6.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp87 > * {
    width: calc(100% - 6.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp88 > * {
    width: calc(100% - 6.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp89 > * {
    width: calc(100% - 6.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp90 > * {
    width: calc(100% - 6.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp91 > * {
    width: calc(100% - 6.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp92 > * {
    width: calc(100% - 6.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp93 > * {
    width: calc(100% - 6.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp94 > * {
    width: calc(100% - 6.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp95 > * {
    width: calc(100% - 6.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp96 > * {
    width: calc(100% - 6.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp97 > * {
    width: calc(100% - 6.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp98 > * {
    width: calc(100% - 7rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp99 > * {
    width: calc(100% - 7.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp100 > * {
    width: calc(100% - 7.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp101 > * {
    width: calc(100% - 7.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp102 > * {
    width: calc(100% - 7.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp103 > * {
    width: calc(100% - 7.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp104 > * {
    width: calc(100% - 7.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp105 > * {
    width: calc(100% - 7.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp106 > * {
    width: calc(100% - 7.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp107 > * {
    width: calc(100% - 7.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp108 > * {
    width: calc(100% - 7.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp109 > * {
    width: calc(100% - 7.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp110 > * {
    width: calc(100% - 7.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp111 > * {
    width: calc(100% - 7.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp112 > * {
    width: calc(100% - 8rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp113 > * {
    width: calc(100% - 8.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp114 > * {
    width: calc(100% - 8.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp115 > * {
    width: calc(100% - 8.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp116 > * {
    width: calc(100% - 8.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp117 > * {
    width: calc(100% - 8.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp118 > * {
    width: calc(100% - 8.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp119 > * {
    width: calc(100% - 8.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp120 > * {
    width: calc(100% - 8.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp121 > * {
    width: calc(100% - 8.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp122 > * {
    width: calc(100% - 8.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp123 > * {
    width: calc(100% - 8.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp124 > * {
    width: calc(100% - 8.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp125 > * {
    width: calc(100% - 8.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp126 > * {
    width: calc(100% - 9rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp127 > * {
    width: calc(100% - 9.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp128 > * {
    width: calc(100% - 9.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp129 > * {
    width: calc(100% - 9.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp130 > * {
    width: calc(100% - 9.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp131 > * {
    width: calc(100% - 9.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp132 > * {
    width: calc(100% - 9.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp133 > * {
    width: calc(100% - 9.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp134 > * {
    width: calc(100% - 9.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp135 > * {
    width: calc(100% - 9.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp136 > * {
    width: calc(100% - 9.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp137 > * {
    width: calc(100% - 9.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp138 > * {
    width: calc(100% - 9.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp139 > * {
    width: calc(100% - 9.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp140 > * {
    width: calc(100% - 10rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp141 > * {
    width: calc(100% - 10.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp142 > * {
    width: calc(100% - 10.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp143 > * {
    width: calc(100% - 10.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp144 > * {
    width: calc(100% - 10.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp145 > * {
    width: calc(100% - 10.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp146 > * {
    width: calc(100% - 10.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp147 > * {
    width: calc(100% - 10.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp148 > * {
    width: calc(100% - 10.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp149 > * {
    width: calc(100% - 10.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp150 > * {
    width: calc(100% - 10.7142857143rem / 1 * 0);
  }
  .flex-sp-col2 {
    flex-wrap: wrap;
  }
  .flex-sp-col2 > * {
    width: 50%;
  }
  .flex-sp-col2.gap1 > * {
    width: calc(50% - 0.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap2 > * {
    width: calc(50% - 0.125rem / 2 * 1);
  }
  .flex-sp-col2.gap3 > * {
    width: calc(50% - 0.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap4 > * {
    width: calc(50% - 0.25rem / 2 * 1);
  }
  .flex-sp-col2.gap5 > * {
    width: calc(50% - 0.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap6 > * {
    width: calc(50% - 0.375rem / 2 * 1);
  }
  .flex-sp-col2.gap7 > * {
    width: calc(50% - 0.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap8 > * {
    width: calc(50% - 0.5rem / 2 * 1);
  }
  .flex-sp-col2.gap9 > * {
    width: calc(50% - 0.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap10 > * {
    width: calc(50% - 0.625rem / 2 * 1);
  }
  .flex-sp-col2.gap11 > * {
    width: calc(50% - 0.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap12 > * {
    width: calc(50% - 0.75rem / 2 * 1);
  }
  .flex-sp-col2.gap13 > * {
    width: calc(50% - 0.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap14 > * {
    width: calc(50% - 0.875rem / 2 * 1);
  }
  .flex-sp-col2.gap15 > * {
    width: calc(50% - 0.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap16 > * {
    width: calc(50% - 1rem / 2 * 1);
  }
  .flex-sp-col2.gap17 > * {
    width: calc(50% - 1.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap18 > * {
    width: calc(50% - 1.125rem / 2 * 1);
  }
  .flex-sp-col2.gap19 > * {
    width: calc(50% - 1.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap20 > * {
    width: calc(50% - 1.25rem / 2 * 1);
  }
  .flex-sp-col2.gap21 > * {
    width: calc(50% - 1.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap22 > * {
    width: calc(50% - 1.375rem / 2 * 1);
  }
  .flex-sp-col2.gap23 > * {
    width: calc(50% - 1.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap24 > * {
    width: calc(50% - 1.5rem / 2 * 1);
  }
  .flex-sp-col2.gap25 > * {
    width: calc(50% - 1.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap26 > * {
    width: calc(50% - 1.625rem / 2 * 1);
  }
  .flex-sp-col2.gap27 > * {
    width: calc(50% - 1.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap28 > * {
    width: calc(50% - 1.75rem / 2 * 1);
  }
  .flex-sp-col2.gap29 > * {
    width: calc(50% - 1.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap30 > * {
    width: calc(50% - 1.875rem / 2 * 1);
  }
  .flex-sp-col2.gap31 > * {
    width: calc(50% - 1.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap32 > * {
    width: calc(50% - 2rem / 2 * 1);
  }
  .flex-sp-col2.gap33 > * {
    width: calc(50% - 2.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap34 > * {
    width: calc(50% - 2.125rem / 2 * 1);
  }
  .flex-sp-col2.gap35 > * {
    width: calc(50% - 2.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap36 > * {
    width: calc(50% - 2.25rem / 2 * 1);
  }
  .flex-sp-col2.gap37 > * {
    width: calc(50% - 2.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap38 > * {
    width: calc(50% - 2.375rem / 2 * 1);
  }
  .flex-sp-col2.gap39 > * {
    width: calc(50% - 2.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap40 > * {
    width: calc(50% - 2.5rem / 2 * 1);
  }
  .flex-sp-col2.gap41 > * {
    width: calc(50% - 2.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap42 > * {
    width: calc(50% - 2.625rem / 2 * 1);
  }
  .flex-sp-col2.gap43 > * {
    width: calc(50% - 2.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap44 > * {
    width: calc(50% - 2.75rem / 2 * 1);
  }
  .flex-sp-col2.gap45 > * {
    width: calc(50% - 2.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap46 > * {
    width: calc(50% - 2.875rem / 2 * 1);
  }
  .flex-sp-col2.gap47 > * {
    width: calc(50% - 2.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap48 > * {
    width: calc(50% - 3rem / 2 * 1);
  }
  .flex-sp-col2.gap49 > * {
    width: calc(50% - 3.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap50 > * {
    width: calc(50% - 3.125rem / 2 * 1);
  }
  .flex-sp-col2.gap51 > * {
    width: calc(50% - 3.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap52 > * {
    width: calc(50% - 3.25rem / 2 * 1);
  }
  .flex-sp-col2.gap53 > * {
    width: calc(50% - 3.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap54 > * {
    width: calc(50% - 3.375rem / 2 * 1);
  }
  .flex-sp-col2.gap55 > * {
    width: calc(50% - 3.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap56 > * {
    width: calc(50% - 3.5rem / 2 * 1);
  }
  .flex-sp-col2.gap57 > * {
    width: calc(50% - 3.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap58 > * {
    width: calc(50% - 3.625rem / 2 * 1);
  }
  .flex-sp-col2.gap59 > * {
    width: calc(50% - 3.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap60 > * {
    width: calc(50% - 3.75rem / 2 * 1);
  }
  .flex-sp-col2.gap61 > * {
    width: calc(50% - 3.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap62 > * {
    width: calc(50% - 3.875rem / 2 * 1);
  }
  .flex-sp-col2.gap63 > * {
    width: calc(50% - 3.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap64 > * {
    width: calc(50% - 4rem / 2 * 1);
  }
  .flex-sp-col2.gap65 > * {
    width: calc(50% - 4.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap66 > * {
    width: calc(50% - 4.125rem / 2 * 1);
  }
  .flex-sp-col2.gap67 > * {
    width: calc(50% - 4.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap68 > * {
    width: calc(50% - 4.25rem / 2 * 1);
  }
  .flex-sp-col2.gap69 > * {
    width: calc(50% - 4.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap70 > * {
    width: calc(50% - 4.375rem / 2 * 1);
  }
  .flex-sp-col2.gap71 > * {
    width: calc(50% - 4.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap72 > * {
    width: calc(50% - 4.5rem / 2 * 1);
  }
  .flex-sp-col2.gap73 > * {
    width: calc(50% - 4.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap74 > * {
    width: calc(50% - 4.625rem / 2 * 1);
  }
  .flex-sp-col2.gap75 > * {
    width: calc(50% - 4.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap76 > * {
    width: calc(50% - 4.75rem / 2 * 1);
  }
  .flex-sp-col2.gap77 > * {
    width: calc(50% - 4.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap78 > * {
    width: calc(50% - 4.875rem / 2 * 1);
  }
  .flex-sp-col2.gap79 > * {
    width: calc(50% - 4.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap80 > * {
    width: calc(50% - 5rem / 2 * 1);
  }
  .flex-sp-col2.gap81 > * {
    width: calc(50% - 5.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap82 > * {
    width: calc(50% - 5.125rem / 2 * 1);
  }
  .flex-sp-col2.gap83 > * {
    width: calc(50% - 5.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap84 > * {
    width: calc(50% - 5.25rem / 2 * 1);
  }
  .flex-sp-col2.gap85 > * {
    width: calc(50% - 5.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap86 > * {
    width: calc(50% - 5.375rem / 2 * 1);
  }
  .flex-sp-col2.gap87 > * {
    width: calc(50% - 5.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap88 > * {
    width: calc(50% - 5.5rem / 2 * 1);
  }
  .flex-sp-col2.gap89 > * {
    width: calc(50% - 5.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap90 > * {
    width: calc(50% - 5.625rem / 2 * 1);
  }
  .flex-sp-col2.gap91 > * {
    width: calc(50% - 5.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap92 > * {
    width: calc(50% - 5.75rem / 2 * 1);
  }
  .flex-sp-col2.gap93 > * {
    width: calc(50% - 5.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap94 > * {
    width: calc(50% - 5.875rem / 2 * 1);
  }
  .flex-sp-col2.gap95 > * {
    width: calc(50% - 5.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap96 > * {
    width: calc(50% - 6rem / 2 * 1);
  }
  .flex-sp-col2.gap97 > * {
    width: calc(50% - 6.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap98 > * {
    width: calc(50% - 6.125rem / 2 * 1);
  }
  .flex-sp-col2.gap99 > * {
    width: calc(50% - 6.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap100 > * {
    width: calc(50% - 6.25rem / 2 * 1);
  }
  .flex-sp-col2.gap101 > * {
    width: calc(50% - 6.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap102 > * {
    width: calc(50% - 6.375rem / 2 * 1);
  }
  .flex-sp-col2.gap103 > * {
    width: calc(50% - 6.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap104 > * {
    width: calc(50% - 6.5rem / 2 * 1);
  }
  .flex-sp-col2.gap105 > * {
    width: calc(50% - 6.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap106 > * {
    width: calc(50% - 6.625rem / 2 * 1);
  }
  .flex-sp-col2.gap107 > * {
    width: calc(50% - 6.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap108 > * {
    width: calc(50% - 6.75rem / 2 * 1);
  }
  .flex-sp-col2.gap109 > * {
    width: calc(50% - 6.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap110 > * {
    width: calc(50% - 6.875rem / 2 * 1);
  }
  .flex-sp-col2.gap111 > * {
    width: calc(50% - 6.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap112 > * {
    width: calc(50% - 7rem / 2 * 1);
  }
  .flex-sp-col2.gap113 > * {
    width: calc(50% - 7.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap114 > * {
    width: calc(50% - 7.125rem / 2 * 1);
  }
  .flex-sp-col2.gap115 > * {
    width: calc(50% - 7.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap116 > * {
    width: calc(50% - 7.25rem / 2 * 1);
  }
  .flex-sp-col2.gap117 > * {
    width: calc(50% - 7.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap118 > * {
    width: calc(50% - 7.375rem / 2 * 1);
  }
  .flex-sp-col2.gap119 > * {
    width: calc(50% - 7.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap120 > * {
    width: calc(50% - 7.5rem / 2 * 1);
  }
  .flex-sp-col2.gap121 > * {
    width: calc(50% - 7.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap122 > * {
    width: calc(50% - 7.625rem / 2 * 1);
  }
  .flex-sp-col2.gap123 > * {
    width: calc(50% - 7.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap124 > * {
    width: calc(50% - 7.75rem / 2 * 1);
  }
  .flex-sp-col2.gap125 > * {
    width: calc(50% - 7.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap126 > * {
    width: calc(50% - 7.875rem / 2 * 1);
  }
  .flex-sp-col2.gap127 > * {
    width: calc(50% - 7.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap128 > * {
    width: calc(50% - 8rem / 2 * 1);
  }
  .flex-sp-col2.gap129 > * {
    width: calc(50% - 8.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap130 > * {
    width: calc(50% - 8.125rem / 2 * 1);
  }
  .flex-sp-col2.gap131 > * {
    width: calc(50% - 8.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap132 > * {
    width: calc(50% - 8.25rem / 2 * 1);
  }
  .flex-sp-col2.gap133 > * {
    width: calc(50% - 8.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap134 > * {
    width: calc(50% - 8.375rem / 2 * 1);
  }
  .flex-sp-col2.gap135 > * {
    width: calc(50% - 8.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap136 > * {
    width: calc(50% - 8.5rem / 2 * 1);
  }
  .flex-sp-col2.gap137 > * {
    width: calc(50% - 8.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap138 > * {
    width: calc(50% - 8.625rem / 2 * 1);
  }
  .flex-sp-col2.gap139 > * {
    width: calc(50% - 8.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap140 > * {
    width: calc(50% - 8.75rem / 2 * 1);
  }
  .flex-sp-col2.gap141 > * {
    width: calc(50% - 8.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap142 > * {
    width: calc(50% - 8.875rem / 2 * 1);
  }
  .flex-sp-col2.gap143 > * {
    width: calc(50% - 8.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap144 > * {
    width: calc(50% - 9rem / 2 * 1);
  }
  .flex-sp-col2.gap145 > * {
    width: calc(50% - 9.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap146 > * {
    width: calc(50% - 9.125rem / 2 * 1);
  }
  .flex-sp-col2.gap147 > * {
    width: calc(50% - 9.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap148 > * {
    width: calc(50% - 9.25rem / 2 * 1);
  }
  .flex-sp-col2.gap149 > * {
    width: calc(50% - 9.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap150 > * {
    width: calc(50% - 9.375rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp1 > * {
    width: calc(50% - 0.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp2 > * {
    width: calc(50% - 0.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp3 > * {
    width: calc(50% - 0.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp4 > * {
    width: calc(50% - 0.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp5 > * {
    width: calc(50% - 0.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp6 > * {
    width: calc(50% - 0.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp7 > * {
    width: calc(50% - 0.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp8 > * {
    width: calc(50% - 0.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp9 > * {
    width: calc(50% - 0.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp10 > * {
    width: calc(50% - 0.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp11 > * {
    width: calc(50% - 0.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp12 > * {
    width: calc(50% - 0.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp13 > * {
    width: calc(50% - 0.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp14 > * {
    width: calc(50% - 1rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp15 > * {
    width: calc(50% - 1.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp16 > * {
    width: calc(50% - 1.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp17 > * {
    width: calc(50% - 1.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp18 > * {
    width: calc(50% - 1.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp19 > * {
    width: calc(50% - 1.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp20 > * {
    width: calc(50% - 1.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp21 > * {
    width: calc(50% - 1.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp22 > * {
    width: calc(50% - 1.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp23 > * {
    width: calc(50% - 1.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp24 > * {
    width: calc(50% - 1.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp25 > * {
    width: calc(50% - 1.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp26 > * {
    width: calc(50% - 1.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp27 > * {
    width: calc(50% - 1.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp28 > * {
    width: calc(50% - 2rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp29 > * {
    width: calc(50% - 2.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp30 > * {
    width: calc(50% - 2.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp31 > * {
    width: calc(50% - 2.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp32 > * {
    width: calc(50% - 2.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp33 > * {
    width: calc(50% - 2.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp34 > * {
    width: calc(50% - 2.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp35 > * {
    width: calc(50% - 2.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp36 > * {
    width: calc(50% - 2.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp37 > * {
    width: calc(50% - 2.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp38 > * {
    width: calc(50% - 2.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp39 > * {
    width: calc(50% - 2.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp40 > * {
    width: calc(50% - 2.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp41 > * {
    width: calc(50% - 2.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp42 > * {
    width: calc(50% - 3rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp43 > * {
    width: calc(50% - 3.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp44 > * {
    width: calc(50% - 3.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp45 > * {
    width: calc(50% - 3.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp46 > * {
    width: calc(50% - 3.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp47 > * {
    width: calc(50% - 3.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp48 > * {
    width: calc(50% - 3.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp49 > * {
    width: calc(50% - 3.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp50 > * {
    width: calc(50% - 3.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp51 > * {
    width: calc(50% - 3.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp52 > * {
    width: calc(50% - 3.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp53 > * {
    width: calc(50% - 3.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp54 > * {
    width: calc(50% - 3.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp55 > * {
    width: calc(50% - 3.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp56 > * {
    width: calc(50% - 4rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp57 > * {
    width: calc(50% - 4.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp58 > * {
    width: calc(50% - 4.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp59 > * {
    width: calc(50% - 4.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp60 > * {
    width: calc(50% - 4.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp61 > * {
    width: calc(50% - 4.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp62 > * {
    width: calc(50% - 4.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp63 > * {
    width: calc(50% - 4.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp64 > * {
    width: calc(50% - 4.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp65 > * {
    width: calc(50% - 4.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp66 > * {
    width: calc(50% - 4.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp67 > * {
    width: calc(50% - 4.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp68 > * {
    width: calc(50% - 4.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp69 > * {
    width: calc(50% - 4.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp70 > * {
    width: calc(50% - 5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp71 > * {
    width: calc(50% - 5.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp72 > * {
    width: calc(50% - 5.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp73 > * {
    width: calc(50% - 5.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp74 > * {
    width: calc(50% - 5.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp75 > * {
    width: calc(50% - 5.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp76 > * {
    width: calc(50% - 5.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp77 > * {
    width: calc(50% - 5.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp78 > * {
    width: calc(50% - 5.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp79 > * {
    width: calc(50% - 5.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp80 > * {
    width: calc(50% - 5.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp81 > * {
    width: calc(50% - 5.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp82 > * {
    width: calc(50% - 5.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp83 > * {
    width: calc(50% - 5.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp84 > * {
    width: calc(50% - 6rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp85 > * {
    width: calc(50% - 6.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp86 > * {
    width: calc(50% - 6.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp87 > * {
    width: calc(50% - 6.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp88 > * {
    width: calc(50% - 6.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp89 > * {
    width: calc(50% - 6.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp90 > * {
    width: calc(50% - 6.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp91 > * {
    width: calc(50% - 6.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp92 > * {
    width: calc(50% - 6.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp93 > * {
    width: calc(50% - 6.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp94 > * {
    width: calc(50% - 6.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp95 > * {
    width: calc(50% - 6.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp96 > * {
    width: calc(50% - 6.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp97 > * {
    width: calc(50% - 6.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp98 > * {
    width: calc(50% - 7rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp99 > * {
    width: calc(50% - 7.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp100 > * {
    width: calc(50% - 7.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp101 > * {
    width: calc(50% - 7.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp102 > * {
    width: calc(50% - 7.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp103 > * {
    width: calc(50% - 7.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp104 > * {
    width: calc(50% - 7.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp105 > * {
    width: calc(50% - 7.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp106 > * {
    width: calc(50% - 7.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp107 > * {
    width: calc(50% - 7.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp108 > * {
    width: calc(50% - 7.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp109 > * {
    width: calc(50% - 7.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp110 > * {
    width: calc(50% - 7.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp111 > * {
    width: calc(50% - 7.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp112 > * {
    width: calc(50% - 8rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp113 > * {
    width: calc(50% - 8.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp114 > * {
    width: calc(50% - 8.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp115 > * {
    width: calc(50% - 8.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp116 > * {
    width: calc(50% - 8.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp117 > * {
    width: calc(50% - 8.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp118 > * {
    width: calc(50% - 8.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp119 > * {
    width: calc(50% - 8.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp120 > * {
    width: calc(50% - 8.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp121 > * {
    width: calc(50% - 8.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp122 > * {
    width: calc(50% - 8.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp123 > * {
    width: calc(50% - 8.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp124 > * {
    width: calc(50% - 8.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp125 > * {
    width: calc(50% - 8.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp126 > * {
    width: calc(50% - 9rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp127 > * {
    width: calc(50% - 9.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp128 > * {
    width: calc(50% - 9.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp129 > * {
    width: calc(50% - 9.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp130 > * {
    width: calc(50% - 9.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp131 > * {
    width: calc(50% - 9.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp132 > * {
    width: calc(50% - 9.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp133 > * {
    width: calc(50% - 9.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp134 > * {
    width: calc(50% - 9.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp135 > * {
    width: calc(50% - 9.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp136 > * {
    width: calc(50% - 9.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp137 > * {
    width: calc(50% - 9.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp138 > * {
    width: calc(50% - 9.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp139 > * {
    width: calc(50% - 9.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp140 > * {
    width: calc(50% - 10rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp141 > * {
    width: calc(50% - 10.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp142 > * {
    width: calc(50% - 10.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp143 > * {
    width: calc(50% - 10.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp144 > * {
    width: calc(50% - 10.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp145 > * {
    width: calc(50% - 10.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp146 > * {
    width: calc(50% - 10.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp147 > * {
    width: calc(50% - 10.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp148 > * {
    width: calc(50% - 10.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp149 > * {
    width: calc(50% - 10.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp150 > * {
    width: calc(50% - 10.7142857143rem / 2 * 1);
  }
  .flex-sp-col3 {
    flex-wrap: wrap;
  }
  .flex-sp-col3 > * {
    width: 33.3333333333%;
  }
  .flex-sp-col3.gap1 > * {
    width: calc(33.3333333333% - 0.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap2 > * {
    width: calc(33.3333333333% - 0.125rem / 3 * 2);
  }
  .flex-sp-col3.gap3 > * {
    width: calc(33.3333333333% - 0.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap4 > * {
    width: calc(33.3333333333% - 0.25rem / 3 * 2);
  }
  .flex-sp-col3.gap5 > * {
    width: calc(33.3333333333% - 0.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap6 > * {
    width: calc(33.3333333333% - 0.375rem / 3 * 2);
  }
  .flex-sp-col3.gap7 > * {
    width: calc(33.3333333333% - 0.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap8 > * {
    width: calc(33.3333333333% - 0.5rem / 3 * 2);
  }
  .flex-sp-col3.gap9 > * {
    width: calc(33.3333333333% - 0.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap10 > * {
    width: calc(33.3333333333% - 0.625rem / 3 * 2);
  }
  .flex-sp-col3.gap11 > * {
    width: calc(33.3333333333% - 0.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap12 > * {
    width: calc(33.3333333333% - 0.75rem / 3 * 2);
  }
  .flex-sp-col3.gap13 > * {
    width: calc(33.3333333333% - 0.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap14 > * {
    width: calc(33.3333333333% - 0.875rem / 3 * 2);
  }
  .flex-sp-col3.gap15 > * {
    width: calc(33.3333333333% - 0.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap16 > * {
    width: calc(33.3333333333% - 1rem / 3 * 2);
  }
  .flex-sp-col3.gap17 > * {
    width: calc(33.3333333333% - 1.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap18 > * {
    width: calc(33.3333333333% - 1.125rem / 3 * 2);
  }
  .flex-sp-col3.gap19 > * {
    width: calc(33.3333333333% - 1.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap20 > * {
    width: calc(33.3333333333% - 1.25rem / 3 * 2);
  }
  .flex-sp-col3.gap21 > * {
    width: calc(33.3333333333% - 1.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap22 > * {
    width: calc(33.3333333333% - 1.375rem / 3 * 2);
  }
  .flex-sp-col3.gap23 > * {
    width: calc(33.3333333333% - 1.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap24 > * {
    width: calc(33.3333333333% - 1.5rem / 3 * 2);
  }
  .flex-sp-col3.gap25 > * {
    width: calc(33.3333333333% - 1.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap26 > * {
    width: calc(33.3333333333% - 1.625rem / 3 * 2);
  }
  .flex-sp-col3.gap27 > * {
    width: calc(33.3333333333% - 1.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap28 > * {
    width: calc(33.3333333333% - 1.75rem / 3 * 2);
  }
  .flex-sp-col3.gap29 > * {
    width: calc(33.3333333333% - 1.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap30 > * {
    width: calc(33.3333333333% - 1.875rem / 3 * 2);
  }
  .flex-sp-col3.gap31 > * {
    width: calc(33.3333333333% - 1.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap32 > * {
    width: calc(33.3333333333% - 2rem / 3 * 2);
  }
  .flex-sp-col3.gap33 > * {
    width: calc(33.3333333333% - 2.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap34 > * {
    width: calc(33.3333333333% - 2.125rem / 3 * 2);
  }
  .flex-sp-col3.gap35 > * {
    width: calc(33.3333333333% - 2.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap36 > * {
    width: calc(33.3333333333% - 2.25rem / 3 * 2);
  }
  .flex-sp-col3.gap37 > * {
    width: calc(33.3333333333% - 2.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap38 > * {
    width: calc(33.3333333333% - 2.375rem / 3 * 2);
  }
  .flex-sp-col3.gap39 > * {
    width: calc(33.3333333333% - 2.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap40 > * {
    width: calc(33.3333333333% - 2.5rem / 3 * 2);
  }
  .flex-sp-col3.gap41 > * {
    width: calc(33.3333333333% - 2.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap42 > * {
    width: calc(33.3333333333% - 2.625rem / 3 * 2);
  }
  .flex-sp-col3.gap43 > * {
    width: calc(33.3333333333% - 2.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap44 > * {
    width: calc(33.3333333333% - 2.75rem / 3 * 2);
  }
  .flex-sp-col3.gap45 > * {
    width: calc(33.3333333333% - 2.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap46 > * {
    width: calc(33.3333333333% - 2.875rem / 3 * 2);
  }
  .flex-sp-col3.gap47 > * {
    width: calc(33.3333333333% - 2.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap48 > * {
    width: calc(33.3333333333% - 3rem / 3 * 2);
  }
  .flex-sp-col3.gap49 > * {
    width: calc(33.3333333333% - 3.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap50 > * {
    width: calc(33.3333333333% - 3.125rem / 3 * 2);
  }
  .flex-sp-col3.gap51 > * {
    width: calc(33.3333333333% - 3.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap52 > * {
    width: calc(33.3333333333% - 3.25rem / 3 * 2);
  }
  .flex-sp-col3.gap53 > * {
    width: calc(33.3333333333% - 3.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap54 > * {
    width: calc(33.3333333333% - 3.375rem / 3 * 2);
  }
  .flex-sp-col3.gap55 > * {
    width: calc(33.3333333333% - 3.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap56 > * {
    width: calc(33.3333333333% - 3.5rem / 3 * 2);
  }
  .flex-sp-col3.gap57 > * {
    width: calc(33.3333333333% - 3.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap58 > * {
    width: calc(33.3333333333% - 3.625rem / 3 * 2);
  }
  .flex-sp-col3.gap59 > * {
    width: calc(33.3333333333% - 3.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap60 > * {
    width: calc(33.3333333333% - 3.75rem / 3 * 2);
  }
  .flex-sp-col3.gap61 > * {
    width: calc(33.3333333333% - 3.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap62 > * {
    width: calc(33.3333333333% - 3.875rem / 3 * 2);
  }
  .flex-sp-col3.gap63 > * {
    width: calc(33.3333333333% - 3.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap64 > * {
    width: calc(33.3333333333% - 4rem / 3 * 2);
  }
  .flex-sp-col3.gap65 > * {
    width: calc(33.3333333333% - 4.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap66 > * {
    width: calc(33.3333333333% - 4.125rem / 3 * 2);
  }
  .flex-sp-col3.gap67 > * {
    width: calc(33.3333333333% - 4.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap68 > * {
    width: calc(33.3333333333% - 4.25rem / 3 * 2);
  }
  .flex-sp-col3.gap69 > * {
    width: calc(33.3333333333% - 4.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap70 > * {
    width: calc(33.3333333333% - 4.375rem / 3 * 2);
  }
  .flex-sp-col3.gap71 > * {
    width: calc(33.3333333333% - 4.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap72 > * {
    width: calc(33.3333333333% - 4.5rem / 3 * 2);
  }
  .flex-sp-col3.gap73 > * {
    width: calc(33.3333333333% - 4.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap74 > * {
    width: calc(33.3333333333% - 4.625rem / 3 * 2);
  }
  .flex-sp-col3.gap75 > * {
    width: calc(33.3333333333% - 4.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap76 > * {
    width: calc(33.3333333333% - 4.75rem / 3 * 2);
  }
  .flex-sp-col3.gap77 > * {
    width: calc(33.3333333333% - 4.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap78 > * {
    width: calc(33.3333333333% - 4.875rem / 3 * 2);
  }
  .flex-sp-col3.gap79 > * {
    width: calc(33.3333333333% - 4.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap80 > * {
    width: calc(33.3333333333% - 5rem / 3 * 2);
  }
  .flex-sp-col3.gap81 > * {
    width: calc(33.3333333333% - 5.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap82 > * {
    width: calc(33.3333333333% - 5.125rem / 3 * 2);
  }
  .flex-sp-col3.gap83 > * {
    width: calc(33.3333333333% - 5.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap84 > * {
    width: calc(33.3333333333% - 5.25rem / 3 * 2);
  }
  .flex-sp-col3.gap85 > * {
    width: calc(33.3333333333% - 5.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap86 > * {
    width: calc(33.3333333333% - 5.375rem / 3 * 2);
  }
  .flex-sp-col3.gap87 > * {
    width: calc(33.3333333333% - 5.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap88 > * {
    width: calc(33.3333333333% - 5.5rem / 3 * 2);
  }
  .flex-sp-col3.gap89 > * {
    width: calc(33.3333333333% - 5.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap90 > * {
    width: calc(33.3333333333% - 5.625rem / 3 * 2);
  }
  .flex-sp-col3.gap91 > * {
    width: calc(33.3333333333% - 5.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap92 > * {
    width: calc(33.3333333333% - 5.75rem / 3 * 2);
  }
  .flex-sp-col3.gap93 > * {
    width: calc(33.3333333333% - 5.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap94 > * {
    width: calc(33.3333333333% - 5.875rem / 3 * 2);
  }
  .flex-sp-col3.gap95 > * {
    width: calc(33.3333333333% - 5.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap96 > * {
    width: calc(33.3333333333% - 6rem / 3 * 2);
  }
  .flex-sp-col3.gap97 > * {
    width: calc(33.3333333333% - 6.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap98 > * {
    width: calc(33.3333333333% - 6.125rem / 3 * 2);
  }
  .flex-sp-col3.gap99 > * {
    width: calc(33.3333333333% - 6.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap100 > * {
    width: calc(33.3333333333% - 6.25rem / 3 * 2);
  }
  .flex-sp-col3.gap101 > * {
    width: calc(33.3333333333% - 6.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap102 > * {
    width: calc(33.3333333333% - 6.375rem / 3 * 2);
  }
  .flex-sp-col3.gap103 > * {
    width: calc(33.3333333333% - 6.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap104 > * {
    width: calc(33.3333333333% - 6.5rem / 3 * 2);
  }
  .flex-sp-col3.gap105 > * {
    width: calc(33.3333333333% - 6.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap106 > * {
    width: calc(33.3333333333% - 6.625rem / 3 * 2);
  }
  .flex-sp-col3.gap107 > * {
    width: calc(33.3333333333% - 6.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap108 > * {
    width: calc(33.3333333333% - 6.75rem / 3 * 2);
  }
  .flex-sp-col3.gap109 > * {
    width: calc(33.3333333333% - 6.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap110 > * {
    width: calc(33.3333333333% - 6.875rem / 3 * 2);
  }
  .flex-sp-col3.gap111 > * {
    width: calc(33.3333333333% - 6.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap112 > * {
    width: calc(33.3333333333% - 7rem / 3 * 2);
  }
  .flex-sp-col3.gap113 > * {
    width: calc(33.3333333333% - 7.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap114 > * {
    width: calc(33.3333333333% - 7.125rem / 3 * 2);
  }
  .flex-sp-col3.gap115 > * {
    width: calc(33.3333333333% - 7.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap116 > * {
    width: calc(33.3333333333% - 7.25rem / 3 * 2);
  }
  .flex-sp-col3.gap117 > * {
    width: calc(33.3333333333% - 7.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap118 > * {
    width: calc(33.3333333333% - 7.375rem / 3 * 2);
  }
  .flex-sp-col3.gap119 > * {
    width: calc(33.3333333333% - 7.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap120 > * {
    width: calc(33.3333333333% - 7.5rem / 3 * 2);
  }
  .flex-sp-col3.gap121 > * {
    width: calc(33.3333333333% - 7.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap122 > * {
    width: calc(33.3333333333% - 7.625rem / 3 * 2);
  }
  .flex-sp-col3.gap123 > * {
    width: calc(33.3333333333% - 7.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap124 > * {
    width: calc(33.3333333333% - 7.75rem / 3 * 2);
  }
  .flex-sp-col3.gap125 > * {
    width: calc(33.3333333333% - 7.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap126 > * {
    width: calc(33.3333333333% - 7.875rem / 3 * 2);
  }
  .flex-sp-col3.gap127 > * {
    width: calc(33.3333333333% - 7.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap128 > * {
    width: calc(33.3333333333% - 8rem / 3 * 2);
  }
  .flex-sp-col3.gap129 > * {
    width: calc(33.3333333333% - 8.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap130 > * {
    width: calc(33.3333333333% - 8.125rem / 3 * 2);
  }
  .flex-sp-col3.gap131 > * {
    width: calc(33.3333333333% - 8.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap132 > * {
    width: calc(33.3333333333% - 8.25rem / 3 * 2);
  }
  .flex-sp-col3.gap133 > * {
    width: calc(33.3333333333% - 8.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap134 > * {
    width: calc(33.3333333333% - 8.375rem / 3 * 2);
  }
  .flex-sp-col3.gap135 > * {
    width: calc(33.3333333333% - 8.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap136 > * {
    width: calc(33.3333333333% - 8.5rem / 3 * 2);
  }
  .flex-sp-col3.gap137 > * {
    width: calc(33.3333333333% - 8.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap138 > * {
    width: calc(33.3333333333% - 8.625rem / 3 * 2);
  }
  .flex-sp-col3.gap139 > * {
    width: calc(33.3333333333% - 8.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap140 > * {
    width: calc(33.3333333333% - 8.75rem / 3 * 2);
  }
  .flex-sp-col3.gap141 > * {
    width: calc(33.3333333333% - 8.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap142 > * {
    width: calc(33.3333333333% - 8.875rem / 3 * 2);
  }
  .flex-sp-col3.gap143 > * {
    width: calc(33.3333333333% - 8.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap144 > * {
    width: calc(33.3333333333% - 9rem / 3 * 2);
  }
  .flex-sp-col3.gap145 > * {
    width: calc(33.3333333333% - 9.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap146 > * {
    width: calc(33.3333333333% - 9.125rem / 3 * 2);
  }
  .flex-sp-col3.gap147 > * {
    width: calc(33.3333333333% - 9.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap148 > * {
    width: calc(33.3333333333% - 9.25rem / 3 * 2);
  }
  .flex-sp-col3.gap149 > * {
    width: calc(33.3333333333% - 9.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap150 > * {
    width: calc(33.3333333333% - 9.375rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp1 > * {
    width: calc(33.3333333333% - 0.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp2 > * {
    width: calc(33.3333333333% - 0.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp3 > * {
    width: calc(33.3333333333% - 0.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp4 > * {
    width: calc(33.3333333333% - 0.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp5 > * {
    width: calc(33.3333333333% - 0.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp6 > * {
    width: calc(33.3333333333% - 0.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp7 > * {
    width: calc(33.3333333333% - 0.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp8 > * {
    width: calc(33.3333333333% - 0.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp9 > * {
    width: calc(33.3333333333% - 0.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp10 > * {
    width: calc(33.3333333333% - 0.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp11 > * {
    width: calc(33.3333333333% - 0.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp12 > * {
    width: calc(33.3333333333% - 0.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp13 > * {
    width: calc(33.3333333333% - 0.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp14 > * {
    width: calc(33.3333333333% - 1rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp15 > * {
    width: calc(33.3333333333% - 1.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp16 > * {
    width: calc(33.3333333333% - 1.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp17 > * {
    width: calc(33.3333333333% - 1.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp18 > * {
    width: calc(33.3333333333% - 1.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp19 > * {
    width: calc(33.3333333333% - 1.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp20 > * {
    width: calc(33.3333333333% - 1.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp21 > * {
    width: calc(33.3333333333% - 1.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp22 > * {
    width: calc(33.3333333333% - 1.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp23 > * {
    width: calc(33.3333333333% - 1.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp24 > * {
    width: calc(33.3333333333% - 1.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp25 > * {
    width: calc(33.3333333333% - 1.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp26 > * {
    width: calc(33.3333333333% - 1.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp27 > * {
    width: calc(33.3333333333% - 1.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp28 > * {
    width: calc(33.3333333333% - 2rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp29 > * {
    width: calc(33.3333333333% - 2.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp30 > * {
    width: calc(33.3333333333% - 2.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp31 > * {
    width: calc(33.3333333333% - 2.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp32 > * {
    width: calc(33.3333333333% - 2.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp33 > * {
    width: calc(33.3333333333% - 2.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp34 > * {
    width: calc(33.3333333333% - 2.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp35 > * {
    width: calc(33.3333333333% - 2.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp36 > * {
    width: calc(33.3333333333% - 2.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp37 > * {
    width: calc(33.3333333333% - 2.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp38 > * {
    width: calc(33.3333333333% - 2.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp39 > * {
    width: calc(33.3333333333% - 2.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp40 > * {
    width: calc(33.3333333333% - 2.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp41 > * {
    width: calc(33.3333333333% - 2.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp42 > * {
    width: calc(33.3333333333% - 3rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp43 > * {
    width: calc(33.3333333333% - 3.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp44 > * {
    width: calc(33.3333333333% - 3.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp45 > * {
    width: calc(33.3333333333% - 3.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp46 > * {
    width: calc(33.3333333333% - 3.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp47 > * {
    width: calc(33.3333333333% - 3.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp48 > * {
    width: calc(33.3333333333% - 3.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp49 > * {
    width: calc(33.3333333333% - 3.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp50 > * {
    width: calc(33.3333333333% - 3.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp51 > * {
    width: calc(33.3333333333% - 3.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp52 > * {
    width: calc(33.3333333333% - 3.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp53 > * {
    width: calc(33.3333333333% - 3.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp54 > * {
    width: calc(33.3333333333% - 3.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp55 > * {
    width: calc(33.3333333333% - 3.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp56 > * {
    width: calc(33.3333333333% - 4rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp57 > * {
    width: calc(33.3333333333% - 4.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp58 > * {
    width: calc(33.3333333333% - 4.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp59 > * {
    width: calc(33.3333333333% - 4.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp60 > * {
    width: calc(33.3333333333% - 4.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp61 > * {
    width: calc(33.3333333333% - 4.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp62 > * {
    width: calc(33.3333333333% - 4.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp63 > * {
    width: calc(33.3333333333% - 4.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp64 > * {
    width: calc(33.3333333333% - 4.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp65 > * {
    width: calc(33.3333333333% - 4.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp66 > * {
    width: calc(33.3333333333% - 4.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp67 > * {
    width: calc(33.3333333333% - 4.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp68 > * {
    width: calc(33.3333333333% - 4.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp69 > * {
    width: calc(33.3333333333% - 4.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp70 > * {
    width: calc(33.3333333333% - 5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp71 > * {
    width: calc(33.3333333333% - 5.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp72 > * {
    width: calc(33.3333333333% - 5.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp73 > * {
    width: calc(33.3333333333% - 5.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp74 > * {
    width: calc(33.3333333333% - 5.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp75 > * {
    width: calc(33.3333333333% - 5.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp76 > * {
    width: calc(33.3333333333% - 5.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp77 > * {
    width: calc(33.3333333333% - 5.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp78 > * {
    width: calc(33.3333333333% - 5.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp79 > * {
    width: calc(33.3333333333% - 5.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp80 > * {
    width: calc(33.3333333333% - 5.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp81 > * {
    width: calc(33.3333333333% - 5.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp82 > * {
    width: calc(33.3333333333% - 5.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp83 > * {
    width: calc(33.3333333333% - 5.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp84 > * {
    width: calc(33.3333333333% - 6rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp85 > * {
    width: calc(33.3333333333% - 6.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp86 > * {
    width: calc(33.3333333333% - 6.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp87 > * {
    width: calc(33.3333333333% - 6.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp88 > * {
    width: calc(33.3333333333% - 6.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp89 > * {
    width: calc(33.3333333333% - 6.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp90 > * {
    width: calc(33.3333333333% - 6.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp91 > * {
    width: calc(33.3333333333% - 6.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp92 > * {
    width: calc(33.3333333333% - 6.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp93 > * {
    width: calc(33.3333333333% - 6.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp94 > * {
    width: calc(33.3333333333% - 6.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp95 > * {
    width: calc(33.3333333333% - 6.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp96 > * {
    width: calc(33.3333333333% - 6.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp97 > * {
    width: calc(33.3333333333% - 6.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp98 > * {
    width: calc(33.3333333333% - 7rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp99 > * {
    width: calc(33.3333333333% - 7.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp100 > * {
    width: calc(33.3333333333% - 7.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp101 > * {
    width: calc(33.3333333333% - 7.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp102 > * {
    width: calc(33.3333333333% - 7.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp103 > * {
    width: calc(33.3333333333% - 7.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp104 > * {
    width: calc(33.3333333333% - 7.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp105 > * {
    width: calc(33.3333333333% - 7.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp106 > * {
    width: calc(33.3333333333% - 7.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp107 > * {
    width: calc(33.3333333333% - 7.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp108 > * {
    width: calc(33.3333333333% - 7.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp109 > * {
    width: calc(33.3333333333% - 7.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp110 > * {
    width: calc(33.3333333333% - 7.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp111 > * {
    width: calc(33.3333333333% - 7.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp112 > * {
    width: calc(33.3333333333% - 8rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp113 > * {
    width: calc(33.3333333333% - 8.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp114 > * {
    width: calc(33.3333333333% - 8.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp115 > * {
    width: calc(33.3333333333% - 8.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp116 > * {
    width: calc(33.3333333333% - 8.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp117 > * {
    width: calc(33.3333333333% - 8.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp118 > * {
    width: calc(33.3333333333% - 8.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp119 > * {
    width: calc(33.3333333333% - 8.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp120 > * {
    width: calc(33.3333333333% - 8.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp121 > * {
    width: calc(33.3333333333% - 8.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp122 > * {
    width: calc(33.3333333333% - 8.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp123 > * {
    width: calc(33.3333333333% - 8.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp124 > * {
    width: calc(33.3333333333% - 8.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp125 > * {
    width: calc(33.3333333333% - 8.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp126 > * {
    width: calc(33.3333333333% - 9rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp127 > * {
    width: calc(33.3333333333% - 9.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp128 > * {
    width: calc(33.3333333333% - 9.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp129 > * {
    width: calc(33.3333333333% - 9.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp130 > * {
    width: calc(33.3333333333% - 9.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp131 > * {
    width: calc(33.3333333333% - 9.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp132 > * {
    width: calc(33.3333333333% - 9.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp133 > * {
    width: calc(33.3333333333% - 9.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp134 > * {
    width: calc(33.3333333333% - 9.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp135 > * {
    width: calc(33.3333333333% - 9.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp136 > * {
    width: calc(33.3333333333% - 9.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp137 > * {
    width: calc(33.3333333333% - 9.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp138 > * {
    width: calc(33.3333333333% - 9.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp139 > * {
    width: calc(33.3333333333% - 9.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp140 > * {
    width: calc(33.3333333333% - 10rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp141 > * {
    width: calc(33.3333333333% - 10.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp142 > * {
    width: calc(33.3333333333% - 10.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp143 > * {
    width: calc(33.3333333333% - 10.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp144 > * {
    width: calc(33.3333333333% - 10.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp145 > * {
    width: calc(33.3333333333% - 10.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp146 > * {
    width: calc(33.3333333333% - 10.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp147 > * {
    width: calc(33.3333333333% - 10.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp148 > * {
    width: calc(33.3333333333% - 10.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp149 > * {
    width: calc(33.3333333333% - 10.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp150 > * {
    width: calc(33.3333333333% - 10.7142857143rem / 3 * 2);
  }
  .flex-sp-col4 {
    flex-wrap: wrap;
  }
  .flex-sp-col4 > * {
    width: 25%;
  }
  .flex-sp-col4.gap1 > * {
    width: calc(25% - 0.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap2 > * {
    width: calc(25% - 0.125rem / 4 * 3);
  }
  .flex-sp-col4.gap3 > * {
    width: calc(25% - 0.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap4 > * {
    width: calc(25% - 0.25rem / 4 * 3);
  }
  .flex-sp-col4.gap5 > * {
    width: calc(25% - 0.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap6 > * {
    width: calc(25% - 0.375rem / 4 * 3);
  }
  .flex-sp-col4.gap7 > * {
    width: calc(25% - 0.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap8 > * {
    width: calc(25% - 0.5rem / 4 * 3);
  }
  .flex-sp-col4.gap9 > * {
    width: calc(25% - 0.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap10 > * {
    width: calc(25% - 0.625rem / 4 * 3);
  }
  .flex-sp-col4.gap11 > * {
    width: calc(25% - 0.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap12 > * {
    width: calc(25% - 0.75rem / 4 * 3);
  }
  .flex-sp-col4.gap13 > * {
    width: calc(25% - 0.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap14 > * {
    width: calc(25% - 0.875rem / 4 * 3);
  }
  .flex-sp-col4.gap15 > * {
    width: calc(25% - 0.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap16 > * {
    width: calc(25% - 1rem / 4 * 3);
  }
  .flex-sp-col4.gap17 > * {
    width: calc(25% - 1.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap18 > * {
    width: calc(25% - 1.125rem / 4 * 3);
  }
  .flex-sp-col4.gap19 > * {
    width: calc(25% - 1.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap20 > * {
    width: calc(25% - 1.25rem / 4 * 3);
  }
  .flex-sp-col4.gap21 > * {
    width: calc(25% - 1.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap22 > * {
    width: calc(25% - 1.375rem / 4 * 3);
  }
  .flex-sp-col4.gap23 > * {
    width: calc(25% - 1.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap24 > * {
    width: calc(25% - 1.5rem / 4 * 3);
  }
  .flex-sp-col4.gap25 > * {
    width: calc(25% - 1.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap26 > * {
    width: calc(25% - 1.625rem / 4 * 3);
  }
  .flex-sp-col4.gap27 > * {
    width: calc(25% - 1.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap28 > * {
    width: calc(25% - 1.75rem / 4 * 3);
  }
  .flex-sp-col4.gap29 > * {
    width: calc(25% - 1.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap30 > * {
    width: calc(25% - 1.875rem / 4 * 3);
  }
  .flex-sp-col4.gap31 > * {
    width: calc(25% - 1.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap32 > * {
    width: calc(25% - 2rem / 4 * 3);
  }
  .flex-sp-col4.gap33 > * {
    width: calc(25% - 2.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap34 > * {
    width: calc(25% - 2.125rem / 4 * 3);
  }
  .flex-sp-col4.gap35 > * {
    width: calc(25% - 2.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap36 > * {
    width: calc(25% - 2.25rem / 4 * 3);
  }
  .flex-sp-col4.gap37 > * {
    width: calc(25% - 2.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap38 > * {
    width: calc(25% - 2.375rem / 4 * 3);
  }
  .flex-sp-col4.gap39 > * {
    width: calc(25% - 2.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap40 > * {
    width: calc(25% - 2.5rem / 4 * 3);
  }
  .flex-sp-col4.gap41 > * {
    width: calc(25% - 2.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap42 > * {
    width: calc(25% - 2.625rem / 4 * 3);
  }
  .flex-sp-col4.gap43 > * {
    width: calc(25% - 2.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap44 > * {
    width: calc(25% - 2.75rem / 4 * 3);
  }
  .flex-sp-col4.gap45 > * {
    width: calc(25% - 2.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap46 > * {
    width: calc(25% - 2.875rem / 4 * 3);
  }
  .flex-sp-col4.gap47 > * {
    width: calc(25% - 2.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap48 > * {
    width: calc(25% - 3rem / 4 * 3);
  }
  .flex-sp-col4.gap49 > * {
    width: calc(25% - 3.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap50 > * {
    width: calc(25% - 3.125rem / 4 * 3);
  }
  .flex-sp-col4.gap51 > * {
    width: calc(25% - 3.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap52 > * {
    width: calc(25% - 3.25rem / 4 * 3);
  }
  .flex-sp-col4.gap53 > * {
    width: calc(25% - 3.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap54 > * {
    width: calc(25% - 3.375rem / 4 * 3);
  }
  .flex-sp-col4.gap55 > * {
    width: calc(25% - 3.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap56 > * {
    width: calc(25% - 3.5rem / 4 * 3);
  }
  .flex-sp-col4.gap57 > * {
    width: calc(25% - 3.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap58 > * {
    width: calc(25% - 3.625rem / 4 * 3);
  }
  .flex-sp-col4.gap59 > * {
    width: calc(25% - 3.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap60 > * {
    width: calc(25% - 3.75rem / 4 * 3);
  }
  .flex-sp-col4.gap61 > * {
    width: calc(25% - 3.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap62 > * {
    width: calc(25% - 3.875rem / 4 * 3);
  }
  .flex-sp-col4.gap63 > * {
    width: calc(25% - 3.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap64 > * {
    width: calc(25% - 4rem / 4 * 3);
  }
  .flex-sp-col4.gap65 > * {
    width: calc(25% - 4.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap66 > * {
    width: calc(25% - 4.125rem / 4 * 3);
  }
  .flex-sp-col4.gap67 > * {
    width: calc(25% - 4.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap68 > * {
    width: calc(25% - 4.25rem / 4 * 3);
  }
  .flex-sp-col4.gap69 > * {
    width: calc(25% - 4.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap70 > * {
    width: calc(25% - 4.375rem / 4 * 3);
  }
  .flex-sp-col4.gap71 > * {
    width: calc(25% - 4.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap72 > * {
    width: calc(25% - 4.5rem / 4 * 3);
  }
  .flex-sp-col4.gap73 > * {
    width: calc(25% - 4.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap74 > * {
    width: calc(25% - 4.625rem / 4 * 3);
  }
  .flex-sp-col4.gap75 > * {
    width: calc(25% - 4.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap76 > * {
    width: calc(25% - 4.75rem / 4 * 3);
  }
  .flex-sp-col4.gap77 > * {
    width: calc(25% - 4.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap78 > * {
    width: calc(25% - 4.875rem / 4 * 3);
  }
  .flex-sp-col4.gap79 > * {
    width: calc(25% - 4.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap80 > * {
    width: calc(25% - 5rem / 4 * 3);
  }
  .flex-sp-col4.gap81 > * {
    width: calc(25% - 5.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap82 > * {
    width: calc(25% - 5.125rem / 4 * 3);
  }
  .flex-sp-col4.gap83 > * {
    width: calc(25% - 5.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap84 > * {
    width: calc(25% - 5.25rem / 4 * 3);
  }
  .flex-sp-col4.gap85 > * {
    width: calc(25% - 5.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap86 > * {
    width: calc(25% - 5.375rem / 4 * 3);
  }
  .flex-sp-col4.gap87 > * {
    width: calc(25% - 5.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap88 > * {
    width: calc(25% - 5.5rem / 4 * 3);
  }
  .flex-sp-col4.gap89 > * {
    width: calc(25% - 5.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap90 > * {
    width: calc(25% - 5.625rem / 4 * 3);
  }
  .flex-sp-col4.gap91 > * {
    width: calc(25% - 5.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap92 > * {
    width: calc(25% - 5.75rem / 4 * 3);
  }
  .flex-sp-col4.gap93 > * {
    width: calc(25% - 5.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap94 > * {
    width: calc(25% - 5.875rem / 4 * 3);
  }
  .flex-sp-col4.gap95 > * {
    width: calc(25% - 5.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap96 > * {
    width: calc(25% - 6rem / 4 * 3);
  }
  .flex-sp-col4.gap97 > * {
    width: calc(25% - 6.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap98 > * {
    width: calc(25% - 6.125rem / 4 * 3);
  }
  .flex-sp-col4.gap99 > * {
    width: calc(25% - 6.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap100 > * {
    width: calc(25% - 6.25rem / 4 * 3);
  }
  .flex-sp-col4.gap101 > * {
    width: calc(25% - 6.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap102 > * {
    width: calc(25% - 6.375rem / 4 * 3);
  }
  .flex-sp-col4.gap103 > * {
    width: calc(25% - 6.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap104 > * {
    width: calc(25% - 6.5rem / 4 * 3);
  }
  .flex-sp-col4.gap105 > * {
    width: calc(25% - 6.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap106 > * {
    width: calc(25% - 6.625rem / 4 * 3);
  }
  .flex-sp-col4.gap107 > * {
    width: calc(25% - 6.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap108 > * {
    width: calc(25% - 6.75rem / 4 * 3);
  }
  .flex-sp-col4.gap109 > * {
    width: calc(25% - 6.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap110 > * {
    width: calc(25% - 6.875rem / 4 * 3);
  }
  .flex-sp-col4.gap111 > * {
    width: calc(25% - 6.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap112 > * {
    width: calc(25% - 7rem / 4 * 3);
  }
  .flex-sp-col4.gap113 > * {
    width: calc(25% - 7.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap114 > * {
    width: calc(25% - 7.125rem / 4 * 3);
  }
  .flex-sp-col4.gap115 > * {
    width: calc(25% - 7.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap116 > * {
    width: calc(25% - 7.25rem / 4 * 3);
  }
  .flex-sp-col4.gap117 > * {
    width: calc(25% - 7.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap118 > * {
    width: calc(25% - 7.375rem / 4 * 3);
  }
  .flex-sp-col4.gap119 > * {
    width: calc(25% - 7.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap120 > * {
    width: calc(25% - 7.5rem / 4 * 3);
  }
  .flex-sp-col4.gap121 > * {
    width: calc(25% - 7.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap122 > * {
    width: calc(25% - 7.625rem / 4 * 3);
  }
  .flex-sp-col4.gap123 > * {
    width: calc(25% - 7.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap124 > * {
    width: calc(25% - 7.75rem / 4 * 3);
  }
  .flex-sp-col4.gap125 > * {
    width: calc(25% - 7.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap126 > * {
    width: calc(25% - 7.875rem / 4 * 3);
  }
  .flex-sp-col4.gap127 > * {
    width: calc(25% - 7.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap128 > * {
    width: calc(25% - 8rem / 4 * 3);
  }
  .flex-sp-col4.gap129 > * {
    width: calc(25% - 8.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap130 > * {
    width: calc(25% - 8.125rem / 4 * 3);
  }
  .flex-sp-col4.gap131 > * {
    width: calc(25% - 8.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap132 > * {
    width: calc(25% - 8.25rem / 4 * 3);
  }
  .flex-sp-col4.gap133 > * {
    width: calc(25% - 8.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap134 > * {
    width: calc(25% - 8.375rem / 4 * 3);
  }
  .flex-sp-col4.gap135 > * {
    width: calc(25% - 8.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap136 > * {
    width: calc(25% - 8.5rem / 4 * 3);
  }
  .flex-sp-col4.gap137 > * {
    width: calc(25% - 8.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap138 > * {
    width: calc(25% - 8.625rem / 4 * 3);
  }
  .flex-sp-col4.gap139 > * {
    width: calc(25% - 8.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap140 > * {
    width: calc(25% - 8.75rem / 4 * 3);
  }
  .flex-sp-col4.gap141 > * {
    width: calc(25% - 8.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap142 > * {
    width: calc(25% - 8.875rem / 4 * 3);
  }
  .flex-sp-col4.gap143 > * {
    width: calc(25% - 8.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap144 > * {
    width: calc(25% - 9rem / 4 * 3);
  }
  .flex-sp-col4.gap145 > * {
    width: calc(25% - 9.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap146 > * {
    width: calc(25% - 9.125rem / 4 * 3);
  }
  .flex-sp-col4.gap147 > * {
    width: calc(25% - 9.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap148 > * {
    width: calc(25% - 9.25rem / 4 * 3);
  }
  .flex-sp-col4.gap149 > * {
    width: calc(25% - 9.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap150 > * {
    width: calc(25% - 9.375rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp1 > * {
    width: calc(25% - 0.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp2 > * {
    width: calc(25% - 0.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp3 > * {
    width: calc(25% - 0.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp4 > * {
    width: calc(25% - 0.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp5 > * {
    width: calc(25% - 0.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp6 > * {
    width: calc(25% - 0.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp7 > * {
    width: calc(25% - 0.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp8 > * {
    width: calc(25% - 0.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp9 > * {
    width: calc(25% - 0.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp10 > * {
    width: calc(25% - 0.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp11 > * {
    width: calc(25% - 0.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp12 > * {
    width: calc(25% - 0.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp13 > * {
    width: calc(25% - 0.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp14 > * {
    width: calc(25% - 1rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp15 > * {
    width: calc(25% - 1.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp16 > * {
    width: calc(25% - 1.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp17 > * {
    width: calc(25% - 1.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp18 > * {
    width: calc(25% - 1.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp19 > * {
    width: calc(25% - 1.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp20 > * {
    width: calc(25% - 1.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp21 > * {
    width: calc(25% - 1.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp22 > * {
    width: calc(25% - 1.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp23 > * {
    width: calc(25% - 1.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp24 > * {
    width: calc(25% - 1.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp25 > * {
    width: calc(25% - 1.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp26 > * {
    width: calc(25% - 1.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp27 > * {
    width: calc(25% - 1.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp28 > * {
    width: calc(25% - 2rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp29 > * {
    width: calc(25% - 2.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp30 > * {
    width: calc(25% - 2.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp31 > * {
    width: calc(25% - 2.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp32 > * {
    width: calc(25% - 2.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp33 > * {
    width: calc(25% - 2.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp34 > * {
    width: calc(25% - 2.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp35 > * {
    width: calc(25% - 2.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp36 > * {
    width: calc(25% - 2.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp37 > * {
    width: calc(25% - 2.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp38 > * {
    width: calc(25% - 2.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp39 > * {
    width: calc(25% - 2.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp40 > * {
    width: calc(25% - 2.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp41 > * {
    width: calc(25% - 2.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp42 > * {
    width: calc(25% - 3rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp43 > * {
    width: calc(25% - 3.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp44 > * {
    width: calc(25% - 3.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp45 > * {
    width: calc(25% - 3.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp46 > * {
    width: calc(25% - 3.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp47 > * {
    width: calc(25% - 3.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp48 > * {
    width: calc(25% - 3.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp49 > * {
    width: calc(25% - 3.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp50 > * {
    width: calc(25% - 3.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp51 > * {
    width: calc(25% - 3.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp52 > * {
    width: calc(25% - 3.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp53 > * {
    width: calc(25% - 3.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp54 > * {
    width: calc(25% - 3.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp55 > * {
    width: calc(25% - 3.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp56 > * {
    width: calc(25% - 4rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp57 > * {
    width: calc(25% - 4.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp58 > * {
    width: calc(25% - 4.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp59 > * {
    width: calc(25% - 4.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp60 > * {
    width: calc(25% - 4.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp61 > * {
    width: calc(25% - 4.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp62 > * {
    width: calc(25% - 4.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp63 > * {
    width: calc(25% - 4.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp64 > * {
    width: calc(25% - 4.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp65 > * {
    width: calc(25% - 4.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp66 > * {
    width: calc(25% - 4.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp67 > * {
    width: calc(25% - 4.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp68 > * {
    width: calc(25% - 4.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp69 > * {
    width: calc(25% - 4.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp70 > * {
    width: calc(25% - 5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp71 > * {
    width: calc(25% - 5.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp72 > * {
    width: calc(25% - 5.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp73 > * {
    width: calc(25% - 5.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp74 > * {
    width: calc(25% - 5.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp75 > * {
    width: calc(25% - 5.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp76 > * {
    width: calc(25% - 5.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp77 > * {
    width: calc(25% - 5.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp78 > * {
    width: calc(25% - 5.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp79 > * {
    width: calc(25% - 5.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp80 > * {
    width: calc(25% - 5.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp81 > * {
    width: calc(25% - 5.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp82 > * {
    width: calc(25% - 5.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp83 > * {
    width: calc(25% - 5.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp84 > * {
    width: calc(25% - 6rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp85 > * {
    width: calc(25% - 6.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp86 > * {
    width: calc(25% - 6.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp87 > * {
    width: calc(25% - 6.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp88 > * {
    width: calc(25% - 6.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp89 > * {
    width: calc(25% - 6.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp90 > * {
    width: calc(25% - 6.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp91 > * {
    width: calc(25% - 6.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp92 > * {
    width: calc(25% - 6.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp93 > * {
    width: calc(25% - 6.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp94 > * {
    width: calc(25% - 6.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp95 > * {
    width: calc(25% - 6.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp96 > * {
    width: calc(25% - 6.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp97 > * {
    width: calc(25% - 6.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp98 > * {
    width: calc(25% - 7rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp99 > * {
    width: calc(25% - 7.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp100 > * {
    width: calc(25% - 7.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp101 > * {
    width: calc(25% - 7.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp102 > * {
    width: calc(25% - 7.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp103 > * {
    width: calc(25% - 7.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp104 > * {
    width: calc(25% - 7.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp105 > * {
    width: calc(25% - 7.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp106 > * {
    width: calc(25% - 7.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp107 > * {
    width: calc(25% - 7.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp108 > * {
    width: calc(25% - 7.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp109 > * {
    width: calc(25% - 7.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp110 > * {
    width: calc(25% - 7.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp111 > * {
    width: calc(25% - 7.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp112 > * {
    width: calc(25% - 8rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp113 > * {
    width: calc(25% - 8.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp114 > * {
    width: calc(25% - 8.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp115 > * {
    width: calc(25% - 8.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp116 > * {
    width: calc(25% - 8.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp117 > * {
    width: calc(25% - 8.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp118 > * {
    width: calc(25% - 8.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp119 > * {
    width: calc(25% - 8.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp120 > * {
    width: calc(25% - 8.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp121 > * {
    width: calc(25% - 8.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp122 > * {
    width: calc(25% - 8.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp123 > * {
    width: calc(25% - 8.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp124 > * {
    width: calc(25% - 8.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp125 > * {
    width: calc(25% - 8.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp126 > * {
    width: calc(25% - 9rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp127 > * {
    width: calc(25% - 9.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp128 > * {
    width: calc(25% - 9.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp129 > * {
    width: calc(25% - 9.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp130 > * {
    width: calc(25% - 9.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp131 > * {
    width: calc(25% - 9.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp132 > * {
    width: calc(25% - 9.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp133 > * {
    width: calc(25% - 9.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp134 > * {
    width: calc(25% - 9.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp135 > * {
    width: calc(25% - 9.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp136 > * {
    width: calc(25% - 9.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp137 > * {
    width: calc(25% - 9.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp138 > * {
    width: calc(25% - 9.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp139 > * {
    width: calc(25% - 9.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp140 > * {
    width: calc(25% - 10rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp141 > * {
    width: calc(25% - 10.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp142 > * {
    width: calc(25% - 10.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp143 > * {
    width: calc(25% - 10.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp144 > * {
    width: calc(25% - 10.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp145 > * {
    width: calc(25% - 10.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp146 > * {
    width: calc(25% - 10.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp147 > * {
    width: calc(25% - 10.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp148 > * {
    width: calc(25% - 10.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp149 > * {
    width: calc(25% - 10.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp150 > * {
    width: calc(25% - 10.7142857143rem / 4 * 3);
  }
  .flex-sp-col5 {
    flex-wrap: wrap;
  }
  .flex-sp-col5 > * {
    width: 20%;
  }
  .flex-sp-col5.gap1 > * {
    width: calc(20% - 0.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap2 > * {
    width: calc(20% - 0.125rem / 5 * 4);
  }
  .flex-sp-col5.gap3 > * {
    width: calc(20% - 0.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap4 > * {
    width: calc(20% - 0.25rem / 5 * 4);
  }
  .flex-sp-col5.gap5 > * {
    width: calc(20% - 0.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap6 > * {
    width: calc(20% - 0.375rem / 5 * 4);
  }
  .flex-sp-col5.gap7 > * {
    width: calc(20% - 0.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap8 > * {
    width: calc(20% - 0.5rem / 5 * 4);
  }
  .flex-sp-col5.gap9 > * {
    width: calc(20% - 0.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap10 > * {
    width: calc(20% - 0.625rem / 5 * 4);
  }
  .flex-sp-col5.gap11 > * {
    width: calc(20% - 0.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap12 > * {
    width: calc(20% - 0.75rem / 5 * 4);
  }
  .flex-sp-col5.gap13 > * {
    width: calc(20% - 0.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap14 > * {
    width: calc(20% - 0.875rem / 5 * 4);
  }
  .flex-sp-col5.gap15 > * {
    width: calc(20% - 0.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap16 > * {
    width: calc(20% - 1rem / 5 * 4);
  }
  .flex-sp-col5.gap17 > * {
    width: calc(20% - 1.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap18 > * {
    width: calc(20% - 1.125rem / 5 * 4);
  }
  .flex-sp-col5.gap19 > * {
    width: calc(20% - 1.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap20 > * {
    width: calc(20% - 1.25rem / 5 * 4);
  }
  .flex-sp-col5.gap21 > * {
    width: calc(20% - 1.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap22 > * {
    width: calc(20% - 1.375rem / 5 * 4);
  }
  .flex-sp-col5.gap23 > * {
    width: calc(20% - 1.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap24 > * {
    width: calc(20% - 1.5rem / 5 * 4);
  }
  .flex-sp-col5.gap25 > * {
    width: calc(20% - 1.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap26 > * {
    width: calc(20% - 1.625rem / 5 * 4);
  }
  .flex-sp-col5.gap27 > * {
    width: calc(20% - 1.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap28 > * {
    width: calc(20% - 1.75rem / 5 * 4);
  }
  .flex-sp-col5.gap29 > * {
    width: calc(20% - 1.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap30 > * {
    width: calc(20% - 1.875rem / 5 * 4);
  }
  .flex-sp-col5.gap31 > * {
    width: calc(20% - 1.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap32 > * {
    width: calc(20% - 2rem / 5 * 4);
  }
  .flex-sp-col5.gap33 > * {
    width: calc(20% - 2.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap34 > * {
    width: calc(20% - 2.125rem / 5 * 4);
  }
  .flex-sp-col5.gap35 > * {
    width: calc(20% - 2.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap36 > * {
    width: calc(20% - 2.25rem / 5 * 4);
  }
  .flex-sp-col5.gap37 > * {
    width: calc(20% - 2.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap38 > * {
    width: calc(20% - 2.375rem / 5 * 4);
  }
  .flex-sp-col5.gap39 > * {
    width: calc(20% - 2.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap40 > * {
    width: calc(20% - 2.5rem / 5 * 4);
  }
  .flex-sp-col5.gap41 > * {
    width: calc(20% - 2.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap42 > * {
    width: calc(20% - 2.625rem / 5 * 4);
  }
  .flex-sp-col5.gap43 > * {
    width: calc(20% - 2.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap44 > * {
    width: calc(20% - 2.75rem / 5 * 4);
  }
  .flex-sp-col5.gap45 > * {
    width: calc(20% - 2.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap46 > * {
    width: calc(20% - 2.875rem / 5 * 4);
  }
  .flex-sp-col5.gap47 > * {
    width: calc(20% - 2.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap48 > * {
    width: calc(20% - 3rem / 5 * 4);
  }
  .flex-sp-col5.gap49 > * {
    width: calc(20% - 3.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap50 > * {
    width: calc(20% - 3.125rem / 5 * 4);
  }
  .flex-sp-col5.gap51 > * {
    width: calc(20% - 3.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap52 > * {
    width: calc(20% - 3.25rem / 5 * 4);
  }
  .flex-sp-col5.gap53 > * {
    width: calc(20% - 3.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap54 > * {
    width: calc(20% - 3.375rem / 5 * 4);
  }
  .flex-sp-col5.gap55 > * {
    width: calc(20% - 3.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap56 > * {
    width: calc(20% - 3.5rem / 5 * 4);
  }
  .flex-sp-col5.gap57 > * {
    width: calc(20% - 3.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap58 > * {
    width: calc(20% - 3.625rem / 5 * 4);
  }
  .flex-sp-col5.gap59 > * {
    width: calc(20% - 3.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap60 > * {
    width: calc(20% - 3.75rem / 5 * 4);
  }
  .flex-sp-col5.gap61 > * {
    width: calc(20% - 3.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap62 > * {
    width: calc(20% - 3.875rem / 5 * 4);
  }
  .flex-sp-col5.gap63 > * {
    width: calc(20% - 3.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap64 > * {
    width: calc(20% - 4rem / 5 * 4);
  }
  .flex-sp-col5.gap65 > * {
    width: calc(20% - 4.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap66 > * {
    width: calc(20% - 4.125rem / 5 * 4);
  }
  .flex-sp-col5.gap67 > * {
    width: calc(20% - 4.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap68 > * {
    width: calc(20% - 4.25rem / 5 * 4);
  }
  .flex-sp-col5.gap69 > * {
    width: calc(20% - 4.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap70 > * {
    width: calc(20% - 4.375rem / 5 * 4);
  }
  .flex-sp-col5.gap71 > * {
    width: calc(20% - 4.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap72 > * {
    width: calc(20% - 4.5rem / 5 * 4);
  }
  .flex-sp-col5.gap73 > * {
    width: calc(20% - 4.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap74 > * {
    width: calc(20% - 4.625rem / 5 * 4);
  }
  .flex-sp-col5.gap75 > * {
    width: calc(20% - 4.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap76 > * {
    width: calc(20% - 4.75rem / 5 * 4);
  }
  .flex-sp-col5.gap77 > * {
    width: calc(20% - 4.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap78 > * {
    width: calc(20% - 4.875rem / 5 * 4);
  }
  .flex-sp-col5.gap79 > * {
    width: calc(20% - 4.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap80 > * {
    width: calc(20% - 5rem / 5 * 4);
  }
  .flex-sp-col5.gap81 > * {
    width: calc(20% - 5.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap82 > * {
    width: calc(20% - 5.125rem / 5 * 4);
  }
  .flex-sp-col5.gap83 > * {
    width: calc(20% - 5.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap84 > * {
    width: calc(20% - 5.25rem / 5 * 4);
  }
  .flex-sp-col5.gap85 > * {
    width: calc(20% - 5.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap86 > * {
    width: calc(20% - 5.375rem / 5 * 4);
  }
  .flex-sp-col5.gap87 > * {
    width: calc(20% - 5.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap88 > * {
    width: calc(20% - 5.5rem / 5 * 4);
  }
  .flex-sp-col5.gap89 > * {
    width: calc(20% - 5.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap90 > * {
    width: calc(20% - 5.625rem / 5 * 4);
  }
  .flex-sp-col5.gap91 > * {
    width: calc(20% - 5.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap92 > * {
    width: calc(20% - 5.75rem / 5 * 4);
  }
  .flex-sp-col5.gap93 > * {
    width: calc(20% - 5.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap94 > * {
    width: calc(20% - 5.875rem / 5 * 4);
  }
  .flex-sp-col5.gap95 > * {
    width: calc(20% - 5.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap96 > * {
    width: calc(20% - 6rem / 5 * 4);
  }
  .flex-sp-col5.gap97 > * {
    width: calc(20% - 6.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap98 > * {
    width: calc(20% - 6.125rem / 5 * 4);
  }
  .flex-sp-col5.gap99 > * {
    width: calc(20% - 6.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap100 > * {
    width: calc(20% - 6.25rem / 5 * 4);
  }
  .flex-sp-col5.gap101 > * {
    width: calc(20% - 6.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap102 > * {
    width: calc(20% - 6.375rem / 5 * 4);
  }
  .flex-sp-col5.gap103 > * {
    width: calc(20% - 6.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap104 > * {
    width: calc(20% - 6.5rem / 5 * 4);
  }
  .flex-sp-col5.gap105 > * {
    width: calc(20% - 6.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap106 > * {
    width: calc(20% - 6.625rem / 5 * 4);
  }
  .flex-sp-col5.gap107 > * {
    width: calc(20% - 6.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap108 > * {
    width: calc(20% - 6.75rem / 5 * 4);
  }
  .flex-sp-col5.gap109 > * {
    width: calc(20% - 6.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap110 > * {
    width: calc(20% - 6.875rem / 5 * 4);
  }
  .flex-sp-col5.gap111 > * {
    width: calc(20% - 6.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap112 > * {
    width: calc(20% - 7rem / 5 * 4);
  }
  .flex-sp-col5.gap113 > * {
    width: calc(20% - 7.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap114 > * {
    width: calc(20% - 7.125rem / 5 * 4);
  }
  .flex-sp-col5.gap115 > * {
    width: calc(20% - 7.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap116 > * {
    width: calc(20% - 7.25rem / 5 * 4);
  }
  .flex-sp-col5.gap117 > * {
    width: calc(20% - 7.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap118 > * {
    width: calc(20% - 7.375rem / 5 * 4);
  }
  .flex-sp-col5.gap119 > * {
    width: calc(20% - 7.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap120 > * {
    width: calc(20% - 7.5rem / 5 * 4);
  }
  .flex-sp-col5.gap121 > * {
    width: calc(20% - 7.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap122 > * {
    width: calc(20% - 7.625rem / 5 * 4);
  }
  .flex-sp-col5.gap123 > * {
    width: calc(20% - 7.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap124 > * {
    width: calc(20% - 7.75rem / 5 * 4);
  }
  .flex-sp-col5.gap125 > * {
    width: calc(20% - 7.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap126 > * {
    width: calc(20% - 7.875rem / 5 * 4);
  }
  .flex-sp-col5.gap127 > * {
    width: calc(20% - 7.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap128 > * {
    width: calc(20% - 8rem / 5 * 4);
  }
  .flex-sp-col5.gap129 > * {
    width: calc(20% - 8.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap130 > * {
    width: calc(20% - 8.125rem / 5 * 4);
  }
  .flex-sp-col5.gap131 > * {
    width: calc(20% - 8.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap132 > * {
    width: calc(20% - 8.25rem / 5 * 4);
  }
  .flex-sp-col5.gap133 > * {
    width: calc(20% - 8.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap134 > * {
    width: calc(20% - 8.375rem / 5 * 4);
  }
  .flex-sp-col5.gap135 > * {
    width: calc(20% - 8.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap136 > * {
    width: calc(20% - 8.5rem / 5 * 4);
  }
  .flex-sp-col5.gap137 > * {
    width: calc(20% - 8.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap138 > * {
    width: calc(20% - 8.625rem / 5 * 4);
  }
  .flex-sp-col5.gap139 > * {
    width: calc(20% - 8.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap140 > * {
    width: calc(20% - 8.75rem / 5 * 4);
  }
  .flex-sp-col5.gap141 > * {
    width: calc(20% - 8.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap142 > * {
    width: calc(20% - 8.875rem / 5 * 4);
  }
  .flex-sp-col5.gap143 > * {
    width: calc(20% - 8.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap144 > * {
    width: calc(20% - 9rem / 5 * 4);
  }
  .flex-sp-col5.gap145 > * {
    width: calc(20% - 9.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap146 > * {
    width: calc(20% - 9.125rem / 5 * 4);
  }
  .flex-sp-col5.gap147 > * {
    width: calc(20% - 9.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap148 > * {
    width: calc(20% - 9.25rem / 5 * 4);
  }
  .flex-sp-col5.gap149 > * {
    width: calc(20% - 9.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap150 > * {
    width: calc(20% - 9.375rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp1 > * {
    width: calc(20% - 0.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp2 > * {
    width: calc(20% - 0.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp3 > * {
    width: calc(20% - 0.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp4 > * {
    width: calc(20% - 0.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp5 > * {
    width: calc(20% - 0.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp6 > * {
    width: calc(20% - 0.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp7 > * {
    width: calc(20% - 0.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp8 > * {
    width: calc(20% - 0.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp9 > * {
    width: calc(20% - 0.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp10 > * {
    width: calc(20% - 0.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp11 > * {
    width: calc(20% - 0.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp12 > * {
    width: calc(20% - 0.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp13 > * {
    width: calc(20% - 0.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp14 > * {
    width: calc(20% - 1rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp15 > * {
    width: calc(20% - 1.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp16 > * {
    width: calc(20% - 1.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp17 > * {
    width: calc(20% - 1.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp18 > * {
    width: calc(20% - 1.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp19 > * {
    width: calc(20% - 1.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp20 > * {
    width: calc(20% - 1.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp21 > * {
    width: calc(20% - 1.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp22 > * {
    width: calc(20% - 1.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp23 > * {
    width: calc(20% - 1.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp24 > * {
    width: calc(20% - 1.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp25 > * {
    width: calc(20% - 1.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp26 > * {
    width: calc(20% - 1.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp27 > * {
    width: calc(20% - 1.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp28 > * {
    width: calc(20% - 2rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp29 > * {
    width: calc(20% - 2.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp30 > * {
    width: calc(20% - 2.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp31 > * {
    width: calc(20% - 2.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp32 > * {
    width: calc(20% - 2.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp33 > * {
    width: calc(20% - 2.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp34 > * {
    width: calc(20% - 2.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp35 > * {
    width: calc(20% - 2.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp36 > * {
    width: calc(20% - 2.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp37 > * {
    width: calc(20% - 2.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp38 > * {
    width: calc(20% - 2.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp39 > * {
    width: calc(20% - 2.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp40 > * {
    width: calc(20% - 2.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp41 > * {
    width: calc(20% - 2.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp42 > * {
    width: calc(20% - 3rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp43 > * {
    width: calc(20% - 3.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp44 > * {
    width: calc(20% - 3.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp45 > * {
    width: calc(20% - 3.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp46 > * {
    width: calc(20% - 3.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp47 > * {
    width: calc(20% - 3.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp48 > * {
    width: calc(20% - 3.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp49 > * {
    width: calc(20% - 3.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp50 > * {
    width: calc(20% - 3.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp51 > * {
    width: calc(20% - 3.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp52 > * {
    width: calc(20% - 3.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp53 > * {
    width: calc(20% - 3.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp54 > * {
    width: calc(20% - 3.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp55 > * {
    width: calc(20% - 3.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp56 > * {
    width: calc(20% - 4rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp57 > * {
    width: calc(20% - 4.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp58 > * {
    width: calc(20% - 4.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp59 > * {
    width: calc(20% - 4.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp60 > * {
    width: calc(20% - 4.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp61 > * {
    width: calc(20% - 4.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp62 > * {
    width: calc(20% - 4.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp63 > * {
    width: calc(20% - 4.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp64 > * {
    width: calc(20% - 4.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp65 > * {
    width: calc(20% - 4.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp66 > * {
    width: calc(20% - 4.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp67 > * {
    width: calc(20% - 4.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp68 > * {
    width: calc(20% - 4.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp69 > * {
    width: calc(20% - 4.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp70 > * {
    width: calc(20% - 5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp71 > * {
    width: calc(20% - 5.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp72 > * {
    width: calc(20% - 5.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp73 > * {
    width: calc(20% - 5.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp74 > * {
    width: calc(20% - 5.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp75 > * {
    width: calc(20% - 5.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp76 > * {
    width: calc(20% - 5.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp77 > * {
    width: calc(20% - 5.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp78 > * {
    width: calc(20% - 5.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp79 > * {
    width: calc(20% - 5.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp80 > * {
    width: calc(20% - 5.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp81 > * {
    width: calc(20% - 5.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp82 > * {
    width: calc(20% - 5.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp83 > * {
    width: calc(20% - 5.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp84 > * {
    width: calc(20% - 6rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp85 > * {
    width: calc(20% - 6.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp86 > * {
    width: calc(20% - 6.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp87 > * {
    width: calc(20% - 6.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp88 > * {
    width: calc(20% - 6.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp89 > * {
    width: calc(20% - 6.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp90 > * {
    width: calc(20% - 6.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp91 > * {
    width: calc(20% - 6.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp92 > * {
    width: calc(20% - 6.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp93 > * {
    width: calc(20% - 6.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp94 > * {
    width: calc(20% - 6.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp95 > * {
    width: calc(20% - 6.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp96 > * {
    width: calc(20% - 6.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp97 > * {
    width: calc(20% - 6.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp98 > * {
    width: calc(20% - 7rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp99 > * {
    width: calc(20% - 7.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp100 > * {
    width: calc(20% - 7.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp101 > * {
    width: calc(20% - 7.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp102 > * {
    width: calc(20% - 7.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp103 > * {
    width: calc(20% - 7.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp104 > * {
    width: calc(20% - 7.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp105 > * {
    width: calc(20% - 7.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp106 > * {
    width: calc(20% - 7.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp107 > * {
    width: calc(20% - 7.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp108 > * {
    width: calc(20% - 7.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp109 > * {
    width: calc(20% - 7.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp110 > * {
    width: calc(20% - 7.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp111 > * {
    width: calc(20% - 7.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp112 > * {
    width: calc(20% - 8rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp113 > * {
    width: calc(20% - 8.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp114 > * {
    width: calc(20% - 8.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp115 > * {
    width: calc(20% - 8.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp116 > * {
    width: calc(20% - 8.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp117 > * {
    width: calc(20% - 8.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp118 > * {
    width: calc(20% - 8.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp119 > * {
    width: calc(20% - 8.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp120 > * {
    width: calc(20% - 8.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp121 > * {
    width: calc(20% - 8.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp122 > * {
    width: calc(20% - 8.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp123 > * {
    width: calc(20% - 8.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp124 > * {
    width: calc(20% - 8.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp125 > * {
    width: calc(20% - 8.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp126 > * {
    width: calc(20% - 9rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp127 > * {
    width: calc(20% - 9.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp128 > * {
    width: calc(20% - 9.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp129 > * {
    width: calc(20% - 9.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp130 > * {
    width: calc(20% - 9.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp131 > * {
    width: calc(20% - 9.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp132 > * {
    width: calc(20% - 9.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp133 > * {
    width: calc(20% - 9.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp134 > * {
    width: calc(20% - 9.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp135 > * {
    width: calc(20% - 9.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp136 > * {
    width: calc(20% - 9.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp137 > * {
    width: calc(20% - 9.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp138 > * {
    width: calc(20% - 9.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp139 > * {
    width: calc(20% - 9.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp140 > * {
    width: calc(20% - 10rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp141 > * {
    width: calc(20% - 10.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp142 > * {
    width: calc(20% - 10.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp143 > * {
    width: calc(20% - 10.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp144 > * {
    width: calc(20% - 10.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp145 > * {
    width: calc(20% - 10.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp146 > * {
    width: calc(20% - 10.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp147 > * {
    width: calc(20% - 10.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp148 > * {
    width: calc(20% - 10.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp149 > * {
    width: calc(20% - 10.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp150 > * {
    width: calc(20% - 10.7142857143rem / 5 * 4);
  }
  .flex-sp-col6 {
    flex-wrap: wrap;
  }
  .flex-sp-col6 > * {
    width: 16.6666666667%;
  }
  .flex-sp-col6.gap1 > * {
    width: calc(16.6666666667% - 0.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap2 > * {
    width: calc(16.6666666667% - 0.125rem / 6 * 5);
  }
  .flex-sp-col6.gap3 > * {
    width: calc(16.6666666667% - 0.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap4 > * {
    width: calc(16.6666666667% - 0.25rem / 6 * 5);
  }
  .flex-sp-col6.gap5 > * {
    width: calc(16.6666666667% - 0.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap6 > * {
    width: calc(16.6666666667% - 0.375rem / 6 * 5);
  }
  .flex-sp-col6.gap7 > * {
    width: calc(16.6666666667% - 0.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap8 > * {
    width: calc(16.6666666667% - 0.5rem / 6 * 5);
  }
  .flex-sp-col6.gap9 > * {
    width: calc(16.6666666667% - 0.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap10 > * {
    width: calc(16.6666666667% - 0.625rem / 6 * 5);
  }
  .flex-sp-col6.gap11 > * {
    width: calc(16.6666666667% - 0.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap12 > * {
    width: calc(16.6666666667% - 0.75rem / 6 * 5);
  }
  .flex-sp-col6.gap13 > * {
    width: calc(16.6666666667% - 0.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap14 > * {
    width: calc(16.6666666667% - 0.875rem / 6 * 5);
  }
  .flex-sp-col6.gap15 > * {
    width: calc(16.6666666667% - 0.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap16 > * {
    width: calc(16.6666666667% - 1rem / 6 * 5);
  }
  .flex-sp-col6.gap17 > * {
    width: calc(16.6666666667% - 1.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap18 > * {
    width: calc(16.6666666667% - 1.125rem / 6 * 5);
  }
  .flex-sp-col6.gap19 > * {
    width: calc(16.6666666667% - 1.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap20 > * {
    width: calc(16.6666666667% - 1.25rem / 6 * 5);
  }
  .flex-sp-col6.gap21 > * {
    width: calc(16.6666666667% - 1.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap22 > * {
    width: calc(16.6666666667% - 1.375rem / 6 * 5);
  }
  .flex-sp-col6.gap23 > * {
    width: calc(16.6666666667% - 1.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap24 > * {
    width: calc(16.6666666667% - 1.5rem / 6 * 5);
  }
  .flex-sp-col6.gap25 > * {
    width: calc(16.6666666667% - 1.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap26 > * {
    width: calc(16.6666666667% - 1.625rem / 6 * 5);
  }
  .flex-sp-col6.gap27 > * {
    width: calc(16.6666666667% - 1.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap28 > * {
    width: calc(16.6666666667% - 1.75rem / 6 * 5);
  }
  .flex-sp-col6.gap29 > * {
    width: calc(16.6666666667% - 1.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap30 > * {
    width: calc(16.6666666667% - 1.875rem / 6 * 5);
  }
  .flex-sp-col6.gap31 > * {
    width: calc(16.6666666667% - 1.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap32 > * {
    width: calc(16.6666666667% - 2rem / 6 * 5);
  }
  .flex-sp-col6.gap33 > * {
    width: calc(16.6666666667% - 2.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap34 > * {
    width: calc(16.6666666667% - 2.125rem / 6 * 5);
  }
  .flex-sp-col6.gap35 > * {
    width: calc(16.6666666667% - 2.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap36 > * {
    width: calc(16.6666666667% - 2.25rem / 6 * 5);
  }
  .flex-sp-col6.gap37 > * {
    width: calc(16.6666666667% - 2.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap38 > * {
    width: calc(16.6666666667% - 2.375rem / 6 * 5);
  }
  .flex-sp-col6.gap39 > * {
    width: calc(16.6666666667% - 2.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap40 > * {
    width: calc(16.6666666667% - 2.5rem / 6 * 5);
  }
  .flex-sp-col6.gap41 > * {
    width: calc(16.6666666667% - 2.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap42 > * {
    width: calc(16.6666666667% - 2.625rem / 6 * 5);
  }
  .flex-sp-col6.gap43 > * {
    width: calc(16.6666666667% - 2.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap44 > * {
    width: calc(16.6666666667% - 2.75rem / 6 * 5);
  }
  .flex-sp-col6.gap45 > * {
    width: calc(16.6666666667% - 2.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap46 > * {
    width: calc(16.6666666667% - 2.875rem / 6 * 5);
  }
  .flex-sp-col6.gap47 > * {
    width: calc(16.6666666667% - 2.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap48 > * {
    width: calc(16.6666666667% - 3rem / 6 * 5);
  }
  .flex-sp-col6.gap49 > * {
    width: calc(16.6666666667% - 3.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap50 > * {
    width: calc(16.6666666667% - 3.125rem / 6 * 5);
  }
  .flex-sp-col6.gap51 > * {
    width: calc(16.6666666667% - 3.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap52 > * {
    width: calc(16.6666666667% - 3.25rem / 6 * 5);
  }
  .flex-sp-col6.gap53 > * {
    width: calc(16.6666666667% - 3.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap54 > * {
    width: calc(16.6666666667% - 3.375rem / 6 * 5);
  }
  .flex-sp-col6.gap55 > * {
    width: calc(16.6666666667% - 3.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap56 > * {
    width: calc(16.6666666667% - 3.5rem / 6 * 5);
  }
  .flex-sp-col6.gap57 > * {
    width: calc(16.6666666667% - 3.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap58 > * {
    width: calc(16.6666666667% - 3.625rem / 6 * 5);
  }
  .flex-sp-col6.gap59 > * {
    width: calc(16.6666666667% - 3.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap60 > * {
    width: calc(16.6666666667% - 3.75rem / 6 * 5);
  }
  .flex-sp-col6.gap61 > * {
    width: calc(16.6666666667% - 3.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap62 > * {
    width: calc(16.6666666667% - 3.875rem / 6 * 5);
  }
  .flex-sp-col6.gap63 > * {
    width: calc(16.6666666667% - 3.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap64 > * {
    width: calc(16.6666666667% - 4rem / 6 * 5);
  }
  .flex-sp-col6.gap65 > * {
    width: calc(16.6666666667% - 4.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap66 > * {
    width: calc(16.6666666667% - 4.125rem / 6 * 5);
  }
  .flex-sp-col6.gap67 > * {
    width: calc(16.6666666667% - 4.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap68 > * {
    width: calc(16.6666666667% - 4.25rem / 6 * 5);
  }
  .flex-sp-col6.gap69 > * {
    width: calc(16.6666666667% - 4.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap70 > * {
    width: calc(16.6666666667% - 4.375rem / 6 * 5);
  }
  .flex-sp-col6.gap71 > * {
    width: calc(16.6666666667% - 4.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap72 > * {
    width: calc(16.6666666667% - 4.5rem / 6 * 5);
  }
  .flex-sp-col6.gap73 > * {
    width: calc(16.6666666667% - 4.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap74 > * {
    width: calc(16.6666666667% - 4.625rem / 6 * 5);
  }
  .flex-sp-col6.gap75 > * {
    width: calc(16.6666666667% - 4.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap76 > * {
    width: calc(16.6666666667% - 4.75rem / 6 * 5);
  }
  .flex-sp-col6.gap77 > * {
    width: calc(16.6666666667% - 4.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap78 > * {
    width: calc(16.6666666667% - 4.875rem / 6 * 5);
  }
  .flex-sp-col6.gap79 > * {
    width: calc(16.6666666667% - 4.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap80 > * {
    width: calc(16.6666666667% - 5rem / 6 * 5);
  }
  .flex-sp-col6.gap81 > * {
    width: calc(16.6666666667% - 5.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap82 > * {
    width: calc(16.6666666667% - 5.125rem / 6 * 5);
  }
  .flex-sp-col6.gap83 > * {
    width: calc(16.6666666667% - 5.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap84 > * {
    width: calc(16.6666666667% - 5.25rem / 6 * 5);
  }
  .flex-sp-col6.gap85 > * {
    width: calc(16.6666666667% - 5.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap86 > * {
    width: calc(16.6666666667% - 5.375rem / 6 * 5);
  }
  .flex-sp-col6.gap87 > * {
    width: calc(16.6666666667% - 5.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap88 > * {
    width: calc(16.6666666667% - 5.5rem / 6 * 5);
  }
  .flex-sp-col6.gap89 > * {
    width: calc(16.6666666667% - 5.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap90 > * {
    width: calc(16.6666666667% - 5.625rem / 6 * 5);
  }
  .flex-sp-col6.gap91 > * {
    width: calc(16.6666666667% - 5.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap92 > * {
    width: calc(16.6666666667% - 5.75rem / 6 * 5);
  }
  .flex-sp-col6.gap93 > * {
    width: calc(16.6666666667% - 5.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap94 > * {
    width: calc(16.6666666667% - 5.875rem / 6 * 5);
  }
  .flex-sp-col6.gap95 > * {
    width: calc(16.6666666667% - 5.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap96 > * {
    width: calc(16.6666666667% - 6rem / 6 * 5);
  }
  .flex-sp-col6.gap97 > * {
    width: calc(16.6666666667% - 6.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap98 > * {
    width: calc(16.6666666667% - 6.125rem / 6 * 5);
  }
  .flex-sp-col6.gap99 > * {
    width: calc(16.6666666667% - 6.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap100 > * {
    width: calc(16.6666666667% - 6.25rem / 6 * 5);
  }
  .flex-sp-col6.gap101 > * {
    width: calc(16.6666666667% - 6.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap102 > * {
    width: calc(16.6666666667% - 6.375rem / 6 * 5);
  }
  .flex-sp-col6.gap103 > * {
    width: calc(16.6666666667% - 6.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap104 > * {
    width: calc(16.6666666667% - 6.5rem / 6 * 5);
  }
  .flex-sp-col6.gap105 > * {
    width: calc(16.6666666667% - 6.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap106 > * {
    width: calc(16.6666666667% - 6.625rem / 6 * 5);
  }
  .flex-sp-col6.gap107 > * {
    width: calc(16.6666666667% - 6.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap108 > * {
    width: calc(16.6666666667% - 6.75rem / 6 * 5);
  }
  .flex-sp-col6.gap109 > * {
    width: calc(16.6666666667% - 6.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap110 > * {
    width: calc(16.6666666667% - 6.875rem / 6 * 5);
  }
  .flex-sp-col6.gap111 > * {
    width: calc(16.6666666667% - 6.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap112 > * {
    width: calc(16.6666666667% - 7rem / 6 * 5);
  }
  .flex-sp-col6.gap113 > * {
    width: calc(16.6666666667% - 7.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap114 > * {
    width: calc(16.6666666667% - 7.125rem / 6 * 5);
  }
  .flex-sp-col6.gap115 > * {
    width: calc(16.6666666667% - 7.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap116 > * {
    width: calc(16.6666666667% - 7.25rem / 6 * 5);
  }
  .flex-sp-col6.gap117 > * {
    width: calc(16.6666666667% - 7.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap118 > * {
    width: calc(16.6666666667% - 7.375rem / 6 * 5);
  }
  .flex-sp-col6.gap119 > * {
    width: calc(16.6666666667% - 7.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap120 > * {
    width: calc(16.6666666667% - 7.5rem / 6 * 5);
  }
  .flex-sp-col6.gap121 > * {
    width: calc(16.6666666667% - 7.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap122 > * {
    width: calc(16.6666666667% - 7.625rem / 6 * 5);
  }
  .flex-sp-col6.gap123 > * {
    width: calc(16.6666666667% - 7.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap124 > * {
    width: calc(16.6666666667% - 7.75rem / 6 * 5);
  }
  .flex-sp-col6.gap125 > * {
    width: calc(16.6666666667% - 7.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap126 > * {
    width: calc(16.6666666667% - 7.875rem / 6 * 5);
  }
  .flex-sp-col6.gap127 > * {
    width: calc(16.6666666667% - 7.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap128 > * {
    width: calc(16.6666666667% - 8rem / 6 * 5);
  }
  .flex-sp-col6.gap129 > * {
    width: calc(16.6666666667% - 8.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap130 > * {
    width: calc(16.6666666667% - 8.125rem / 6 * 5);
  }
  .flex-sp-col6.gap131 > * {
    width: calc(16.6666666667% - 8.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap132 > * {
    width: calc(16.6666666667% - 8.25rem / 6 * 5);
  }
  .flex-sp-col6.gap133 > * {
    width: calc(16.6666666667% - 8.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap134 > * {
    width: calc(16.6666666667% - 8.375rem / 6 * 5);
  }
  .flex-sp-col6.gap135 > * {
    width: calc(16.6666666667% - 8.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap136 > * {
    width: calc(16.6666666667% - 8.5rem / 6 * 5);
  }
  .flex-sp-col6.gap137 > * {
    width: calc(16.6666666667% - 8.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap138 > * {
    width: calc(16.6666666667% - 8.625rem / 6 * 5);
  }
  .flex-sp-col6.gap139 > * {
    width: calc(16.6666666667% - 8.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap140 > * {
    width: calc(16.6666666667% - 8.75rem / 6 * 5);
  }
  .flex-sp-col6.gap141 > * {
    width: calc(16.6666666667% - 8.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap142 > * {
    width: calc(16.6666666667% - 8.875rem / 6 * 5);
  }
  .flex-sp-col6.gap143 > * {
    width: calc(16.6666666667% - 8.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap144 > * {
    width: calc(16.6666666667% - 9rem / 6 * 5);
  }
  .flex-sp-col6.gap145 > * {
    width: calc(16.6666666667% - 9.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap146 > * {
    width: calc(16.6666666667% - 9.125rem / 6 * 5);
  }
  .flex-sp-col6.gap147 > * {
    width: calc(16.6666666667% - 9.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap148 > * {
    width: calc(16.6666666667% - 9.25rem / 6 * 5);
  }
  .flex-sp-col6.gap149 > * {
    width: calc(16.6666666667% - 9.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap150 > * {
    width: calc(16.6666666667% - 9.375rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp1 > * {
    width: calc(16.6666666667% - 0.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp2 > * {
    width: calc(16.6666666667% - 0.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp3 > * {
    width: calc(16.6666666667% - 0.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp4 > * {
    width: calc(16.6666666667% - 0.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp5 > * {
    width: calc(16.6666666667% - 0.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp6 > * {
    width: calc(16.6666666667% - 0.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp7 > * {
    width: calc(16.6666666667% - 0.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp8 > * {
    width: calc(16.6666666667% - 0.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp9 > * {
    width: calc(16.6666666667% - 0.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp10 > * {
    width: calc(16.6666666667% - 0.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp11 > * {
    width: calc(16.6666666667% - 0.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp12 > * {
    width: calc(16.6666666667% - 0.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp13 > * {
    width: calc(16.6666666667% - 0.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp14 > * {
    width: calc(16.6666666667% - 1rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp15 > * {
    width: calc(16.6666666667% - 1.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp16 > * {
    width: calc(16.6666666667% - 1.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp17 > * {
    width: calc(16.6666666667% - 1.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp18 > * {
    width: calc(16.6666666667% - 1.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp19 > * {
    width: calc(16.6666666667% - 1.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp20 > * {
    width: calc(16.6666666667% - 1.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp21 > * {
    width: calc(16.6666666667% - 1.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp22 > * {
    width: calc(16.6666666667% - 1.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp23 > * {
    width: calc(16.6666666667% - 1.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp24 > * {
    width: calc(16.6666666667% - 1.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp25 > * {
    width: calc(16.6666666667% - 1.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp26 > * {
    width: calc(16.6666666667% - 1.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp27 > * {
    width: calc(16.6666666667% - 1.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp28 > * {
    width: calc(16.6666666667% - 2rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp29 > * {
    width: calc(16.6666666667% - 2.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp30 > * {
    width: calc(16.6666666667% - 2.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp31 > * {
    width: calc(16.6666666667% - 2.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp32 > * {
    width: calc(16.6666666667% - 2.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp33 > * {
    width: calc(16.6666666667% - 2.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp34 > * {
    width: calc(16.6666666667% - 2.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp35 > * {
    width: calc(16.6666666667% - 2.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp36 > * {
    width: calc(16.6666666667% - 2.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp37 > * {
    width: calc(16.6666666667% - 2.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp38 > * {
    width: calc(16.6666666667% - 2.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp39 > * {
    width: calc(16.6666666667% - 2.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp40 > * {
    width: calc(16.6666666667% - 2.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp41 > * {
    width: calc(16.6666666667% - 2.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp42 > * {
    width: calc(16.6666666667% - 3rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp43 > * {
    width: calc(16.6666666667% - 3.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp44 > * {
    width: calc(16.6666666667% - 3.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp45 > * {
    width: calc(16.6666666667% - 3.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp46 > * {
    width: calc(16.6666666667% - 3.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp47 > * {
    width: calc(16.6666666667% - 3.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp48 > * {
    width: calc(16.6666666667% - 3.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp49 > * {
    width: calc(16.6666666667% - 3.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp50 > * {
    width: calc(16.6666666667% - 3.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp51 > * {
    width: calc(16.6666666667% - 3.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp52 > * {
    width: calc(16.6666666667% - 3.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp53 > * {
    width: calc(16.6666666667% - 3.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp54 > * {
    width: calc(16.6666666667% - 3.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp55 > * {
    width: calc(16.6666666667% - 3.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp56 > * {
    width: calc(16.6666666667% - 4rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp57 > * {
    width: calc(16.6666666667% - 4.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp58 > * {
    width: calc(16.6666666667% - 4.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp59 > * {
    width: calc(16.6666666667% - 4.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp60 > * {
    width: calc(16.6666666667% - 4.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp61 > * {
    width: calc(16.6666666667% - 4.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp62 > * {
    width: calc(16.6666666667% - 4.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp63 > * {
    width: calc(16.6666666667% - 4.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp64 > * {
    width: calc(16.6666666667% - 4.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp65 > * {
    width: calc(16.6666666667% - 4.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp66 > * {
    width: calc(16.6666666667% - 4.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp67 > * {
    width: calc(16.6666666667% - 4.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp68 > * {
    width: calc(16.6666666667% - 4.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp69 > * {
    width: calc(16.6666666667% - 4.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp70 > * {
    width: calc(16.6666666667% - 5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp71 > * {
    width: calc(16.6666666667% - 5.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp72 > * {
    width: calc(16.6666666667% - 5.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp73 > * {
    width: calc(16.6666666667% - 5.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp74 > * {
    width: calc(16.6666666667% - 5.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp75 > * {
    width: calc(16.6666666667% - 5.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp76 > * {
    width: calc(16.6666666667% - 5.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp77 > * {
    width: calc(16.6666666667% - 5.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp78 > * {
    width: calc(16.6666666667% - 5.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp79 > * {
    width: calc(16.6666666667% - 5.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp80 > * {
    width: calc(16.6666666667% - 5.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp81 > * {
    width: calc(16.6666666667% - 5.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp82 > * {
    width: calc(16.6666666667% - 5.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp83 > * {
    width: calc(16.6666666667% - 5.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp84 > * {
    width: calc(16.6666666667% - 6rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp85 > * {
    width: calc(16.6666666667% - 6.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp86 > * {
    width: calc(16.6666666667% - 6.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp87 > * {
    width: calc(16.6666666667% - 6.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp88 > * {
    width: calc(16.6666666667% - 6.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp89 > * {
    width: calc(16.6666666667% - 6.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp90 > * {
    width: calc(16.6666666667% - 6.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp91 > * {
    width: calc(16.6666666667% - 6.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp92 > * {
    width: calc(16.6666666667% - 6.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp93 > * {
    width: calc(16.6666666667% - 6.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp94 > * {
    width: calc(16.6666666667% - 6.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp95 > * {
    width: calc(16.6666666667% - 6.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp96 > * {
    width: calc(16.6666666667% - 6.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp97 > * {
    width: calc(16.6666666667% - 6.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp98 > * {
    width: calc(16.6666666667% - 7rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp99 > * {
    width: calc(16.6666666667% - 7.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp100 > * {
    width: calc(16.6666666667% - 7.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp101 > * {
    width: calc(16.6666666667% - 7.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp102 > * {
    width: calc(16.6666666667% - 7.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp103 > * {
    width: calc(16.6666666667% - 7.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp104 > * {
    width: calc(16.6666666667% - 7.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp105 > * {
    width: calc(16.6666666667% - 7.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp106 > * {
    width: calc(16.6666666667% - 7.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp107 > * {
    width: calc(16.6666666667% - 7.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp108 > * {
    width: calc(16.6666666667% - 7.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp109 > * {
    width: calc(16.6666666667% - 7.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp110 > * {
    width: calc(16.6666666667% - 7.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp111 > * {
    width: calc(16.6666666667% - 7.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp112 > * {
    width: calc(16.6666666667% - 8rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp113 > * {
    width: calc(16.6666666667% - 8.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp114 > * {
    width: calc(16.6666666667% - 8.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp115 > * {
    width: calc(16.6666666667% - 8.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp116 > * {
    width: calc(16.6666666667% - 8.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp117 > * {
    width: calc(16.6666666667% - 8.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp118 > * {
    width: calc(16.6666666667% - 8.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp119 > * {
    width: calc(16.6666666667% - 8.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp120 > * {
    width: calc(16.6666666667% - 8.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp121 > * {
    width: calc(16.6666666667% - 8.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp122 > * {
    width: calc(16.6666666667% - 8.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp123 > * {
    width: calc(16.6666666667% - 8.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp124 > * {
    width: calc(16.6666666667% - 8.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp125 > * {
    width: calc(16.6666666667% - 8.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp126 > * {
    width: calc(16.6666666667% - 9rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp127 > * {
    width: calc(16.6666666667% - 9.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp128 > * {
    width: calc(16.6666666667% - 9.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp129 > * {
    width: calc(16.6666666667% - 9.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp130 > * {
    width: calc(16.6666666667% - 9.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp131 > * {
    width: calc(16.6666666667% - 9.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp132 > * {
    width: calc(16.6666666667% - 9.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp133 > * {
    width: calc(16.6666666667% - 9.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp134 > * {
    width: calc(16.6666666667% - 9.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp135 > * {
    width: calc(16.6666666667% - 9.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp136 > * {
    width: calc(16.6666666667% - 9.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp137 > * {
    width: calc(16.6666666667% - 9.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp138 > * {
    width: calc(16.6666666667% - 9.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp139 > * {
    width: calc(16.6666666667% - 9.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp140 > * {
    width: calc(16.6666666667% - 10rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp141 > * {
    width: calc(16.6666666667% - 10.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp142 > * {
    width: calc(16.6666666667% - 10.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp143 > * {
    width: calc(16.6666666667% - 10.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp144 > * {
    width: calc(16.6666666667% - 10.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp145 > * {
    width: calc(16.6666666667% - 10.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp146 > * {
    width: calc(16.6666666667% - 10.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp147 > * {
    width: calc(16.6666666667% - 10.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp148 > * {
    width: calc(16.6666666667% - 10.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp149 > * {
    width: calc(16.6666666667% - 10.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp150 > * {
    width: calc(16.6666666667% - 10.7142857143rem / 6 * 5);
  }
  .flex-sp-col7 {
    flex-wrap: wrap;
  }
  .flex-sp-col7 > * {
    width: 14.2857142857%;
  }
  .flex-sp-col7.gap1 > * {
    width: calc(14.2857142857% - 0.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap2 > * {
    width: calc(14.2857142857% - 0.125rem / 7 * 6);
  }
  .flex-sp-col7.gap3 > * {
    width: calc(14.2857142857% - 0.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap4 > * {
    width: calc(14.2857142857% - 0.25rem / 7 * 6);
  }
  .flex-sp-col7.gap5 > * {
    width: calc(14.2857142857% - 0.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap6 > * {
    width: calc(14.2857142857% - 0.375rem / 7 * 6);
  }
  .flex-sp-col7.gap7 > * {
    width: calc(14.2857142857% - 0.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap8 > * {
    width: calc(14.2857142857% - 0.5rem / 7 * 6);
  }
  .flex-sp-col7.gap9 > * {
    width: calc(14.2857142857% - 0.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap10 > * {
    width: calc(14.2857142857% - 0.625rem / 7 * 6);
  }
  .flex-sp-col7.gap11 > * {
    width: calc(14.2857142857% - 0.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap12 > * {
    width: calc(14.2857142857% - 0.75rem / 7 * 6);
  }
  .flex-sp-col7.gap13 > * {
    width: calc(14.2857142857% - 0.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap14 > * {
    width: calc(14.2857142857% - 0.875rem / 7 * 6);
  }
  .flex-sp-col7.gap15 > * {
    width: calc(14.2857142857% - 0.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap16 > * {
    width: calc(14.2857142857% - 1rem / 7 * 6);
  }
  .flex-sp-col7.gap17 > * {
    width: calc(14.2857142857% - 1.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap18 > * {
    width: calc(14.2857142857% - 1.125rem / 7 * 6);
  }
  .flex-sp-col7.gap19 > * {
    width: calc(14.2857142857% - 1.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap20 > * {
    width: calc(14.2857142857% - 1.25rem / 7 * 6);
  }
  .flex-sp-col7.gap21 > * {
    width: calc(14.2857142857% - 1.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap22 > * {
    width: calc(14.2857142857% - 1.375rem / 7 * 6);
  }
  .flex-sp-col7.gap23 > * {
    width: calc(14.2857142857% - 1.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap24 > * {
    width: calc(14.2857142857% - 1.5rem / 7 * 6);
  }
  .flex-sp-col7.gap25 > * {
    width: calc(14.2857142857% - 1.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap26 > * {
    width: calc(14.2857142857% - 1.625rem / 7 * 6);
  }
  .flex-sp-col7.gap27 > * {
    width: calc(14.2857142857% - 1.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap28 > * {
    width: calc(14.2857142857% - 1.75rem / 7 * 6);
  }
  .flex-sp-col7.gap29 > * {
    width: calc(14.2857142857% - 1.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap30 > * {
    width: calc(14.2857142857% - 1.875rem / 7 * 6);
  }
  .flex-sp-col7.gap31 > * {
    width: calc(14.2857142857% - 1.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap32 > * {
    width: calc(14.2857142857% - 2rem / 7 * 6);
  }
  .flex-sp-col7.gap33 > * {
    width: calc(14.2857142857% - 2.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap34 > * {
    width: calc(14.2857142857% - 2.125rem / 7 * 6);
  }
  .flex-sp-col7.gap35 > * {
    width: calc(14.2857142857% - 2.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap36 > * {
    width: calc(14.2857142857% - 2.25rem / 7 * 6);
  }
  .flex-sp-col7.gap37 > * {
    width: calc(14.2857142857% - 2.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap38 > * {
    width: calc(14.2857142857% - 2.375rem / 7 * 6);
  }
  .flex-sp-col7.gap39 > * {
    width: calc(14.2857142857% - 2.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap40 > * {
    width: calc(14.2857142857% - 2.5rem / 7 * 6);
  }
  .flex-sp-col7.gap41 > * {
    width: calc(14.2857142857% - 2.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap42 > * {
    width: calc(14.2857142857% - 2.625rem / 7 * 6);
  }
  .flex-sp-col7.gap43 > * {
    width: calc(14.2857142857% - 2.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap44 > * {
    width: calc(14.2857142857% - 2.75rem / 7 * 6);
  }
  .flex-sp-col7.gap45 > * {
    width: calc(14.2857142857% - 2.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap46 > * {
    width: calc(14.2857142857% - 2.875rem / 7 * 6);
  }
  .flex-sp-col7.gap47 > * {
    width: calc(14.2857142857% - 2.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap48 > * {
    width: calc(14.2857142857% - 3rem / 7 * 6);
  }
  .flex-sp-col7.gap49 > * {
    width: calc(14.2857142857% - 3.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap50 > * {
    width: calc(14.2857142857% - 3.125rem / 7 * 6);
  }
  .flex-sp-col7.gap51 > * {
    width: calc(14.2857142857% - 3.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap52 > * {
    width: calc(14.2857142857% - 3.25rem / 7 * 6);
  }
  .flex-sp-col7.gap53 > * {
    width: calc(14.2857142857% - 3.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap54 > * {
    width: calc(14.2857142857% - 3.375rem / 7 * 6);
  }
  .flex-sp-col7.gap55 > * {
    width: calc(14.2857142857% - 3.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap56 > * {
    width: calc(14.2857142857% - 3.5rem / 7 * 6);
  }
  .flex-sp-col7.gap57 > * {
    width: calc(14.2857142857% - 3.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap58 > * {
    width: calc(14.2857142857% - 3.625rem / 7 * 6);
  }
  .flex-sp-col7.gap59 > * {
    width: calc(14.2857142857% - 3.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap60 > * {
    width: calc(14.2857142857% - 3.75rem / 7 * 6);
  }
  .flex-sp-col7.gap61 > * {
    width: calc(14.2857142857% - 3.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap62 > * {
    width: calc(14.2857142857% - 3.875rem / 7 * 6);
  }
  .flex-sp-col7.gap63 > * {
    width: calc(14.2857142857% - 3.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap64 > * {
    width: calc(14.2857142857% - 4rem / 7 * 6);
  }
  .flex-sp-col7.gap65 > * {
    width: calc(14.2857142857% - 4.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap66 > * {
    width: calc(14.2857142857% - 4.125rem / 7 * 6);
  }
  .flex-sp-col7.gap67 > * {
    width: calc(14.2857142857% - 4.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap68 > * {
    width: calc(14.2857142857% - 4.25rem / 7 * 6);
  }
  .flex-sp-col7.gap69 > * {
    width: calc(14.2857142857% - 4.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap70 > * {
    width: calc(14.2857142857% - 4.375rem / 7 * 6);
  }
  .flex-sp-col7.gap71 > * {
    width: calc(14.2857142857% - 4.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap72 > * {
    width: calc(14.2857142857% - 4.5rem / 7 * 6);
  }
  .flex-sp-col7.gap73 > * {
    width: calc(14.2857142857% - 4.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap74 > * {
    width: calc(14.2857142857% - 4.625rem / 7 * 6);
  }
  .flex-sp-col7.gap75 > * {
    width: calc(14.2857142857% - 4.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap76 > * {
    width: calc(14.2857142857% - 4.75rem / 7 * 6);
  }
  .flex-sp-col7.gap77 > * {
    width: calc(14.2857142857% - 4.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap78 > * {
    width: calc(14.2857142857% - 4.875rem / 7 * 6);
  }
  .flex-sp-col7.gap79 > * {
    width: calc(14.2857142857% - 4.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap80 > * {
    width: calc(14.2857142857% - 5rem / 7 * 6);
  }
  .flex-sp-col7.gap81 > * {
    width: calc(14.2857142857% - 5.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap82 > * {
    width: calc(14.2857142857% - 5.125rem / 7 * 6);
  }
  .flex-sp-col7.gap83 > * {
    width: calc(14.2857142857% - 5.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap84 > * {
    width: calc(14.2857142857% - 5.25rem / 7 * 6);
  }
  .flex-sp-col7.gap85 > * {
    width: calc(14.2857142857% - 5.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap86 > * {
    width: calc(14.2857142857% - 5.375rem / 7 * 6);
  }
  .flex-sp-col7.gap87 > * {
    width: calc(14.2857142857% - 5.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap88 > * {
    width: calc(14.2857142857% - 5.5rem / 7 * 6);
  }
  .flex-sp-col7.gap89 > * {
    width: calc(14.2857142857% - 5.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap90 > * {
    width: calc(14.2857142857% - 5.625rem / 7 * 6);
  }
  .flex-sp-col7.gap91 > * {
    width: calc(14.2857142857% - 5.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap92 > * {
    width: calc(14.2857142857% - 5.75rem / 7 * 6);
  }
  .flex-sp-col7.gap93 > * {
    width: calc(14.2857142857% - 5.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap94 > * {
    width: calc(14.2857142857% - 5.875rem / 7 * 6);
  }
  .flex-sp-col7.gap95 > * {
    width: calc(14.2857142857% - 5.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap96 > * {
    width: calc(14.2857142857% - 6rem / 7 * 6);
  }
  .flex-sp-col7.gap97 > * {
    width: calc(14.2857142857% - 6.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap98 > * {
    width: calc(14.2857142857% - 6.125rem / 7 * 6);
  }
  .flex-sp-col7.gap99 > * {
    width: calc(14.2857142857% - 6.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap100 > * {
    width: calc(14.2857142857% - 6.25rem / 7 * 6);
  }
  .flex-sp-col7.gap101 > * {
    width: calc(14.2857142857% - 6.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap102 > * {
    width: calc(14.2857142857% - 6.375rem / 7 * 6);
  }
  .flex-sp-col7.gap103 > * {
    width: calc(14.2857142857% - 6.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap104 > * {
    width: calc(14.2857142857% - 6.5rem / 7 * 6);
  }
  .flex-sp-col7.gap105 > * {
    width: calc(14.2857142857% - 6.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap106 > * {
    width: calc(14.2857142857% - 6.625rem / 7 * 6);
  }
  .flex-sp-col7.gap107 > * {
    width: calc(14.2857142857% - 6.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap108 > * {
    width: calc(14.2857142857% - 6.75rem / 7 * 6);
  }
  .flex-sp-col7.gap109 > * {
    width: calc(14.2857142857% - 6.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap110 > * {
    width: calc(14.2857142857% - 6.875rem / 7 * 6);
  }
  .flex-sp-col7.gap111 > * {
    width: calc(14.2857142857% - 6.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap112 > * {
    width: calc(14.2857142857% - 7rem / 7 * 6);
  }
  .flex-sp-col7.gap113 > * {
    width: calc(14.2857142857% - 7.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap114 > * {
    width: calc(14.2857142857% - 7.125rem / 7 * 6);
  }
  .flex-sp-col7.gap115 > * {
    width: calc(14.2857142857% - 7.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap116 > * {
    width: calc(14.2857142857% - 7.25rem / 7 * 6);
  }
  .flex-sp-col7.gap117 > * {
    width: calc(14.2857142857% - 7.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap118 > * {
    width: calc(14.2857142857% - 7.375rem / 7 * 6);
  }
  .flex-sp-col7.gap119 > * {
    width: calc(14.2857142857% - 7.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap120 > * {
    width: calc(14.2857142857% - 7.5rem / 7 * 6);
  }
  .flex-sp-col7.gap121 > * {
    width: calc(14.2857142857% - 7.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap122 > * {
    width: calc(14.2857142857% - 7.625rem / 7 * 6);
  }
  .flex-sp-col7.gap123 > * {
    width: calc(14.2857142857% - 7.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap124 > * {
    width: calc(14.2857142857% - 7.75rem / 7 * 6);
  }
  .flex-sp-col7.gap125 > * {
    width: calc(14.2857142857% - 7.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap126 > * {
    width: calc(14.2857142857% - 7.875rem / 7 * 6);
  }
  .flex-sp-col7.gap127 > * {
    width: calc(14.2857142857% - 7.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap128 > * {
    width: calc(14.2857142857% - 8rem / 7 * 6);
  }
  .flex-sp-col7.gap129 > * {
    width: calc(14.2857142857% - 8.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap130 > * {
    width: calc(14.2857142857% - 8.125rem / 7 * 6);
  }
  .flex-sp-col7.gap131 > * {
    width: calc(14.2857142857% - 8.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap132 > * {
    width: calc(14.2857142857% - 8.25rem / 7 * 6);
  }
  .flex-sp-col7.gap133 > * {
    width: calc(14.2857142857% - 8.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap134 > * {
    width: calc(14.2857142857% - 8.375rem / 7 * 6);
  }
  .flex-sp-col7.gap135 > * {
    width: calc(14.2857142857% - 8.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap136 > * {
    width: calc(14.2857142857% - 8.5rem / 7 * 6);
  }
  .flex-sp-col7.gap137 > * {
    width: calc(14.2857142857% - 8.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap138 > * {
    width: calc(14.2857142857% - 8.625rem / 7 * 6);
  }
  .flex-sp-col7.gap139 > * {
    width: calc(14.2857142857% - 8.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap140 > * {
    width: calc(14.2857142857% - 8.75rem / 7 * 6);
  }
  .flex-sp-col7.gap141 > * {
    width: calc(14.2857142857% - 8.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap142 > * {
    width: calc(14.2857142857% - 8.875rem / 7 * 6);
  }
  .flex-sp-col7.gap143 > * {
    width: calc(14.2857142857% - 8.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap144 > * {
    width: calc(14.2857142857% - 9rem / 7 * 6);
  }
  .flex-sp-col7.gap145 > * {
    width: calc(14.2857142857% - 9.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap146 > * {
    width: calc(14.2857142857% - 9.125rem / 7 * 6);
  }
  .flex-sp-col7.gap147 > * {
    width: calc(14.2857142857% - 9.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap148 > * {
    width: calc(14.2857142857% - 9.25rem / 7 * 6);
  }
  .flex-sp-col7.gap149 > * {
    width: calc(14.2857142857% - 9.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap150 > * {
    width: calc(14.2857142857% - 9.375rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp1 > * {
    width: calc(14.2857142857% - 0.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp2 > * {
    width: calc(14.2857142857% - 0.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp3 > * {
    width: calc(14.2857142857% - 0.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp4 > * {
    width: calc(14.2857142857% - 0.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp5 > * {
    width: calc(14.2857142857% - 0.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp6 > * {
    width: calc(14.2857142857% - 0.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp7 > * {
    width: calc(14.2857142857% - 0.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp8 > * {
    width: calc(14.2857142857% - 0.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp9 > * {
    width: calc(14.2857142857% - 0.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp10 > * {
    width: calc(14.2857142857% - 0.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp11 > * {
    width: calc(14.2857142857% - 0.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp12 > * {
    width: calc(14.2857142857% - 0.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp13 > * {
    width: calc(14.2857142857% - 0.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp14 > * {
    width: calc(14.2857142857% - 1rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp15 > * {
    width: calc(14.2857142857% - 1.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp16 > * {
    width: calc(14.2857142857% - 1.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp17 > * {
    width: calc(14.2857142857% - 1.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp18 > * {
    width: calc(14.2857142857% - 1.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp19 > * {
    width: calc(14.2857142857% - 1.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp20 > * {
    width: calc(14.2857142857% - 1.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp21 > * {
    width: calc(14.2857142857% - 1.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp22 > * {
    width: calc(14.2857142857% - 1.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp23 > * {
    width: calc(14.2857142857% - 1.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp24 > * {
    width: calc(14.2857142857% - 1.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp25 > * {
    width: calc(14.2857142857% - 1.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp26 > * {
    width: calc(14.2857142857% - 1.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp27 > * {
    width: calc(14.2857142857% - 1.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp28 > * {
    width: calc(14.2857142857% - 2rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp29 > * {
    width: calc(14.2857142857% - 2.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp30 > * {
    width: calc(14.2857142857% - 2.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp31 > * {
    width: calc(14.2857142857% - 2.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp32 > * {
    width: calc(14.2857142857% - 2.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp33 > * {
    width: calc(14.2857142857% - 2.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp34 > * {
    width: calc(14.2857142857% - 2.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp35 > * {
    width: calc(14.2857142857% - 2.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp36 > * {
    width: calc(14.2857142857% - 2.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp37 > * {
    width: calc(14.2857142857% - 2.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp38 > * {
    width: calc(14.2857142857% - 2.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp39 > * {
    width: calc(14.2857142857% - 2.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp40 > * {
    width: calc(14.2857142857% - 2.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp41 > * {
    width: calc(14.2857142857% - 2.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp42 > * {
    width: calc(14.2857142857% - 3rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp43 > * {
    width: calc(14.2857142857% - 3.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp44 > * {
    width: calc(14.2857142857% - 3.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp45 > * {
    width: calc(14.2857142857% - 3.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp46 > * {
    width: calc(14.2857142857% - 3.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp47 > * {
    width: calc(14.2857142857% - 3.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp48 > * {
    width: calc(14.2857142857% - 3.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp49 > * {
    width: calc(14.2857142857% - 3.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp50 > * {
    width: calc(14.2857142857% - 3.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp51 > * {
    width: calc(14.2857142857% - 3.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp52 > * {
    width: calc(14.2857142857% - 3.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp53 > * {
    width: calc(14.2857142857% - 3.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp54 > * {
    width: calc(14.2857142857% - 3.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp55 > * {
    width: calc(14.2857142857% - 3.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp56 > * {
    width: calc(14.2857142857% - 4rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp57 > * {
    width: calc(14.2857142857% - 4.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp58 > * {
    width: calc(14.2857142857% - 4.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp59 > * {
    width: calc(14.2857142857% - 4.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp60 > * {
    width: calc(14.2857142857% - 4.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp61 > * {
    width: calc(14.2857142857% - 4.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp62 > * {
    width: calc(14.2857142857% - 4.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp63 > * {
    width: calc(14.2857142857% - 4.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp64 > * {
    width: calc(14.2857142857% - 4.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp65 > * {
    width: calc(14.2857142857% - 4.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp66 > * {
    width: calc(14.2857142857% - 4.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp67 > * {
    width: calc(14.2857142857% - 4.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp68 > * {
    width: calc(14.2857142857% - 4.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp69 > * {
    width: calc(14.2857142857% - 4.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp70 > * {
    width: calc(14.2857142857% - 5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp71 > * {
    width: calc(14.2857142857% - 5.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp72 > * {
    width: calc(14.2857142857% - 5.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp73 > * {
    width: calc(14.2857142857% - 5.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp74 > * {
    width: calc(14.2857142857% - 5.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp75 > * {
    width: calc(14.2857142857% - 5.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp76 > * {
    width: calc(14.2857142857% - 5.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp77 > * {
    width: calc(14.2857142857% - 5.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp78 > * {
    width: calc(14.2857142857% - 5.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp79 > * {
    width: calc(14.2857142857% - 5.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp80 > * {
    width: calc(14.2857142857% - 5.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp81 > * {
    width: calc(14.2857142857% - 5.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp82 > * {
    width: calc(14.2857142857% - 5.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp83 > * {
    width: calc(14.2857142857% - 5.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp84 > * {
    width: calc(14.2857142857% - 6rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp85 > * {
    width: calc(14.2857142857% - 6.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp86 > * {
    width: calc(14.2857142857% - 6.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp87 > * {
    width: calc(14.2857142857% - 6.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp88 > * {
    width: calc(14.2857142857% - 6.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp89 > * {
    width: calc(14.2857142857% - 6.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp90 > * {
    width: calc(14.2857142857% - 6.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp91 > * {
    width: calc(14.2857142857% - 6.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp92 > * {
    width: calc(14.2857142857% - 6.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp93 > * {
    width: calc(14.2857142857% - 6.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp94 > * {
    width: calc(14.2857142857% - 6.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp95 > * {
    width: calc(14.2857142857% - 6.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp96 > * {
    width: calc(14.2857142857% - 6.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp97 > * {
    width: calc(14.2857142857% - 6.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp98 > * {
    width: calc(14.2857142857% - 7rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp99 > * {
    width: calc(14.2857142857% - 7.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp100 > * {
    width: calc(14.2857142857% - 7.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp101 > * {
    width: calc(14.2857142857% - 7.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp102 > * {
    width: calc(14.2857142857% - 7.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp103 > * {
    width: calc(14.2857142857% - 7.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp104 > * {
    width: calc(14.2857142857% - 7.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp105 > * {
    width: calc(14.2857142857% - 7.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp106 > * {
    width: calc(14.2857142857% - 7.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp107 > * {
    width: calc(14.2857142857% - 7.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp108 > * {
    width: calc(14.2857142857% - 7.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp109 > * {
    width: calc(14.2857142857% - 7.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp110 > * {
    width: calc(14.2857142857% - 7.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp111 > * {
    width: calc(14.2857142857% - 7.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp112 > * {
    width: calc(14.2857142857% - 8rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp113 > * {
    width: calc(14.2857142857% - 8.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp114 > * {
    width: calc(14.2857142857% - 8.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp115 > * {
    width: calc(14.2857142857% - 8.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp116 > * {
    width: calc(14.2857142857% - 8.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp117 > * {
    width: calc(14.2857142857% - 8.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp118 > * {
    width: calc(14.2857142857% - 8.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp119 > * {
    width: calc(14.2857142857% - 8.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp120 > * {
    width: calc(14.2857142857% - 8.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp121 > * {
    width: calc(14.2857142857% - 8.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp122 > * {
    width: calc(14.2857142857% - 8.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp123 > * {
    width: calc(14.2857142857% - 8.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp124 > * {
    width: calc(14.2857142857% - 8.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp125 > * {
    width: calc(14.2857142857% - 8.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp126 > * {
    width: calc(14.2857142857% - 9rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp127 > * {
    width: calc(14.2857142857% - 9.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp128 > * {
    width: calc(14.2857142857% - 9.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp129 > * {
    width: calc(14.2857142857% - 9.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp130 > * {
    width: calc(14.2857142857% - 9.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp131 > * {
    width: calc(14.2857142857% - 9.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp132 > * {
    width: calc(14.2857142857% - 9.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp133 > * {
    width: calc(14.2857142857% - 9.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp134 > * {
    width: calc(14.2857142857% - 9.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp135 > * {
    width: calc(14.2857142857% - 9.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp136 > * {
    width: calc(14.2857142857% - 9.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp137 > * {
    width: calc(14.2857142857% - 9.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp138 > * {
    width: calc(14.2857142857% - 9.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp139 > * {
    width: calc(14.2857142857% - 9.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp140 > * {
    width: calc(14.2857142857% - 10rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp141 > * {
    width: calc(14.2857142857% - 10.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp142 > * {
    width: calc(14.2857142857% - 10.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp143 > * {
    width: calc(14.2857142857% - 10.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp144 > * {
    width: calc(14.2857142857% - 10.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp145 > * {
    width: calc(14.2857142857% - 10.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp146 > * {
    width: calc(14.2857142857% - 10.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp147 > * {
    width: calc(14.2857142857% - 10.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp148 > * {
    width: calc(14.2857142857% - 10.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp149 > * {
    width: calc(14.2857142857% - 10.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp150 > * {
    width: calc(14.2857142857% - 10.7142857143rem / 7 * 6);
  }
  .flex-sp-col8 {
    flex-wrap: wrap;
  }
  .flex-sp-col8 > * {
    width: 12.5%;
  }
  .flex-sp-col8.gap1 > * {
    width: calc(12.5% - 0.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap2 > * {
    width: calc(12.5% - 0.125rem / 8 * 7);
  }
  .flex-sp-col8.gap3 > * {
    width: calc(12.5% - 0.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap4 > * {
    width: calc(12.5% - 0.25rem / 8 * 7);
  }
  .flex-sp-col8.gap5 > * {
    width: calc(12.5% - 0.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap6 > * {
    width: calc(12.5% - 0.375rem / 8 * 7);
  }
  .flex-sp-col8.gap7 > * {
    width: calc(12.5% - 0.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap8 > * {
    width: calc(12.5% - 0.5rem / 8 * 7);
  }
  .flex-sp-col8.gap9 > * {
    width: calc(12.5% - 0.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap10 > * {
    width: calc(12.5% - 0.625rem / 8 * 7);
  }
  .flex-sp-col8.gap11 > * {
    width: calc(12.5% - 0.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap12 > * {
    width: calc(12.5% - 0.75rem / 8 * 7);
  }
  .flex-sp-col8.gap13 > * {
    width: calc(12.5% - 0.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap14 > * {
    width: calc(12.5% - 0.875rem / 8 * 7);
  }
  .flex-sp-col8.gap15 > * {
    width: calc(12.5% - 0.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap16 > * {
    width: calc(12.5% - 1rem / 8 * 7);
  }
  .flex-sp-col8.gap17 > * {
    width: calc(12.5% - 1.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap18 > * {
    width: calc(12.5% - 1.125rem / 8 * 7);
  }
  .flex-sp-col8.gap19 > * {
    width: calc(12.5% - 1.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap20 > * {
    width: calc(12.5% - 1.25rem / 8 * 7);
  }
  .flex-sp-col8.gap21 > * {
    width: calc(12.5% - 1.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap22 > * {
    width: calc(12.5% - 1.375rem / 8 * 7);
  }
  .flex-sp-col8.gap23 > * {
    width: calc(12.5% - 1.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap24 > * {
    width: calc(12.5% - 1.5rem / 8 * 7);
  }
  .flex-sp-col8.gap25 > * {
    width: calc(12.5% - 1.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap26 > * {
    width: calc(12.5% - 1.625rem / 8 * 7);
  }
  .flex-sp-col8.gap27 > * {
    width: calc(12.5% - 1.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap28 > * {
    width: calc(12.5% - 1.75rem / 8 * 7);
  }
  .flex-sp-col8.gap29 > * {
    width: calc(12.5% - 1.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap30 > * {
    width: calc(12.5% - 1.875rem / 8 * 7);
  }
  .flex-sp-col8.gap31 > * {
    width: calc(12.5% - 1.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap32 > * {
    width: calc(12.5% - 2rem / 8 * 7);
  }
  .flex-sp-col8.gap33 > * {
    width: calc(12.5% - 2.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap34 > * {
    width: calc(12.5% - 2.125rem / 8 * 7);
  }
  .flex-sp-col8.gap35 > * {
    width: calc(12.5% - 2.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap36 > * {
    width: calc(12.5% - 2.25rem / 8 * 7);
  }
  .flex-sp-col8.gap37 > * {
    width: calc(12.5% - 2.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap38 > * {
    width: calc(12.5% - 2.375rem / 8 * 7);
  }
  .flex-sp-col8.gap39 > * {
    width: calc(12.5% - 2.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap40 > * {
    width: calc(12.5% - 2.5rem / 8 * 7);
  }
  .flex-sp-col8.gap41 > * {
    width: calc(12.5% - 2.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap42 > * {
    width: calc(12.5% - 2.625rem / 8 * 7);
  }
  .flex-sp-col8.gap43 > * {
    width: calc(12.5% - 2.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap44 > * {
    width: calc(12.5% - 2.75rem / 8 * 7);
  }
  .flex-sp-col8.gap45 > * {
    width: calc(12.5% - 2.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap46 > * {
    width: calc(12.5% - 2.875rem / 8 * 7);
  }
  .flex-sp-col8.gap47 > * {
    width: calc(12.5% - 2.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap48 > * {
    width: calc(12.5% - 3rem / 8 * 7);
  }
  .flex-sp-col8.gap49 > * {
    width: calc(12.5% - 3.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap50 > * {
    width: calc(12.5% - 3.125rem / 8 * 7);
  }
  .flex-sp-col8.gap51 > * {
    width: calc(12.5% - 3.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap52 > * {
    width: calc(12.5% - 3.25rem / 8 * 7);
  }
  .flex-sp-col8.gap53 > * {
    width: calc(12.5% - 3.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap54 > * {
    width: calc(12.5% - 3.375rem / 8 * 7);
  }
  .flex-sp-col8.gap55 > * {
    width: calc(12.5% - 3.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap56 > * {
    width: calc(12.5% - 3.5rem / 8 * 7);
  }
  .flex-sp-col8.gap57 > * {
    width: calc(12.5% - 3.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap58 > * {
    width: calc(12.5% - 3.625rem / 8 * 7);
  }
  .flex-sp-col8.gap59 > * {
    width: calc(12.5% - 3.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap60 > * {
    width: calc(12.5% - 3.75rem / 8 * 7);
  }
  .flex-sp-col8.gap61 > * {
    width: calc(12.5% - 3.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap62 > * {
    width: calc(12.5% - 3.875rem / 8 * 7);
  }
  .flex-sp-col8.gap63 > * {
    width: calc(12.5% - 3.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap64 > * {
    width: calc(12.5% - 4rem / 8 * 7);
  }
  .flex-sp-col8.gap65 > * {
    width: calc(12.5% - 4.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap66 > * {
    width: calc(12.5% - 4.125rem / 8 * 7);
  }
  .flex-sp-col8.gap67 > * {
    width: calc(12.5% - 4.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap68 > * {
    width: calc(12.5% - 4.25rem / 8 * 7);
  }
  .flex-sp-col8.gap69 > * {
    width: calc(12.5% - 4.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap70 > * {
    width: calc(12.5% - 4.375rem / 8 * 7);
  }
  .flex-sp-col8.gap71 > * {
    width: calc(12.5% - 4.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap72 > * {
    width: calc(12.5% - 4.5rem / 8 * 7);
  }
  .flex-sp-col8.gap73 > * {
    width: calc(12.5% - 4.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap74 > * {
    width: calc(12.5% - 4.625rem / 8 * 7);
  }
  .flex-sp-col8.gap75 > * {
    width: calc(12.5% - 4.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap76 > * {
    width: calc(12.5% - 4.75rem / 8 * 7);
  }
  .flex-sp-col8.gap77 > * {
    width: calc(12.5% - 4.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap78 > * {
    width: calc(12.5% - 4.875rem / 8 * 7);
  }
  .flex-sp-col8.gap79 > * {
    width: calc(12.5% - 4.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap80 > * {
    width: calc(12.5% - 5rem / 8 * 7);
  }
  .flex-sp-col8.gap81 > * {
    width: calc(12.5% - 5.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap82 > * {
    width: calc(12.5% - 5.125rem / 8 * 7);
  }
  .flex-sp-col8.gap83 > * {
    width: calc(12.5% - 5.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap84 > * {
    width: calc(12.5% - 5.25rem / 8 * 7);
  }
  .flex-sp-col8.gap85 > * {
    width: calc(12.5% - 5.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap86 > * {
    width: calc(12.5% - 5.375rem / 8 * 7);
  }
  .flex-sp-col8.gap87 > * {
    width: calc(12.5% - 5.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap88 > * {
    width: calc(12.5% - 5.5rem / 8 * 7);
  }
  .flex-sp-col8.gap89 > * {
    width: calc(12.5% - 5.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap90 > * {
    width: calc(12.5% - 5.625rem / 8 * 7);
  }
  .flex-sp-col8.gap91 > * {
    width: calc(12.5% - 5.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap92 > * {
    width: calc(12.5% - 5.75rem / 8 * 7);
  }
  .flex-sp-col8.gap93 > * {
    width: calc(12.5% - 5.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap94 > * {
    width: calc(12.5% - 5.875rem / 8 * 7);
  }
  .flex-sp-col8.gap95 > * {
    width: calc(12.5% - 5.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap96 > * {
    width: calc(12.5% - 6rem / 8 * 7);
  }
  .flex-sp-col8.gap97 > * {
    width: calc(12.5% - 6.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap98 > * {
    width: calc(12.5% - 6.125rem / 8 * 7);
  }
  .flex-sp-col8.gap99 > * {
    width: calc(12.5% - 6.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap100 > * {
    width: calc(12.5% - 6.25rem / 8 * 7);
  }
  .flex-sp-col8.gap101 > * {
    width: calc(12.5% - 6.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap102 > * {
    width: calc(12.5% - 6.375rem / 8 * 7);
  }
  .flex-sp-col8.gap103 > * {
    width: calc(12.5% - 6.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap104 > * {
    width: calc(12.5% - 6.5rem / 8 * 7);
  }
  .flex-sp-col8.gap105 > * {
    width: calc(12.5% - 6.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap106 > * {
    width: calc(12.5% - 6.625rem / 8 * 7);
  }
  .flex-sp-col8.gap107 > * {
    width: calc(12.5% - 6.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap108 > * {
    width: calc(12.5% - 6.75rem / 8 * 7);
  }
  .flex-sp-col8.gap109 > * {
    width: calc(12.5% - 6.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap110 > * {
    width: calc(12.5% - 6.875rem / 8 * 7);
  }
  .flex-sp-col8.gap111 > * {
    width: calc(12.5% - 6.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap112 > * {
    width: calc(12.5% - 7rem / 8 * 7);
  }
  .flex-sp-col8.gap113 > * {
    width: calc(12.5% - 7.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap114 > * {
    width: calc(12.5% - 7.125rem / 8 * 7);
  }
  .flex-sp-col8.gap115 > * {
    width: calc(12.5% - 7.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap116 > * {
    width: calc(12.5% - 7.25rem / 8 * 7);
  }
  .flex-sp-col8.gap117 > * {
    width: calc(12.5% - 7.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap118 > * {
    width: calc(12.5% - 7.375rem / 8 * 7);
  }
  .flex-sp-col8.gap119 > * {
    width: calc(12.5% - 7.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap120 > * {
    width: calc(12.5% - 7.5rem / 8 * 7);
  }
  .flex-sp-col8.gap121 > * {
    width: calc(12.5% - 7.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap122 > * {
    width: calc(12.5% - 7.625rem / 8 * 7);
  }
  .flex-sp-col8.gap123 > * {
    width: calc(12.5% - 7.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap124 > * {
    width: calc(12.5% - 7.75rem / 8 * 7);
  }
  .flex-sp-col8.gap125 > * {
    width: calc(12.5% - 7.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap126 > * {
    width: calc(12.5% - 7.875rem / 8 * 7);
  }
  .flex-sp-col8.gap127 > * {
    width: calc(12.5% - 7.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap128 > * {
    width: calc(12.5% - 8rem / 8 * 7);
  }
  .flex-sp-col8.gap129 > * {
    width: calc(12.5% - 8.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap130 > * {
    width: calc(12.5% - 8.125rem / 8 * 7);
  }
  .flex-sp-col8.gap131 > * {
    width: calc(12.5% - 8.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap132 > * {
    width: calc(12.5% - 8.25rem / 8 * 7);
  }
  .flex-sp-col8.gap133 > * {
    width: calc(12.5% - 8.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap134 > * {
    width: calc(12.5% - 8.375rem / 8 * 7);
  }
  .flex-sp-col8.gap135 > * {
    width: calc(12.5% - 8.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap136 > * {
    width: calc(12.5% - 8.5rem / 8 * 7);
  }
  .flex-sp-col8.gap137 > * {
    width: calc(12.5% - 8.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap138 > * {
    width: calc(12.5% - 8.625rem / 8 * 7);
  }
  .flex-sp-col8.gap139 > * {
    width: calc(12.5% - 8.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap140 > * {
    width: calc(12.5% - 8.75rem / 8 * 7);
  }
  .flex-sp-col8.gap141 > * {
    width: calc(12.5% - 8.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap142 > * {
    width: calc(12.5% - 8.875rem / 8 * 7);
  }
  .flex-sp-col8.gap143 > * {
    width: calc(12.5% - 8.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap144 > * {
    width: calc(12.5% - 9rem / 8 * 7);
  }
  .flex-sp-col8.gap145 > * {
    width: calc(12.5% - 9.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap146 > * {
    width: calc(12.5% - 9.125rem / 8 * 7);
  }
  .flex-sp-col8.gap147 > * {
    width: calc(12.5% - 9.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap148 > * {
    width: calc(12.5% - 9.25rem / 8 * 7);
  }
  .flex-sp-col8.gap149 > * {
    width: calc(12.5% - 9.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap150 > * {
    width: calc(12.5% - 9.375rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp1 > * {
    width: calc(12.5% - 0.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp2 > * {
    width: calc(12.5% - 0.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp3 > * {
    width: calc(12.5% - 0.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp4 > * {
    width: calc(12.5% - 0.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp5 > * {
    width: calc(12.5% - 0.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp6 > * {
    width: calc(12.5% - 0.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp7 > * {
    width: calc(12.5% - 0.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp8 > * {
    width: calc(12.5% - 0.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp9 > * {
    width: calc(12.5% - 0.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp10 > * {
    width: calc(12.5% - 0.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp11 > * {
    width: calc(12.5% - 0.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp12 > * {
    width: calc(12.5% - 0.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp13 > * {
    width: calc(12.5% - 0.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp14 > * {
    width: calc(12.5% - 1rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp15 > * {
    width: calc(12.5% - 1.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp16 > * {
    width: calc(12.5% - 1.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp17 > * {
    width: calc(12.5% - 1.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp18 > * {
    width: calc(12.5% - 1.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp19 > * {
    width: calc(12.5% - 1.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp20 > * {
    width: calc(12.5% - 1.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp21 > * {
    width: calc(12.5% - 1.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp22 > * {
    width: calc(12.5% - 1.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp23 > * {
    width: calc(12.5% - 1.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp24 > * {
    width: calc(12.5% - 1.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp25 > * {
    width: calc(12.5% - 1.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp26 > * {
    width: calc(12.5% - 1.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp27 > * {
    width: calc(12.5% - 1.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp28 > * {
    width: calc(12.5% - 2rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp29 > * {
    width: calc(12.5% - 2.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp30 > * {
    width: calc(12.5% - 2.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp31 > * {
    width: calc(12.5% - 2.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp32 > * {
    width: calc(12.5% - 2.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp33 > * {
    width: calc(12.5% - 2.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp34 > * {
    width: calc(12.5% - 2.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp35 > * {
    width: calc(12.5% - 2.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp36 > * {
    width: calc(12.5% - 2.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp37 > * {
    width: calc(12.5% - 2.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp38 > * {
    width: calc(12.5% - 2.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp39 > * {
    width: calc(12.5% - 2.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp40 > * {
    width: calc(12.5% - 2.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp41 > * {
    width: calc(12.5% - 2.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp42 > * {
    width: calc(12.5% - 3rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp43 > * {
    width: calc(12.5% - 3.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp44 > * {
    width: calc(12.5% - 3.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp45 > * {
    width: calc(12.5% - 3.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp46 > * {
    width: calc(12.5% - 3.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp47 > * {
    width: calc(12.5% - 3.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp48 > * {
    width: calc(12.5% - 3.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp49 > * {
    width: calc(12.5% - 3.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp50 > * {
    width: calc(12.5% - 3.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp51 > * {
    width: calc(12.5% - 3.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp52 > * {
    width: calc(12.5% - 3.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp53 > * {
    width: calc(12.5% - 3.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp54 > * {
    width: calc(12.5% - 3.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp55 > * {
    width: calc(12.5% - 3.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp56 > * {
    width: calc(12.5% - 4rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp57 > * {
    width: calc(12.5% - 4.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp58 > * {
    width: calc(12.5% - 4.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp59 > * {
    width: calc(12.5% - 4.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp60 > * {
    width: calc(12.5% - 4.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp61 > * {
    width: calc(12.5% - 4.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp62 > * {
    width: calc(12.5% - 4.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp63 > * {
    width: calc(12.5% - 4.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp64 > * {
    width: calc(12.5% - 4.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp65 > * {
    width: calc(12.5% - 4.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp66 > * {
    width: calc(12.5% - 4.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp67 > * {
    width: calc(12.5% - 4.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp68 > * {
    width: calc(12.5% - 4.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp69 > * {
    width: calc(12.5% - 4.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp70 > * {
    width: calc(12.5% - 5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp71 > * {
    width: calc(12.5% - 5.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp72 > * {
    width: calc(12.5% - 5.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp73 > * {
    width: calc(12.5% - 5.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp74 > * {
    width: calc(12.5% - 5.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp75 > * {
    width: calc(12.5% - 5.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp76 > * {
    width: calc(12.5% - 5.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp77 > * {
    width: calc(12.5% - 5.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp78 > * {
    width: calc(12.5% - 5.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp79 > * {
    width: calc(12.5% - 5.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp80 > * {
    width: calc(12.5% - 5.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp81 > * {
    width: calc(12.5% - 5.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp82 > * {
    width: calc(12.5% - 5.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp83 > * {
    width: calc(12.5% - 5.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp84 > * {
    width: calc(12.5% - 6rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp85 > * {
    width: calc(12.5% - 6.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp86 > * {
    width: calc(12.5% - 6.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp87 > * {
    width: calc(12.5% - 6.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp88 > * {
    width: calc(12.5% - 6.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp89 > * {
    width: calc(12.5% - 6.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp90 > * {
    width: calc(12.5% - 6.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp91 > * {
    width: calc(12.5% - 6.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp92 > * {
    width: calc(12.5% - 6.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp93 > * {
    width: calc(12.5% - 6.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp94 > * {
    width: calc(12.5% - 6.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp95 > * {
    width: calc(12.5% - 6.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp96 > * {
    width: calc(12.5% - 6.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp97 > * {
    width: calc(12.5% - 6.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp98 > * {
    width: calc(12.5% - 7rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp99 > * {
    width: calc(12.5% - 7.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp100 > * {
    width: calc(12.5% - 7.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp101 > * {
    width: calc(12.5% - 7.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp102 > * {
    width: calc(12.5% - 7.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp103 > * {
    width: calc(12.5% - 7.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp104 > * {
    width: calc(12.5% - 7.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp105 > * {
    width: calc(12.5% - 7.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp106 > * {
    width: calc(12.5% - 7.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp107 > * {
    width: calc(12.5% - 7.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp108 > * {
    width: calc(12.5% - 7.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp109 > * {
    width: calc(12.5% - 7.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp110 > * {
    width: calc(12.5% - 7.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp111 > * {
    width: calc(12.5% - 7.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp112 > * {
    width: calc(12.5% - 8rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp113 > * {
    width: calc(12.5% - 8.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp114 > * {
    width: calc(12.5% - 8.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp115 > * {
    width: calc(12.5% - 8.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp116 > * {
    width: calc(12.5% - 8.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp117 > * {
    width: calc(12.5% - 8.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp118 > * {
    width: calc(12.5% - 8.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp119 > * {
    width: calc(12.5% - 8.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp120 > * {
    width: calc(12.5% - 8.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp121 > * {
    width: calc(12.5% - 8.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp122 > * {
    width: calc(12.5% - 8.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp123 > * {
    width: calc(12.5% - 8.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp124 > * {
    width: calc(12.5% - 8.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp125 > * {
    width: calc(12.5% - 8.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp126 > * {
    width: calc(12.5% - 9rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp127 > * {
    width: calc(12.5% - 9.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp128 > * {
    width: calc(12.5% - 9.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp129 > * {
    width: calc(12.5% - 9.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp130 > * {
    width: calc(12.5% - 9.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp131 > * {
    width: calc(12.5% - 9.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp132 > * {
    width: calc(12.5% - 9.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp133 > * {
    width: calc(12.5% - 9.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp134 > * {
    width: calc(12.5% - 9.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp135 > * {
    width: calc(12.5% - 9.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp136 > * {
    width: calc(12.5% - 9.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp137 > * {
    width: calc(12.5% - 9.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp138 > * {
    width: calc(12.5% - 9.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp139 > * {
    width: calc(12.5% - 9.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp140 > * {
    width: calc(12.5% - 10rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp141 > * {
    width: calc(12.5% - 10.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp142 > * {
    width: calc(12.5% - 10.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp143 > * {
    width: calc(12.5% - 10.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp144 > * {
    width: calc(12.5% - 10.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp145 > * {
    width: calc(12.5% - 10.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp146 > * {
    width: calc(12.5% - 10.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp147 > * {
    width: calc(12.5% - 10.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp148 > * {
    width: calc(12.5% - 10.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp149 > * {
    width: calc(12.5% - 10.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp150 > * {
    width: calc(12.5% - 10.7142857143rem / 8 * 7);
  }
  .flex-sp-block[class*=gap] {
    gap: 1.7142857143rem;
  }
  .flex-sp-block > * {
    width: 100% !important;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
.foot_entry {
  padding-top: 8.5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .foot_entry {
    padding-top: 5rem;
    padding-bottom: 4.5714285714rem;
  }
}

.foot_entry--ttl {
  margin-bottom: 1.5rem;
  font-size: 4.375rem;
  letter-spacing: 0.13125rem;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .foot_entry--ttl {
    font-size: 12vw;
  }
}
.foot_entry--ttl::before {
  content: "";
  display: block;
  width: 3.25rem;
  height: 1rem;
  background-size: cover;
  background-image: url(../images/common/wave.svg);
  margin-bottom: 1rem;
}
.foot_entry--ttl span {
  background: linear-gradient(transparent 60%, rgba(100, 173, 235, 0.2) 60%);
}

.foot_entry--lead {
  width: 27.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .foot_entry--lead {
    width: 100%;
    font-size: 1.2857142857rem;
  }
}

.foot_entry--link {
  text-indent: -0.49375rem;
}

.foot_entry--link a {
  position: relative;
  font-size: 19vw;
  letter-spacing: 0.49375rem;
  padding-right: 5rem;
  line-height: 1;
  color: #F0F0F0;
}
@media screen and (max-width: 47.9375em) {
  .foot_entry--link a {
    font-size: 28vw;
  }
}
.foot_entry--link a::after {
  content: "";
  position: absolute;
  top: 0;
  right: -2.75rem;
  bottom: 0;
  height: 3.625rem;
  margin: auto 0;
  border: 2.75rem solid transparent;
  border-left: 3.625rem solid;
}

.foot_entry .imgarea {
  flex: 1;
  text-align: right;
  margin-left: -9%;
  margin-right: -16%;
}
@media screen and (max-width: 47.9375em) {
  .foot_entry .imgarea {
    margin-top: 2.8571428571rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.foot_entry .imgarea video {
  width: 100% !important;
  height: auto;
}

.footer {
  background-color: var(--color-primary-default);
}

/* footer_top
********************************************** */
.footer_top {
  padding-top: 7.75rem;
}
@media screen and (max-width: 47.9375em) {
  .footer_top {
    padding-top: 4.5714285714rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .footer_top--info {
    text-align: center;
    margin-bottom: 1.1428571429rem;
  }
}

/* ---fnav --- */
.fnav {
  flex: 1;
}

.fnav--menu {
  border-left: 1px solid #fff;
  padding-left: 2rem;
  margin-left: 4.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .fnav--menu:nth-child(1) > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

@media screen and (max-width: 47.9375em) {
  .fnav--menu:nth-child(2) {
    margin-bottom: 1.1428571429rem;
  }
  .fnav--menu:nth-child(2) > li:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.fnav--menu a {
  display: block;
  padding: 0.5em 1em;
  color: #fff;
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu a {
    position: relative;
    padding: 0.5em;
    font-size: 1.1428571429rem;
  }
}
.fnav--menu a:hover {
  opacity: 0.7;
}
.fnav--menu a .font-en {
  letter-spacing: 0.027em;
  font-family: "Antonio", sans-serif;
}

.fnav--menu .sub-menu li a {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu .sub-menu li a {
    font-size: 1rem;
  }
}
.fnav--menu .sub-menu li a::before {
  content: "…";
  margin-right: 0.25rem;
}

.page_top {
  width: 6.25rem;
  height: 6.25rem;
  margin-left: auto;
}
@media screen and (max-width: 47.9375em) {
  .page_top {
    display: none;
  }
}

.page_top a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border-radius: 50%;
  border: 1px solid;
  color: #fff;
  text-align: center;
  padding: 1.25rem;
}
.page_top a .font-en {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  width: 100%;
  border-top: 1px dashed #fff;
  margin-right: -0.25rem;
  font-family: "Antonio", sans-serif;
  letter-spacing: 0.25rem;
  line-height: 1;
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
.sns_area a:hover {
  opacity: 0.7;
}

.sns_area svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #fff;
}
.sns_area svg.icon-x {
  width: 1.375rem;
  height: 1.375rem;
}

/* footer_btm
*************************************************** */
.footer_btm > .inner {
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #fff;
}

@media screen and (max-width: 47.9375em) {
  .footer_btm--nav {
    text-align: center;
    margin-bottom: 1.1428571429rem;
  }
}

.footer_btm--nav li:not(:last-child) {
  margin-right: 1.5rem;
}

.footer_btm--nav a {
  color: #fff;
  font-size: 0.875rem;
}
.footer_btm--nav a:hover {
  opacity: 0.7;
}
.footer_btm--nav a svg {
  fill: #fff;
  margin-left: 0.5rem;
  vertical-align: middle;
  transform: translateY(-2px);
}

.copyright {
  gap: 1em;
  justify-content: center;
}
@media screen and (max-width: 47.9375em) {
  .copyright {
    gap: 0;
    text-align: center;
  }
}

.pbl a {
  opacity: 0.5;
}
.pbl a:hover {
  opacity: 1;
}

.footer_under {
  position: relative;
}
.footer_under .txt_slide_deco {
  opacity: 0.2;
}
.footer_under p {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  text-align: center;
}

/* ##############################################################################

    スタンダード用

############################################################################## */
/* footer-simple
*************************************************** */
.footer-simple {
  padding: 0.5rem 0;
}

@media print, screen and (min-width: 48em) {
  .footer-simple:has(.privacy) > .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

[class*=grid-] {
  display: grid;
}

.grid-col1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-col2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-col3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-col4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-col5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-col6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-col7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-col8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-rgt1 {
  grid-template-columns: minmax(0, 1fr) 0.0625rem;
}

.grid-rgt2 {
  grid-template-columns: minmax(0, 1fr) 0.125rem;
}

.grid-rgt3 {
  grid-template-columns: minmax(0, 1fr) 0.1875rem;
}

.grid-rgt4 {
  grid-template-columns: minmax(0, 1fr) 0.25rem;
}

.grid-rgt5 {
  grid-template-columns: minmax(0, 1fr) 0.3125rem;
}

.grid-rgt6 {
  grid-template-columns: minmax(0, 1fr) 0.375rem;
}

.grid-rgt7 {
  grid-template-columns: minmax(0, 1fr) 0.4375rem;
}

.grid-rgt8 {
  grid-template-columns: minmax(0, 1fr) 0.5rem;
}

.grid-rgt9 {
  grid-template-columns: minmax(0, 1fr) 0.5625rem;
}

.grid-rgt10 {
  grid-template-columns: minmax(0, 1fr) 0.625rem;
}

.grid-rgt11 {
  grid-template-columns: minmax(0, 1fr) 0.6875rem;
}

.grid-rgt12 {
  grid-template-columns: minmax(0, 1fr) 0.75rem;
}

.grid-rgt13 {
  grid-template-columns: minmax(0, 1fr) 0.8125rem;
}

.grid-rgt14 {
  grid-template-columns: minmax(0, 1fr) 0.875rem;
}

.grid-rgt15 {
  grid-template-columns: minmax(0, 1fr) 0.9375rem;
}

.grid-rgt16 {
  grid-template-columns: minmax(0, 1fr) 1rem;
}

.grid-rgt17 {
  grid-template-columns: minmax(0, 1fr) 1.0625rem;
}

.grid-rgt18 {
  grid-template-columns: minmax(0, 1fr) 1.125rem;
}

.grid-rgt19 {
  grid-template-columns: minmax(0, 1fr) 1.1875rem;
}

.grid-rgt20 {
  grid-template-columns: minmax(0, 1fr) 1.25rem;
}

.grid-rgt21 {
  grid-template-columns: minmax(0, 1fr) 1.3125rem;
}

.grid-rgt22 {
  grid-template-columns: minmax(0, 1fr) 1.375rem;
}

.grid-rgt23 {
  grid-template-columns: minmax(0, 1fr) 1.4375rem;
}

.grid-rgt24 {
  grid-template-columns: minmax(0, 1fr) 1.5rem;
}

.grid-rgt25 {
  grid-template-columns: minmax(0, 1fr) 1.5625rem;
}

.grid-rgt26 {
  grid-template-columns: minmax(0, 1fr) 1.625rem;
}

.grid-rgt27 {
  grid-template-columns: minmax(0, 1fr) 1.6875rem;
}

.grid-rgt28 {
  grid-template-columns: minmax(0, 1fr) 1.75rem;
}

.grid-rgt29 {
  grid-template-columns: minmax(0, 1fr) 1.8125rem;
}

.grid-rgt30 {
  grid-template-columns: minmax(0, 1fr) 1.875rem;
}

.grid-rgt31 {
  grid-template-columns: minmax(0, 1fr) 1.9375rem;
}

.grid-rgt32 {
  grid-template-columns: minmax(0, 1fr) 2rem;
}

.grid-rgt33 {
  grid-template-columns: minmax(0, 1fr) 2.0625rem;
}

.grid-rgt34 {
  grid-template-columns: minmax(0, 1fr) 2.125rem;
}

.grid-rgt35 {
  grid-template-columns: minmax(0, 1fr) 2.1875rem;
}

.grid-rgt36 {
  grid-template-columns: minmax(0, 1fr) 2.25rem;
}

.grid-rgt37 {
  grid-template-columns: minmax(0, 1fr) 2.3125rem;
}

.grid-rgt38 {
  grid-template-columns: minmax(0, 1fr) 2.375rem;
}

.grid-rgt39 {
  grid-template-columns: minmax(0, 1fr) 2.4375rem;
}

.grid-rgt40 {
  grid-template-columns: minmax(0, 1fr) 2.5rem;
}

.grid-rgt41 {
  grid-template-columns: minmax(0, 1fr) 2.5625rem;
}

.grid-rgt42 {
  grid-template-columns: minmax(0, 1fr) 2.625rem;
}

.grid-rgt43 {
  grid-template-columns: minmax(0, 1fr) 2.6875rem;
}

.grid-rgt44 {
  grid-template-columns: minmax(0, 1fr) 2.75rem;
}

.grid-rgt45 {
  grid-template-columns: minmax(0, 1fr) 2.8125rem;
}

.grid-rgt46 {
  grid-template-columns: minmax(0, 1fr) 2.875rem;
}

.grid-rgt47 {
  grid-template-columns: minmax(0, 1fr) 2.9375rem;
}

.grid-rgt48 {
  grid-template-columns: minmax(0, 1fr) 3rem;
}

.grid-rgt49 {
  grid-template-columns: minmax(0, 1fr) 3.0625rem;
}

.grid-rgt50 {
  grid-template-columns: minmax(0, 1fr) 3.125rem;
}

.grid-rgt51 {
  grid-template-columns: minmax(0, 1fr) 3.1875rem;
}

.grid-rgt52 {
  grid-template-columns: minmax(0, 1fr) 3.25rem;
}

.grid-rgt53 {
  grid-template-columns: minmax(0, 1fr) 3.3125rem;
}

.grid-rgt54 {
  grid-template-columns: minmax(0, 1fr) 3.375rem;
}

.grid-rgt55 {
  grid-template-columns: minmax(0, 1fr) 3.4375rem;
}

.grid-rgt56 {
  grid-template-columns: minmax(0, 1fr) 3.5rem;
}

.grid-rgt57 {
  grid-template-columns: minmax(0, 1fr) 3.5625rem;
}

.grid-rgt58 {
  grid-template-columns: minmax(0, 1fr) 3.625rem;
}

.grid-rgt59 {
  grid-template-columns: minmax(0, 1fr) 3.6875rem;
}

.grid-rgt60 {
  grid-template-columns: minmax(0, 1fr) 3.75rem;
}

.grid-rgt61 {
  grid-template-columns: minmax(0, 1fr) 3.8125rem;
}

.grid-rgt62 {
  grid-template-columns: minmax(0, 1fr) 3.875rem;
}

.grid-rgt63 {
  grid-template-columns: minmax(0, 1fr) 3.9375rem;
}

.grid-rgt64 {
  grid-template-columns: minmax(0, 1fr) 4rem;
}

.grid-rgt65 {
  grid-template-columns: minmax(0, 1fr) 4.0625rem;
}

.grid-rgt66 {
  grid-template-columns: minmax(0, 1fr) 4.125rem;
}

.grid-rgt67 {
  grid-template-columns: minmax(0, 1fr) 4.1875rem;
}

.grid-rgt68 {
  grid-template-columns: minmax(0, 1fr) 4.25rem;
}

.grid-rgt69 {
  grid-template-columns: minmax(0, 1fr) 4.3125rem;
}

.grid-rgt70 {
  grid-template-columns: minmax(0, 1fr) 4.375rem;
}

.grid-rgt71 {
  grid-template-columns: minmax(0, 1fr) 4.4375rem;
}

.grid-rgt72 {
  grid-template-columns: minmax(0, 1fr) 4.5rem;
}

.grid-rgt73 {
  grid-template-columns: minmax(0, 1fr) 4.5625rem;
}

.grid-rgt74 {
  grid-template-columns: minmax(0, 1fr) 4.625rem;
}

.grid-rgt75 {
  grid-template-columns: minmax(0, 1fr) 4.6875rem;
}

.grid-rgt76 {
  grid-template-columns: minmax(0, 1fr) 4.75rem;
}

.grid-rgt77 {
  grid-template-columns: minmax(0, 1fr) 4.8125rem;
}

.grid-rgt78 {
  grid-template-columns: minmax(0, 1fr) 4.875rem;
}

.grid-rgt79 {
  grid-template-columns: minmax(0, 1fr) 4.9375rem;
}

.grid-rgt80 {
  grid-template-columns: minmax(0, 1fr) 5rem;
}

.grid-rgt81 {
  grid-template-columns: minmax(0, 1fr) 5.0625rem;
}

.grid-rgt82 {
  grid-template-columns: minmax(0, 1fr) 5.125rem;
}

.grid-rgt83 {
  grid-template-columns: minmax(0, 1fr) 5.1875rem;
}

.grid-rgt84 {
  grid-template-columns: minmax(0, 1fr) 5.25rem;
}

.grid-rgt85 {
  grid-template-columns: minmax(0, 1fr) 5.3125rem;
}

.grid-rgt86 {
  grid-template-columns: minmax(0, 1fr) 5.375rem;
}

.grid-rgt87 {
  grid-template-columns: minmax(0, 1fr) 5.4375rem;
}

.grid-rgt88 {
  grid-template-columns: minmax(0, 1fr) 5.5rem;
}

.grid-rgt89 {
  grid-template-columns: minmax(0, 1fr) 5.5625rem;
}

.grid-rgt90 {
  grid-template-columns: minmax(0, 1fr) 5.625rem;
}

.grid-rgt91 {
  grid-template-columns: minmax(0, 1fr) 5.6875rem;
}

.grid-rgt92 {
  grid-template-columns: minmax(0, 1fr) 5.75rem;
}

.grid-rgt93 {
  grid-template-columns: minmax(0, 1fr) 5.8125rem;
}

.grid-rgt94 {
  grid-template-columns: minmax(0, 1fr) 5.875rem;
}

.grid-rgt95 {
  grid-template-columns: minmax(0, 1fr) 5.9375rem;
}

.grid-rgt96 {
  grid-template-columns: minmax(0, 1fr) 6rem;
}

.grid-rgt97 {
  grid-template-columns: minmax(0, 1fr) 6.0625rem;
}

.grid-rgt98 {
  grid-template-columns: minmax(0, 1fr) 6.125rem;
}

.grid-rgt99 {
  grid-template-columns: minmax(0, 1fr) 6.1875rem;
}

.grid-rgt100 {
  grid-template-columns: minmax(0, 1fr) 6.25rem;
}

.grid-rgt101 {
  grid-template-columns: minmax(0, 1fr) 6.3125rem;
}

.grid-rgt102 {
  grid-template-columns: minmax(0, 1fr) 6.375rem;
}

.grid-rgt103 {
  grid-template-columns: minmax(0, 1fr) 6.4375rem;
}

.grid-rgt104 {
  grid-template-columns: minmax(0, 1fr) 6.5rem;
}

.grid-rgt105 {
  grid-template-columns: minmax(0, 1fr) 6.5625rem;
}

.grid-rgt106 {
  grid-template-columns: minmax(0, 1fr) 6.625rem;
}

.grid-rgt107 {
  grid-template-columns: minmax(0, 1fr) 6.6875rem;
}

.grid-rgt108 {
  grid-template-columns: minmax(0, 1fr) 6.75rem;
}

.grid-rgt109 {
  grid-template-columns: minmax(0, 1fr) 6.8125rem;
}

.grid-rgt110 {
  grid-template-columns: minmax(0, 1fr) 6.875rem;
}

.grid-rgt111 {
  grid-template-columns: minmax(0, 1fr) 6.9375rem;
}

.grid-rgt112 {
  grid-template-columns: minmax(0, 1fr) 7rem;
}

.grid-rgt113 {
  grid-template-columns: minmax(0, 1fr) 7.0625rem;
}

.grid-rgt114 {
  grid-template-columns: minmax(0, 1fr) 7.125rem;
}

.grid-rgt115 {
  grid-template-columns: minmax(0, 1fr) 7.1875rem;
}

.grid-rgt116 {
  grid-template-columns: minmax(0, 1fr) 7.25rem;
}

.grid-rgt117 {
  grid-template-columns: minmax(0, 1fr) 7.3125rem;
}

.grid-rgt118 {
  grid-template-columns: minmax(0, 1fr) 7.375rem;
}

.grid-rgt119 {
  grid-template-columns: minmax(0, 1fr) 7.4375rem;
}

.grid-rgt120 {
  grid-template-columns: minmax(0, 1fr) 7.5rem;
}

.grid-rgt121 {
  grid-template-columns: minmax(0, 1fr) 7.5625rem;
}

.grid-rgt122 {
  grid-template-columns: minmax(0, 1fr) 7.625rem;
}

.grid-rgt123 {
  grid-template-columns: minmax(0, 1fr) 7.6875rem;
}

.grid-rgt124 {
  grid-template-columns: minmax(0, 1fr) 7.75rem;
}

.grid-rgt125 {
  grid-template-columns: minmax(0, 1fr) 7.8125rem;
}

.grid-rgt126 {
  grid-template-columns: minmax(0, 1fr) 7.875rem;
}

.grid-rgt127 {
  grid-template-columns: minmax(0, 1fr) 7.9375rem;
}

.grid-rgt128 {
  grid-template-columns: minmax(0, 1fr) 8rem;
}

.grid-rgt129 {
  grid-template-columns: minmax(0, 1fr) 8.0625rem;
}

.grid-rgt130 {
  grid-template-columns: minmax(0, 1fr) 8.125rem;
}

.grid-rgt131 {
  grid-template-columns: minmax(0, 1fr) 8.1875rem;
}

.grid-rgt132 {
  grid-template-columns: minmax(0, 1fr) 8.25rem;
}

.grid-rgt133 {
  grid-template-columns: minmax(0, 1fr) 8.3125rem;
}

.grid-rgt134 {
  grid-template-columns: minmax(0, 1fr) 8.375rem;
}

.grid-rgt135 {
  grid-template-columns: minmax(0, 1fr) 8.4375rem;
}

.grid-rgt136 {
  grid-template-columns: minmax(0, 1fr) 8.5rem;
}

.grid-rgt137 {
  grid-template-columns: minmax(0, 1fr) 8.5625rem;
}

.grid-rgt138 {
  grid-template-columns: minmax(0, 1fr) 8.625rem;
}

.grid-rgt139 {
  grid-template-columns: minmax(0, 1fr) 8.6875rem;
}

.grid-rgt140 {
  grid-template-columns: minmax(0, 1fr) 8.75rem;
}

.grid-rgt141 {
  grid-template-columns: minmax(0, 1fr) 8.8125rem;
}

.grid-rgt142 {
  grid-template-columns: minmax(0, 1fr) 8.875rem;
}

.grid-rgt143 {
  grid-template-columns: minmax(0, 1fr) 8.9375rem;
}

.grid-rgt144 {
  grid-template-columns: minmax(0, 1fr) 9rem;
}

.grid-rgt145 {
  grid-template-columns: minmax(0, 1fr) 9.0625rem;
}

.grid-rgt146 {
  grid-template-columns: minmax(0, 1fr) 9.125rem;
}

.grid-rgt147 {
  grid-template-columns: minmax(0, 1fr) 9.1875rem;
}

.grid-rgt148 {
  grid-template-columns: minmax(0, 1fr) 9.25rem;
}

.grid-rgt149 {
  grid-template-columns: minmax(0, 1fr) 9.3125rem;
}

.grid-rgt150 {
  grid-template-columns: minmax(0, 1fr) 9.375rem;
}

.grid-rgt151 {
  grid-template-columns: minmax(0, 1fr) 9.4375rem;
}

.grid-rgt152 {
  grid-template-columns: minmax(0, 1fr) 9.5rem;
}

.grid-rgt153 {
  grid-template-columns: minmax(0, 1fr) 9.5625rem;
}

.grid-rgt154 {
  grid-template-columns: minmax(0, 1fr) 9.625rem;
}

.grid-rgt155 {
  grid-template-columns: minmax(0, 1fr) 9.6875rem;
}

.grid-rgt156 {
  grid-template-columns: minmax(0, 1fr) 9.75rem;
}

.grid-rgt157 {
  grid-template-columns: minmax(0, 1fr) 9.8125rem;
}

.grid-rgt158 {
  grid-template-columns: minmax(0, 1fr) 9.875rem;
}

.grid-rgt159 {
  grid-template-columns: minmax(0, 1fr) 9.9375rem;
}

.grid-rgt160 {
  grid-template-columns: minmax(0, 1fr) 10rem;
}

.grid-rgt161 {
  grid-template-columns: minmax(0, 1fr) 10.0625rem;
}

.grid-rgt162 {
  grid-template-columns: minmax(0, 1fr) 10.125rem;
}

.grid-rgt163 {
  grid-template-columns: minmax(0, 1fr) 10.1875rem;
}

.grid-rgt164 {
  grid-template-columns: minmax(0, 1fr) 10.25rem;
}

.grid-rgt165 {
  grid-template-columns: minmax(0, 1fr) 10.3125rem;
}

.grid-rgt166 {
  grid-template-columns: minmax(0, 1fr) 10.375rem;
}

.grid-rgt167 {
  grid-template-columns: minmax(0, 1fr) 10.4375rem;
}

.grid-rgt168 {
  grid-template-columns: minmax(0, 1fr) 10.5rem;
}

.grid-rgt169 {
  grid-template-columns: minmax(0, 1fr) 10.5625rem;
}

.grid-rgt170 {
  grid-template-columns: minmax(0, 1fr) 10.625rem;
}

.grid-rgt171 {
  grid-template-columns: minmax(0, 1fr) 10.6875rem;
}

.grid-rgt172 {
  grid-template-columns: minmax(0, 1fr) 10.75rem;
}

.grid-rgt173 {
  grid-template-columns: minmax(0, 1fr) 10.8125rem;
}

.grid-rgt174 {
  grid-template-columns: minmax(0, 1fr) 10.875rem;
}

.grid-rgt175 {
  grid-template-columns: minmax(0, 1fr) 10.9375rem;
}

.grid-rgt176 {
  grid-template-columns: minmax(0, 1fr) 11rem;
}

.grid-rgt177 {
  grid-template-columns: minmax(0, 1fr) 11.0625rem;
}

.grid-rgt178 {
  grid-template-columns: minmax(0, 1fr) 11.125rem;
}

.grid-rgt179 {
  grid-template-columns: minmax(0, 1fr) 11.1875rem;
}

.grid-rgt180 {
  grid-template-columns: minmax(0, 1fr) 11.25rem;
}

.grid-rgt181 {
  grid-template-columns: minmax(0, 1fr) 11.3125rem;
}

.grid-rgt182 {
  grid-template-columns: minmax(0, 1fr) 11.375rem;
}

.grid-rgt183 {
  grid-template-columns: minmax(0, 1fr) 11.4375rem;
}

.grid-rgt184 {
  grid-template-columns: minmax(0, 1fr) 11.5rem;
}

.grid-rgt185 {
  grid-template-columns: minmax(0, 1fr) 11.5625rem;
}

.grid-rgt186 {
  grid-template-columns: minmax(0, 1fr) 11.625rem;
}

.grid-rgt187 {
  grid-template-columns: minmax(0, 1fr) 11.6875rem;
}

.grid-rgt188 {
  grid-template-columns: minmax(0, 1fr) 11.75rem;
}

.grid-rgt189 {
  grid-template-columns: minmax(0, 1fr) 11.8125rem;
}

.grid-rgt190 {
  grid-template-columns: minmax(0, 1fr) 11.875rem;
}

.grid-rgt191 {
  grid-template-columns: minmax(0, 1fr) 11.9375rem;
}

.grid-rgt192 {
  grid-template-columns: minmax(0, 1fr) 12rem;
}

.grid-rgt193 {
  grid-template-columns: minmax(0, 1fr) 12.0625rem;
}

.grid-rgt194 {
  grid-template-columns: minmax(0, 1fr) 12.125rem;
}

.grid-rgt195 {
  grid-template-columns: minmax(0, 1fr) 12.1875rem;
}

.grid-rgt196 {
  grid-template-columns: minmax(0, 1fr) 12.25rem;
}

.grid-rgt197 {
  grid-template-columns: minmax(0, 1fr) 12.3125rem;
}

.grid-rgt198 {
  grid-template-columns: minmax(0, 1fr) 12.375rem;
}

.grid-rgt199 {
  grid-template-columns: minmax(0, 1fr) 12.4375rem;
}

.grid-rgt200 {
  grid-template-columns: minmax(0, 1fr) 12.5rem;
}

.grid-rgt201 {
  grid-template-columns: minmax(0, 1fr) 12.5625rem;
}

.grid-rgt202 {
  grid-template-columns: minmax(0, 1fr) 12.625rem;
}

.grid-rgt203 {
  grid-template-columns: minmax(0, 1fr) 12.6875rem;
}

.grid-rgt204 {
  grid-template-columns: minmax(0, 1fr) 12.75rem;
}

.grid-rgt205 {
  grid-template-columns: minmax(0, 1fr) 12.8125rem;
}

.grid-rgt206 {
  grid-template-columns: minmax(0, 1fr) 12.875rem;
}

.grid-rgt207 {
  grid-template-columns: minmax(0, 1fr) 12.9375rem;
}

.grid-rgt208 {
  grid-template-columns: minmax(0, 1fr) 13rem;
}

.grid-rgt209 {
  grid-template-columns: minmax(0, 1fr) 13.0625rem;
}

.grid-rgt210 {
  grid-template-columns: minmax(0, 1fr) 13.125rem;
}

.grid-rgt211 {
  grid-template-columns: minmax(0, 1fr) 13.1875rem;
}

.grid-rgt212 {
  grid-template-columns: minmax(0, 1fr) 13.25rem;
}

.grid-rgt213 {
  grid-template-columns: minmax(0, 1fr) 13.3125rem;
}

.grid-rgt214 {
  grid-template-columns: minmax(0, 1fr) 13.375rem;
}

.grid-rgt215 {
  grid-template-columns: minmax(0, 1fr) 13.4375rem;
}

.grid-rgt216 {
  grid-template-columns: minmax(0, 1fr) 13.5rem;
}

.grid-rgt217 {
  grid-template-columns: minmax(0, 1fr) 13.5625rem;
}

.grid-rgt218 {
  grid-template-columns: minmax(0, 1fr) 13.625rem;
}

.grid-rgt219 {
  grid-template-columns: minmax(0, 1fr) 13.6875rem;
}

.grid-rgt220 {
  grid-template-columns: minmax(0, 1fr) 13.75rem;
}

.grid-rgt221 {
  grid-template-columns: minmax(0, 1fr) 13.8125rem;
}

.grid-rgt222 {
  grid-template-columns: minmax(0, 1fr) 13.875rem;
}

.grid-rgt223 {
  grid-template-columns: minmax(0, 1fr) 13.9375rem;
}

.grid-rgt224 {
  grid-template-columns: minmax(0, 1fr) 14rem;
}

.grid-rgt225 {
  grid-template-columns: minmax(0, 1fr) 14.0625rem;
}

.grid-rgt226 {
  grid-template-columns: minmax(0, 1fr) 14.125rem;
}

.grid-rgt227 {
  grid-template-columns: minmax(0, 1fr) 14.1875rem;
}

.grid-rgt228 {
  grid-template-columns: minmax(0, 1fr) 14.25rem;
}

.grid-rgt229 {
  grid-template-columns: minmax(0, 1fr) 14.3125rem;
}

.grid-rgt230 {
  grid-template-columns: minmax(0, 1fr) 14.375rem;
}

.grid-rgt231 {
  grid-template-columns: minmax(0, 1fr) 14.4375rem;
}

.grid-rgt232 {
  grid-template-columns: minmax(0, 1fr) 14.5rem;
}

.grid-rgt233 {
  grid-template-columns: minmax(0, 1fr) 14.5625rem;
}

.grid-rgt234 {
  grid-template-columns: minmax(0, 1fr) 14.625rem;
}

.grid-rgt235 {
  grid-template-columns: minmax(0, 1fr) 14.6875rem;
}

.grid-rgt236 {
  grid-template-columns: minmax(0, 1fr) 14.75rem;
}

.grid-rgt237 {
  grid-template-columns: minmax(0, 1fr) 14.8125rem;
}

.grid-rgt238 {
  grid-template-columns: minmax(0, 1fr) 14.875rem;
}

.grid-rgt239 {
  grid-template-columns: minmax(0, 1fr) 14.9375rem;
}

.grid-rgt240 {
  grid-template-columns: minmax(0, 1fr) 15rem;
}

.grid-rgt241 {
  grid-template-columns: minmax(0, 1fr) 15.0625rem;
}

.grid-rgt242 {
  grid-template-columns: minmax(0, 1fr) 15.125rem;
}

.grid-rgt243 {
  grid-template-columns: minmax(0, 1fr) 15.1875rem;
}

.grid-rgt244 {
  grid-template-columns: minmax(0, 1fr) 15.25rem;
}

.grid-rgt245 {
  grid-template-columns: minmax(0, 1fr) 15.3125rem;
}

.grid-rgt246 {
  grid-template-columns: minmax(0, 1fr) 15.375rem;
}

.grid-rgt247 {
  grid-template-columns: minmax(0, 1fr) 15.4375rem;
}

.grid-rgt248 {
  grid-template-columns: minmax(0, 1fr) 15.5rem;
}

.grid-rgt249 {
  grid-template-columns: minmax(0, 1fr) 15.5625rem;
}

.grid-rgt250 {
  grid-template-columns: minmax(0, 1fr) 15.625rem;
}

.grid-rgt251 {
  grid-template-columns: minmax(0, 1fr) 15.6875rem;
}

.grid-rgt252 {
  grid-template-columns: minmax(0, 1fr) 15.75rem;
}

.grid-rgt253 {
  grid-template-columns: minmax(0, 1fr) 15.8125rem;
}

.grid-rgt254 {
  grid-template-columns: minmax(0, 1fr) 15.875rem;
}

.grid-rgt255 {
  grid-template-columns: minmax(0, 1fr) 15.9375rem;
}

.grid-rgt256 {
  grid-template-columns: minmax(0, 1fr) 16rem;
}

.grid-rgt257 {
  grid-template-columns: minmax(0, 1fr) 16.0625rem;
}

.grid-rgt258 {
  grid-template-columns: minmax(0, 1fr) 16.125rem;
}

.grid-rgt259 {
  grid-template-columns: minmax(0, 1fr) 16.1875rem;
}

.grid-rgt260 {
  grid-template-columns: minmax(0, 1fr) 16.25rem;
}

.grid-rgt261 {
  grid-template-columns: minmax(0, 1fr) 16.3125rem;
}

.grid-rgt262 {
  grid-template-columns: minmax(0, 1fr) 16.375rem;
}

.grid-rgt263 {
  grid-template-columns: minmax(0, 1fr) 16.4375rem;
}

.grid-rgt264 {
  grid-template-columns: minmax(0, 1fr) 16.5rem;
}

.grid-rgt265 {
  grid-template-columns: minmax(0, 1fr) 16.5625rem;
}

.grid-rgt266 {
  grid-template-columns: minmax(0, 1fr) 16.625rem;
}

.grid-rgt267 {
  grid-template-columns: minmax(0, 1fr) 16.6875rem;
}

.grid-rgt268 {
  grid-template-columns: minmax(0, 1fr) 16.75rem;
}

.grid-rgt269 {
  grid-template-columns: minmax(0, 1fr) 16.8125rem;
}

.grid-rgt270 {
  grid-template-columns: minmax(0, 1fr) 16.875rem;
}

.grid-rgt271 {
  grid-template-columns: minmax(0, 1fr) 16.9375rem;
}

.grid-rgt272 {
  grid-template-columns: minmax(0, 1fr) 17rem;
}

.grid-rgt273 {
  grid-template-columns: minmax(0, 1fr) 17.0625rem;
}

.grid-rgt274 {
  grid-template-columns: minmax(0, 1fr) 17.125rem;
}

.grid-rgt275 {
  grid-template-columns: minmax(0, 1fr) 17.1875rem;
}

.grid-rgt276 {
  grid-template-columns: minmax(0, 1fr) 17.25rem;
}

.grid-rgt277 {
  grid-template-columns: minmax(0, 1fr) 17.3125rem;
}

.grid-rgt278 {
  grid-template-columns: minmax(0, 1fr) 17.375rem;
}

.grid-rgt279 {
  grid-template-columns: minmax(0, 1fr) 17.4375rem;
}

.grid-rgt280 {
  grid-template-columns: minmax(0, 1fr) 17.5rem;
}

.grid-rgt281 {
  grid-template-columns: minmax(0, 1fr) 17.5625rem;
}

.grid-rgt282 {
  grid-template-columns: minmax(0, 1fr) 17.625rem;
}

.grid-rgt283 {
  grid-template-columns: minmax(0, 1fr) 17.6875rem;
}

.grid-rgt284 {
  grid-template-columns: minmax(0, 1fr) 17.75rem;
}

.grid-rgt285 {
  grid-template-columns: minmax(0, 1fr) 17.8125rem;
}

.grid-rgt286 {
  grid-template-columns: minmax(0, 1fr) 17.875rem;
}

.grid-rgt287 {
  grid-template-columns: minmax(0, 1fr) 17.9375rem;
}

.grid-rgt288 {
  grid-template-columns: minmax(0, 1fr) 18rem;
}

.grid-rgt289 {
  grid-template-columns: minmax(0, 1fr) 18.0625rem;
}

.grid-rgt290 {
  grid-template-columns: minmax(0, 1fr) 18.125rem;
}

.grid-rgt291 {
  grid-template-columns: minmax(0, 1fr) 18.1875rem;
}

.grid-rgt292 {
  grid-template-columns: minmax(0, 1fr) 18.25rem;
}

.grid-rgt293 {
  grid-template-columns: minmax(0, 1fr) 18.3125rem;
}

.grid-rgt294 {
  grid-template-columns: minmax(0, 1fr) 18.375rem;
}

.grid-rgt295 {
  grid-template-columns: minmax(0, 1fr) 18.4375rem;
}

.grid-rgt296 {
  grid-template-columns: minmax(0, 1fr) 18.5rem;
}

.grid-rgt297 {
  grid-template-columns: minmax(0, 1fr) 18.5625rem;
}

.grid-rgt298 {
  grid-template-columns: minmax(0, 1fr) 18.625rem;
}

.grid-rgt299 {
  grid-template-columns: minmax(0, 1fr) 18.6875rem;
}

.grid-rgt300 {
  grid-template-columns: minmax(0, 1fr) 18.75rem;
}

.grid-rgt301 {
  grid-template-columns: minmax(0, 1fr) 18.8125rem;
}

.grid-rgt302 {
  grid-template-columns: minmax(0, 1fr) 18.875rem;
}

.grid-rgt303 {
  grid-template-columns: minmax(0, 1fr) 18.9375rem;
}

.grid-rgt304 {
  grid-template-columns: minmax(0, 1fr) 19rem;
}

.grid-rgt305 {
  grid-template-columns: minmax(0, 1fr) 19.0625rem;
}

.grid-rgt306 {
  grid-template-columns: minmax(0, 1fr) 19.125rem;
}

.grid-rgt307 {
  grid-template-columns: minmax(0, 1fr) 19.1875rem;
}

.grid-rgt308 {
  grid-template-columns: minmax(0, 1fr) 19.25rem;
}

.grid-rgt309 {
  grid-template-columns: minmax(0, 1fr) 19.3125rem;
}

.grid-rgt310 {
  grid-template-columns: minmax(0, 1fr) 19.375rem;
}

.grid-rgt311 {
  grid-template-columns: minmax(0, 1fr) 19.4375rem;
}

.grid-rgt312 {
  grid-template-columns: minmax(0, 1fr) 19.5rem;
}

.grid-rgt313 {
  grid-template-columns: minmax(0, 1fr) 19.5625rem;
}

.grid-rgt314 {
  grid-template-columns: minmax(0, 1fr) 19.625rem;
}

.grid-rgt315 {
  grid-template-columns: minmax(0, 1fr) 19.6875rem;
}

.grid-rgt316 {
  grid-template-columns: minmax(0, 1fr) 19.75rem;
}

.grid-rgt317 {
  grid-template-columns: minmax(0, 1fr) 19.8125rem;
}

.grid-rgt318 {
  grid-template-columns: minmax(0, 1fr) 19.875rem;
}

.grid-rgt319 {
  grid-template-columns: minmax(0, 1fr) 19.9375rem;
}

.grid-rgt320 {
  grid-template-columns: minmax(0, 1fr) 20rem;
}

.grid-rgt321 {
  grid-template-columns: minmax(0, 1fr) 20.0625rem;
}

.grid-rgt322 {
  grid-template-columns: minmax(0, 1fr) 20.125rem;
}

.grid-rgt323 {
  grid-template-columns: minmax(0, 1fr) 20.1875rem;
}

.grid-rgt324 {
  grid-template-columns: minmax(0, 1fr) 20.25rem;
}

.grid-rgt325 {
  grid-template-columns: minmax(0, 1fr) 20.3125rem;
}

.grid-rgt326 {
  grid-template-columns: minmax(0, 1fr) 20.375rem;
}

.grid-rgt327 {
  grid-template-columns: minmax(0, 1fr) 20.4375rem;
}

.grid-rgt328 {
  grid-template-columns: minmax(0, 1fr) 20.5rem;
}

.grid-rgt329 {
  grid-template-columns: minmax(0, 1fr) 20.5625rem;
}

.grid-rgt330 {
  grid-template-columns: minmax(0, 1fr) 20.625rem;
}

.grid-rgt331 {
  grid-template-columns: minmax(0, 1fr) 20.6875rem;
}

.grid-rgt332 {
  grid-template-columns: minmax(0, 1fr) 20.75rem;
}

.grid-rgt333 {
  grid-template-columns: minmax(0, 1fr) 20.8125rem;
}

.grid-rgt334 {
  grid-template-columns: minmax(0, 1fr) 20.875rem;
}

.grid-rgt335 {
  grid-template-columns: minmax(0, 1fr) 20.9375rem;
}

.grid-rgt336 {
  grid-template-columns: minmax(0, 1fr) 21rem;
}

.grid-rgt337 {
  grid-template-columns: minmax(0, 1fr) 21.0625rem;
}

.grid-rgt338 {
  grid-template-columns: minmax(0, 1fr) 21.125rem;
}

.grid-rgt339 {
  grid-template-columns: minmax(0, 1fr) 21.1875rem;
}

.grid-rgt340 {
  grid-template-columns: minmax(0, 1fr) 21.25rem;
}

.grid-rgt341 {
  grid-template-columns: minmax(0, 1fr) 21.3125rem;
}

.grid-rgt342 {
  grid-template-columns: minmax(0, 1fr) 21.375rem;
}

.grid-rgt343 {
  grid-template-columns: minmax(0, 1fr) 21.4375rem;
}

.grid-rgt344 {
  grid-template-columns: minmax(0, 1fr) 21.5rem;
}

.grid-rgt345 {
  grid-template-columns: minmax(0, 1fr) 21.5625rem;
}

.grid-rgt346 {
  grid-template-columns: minmax(0, 1fr) 21.625rem;
}

.grid-rgt347 {
  grid-template-columns: minmax(0, 1fr) 21.6875rem;
}

.grid-rgt348 {
  grid-template-columns: minmax(0, 1fr) 21.75rem;
}

.grid-rgt349 {
  grid-template-columns: minmax(0, 1fr) 21.8125rem;
}

.grid-rgt350 {
  grid-template-columns: minmax(0, 1fr) 21.875rem;
}

.grid-rgt351 {
  grid-template-columns: minmax(0, 1fr) 21.9375rem;
}

.grid-rgt352 {
  grid-template-columns: minmax(0, 1fr) 22rem;
}

.grid-rgt353 {
  grid-template-columns: minmax(0, 1fr) 22.0625rem;
}

.grid-rgt354 {
  grid-template-columns: minmax(0, 1fr) 22.125rem;
}

.grid-rgt355 {
  grid-template-columns: minmax(0, 1fr) 22.1875rem;
}

.grid-rgt356 {
  grid-template-columns: minmax(0, 1fr) 22.25rem;
}

.grid-rgt357 {
  grid-template-columns: minmax(0, 1fr) 22.3125rem;
}

.grid-rgt358 {
  grid-template-columns: minmax(0, 1fr) 22.375rem;
}

.grid-rgt359 {
  grid-template-columns: minmax(0, 1fr) 22.4375rem;
}

.grid-rgt360 {
  grid-template-columns: minmax(0, 1fr) 22.5rem;
}

.grid-rgt361 {
  grid-template-columns: minmax(0, 1fr) 22.5625rem;
}

.grid-rgt362 {
  grid-template-columns: minmax(0, 1fr) 22.625rem;
}

.grid-rgt363 {
  grid-template-columns: minmax(0, 1fr) 22.6875rem;
}

.grid-rgt364 {
  grid-template-columns: minmax(0, 1fr) 22.75rem;
}

.grid-rgt365 {
  grid-template-columns: minmax(0, 1fr) 22.8125rem;
}

.grid-rgt366 {
  grid-template-columns: minmax(0, 1fr) 22.875rem;
}

.grid-rgt367 {
  grid-template-columns: minmax(0, 1fr) 22.9375rem;
}

.grid-rgt368 {
  grid-template-columns: minmax(0, 1fr) 23rem;
}

.grid-rgt369 {
  grid-template-columns: minmax(0, 1fr) 23.0625rem;
}

.grid-rgt370 {
  grid-template-columns: minmax(0, 1fr) 23.125rem;
}

.grid-rgt371 {
  grid-template-columns: minmax(0, 1fr) 23.1875rem;
}

.grid-rgt372 {
  grid-template-columns: minmax(0, 1fr) 23.25rem;
}

.grid-rgt373 {
  grid-template-columns: minmax(0, 1fr) 23.3125rem;
}

.grid-rgt374 {
  grid-template-columns: minmax(0, 1fr) 23.375rem;
}

.grid-rgt375 {
  grid-template-columns: minmax(0, 1fr) 23.4375rem;
}

.grid-rgt376 {
  grid-template-columns: minmax(0, 1fr) 23.5rem;
}

.grid-rgt377 {
  grid-template-columns: minmax(0, 1fr) 23.5625rem;
}

.grid-rgt378 {
  grid-template-columns: minmax(0, 1fr) 23.625rem;
}

.grid-rgt379 {
  grid-template-columns: minmax(0, 1fr) 23.6875rem;
}

.grid-rgt380 {
  grid-template-columns: minmax(0, 1fr) 23.75rem;
}

.grid-rgt381 {
  grid-template-columns: minmax(0, 1fr) 23.8125rem;
}

.grid-rgt382 {
  grid-template-columns: minmax(0, 1fr) 23.875rem;
}

.grid-rgt383 {
  grid-template-columns: minmax(0, 1fr) 23.9375rem;
}

.grid-rgt384 {
  grid-template-columns: minmax(0, 1fr) 24rem;
}

.grid-rgt385 {
  grid-template-columns: minmax(0, 1fr) 24.0625rem;
}

.grid-rgt386 {
  grid-template-columns: minmax(0, 1fr) 24.125rem;
}

.grid-rgt387 {
  grid-template-columns: minmax(0, 1fr) 24.1875rem;
}

.grid-rgt388 {
  grid-template-columns: minmax(0, 1fr) 24.25rem;
}

.grid-rgt389 {
  grid-template-columns: minmax(0, 1fr) 24.3125rem;
}

.grid-rgt390 {
  grid-template-columns: minmax(0, 1fr) 24.375rem;
}

.grid-rgt391 {
  grid-template-columns: minmax(0, 1fr) 24.4375rem;
}

.grid-rgt392 {
  grid-template-columns: minmax(0, 1fr) 24.5rem;
}

.grid-rgt393 {
  grid-template-columns: minmax(0, 1fr) 24.5625rem;
}

.grid-rgt394 {
  grid-template-columns: minmax(0, 1fr) 24.625rem;
}

.grid-rgt395 {
  grid-template-columns: minmax(0, 1fr) 24.6875rem;
}

.grid-rgt396 {
  grid-template-columns: minmax(0, 1fr) 24.75rem;
}

.grid-rgt397 {
  grid-template-columns: minmax(0, 1fr) 24.8125rem;
}

.grid-rgt398 {
  grid-template-columns: minmax(0, 1fr) 24.875rem;
}

.grid-rgt399 {
  grid-template-columns: minmax(0, 1fr) 24.9375rem;
}

.grid-rgt400 {
  grid-template-columns: minmax(0, 1fr) 25rem;
}

.grid-rgt401 {
  grid-template-columns: minmax(0, 1fr) 25.0625rem;
}

.grid-rgt402 {
  grid-template-columns: minmax(0, 1fr) 25.125rem;
}

.grid-rgt403 {
  grid-template-columns: minmax(0, 1fr) 25.1875rem;
}

.grid-rgt404 {
  grid-template-columns: minmax(0, 1fr) 25.25rem;
}

.grid-rgt405 {
  grid-template-columns: minmax(0, 1fr) 25.3125rem;
}

.grid-rgt406 {
  grid-template-columns: minmax(0, 1fr) 25.375rem;
}

.grid-rgt407 {
  grid-template-columns: minmax(0, 1fr) 25.4375rem;
}

.grid-rgt408 {
  grid-template-columns: minmax(0, 1fr) 25.5rem;
}

.grid-rgt409 {
  grid-template-columns: minmax(0, 1fr) 25.5625rem;
}

.grid-rgt410 {
  grid-template-columns: minmax(0, 1fr) 25.625rem;
}

.grid-rgt411 {
  grid-template-columns: minmax(0, 1fr) 25.6875rem;
}

.grid-rgt412 {
  grid-template-columns: minmax(0, 1fr) 25.75rem;
}

.grid-rgt413 {
  grid-template-columns: minmax(0, 1fr) 25.8125rem;
}

.grid-rgt414 {
  grid-template-columns: minmax(0, 1fr) 25.875rem;
}

.grid-rgt415 {
  grid-template-columns: minmax(0, 1fr) 25.9375rem;
}

.grid-rgt416 {
  grid-template-columns: minmax(0, 1fr) 26rem;
}

.grid-rgt417 {
  grid-template-columns: minmax(0, 1fr) 26.0625rem;
}

.grid-rgt418 {
  grid-template-columns: minmax(0, 1fr) 26.125rem;
}

.grid-rgt419 {
  grid-template-columns: minmax(0, 1fr) 26.1875rem;
}

.grid-rgt420 {
  grid-template-columns: minmax(0, 1fr) 26.25rem;
}

.grid-rgt421 {
  grid-template-columns: minmax(0, 1fr) 26.3125rem;
}

.grid-rgt422 {
  grid-template-columns: minmax(0, 1fr) 26.375rem;
}

.grid-rgt423 {
  grid-template-columns: minmax(0, 1fr) 26.4375rem;
}

.grid-rgt424 {
  grid-template-columns: minmax(0, 1fr) 26.5rem;
}

.grid-rgt425 {
  grid-template-columns: minmax(0, 1fr) 26.5625rem;
}

.grid-rgt426 {
  grid-template-columns: minmax(0, 1fr) 26.625rem;
}

.grid-rgt427 {
  grid-template-columns: minmax(0, 1fr) 26.6875rem;
}

.grid-rgt428 {
  grid-template-columns: minmax(0, 1fr) 26.75rem;
}

.grid-rgt429 {
  grid-template-columns: minmax(0, 1fr) 26.8125rem;
}

.grid-rgt430 {
  grid-template-columns: minmax(0, 1fr) 26.875rem;
}

.grid-rgt431 {
  grid-template-columns: minmax(0, 1fr) 26.9375rem;
}

.grid-rgt432 {
  grid-template-columns: minmax(0, 1fr) 27rem;
}

.grid-rgt433 {
  grid-template-columns: minmax(0, 1fr) 27.0625rem;
}

.grid-rgt434 {
  grid-template-columns: minmax(0, 1fr) 27.125rem;
}

.grid-rgt435 {
  grid-template-columns: minmax(0, 1fr) 27.1875rem;
}

.grid-rgt436 {
  grid-template-columns: minmax(0, 1fr) 27.25rem;
}

.grid-rgt437 {
  grid-template-columns: minmax(0, 1fr) 27.3125rem;
}

.grid-rgt438 {
  grid-template-columns: minmax(0, 1fr) 27.375rem;
}

.grid-rgt439 {
  grid-template-columns: minmax(0, 1fr) 27.4375rem;
}

.grid-rgt440 {
  grid-template-columns: minmax(0, 1fr) 27.5rem;
}

.grid-rgt441 {
  grid-template-columns: minmax(0, 1fr) 27.5625rem;
}

.grid-rgt442 {
  grid-template-columns: minmax(0, 1fr) 27.625rem;
}

.grid-rgt443 {
  grid-template-columns: minmax(0, 1fr) 27.6875rem;
}

.grid-rgt444 {
  grid-template-columns: minmax(0, 1fr) 27.75rem;
}

.grid-rgt445 {
  grid-template-columns: minmax(0, 1fr) 27.8125rem;
}

.grid-rgt446 {
  grid-template-columns: minmax(0, 1fr) 27.875rem;
}

.grid-rgt447 {
  grid-template-columns: minmax(0, 1fr) 27.9375rem;
}

.grid-rgt448 {
  grid-template-columns: minmax(0, 1fr) 28rem;
}

.grid-rgt449 {
  grid-template-columns: minmax(0, 1fr) 28.0625rem;
}

.grid-rgt450 {
  grid-template-columns: minmax(0, 1fr) 28.125rem;
}

.grid-rgt451 {
  grid-template-columns: minmax(0, 1fr) 28.1875rem;
}

.grid-rgt452 {
  grid-template-columns: minmax(0, 1fr) 28.25rem;
}

.grid-rgt453 {
  grid-template-columns: minmax(0, 1fr) 28.3125rem;
}

.grid-rgt454 {
  grid-template-columns: minmax(0, 1fr) 28.375rem;
}

.grid-rgt455 {
  grid-template-columns: minmax(0, 1fr) 28.4375rem;
}

.grid-rgt456 {
  grid-template-columns: minmax(0, 1fr) 28.5rem;
}

.grid-rgt457 {
  grid-template-columns: minmax(0, 1fr) 28.5625rem;
}

.grid-rgt458 {
  grid-template-columns: minmax(0, 1fr) 28.625rem;
}

.grid-rgt459 {
  grid-template-columns: minmax(0, 1fr) 28.6875rem;
}

.grid-rgt460 {
  grid-template-columns: minmax(0, 1fr) 28.75rem;
}

.grid-rgt461 {
  grid-template-columns: minmax(0, 1fr) 28.8125rem;
}

.grid-rgt462 {
  grid-template-columns: minmax(0, 1fr) 28.875rem;
}

.grid-rgt463 {
  grid-template-columns: minmax(0, 1fr) 28.9375rem;
}

.grid-rgt464 {
  grid-template-columns: minmax(0, 1fr) 29rem;
}

.grid-rgt465 {
  grid-template-columns: minmax(0, 1fr) 29.0625rem;
}

.grid-rgt466 {
  grid-template-columns: minmax(0, 1fr) 29.125rem;
}

.grid-rgt467 {
  grid-template-columns: minmax(0, 1fr) 29.1875rem;
}

.grid-rgt468 {
  grid-template-columns: minmax(0, 1fr) 29.25rem;
}

.grid-rgt469 {
  grid-template-columns: minmax(0, 1fr) 29.3125rem;
}

.grid-rgt470 {
  grid-template-columns: minmax(0, 1fr) 29.375rem;
}

.grid-rgt471 {
  grid-template-columns: minmax(0, 1fr) 29.4375rem;
}

.grid-rgt472 {
  grid-template-columns: minmax(0, 1fr) 29.5rem;
}

.grid-rgt473 {
  grid-template-columns: minmax(0, 1fr) 29.5625rem;
}

.grid-rgt474 {
  grid-template-columns: minmax(0, 1fr) 29.625rem;
}

.grid-rgt475 {
  grid-template-columns: minmax(0, 1fr) 29.6875rem;
}

.grid-rgt476 {
  grid-template-columns: minmax(0, 1fr) 29.75rem;
}

.grid-rgt477 {
  grid-template-columns: minmax(0, 1fr) 29.8125rem;
}

.grid-rgt478 {
  grid-template-columns: minmax(0, 1fr) 29.875rem;
}

.grid-rgt479 {
  grid-template-columns: minmax(0, 1fr) 29.9375rem;
}

.grid-rgt480 {
  grid-template-columns: minmax(0, 1fr) 30rem;
}

.grid-rgt481 {
  grid-template-columns: minmax(0, 1fr) 30.0625rem;
}

.grid-rgt482 {
  grid-template-columns: minmax(0, 1fr) 30.125rem;
}

.grid-rgt483 {
  grid-template-columns: minmax(0, 1fr) 30.1875rem;
}

.grid-rgt484 {
  grid-template-columns: minmax(0, 1fr) 30.25rem;
}

.grid-rgt485 {
  grid-template-columns: minmax(0, 1fr) 30.3125rem;
}

.grid-rgt486 {
  grid-template-columns: minmax(0, 1fr) 30.375rem;
}

.grid-rgt487 {
  grid-template-columns: minmax(0, 1fr) 30.4375rem;
}

.grid-rgt488 {
  grid-template-columns: minmax(0, 1fr) 30.5rem;
}

.grid-rgt489 {
  grid-template-columns: minmax(0, 1fr) 30.5625rem;
}

.grid-rgt490 {
  grid-template-columns: minmax(0, 1fr) 30.625rem;
}

.grid-rgt491 {
  grid-template-columns: minmax(0, 1fr) 30.6875rem;
}

.grid-rgt492 {
  grid-template-columns: minmax(0, 1fr) 30.75rem;
}

.grid-rgt493 {
  grid-template-columns: minmax(0, 1fr) 30.8125rem;
}

.grid-rgt494 {
  grid-template-columns: minmax(0, 1fr) 30.875rem;
}

.grid-rgt495 {
  grid-template-columns: minmax(0, 1fr) 30.9375rem;
}

.grid-rgt496 {
  grid-template-columns: minmax(0, 1fr) 31rem;
}

.grid-rgt497 {
  grid-template-columns: minmax(0, 1fr) 31.0625rem;
}

.grid-rgt498 {
  grid-template-columns: minmax(0, 1fr) 31.125rem;
}

.grid-rgt499 {
  grid-template-columns: minmax(0, 1fr) 31.1875rem;
}

.grid-rgt500 {
  grid-template-columns: minmax(0, 1fr) 31.25rem;
}

.grid-rgt501 {
  grid-template-columns: minmax(0, 1fr) 31.3125rem;
}

.grid-rgt502 {
  grid-template-columns: minmax(0, 1fr) 31.375rem;
}

.grid-rgt503 {
  grid-template-columns: minmax(0, 1fr) 31.4375rem;
}

.grid-rgt504 {
  grid-template-columns: minmax(0, 1fr) 31.5rem;
}

.grid-rgt505 {
  grid-template-columns: minmax(0, 1fr) 31.5625rem;
}

.grid-rgt506 {
  grid-template-columns: minmax(0, 1fr) 31.625rem;
}

.grid-rgt507 {
  grid-template-columns: minmax(0, 1fr) 31.6875rem;
}

.grid-rgt508 {
  grid-template-columns: minmax(0, 1fr) 31.75rem;
}

.grid-rgt509 {
  grid-template-columns: minmax(0, 1fr) 31.8125rem;
}

.grid-rgt510 {
  grid-template-columns: minmax(0, 1fr) 31.875rem;
}

.grid-rgt511 {
  grid-template-columns: minmax(0, 1fr) 31.9375rem;
}

.grid-rgt512 {
  grid-template-columns: minmax(0, 1fr) 32rem;
}

.grid-rgt513 {
  grid-template-columns: minmax(0, 1fr) 32.0625rem;
}

.grid-rgt514 {
  grid-template-columns: minmax(0, 1fr) 32.125rem;
}

.grid-rgt515 {
  grid-template-columns: minmax(0, 1fr) 32.1875rem;
}

.grid-rgt516 {
  grid-template-columns: minmax(0, 1fr) 32.25rem;
}

.grid-rgt517 {
  grid-template-columns: minmax(0, 1fr) 32.3125rem;
}

.grid-rgt518 {
  grid-template-columns: minmax(0, 1fr) 32.375rem;
}

.grid-rgt519 {
  grid-template-columns: minmax(0, 1fr) 32.4375rem;
}

.grid-rgt520 {
  grid-template-columns: minmax(0, 1fr) 32.5rem;
}

.grid-rgt521 {
  grid-template-columns: minmax(0, 1fr) 32.5625rem;
}

.grid-rgt522 {
  grid-template-columns: minmax(0, 1fr) 32.625rem;
}

.grid-rgt523 {
  grid-template-columns: minmax(0, 1fr) 32.6875rem;
}

.grid-rgt524 {
  grid-template-columns: minmax(0, 1fr) 32.75rem;
}

.grid-rgt525 {
  grid-template-columns: minmax(0, 1fr) 32.8125rem;
}

.grid-rgt526 {
  grid-template-columns: minmax(0, 1fr) 32.875rem;
}

.grid-rgt527 {
  grid-template-columns: minmax(0, 1fr) 32.9375rem;
}

.grid-rgt528 {
  grid-template-columns: minmax(0, 1fr) 33rem;
}

.grid-rgt529 {
  grid-template-columns: minmax(0, 1fr) 33.0625rem;
}

.grid-rgt530 {
  grid-template-columns: minmax(0, 1fr) 33.125rem;
}

.grid-rgt531 {
  grid-template-columns: minmax(0, 1fr) 33.1875rem;
}

.grid-rgt532 {
  grid-template-columns: minmax(0, 1fr) 33.25rem;
}

.grid-rgt533 {
  grid-template-columns: minmax(0, 1fr) 33.3125rem;
}

.grid-rgt534 {
  grid-template-columns: minmax(0, 1fr) 33.375rem;
}

.grid-rgt535 {
  grid-template-columns: minmax(0, 1fr) 33.4375rem;
}

.grid-rgt536 {
  grid-template-columns: minmax(0, 1fr) 33.5rem;
}

.grid-rgt537 {
  grid-template-columns: minmax(0, 1fr) 33.5625rem;
}

.grid-rgt538 {
  grid-template-columns: minmax(0, 1fr) 33.625rem;
}

.grid-rgt539 {
  grid-template-columns: minmax(0, 1fr) 33.6875rem;
}

.grid-rgt540 {
  grid-template-columns: minmax(0, 1fr) 33.75rem;
}

.grid-rgt541 {
  grid-template-columns: minmax(0, 1fr) 33.8125rem;
}

.grid-rgt542 {
  grid-template-columns: minmax(0, 1fr) 33.875rem;
}

.grid-rgt543 {
  grid-template-columns: minmax(0, 1fr) 33.9375rem;
}

.grid-rgt544 {
  grid-template-columns: minmax(0, 1fr) 34rem;
}

.grid-rgt545 {
  grid-template-columns: minmax(0, 1fr) 34.0625rem;
}

.grid-rgt546 {
  grid-template-columns: minmax(0, 1fr) 34.125rem;
}

.grid-rgt547 {
  grid-template-columns: minmax(0, 1fr) 34.1875rem;
}

.grid-rgt548 {
  grid-template-columns: minmax(0, 1fr) 34.25rem;
}

.grid-rgt549 {
  grid-template-columns: minmax(0, 1fr) 34.3125rem;
}

.grid-rgt550 {
  grid-template-columns: minmax(0, 1fr) 34.375rem;
}

.grid-rgt551 {
  grid-template-columns: minmax(0, 1fr) 34.4375rem;
}

.grid-rgt552 {
  grid-template-columns: minmax(0, 1fr) 34.5rem;
}

.grid-rgt553 {
  grid-template-columns: minmax(0, 1fr) 34.5625rem;
}

.grid-rgt554 {
  grid-template-columns: minmax(0, 1fr) 34.625rem;
}

.grid-rgt555 {
  grid-template-columns: minmax(0, 1fr) 34.6875rem;
}

.grid-rgt556 {
  grid-template-columns: minmax(0, 1fr) 34.75rem;
}

.grid-rgt557 {
  grid-template-columns: minmax(0, 1fr) 34.8125rem;
}

.grid-rgt558 {
  grid-template-columns: minmax(0, 1fr) 34.875rem;
}

.grid-rgt559 {
  grid-template-columns: minmax(0, 1fr) 34.9375rem;
}

.grid-rgt560 {
  grid-template-columns: minmax(0, 1fr) 35rem;
}

.grid-rgt561 {
  grid-template-columns: minmax(0, 1fr) 35.0625rem;
}

.grid-rgt562 {
  grid-template-columns: minmax(0, 1fr) 35.125rem;
}

.grid-rgt563 {
  grid-template-columns: minmax(0, 1fr) 35.1875rem;
}

.grid-rgt564 {
  grid-template-columns: minmax(0, 1fr) 35.25rem;
}

.grid-rgt565 {
  grid-template-columns: minmax(0, 1fr) 35.3125rem;
}

.grid-rgt566 {
  grid-template-columns: minmax(0, 1fr) 35.375rem;
}

.grid-rgt567 {
  grid-template-columns: minmax(0, 1fr) 35.4375rem;
}

.grid-rgt568 {
  grid-template-columns: minmax(0, 1fr) 35.5rem;
}

.grid-rgt569 {
  grid-template-columns: minmax(0, 1fr) 35.5625rem;
}

.grid-rgt570 {
  grid-template-columns: minmax(0, 1fr) 35.625rem;
}

.grid-rgt571 {
  grid-template-columns: minmax(0, 1fr) 35.6875rem;
}

.grid-rgt572 {
  grid-template-columns: minmax(0, 1fr) 35.75rem;
}

.grid-rgt573 {
  grid-template-columns: minmax(0, 1fr) 35.8125rem;
}

.grid-rgt574 {
  grid-template-columns: minmax(0, 1fr) 35.875rem;
}

.grid-rgt575 {
  grid-template-columns: minmax(0, 1fr) 35.9375rem;
}

.grid-rgt576 {
  grid-template-columns: minmax(0, 1fr) 36rem;
}

.grid-rgt577 {
  grid-template-columns: minmax(0, 1fr) 36.0625rem;
}

.grid-rgt578 {
  grid-template-columns: minmax(0, 1fr) 36.125rem;
}

.grid-rgt579 {
  grid-template-columns: minmax(0, 1fr) 36.1875rem;
}

.grid-rgt580 {
  grid-template-columns: minmax(0, 1fr) 36.25rem;
}

.grid-rgt581 {
  grid-template-columns: minmax(0, 1fr) 36.3125rem;
}

.grid-rgt582 {
  grid-template-columns: minmax(0, 1fr) 36.375rem;
}

.grid-rgt583 {
  grid-template-columns: minmax(0, 1fr) 36.4375rem;
}

.grid-rgt584 {
  grid-template-columns: minmax(0, 1fr) 36.5rem;
}

.grid-rgt585 {
  grid-template-columns: minmax(0, 1fr) 36.5625rem;
}

.grid-rgt586 {
  grid-template-columns: minmax(0, 1fr) 36.625rem;
}

.grid-rgt587 {
  grid-template-columns: minmax(0, 1fr) 36.6875rem;
}

.grid-rgt588 {
  grid-template-columns: minmax(0, 1fr) 36.75rem;
}

.grid-rgt589 {
  grid-template-columns: minmax(0, 1fr) 36.8125rem;
}

.grid-rgt590 {
  grid-template-columns: minmax(0, 1fr) 36.875rem;
}

.grid-rgt591 {
  grid-template-columns: minmax(0, 1fr) 36.9375rem;
}

.grid-rgt592 {
  grid-template-columns: minmax(0, 1fr) 37rem;
}

.grid-rgt593 {
  grid-template-columns: minmax(0, 1fr) 37.0625rem;
}

.grid-rgt594 {
  grid-template-columns: minmax(0, 1fr) 37.125rem;
}

.grid-rgt595 {
  grid-template-columns: minmax(0, 1fr) 37.1875rem;
}

.grid-rgt596 {
  grid-template-columns: minmax(0, 1fr) 37.25rem;
}

.grid-rgt597 {
  grid-template-columns: minmax(0, 1fr) 37.3125rem;
}

.grid-rgt598 {
  grid-template-columns: minmax(0, 1fr) 37.375rem;
}

.grid-rgt599 {
  grid-template-columns: minmax(0, 1fr) 37.4375rem;
}

.grid-rgt600 {
  grid-template-columns: minmax(0, 1fr) 37.5rem;
}

.grid-lft1 {
  grid-template-columns: 0.0625rem minmax(0, 1fr);
}

.grid-lft2 {
  grid-template-columns: 0.125rem minmax(0, 1fr);
}

.grid-lft3 {
  grid-template-columns: 0.1875rem minmax(0, 1fr);
}

.grid-lft4 {
  grid-template-columns: 0.25rem minmax(0, 1fr);
}

.grid-lft5 {
  grid-template-columns: 0.3125rem minmax(0, 1fr);
}

.grid-lft6 {
  grid-template-columns: 0.375rem minmax(0, 1fr);
}

.grid-lft7 {
  grid-template-columns: 0.4375rem minmax(0, 1fr);
}

.grid-lft8 {
  grid-template-columns: 0.5rem minmax(0, 1fr);
}

.grid-lft9 {
  grid-template-columns: 0.5625rem minmax(0, 1fr);
}

.grid-lft10 {
  grid-template-columns: 0.625rem minmax(0, 1fr);
}

.grid-lft11 {
  grid-template-columns: 0.6875rem minmax(0, 1fr);
}

.grid-lft12 {
  grid-template-columns: 0.75rem minmax(0, 1fr);
}

.grid-lft13 {
  grid-template-columns: 0.8125rem minmax(0, 1fr);
}

.grid-lft14 {
  grid-template-columns: 0.875rem minmax(0, 1fr);
}

.grid-lft15 {
  grid-template-columns: 0.9375rem minmax(0, 1fr);
}

.grid-lft16 {
  grid-template-columns: 1rem minmax(0, 1fr);
}

.grid-lft17 {
  grid-template-columns: 1.0625rem minmax(0, 1fr);
}

.grid-lft18 {
  grid-template-columns: 1.125rem minmax(0, 1fr);
}

.grid-lft19 {
  grid-template-columns: 1.1875rem minmax(0, 1fr);
}

.grid-lft20 {
  grid-template-columns: 1.25rem minmax(0, 1fr);
}

.grid-lft21 {
  grid-template-columns: 1.3125rem minmax(0, 1fr);
}

.grid-lft22 {
  grid-template-columns: 1.375rem minmax(0, 1fr);
}

.grid-lft23 {
  grid-template-columns: 1.4375rem minmax(0, 1fr);
}

.grid-lft24 {
  grid-template-columns: 1.5rem minmax(0, 1fr);
}

.grid-lft25 {
  grid-template-columns: 1.5625rem minmax(0, 1fr);
}

.grid-lft26 {
  grid-template-columns: 1.625rem minmax(0, 1fr);
}

.grid-lft27 {
  grid-template-columns: 1.6875rem minmax(0, 1fr);
}

.grid-lft28 {
  grid-template-columns: 1.75rem minmax(0, 1fr);
}

.grid-lft29 {
  grid-template-columns: 1.8125rem minmax(0, 1fr);
}

.grid-lft30 {
  grid-template-columns: 1.875rem minmax(0, 1fr);
}

.grid-lft31 {
  grid-template-columns: 1.9375rem minmax(0, 1fr);
}

.grid-lft32 {
  grid-template-columns: 2rem minmax(0, 1fr);
}

.grid-lft33 {
  grid-template-columns: 2.0625rem minmax(0, 1fr);
}

.grid-lft34 {
  grid-template-columns: 2.125rem minmax(0, 1fr);
}

.grid-lft35 {
  grid-template-columns: 2.1875rem minmax(0, 1fr);
}

.grid-lft36 {
  grid-template-columns: 2.25rem minmax(0, 1fr);
}

.grid-lft37 {
  grid-template-columns: 2.3125rem minmax(0, 1fr);
}

.grid-lft38 {
  grid-template-columns: 2.375rem minmax(0, 1fr);
}

.grid-lft39 {
  grid-template-columns: 2.4375rem minmax(0, 1fr);
}

.grid-lft40 {
  grid-template-columns: 2.5rem minmax(0, 1fr);
}

.grid-lft41 {
  grid-template-columns: 2.5625rem minmax(0, 1fr);
}

.grid-lft42 {
  grid-template-columns: 2.625rem minmax(0, 1fr);
}

.grid-lft43 {
  grid-template-columns: 2.6875rem minmax(0, 1fr);
}

.grid-lft44 {
  grid-template-columns: 2.75rem minmax(0, 1fr);
}

.grid-lft45 {
  grid-template-columns: 2.8125rem minmax(0, 1fr);
}

.grid-lft46 {
  grid-template-columns: 2.875rem minmax(0, 1fr);
}

.grid-lft47 {
  grid-template-columns: 2.9375rem minmax(0, 1fr);
}

.grid-lft48 {
  grid-template-columns: 3rem minmax(0, 1fr);
}

.grid-lft49 {
  grid-template-columns: 3.0625rem minmax(0, 1fr);
}

.grid-lft50 {
  grid-template-columns: 3.125rem minmax(0, 1fr);
}

.grid-lft51 {
  grid-template-columns: 3.1875rem minmax(0, 1fr);
}

.grid-lft52 {
  grid-template-columns: 3.25rem minmax(0, 1fr);
}

.grid-lft53 {
  grid-template-columns: 3.3125rem minmax(0, 1fr);
}

.grid-lft54 {
  grid-template-columns: 3.375rem minmax(0, 1fr);
}

.grid-lft55 {
  grid-template-columns: 3.4375rem minmax(0, 1fr);
}

.grid-lft56 {
  grid-template-columns: 3.5rem minmax(0, 1fr);
}

.grid-lft57 {
  grid-template-columns: 3.5625rem minmax(0, 1fr);
}

.grid-lft58 {
  grid-template-columns: 3.625rem minmax(0, 1fr);
}

.grid-lft59 {
  grid-template-columns: 3.6875rem minmax(0, 1fr);
}

.grid-lft60 {
  grid-template-columns: 3.75rem minmax(0, 1fr);
}

.grid-lft61 {
  grid-template-columns: 3.8125rem minmax(0, 1fr);
}

.grid-lft62 {
  grid-template-columns: 3.875rem minmax(0, 1fr);
}

.grid-lft63 {
  grid-template-columns: 3.9375rem minmax(0, 1fr);
}

.grid-lft64 {
  grid-template-columns: 4rem minmax(0, 1fr);
}

.grid-lft65 {
  grid-template-columns: 4.0625rem minmax(0, 1fr);
}

.grid-lft66 {
  grid-template-columns: 4.125rem minmax(0, 1fr);
}

.grid-lft67 {
  grid-template-columns: 4.1875rem minmax(0, 1fr);
}

.grid-lft68 {
  grid-template-columns: 4.25rem minmax(0, 1fr);
}

.grid-lft69 {
  grid-template-columns: 4.3125rem minmax(0, 1fr);
}

.grid-lft70 {
  grid-template-columns: 4.375rem minmax(0, 1fr);
}

.grid-lft71 {
  grid-template-columns: 4.4375rem minmax(0, 1fr);
}

.grid-lft72 {
  grid-template-columns: 4.5rem minmax(0, 1fr);
}

.grid-lft73 {
  grid-template-columns: 4.5625rem minmax(0, 1fr);
}

.grid-lft74 {
  grid-template-columns: 4.625rem minmax(0, 1fr);
}

.grid-lft75 {
  grid-template-columns: 4.6875rem minmax(0, 1fr);
}

.grid-lft76 {
  grid-template-columns: 4.75rem minmax(0, 1fr);
}

.grid-lft77 {
  grid-template-columns: 4.8125rem minmax(0, 1fr);
}

.grid-lft78 {
  grid-template-columns: 4.875rem minmax(0, 1fr);
}

.grid-lft79 {
  grid-template-columns: 4.9375rem minmax(0, 1fr);
}

.grid-lft80 {
  grid-template-columns: 5rem minmax(0, 1fr);
}

.grid-lft81 {
  grid-template-columns: 5.0625rem minmax(0, 1fr);
}

.grid-lft82 {
  grid-template-columns: 5.125rem minmax(0, 1fr);
}

.grid-lft83 {
  grid-template-columns: 5.1875rem minmax(0, 1fr);
}

.grid-lft84 {
  grid-template-columns: 5.25rem minmax(0, 1fr);
}

.grid-lft85 {
  grid-template-columns: 5.3125rem minmax(0, 1fr);
}

.grid-lft86 {
  grid-template-columns: 5.375rem minmax(0, 1fr);
}

.grid-lft87 {
  grid-template-columns: 5.4375rem minmax(0, 1fr);
}

.grid-lft88 {
  grid-template-columns: 5.5rem minmax(0, 1fr);
}

.grid-lft89 {
  grid-template-columns: 5.5625rem minmax(0, 1fr);
}

.grid-lft90 {
  grid-template-columns: 5.625rem minmax(0, 1fr);
}

.grid-lft91 {
  grid-template-columns: 5.6875rem minmax(0, 1fr);
}

.grid-lft92 {
  grid-template-columns: 5.75rem minmax(0, 1fr);
}

.grid-lft93 {
  grid-template-columns: 5.8125rem minmax(0, 1fr);
}

.grid-lft94 {
  grid-template-columns: 5.875rem minmax(0, 1fr);
}

.grid-lft95 {
  grid-template-columns: 5.9375rem minmax(0, 1fr);
}

.grid-lft96 {
  grid-template-columns: 6rem minmax(0, 1fr);
}

.grid-lft97 {
  grid-template-columns: 6.0625rem minmax(0, 1fr);
}

.grid-lft98 {
  grid-template-columns: 6.125rem minmax(0, 1fr);
}

.grid-lft99 {
  grid-template-columns: 6.1875rem minmax(0, 1fr);
}

.grid-lft100 {
  grid-template-columns: 6.25rem minmax(0, 1fr);
}

.grid-lft101 {
  grid-template-columns: 6.3125rem minmax(0, 1fr);
}

.grid-lft102 {
  grid-template-columns: 6.375rem minmax(0, 1fr);
}

.grid-lft103 {
  grid-template-columns: 6.4375rem minmax(0, 1fr);
}

.grid-lft104 {
  grid-template-columns: 6.5rem minmax(0, 1fr);
}

.grid-lft105 {
  grid-template-columns: 6.5625rem minmax(0, 1fr);
}

.grid-lft106 {
  grid-template-columns: 6.625rem minmax(0, 1fr);
}

.grid-lft107 {
  grid-template-columns: 6.6875rem minmax(0, 1fr);
}

.grid-lft108 {
  grid-template-columns: 6.75rem minmax(0, 1fr);
}

.grid-lft109 {
  grid-template-columns: 6.8125rem minmax(0, 1fr);
}

.grid-lft110 {
  grid-template-columns: 6.875rem minmax(0, 1fr);
}

.grid-lft111 {
  grid-template-columns: 6.9375rem minmax(0, 1fr);
}

.grid-lft112 {
  grid-template-columns: 7rem minmax(0, 1fr);
}

.grid-lft113 {
  grid-template-columns: 7.0625rem minmax(0, 1fr);
}

.grid-lft114 {
  grid-template-columns: 7.125rem minmax(0, 1fr);
}

.grid-lft115 {
  grid-template-columns: 7.1875rem minmax(0, 1fr);
}

.grid-lft116 {
  grid-template-columns: 7.25rem minmax(0, 1fr);
}

.grid-lft117 {
  grid-template-columns: 7.3125rem minmax(0, 1fr);
}

.grid-lft118 {
  grid-template-columns: 7.375rem minmax(0, 1fr);
}

.grid-lft119 {
  grid-template-columns: 7.4375rem minmax(0, 1fr);
}

.grid-lft120 {
  grid-template-columns: 7.5rem minmax(0, 1fr);
}

.grid-lft121 {
  grid-template-columns: 7.5625rem minmax(0, 1fr);
}

.grid-lft122 {
  grid-template-columns: 7.625rem minmax(0, 1fr);
}

.grid-lft123 {
  grid-template-columns: 7.6875rem minmax(0, 1fr);
}

.grid-lft124 {
  grid-template-columns: 7.75rem minmax(0, 1fr);
}

.grid-lft125 {
  grid-template-columns: 7.8125rem minmax(0, 1fr);
}

.grid-lft126 {
  grid-template-columns: 7.875rem minmax(0, 1fr);
}

.grid-lft127 {
  grid-template-columns: 7.9375rem minmax(0, 1fr);
}

.grid-lft128 {
  grid-template-columns: 8rem minmax(0, 1fr);
}

.grid-lft129 {
  grid-template-columns: 8.0625rem minmax(0, 1fr);
}

.grid-lft130 {
  grid-template-columns: 8.125rem minmax(0, 1fr);
}

.grid-lft131 {
  grid-template-columns: 8.1875rem minmax(0, 1fr);
}

.grid-lft132 {
  grid-template-columns: 8.25rem minmax(0, 1fr);
}

.grid-lft133 {
  grid-template-columns: 8.3125rem minmax(0, 1fr);
}

.grid-lft134 {
  grid-template-columns: 8.375rem minmax(0, 1fr);
}

.grid-lft135 {
  grid-template-columns: 8.4375rem minmax(0, 1fr);
}

.grid-lft136 {
  grid-template-columns: 8.5rem minmax(0, 1fr);
}

.grid-lft137 {
  grid-template-columns: 8.5625rem minmax(0, 1fr);
}

.grid-lft138 {
  grid-template-columns: 8.625rem minmax(0, 1fr);
}

.grid-lft139 {
  grid-template-columns: 8.6875rem minmax(0, 1fr);
}

.grid-lft140 {
  grid-template-columns: 8.75rem minmax(0, 1fr);
}

.grid-lft141 {
  grid-template-columns: 8.8125rem minmax(0, 1fr);
}

.grid-lft142 {
  grid-template-columns: 8.875rem minmax(0, 1fr);
}

.grid-lft143 {
  grid-template-columns: 8.9375rem minmax(0, 1fr);
}

.grid-lft144 {
  grid-template-columns: 9rem minmax(0, 1fr);
}

.grid-lft145 {
  grid-template-columns: 9.0625rem minmax(0, 1fr);
}

.grid-lft146 {
  grid-template-columns: 9.125rem minmax(0, 1fr);
}

.grid-lft147 {
  grid-template-columns: 9.1875rem minmax(0, 1fr);
}

.grid-lft148 {
  grid-template-columns: 9.25rem minmax(0, 1fr);
}

.grid-lft149 {
  grid-template-columns: 9.3125rem minmax(0, 1fr);
}

.grid-lft150 {
  grid-template-columns: 9.375rem minmax(0, 1fr);
}

.grid-lft151 {
  grid-template-columns: 9.4375rem minmax(0, 1fr);
}

.grid-lft152 {
  grid-template-columns: 9.5rem minmax(0, 1fr);
}

.grid-lft153 {
  grid-template-columns: 9.5625rem minmax(0, 1fr);
}

.grid-lft154 {
  grid-template-columns: 9.625rem minmax(0, 1fr);
}

.grid-lft155 {
  grid-template-columns: 9.6875rem minmax(0, 1fr);
}

.grid-lft156 {
  grid-template-columns: 9.75rem minmax(0, 1fr);
}

.grid-lft157 {
  grid-template-columns: 9.8125rem minmax(0, 1fr);
}

.grid-lft158 {
  grid-template-columns: 9.875rem minmax(0, 1fr);
}

.grid-lft159 {
  grid-template-columns: 9.9375rem minmax(0, 1fr);
}

.grid-lft160 {
  grid-template-columns: 10rem minmax(0, 1fr);
}

.grid-lft161 {
  grid-template-columns: 10.0625rem minmax(0, 1fr);
}

.grid-lft162 {
  grid-template-columns: 10.125rem minmax(0, 1fr);
}

.grid-lft163 {
  grid-template-columns: 10.1875rem minmax(0, 1fr);
}

.grid-lft164 {
  grid-template-columns: 10.25rem minmax(0, 1fr);
}

.grid-lft165 {
  grid-template-columns: 10.3125rem minmax(0, 1fr);
}

.grid-lft166 {
  grid-template-columns: 10.375rem minmax(0, 1fr);
}

.grid-lft167 {
  grid-template-columns: 10.4375rem minmax(0, 1fr);
}

.grid-lft168 {
  grid-template-columns: 10.5rem minmax(0, 1fr);
}

.grid-lft169 {
  grid-template-columns: 10.5625rem minmax(0, 1fr);
}

.grid-lft170 {
  grid-template-columns: 10.625rem minmax(0, 1fr);
}

.grid-lft171 {
  grid-template-columns: 10.6875rem minmax(0, 1fr);
}

.grid-lft172 {
  grid-template-columns: 10.75rem minmax(0, 1fr);
}

.grid-lft173 {
  grid-template-columns: 10.8125rem minmax(0, 1fr);
}

.grid-lft174 {
  grid-template-columns: 10.875rem minmax(0, 1fr);
}

.grid-lft175 {
  grid-template-columns: 10.9375rem minmax(0, 1fr);
}

.grid-lft176 {
  grid-template-columns: 11rem minmax(0, 1fr);
}

.grid-lft177 {
  grid-template-columns: 11.0625rem minmax(0, 1fr);
}

.grid-lft178 {
  grid-template-columns: 11.125rem minmax(0, 1fr);
}

.grid-lft179 {
  grid-template-columns: 11.1875rem minmax(0, 1fr);
}

.grid-lft180 {
  grid-template-columns: 11.25rem minmax(0, 1fr);
}

.grid-lft181 {
  grid-template-columns: 11.3125rem minmax(0, 1fr);
}

.grid-lft182 {
  grid-template-columns: 11.375rem minmax(0, 1fr);
}

.grid-lft183 {
  grid-template-columns: 11.4375rem minmax(0, 1fr);
}

.grid-lft184 {
  grid-template-columns: 11.5rem minmax(0, 1fr);
}

.grid-lft185 {
  grid-template-columns: 11.5625rem minmax(0, 1fr);
}

.grid-lft186 {
  grid-template-columns: 11.625rem minmax(0, 1fr);
}

.grid-lft187 {
  grid-template-columns: 11.6875rem minmax(0, 1fr);
}

.grid-lft188 {
  grid-template-columns: 11.75rem minmax(0, 1fr);
}

.grid-lft189 {
  grid-template-columns: 11.8125rem minmax(0, 1fr);
}

.grid-lft190 {
  grid-template-columns: 11.875rem minmax(0, 1fr);
}

.grid-lft191 {
  grid-template-columns: 11.9375rem minmax(0, 1fr);
}

.grid-lft192 {
  grid-template-columns: 12rem minmax(0, 1fr);
}

.grid-lft193 {
  grid-template-columns: 12.0625rem minmax(0, 1fr);
}

.grid-lft194 {
  grid-template-columns: 12.125rem minmax(0, 1fr);
}

.grid-lft195 {
  grid-template-columns: 12.1875rem minmax(0, 1fr);
}

.grid-lft196 {
  grid-template-columns: 12.25rem minmax(0, 1fr);
}

.grid-lft197 {
  grid-template-columns: 12.3125rem minmax(0, 1fr);
}

.grid-lft198 {
  grid-template-columns: 12.375rem minmax(0, 1fr);
}

.grid-lft199 {
  grid-template-columns: 12.4375rem minmax(0, 1fr);
}

.grid-lft200 {
  grid-template-columns: 12.5rem minmax(0, 1fr);
}

.grid-lft201 {
  grid-template-columns: 12.5625rem minmax(0, 1fr);
}

.grid-lft202 {
  grid-template-columns: 12.625rem minmax(0, 1fr);
}

.grid-lft203 {
  grid-template-columns: 12.6875rem minmax(0, 1fr);
}

.grid-lft204 {
  grid-template-columns: 12.75rem minmax(0, 1fr);
}

.grid-lft205 {
  grid-template-columns: 12.8125rem minmax(0, 1fr);
}

.grid-lft206 {
  grid-template-columns: 12.875rem minmax(0, 1fr);
}

.grid-lft207 {
  grid-template-columns: 12.9375rem minmax(0, 1fr);
}

.grid-lft208 {
  grid-template-columns: 13rem minmax(0, 1fr);
}

.grid-lft209 {
  grid-template-columns: 13.0625rem minmax(0, 1fr);
}

.grid-lft210 {
  grid-template-columns: 13.125rem minmax(0, 1fr);
}

.grid-lft211 {
  grid-template-columns: 13.1875rem minmax(0, 1fr);
}

.grid-lft212 {
  grid-template-columns: 13.25rem minmax(0, 1fr);
}

.grid-lft213 {
  grid-template-columns: 13.3125rem minmax(0, 1fr);
}

.grid-lft214 {
  grid-template-columns: 13.375rem minmax(0, 1fr);
}

.grid-lft215 {
  grid-template-columns: 13.4375rem minmax(0, 1fr);
}

.grid-lft216 {
  grid-template-columns: 13.5rem minmax(0, 1fr);
}

.grid-lft217 {
  grid-template-columns: 13.5625rem minmax(0, 1fr);
}

.grid-lft218 {
  grid-template-columns: 13.625rem minmax(0, 1fr);
}

.grid-lft219 {
  grid-template-columns: 13.6875rem minmax(0, 1fr);
}

.grid-lft220 {
  grid-template-columns: 13.75rem minmax(0, 1fr);
}

.grid-lft221 {
  grid-template-columns: 13.8125rem minmax(0, 1fr);
}

.grid-lft222 {
  grid-template-columns: 13.875rem minmax(0, 1fr);
}

.grid-lft223 {
  grid-template-columns: 13.9375rem minmax(0, 1fr);
}

.grid-lft224 {
  grid-template-columns: 14rem minmax(0, 1fr);
}

.grid-lft225 {
  grid-template-columns: 14.0625rem minmax(0, 1fr);
}

.grid-lft226 {
  grid-template-columns: 14.125rem minmax(0, 1fr);
}

.grid-lft227 {
  grid-template-columns: 14.1875rem minmax(0, 1fr);
}

.grid-lft228 {
  grid-template-columns: 14.25rem minmax(0, 1fr);
}

.grid-lft229 {
  grid-template-columns: 14.3125rem minmax(0, 1fr);
}

.grid-lft230 {
  grid-template-columns: 14.375rem minmax(0, 1fr);
}

.grid-lft231 {
  grid-template-columns: 14.4375rem minmax(0, 1fr);
}

.grid-lft232 {
  grid-template-columns: 14.5rem minmax(0, 1fr);
}

.grid-lft233 {
  grid-template-columns: 14.5625rem minmax(0, 1fr);
}

.grid-lft234 {
  grid-template-columns: 14.625rem minmax(0, 1fr);
}

.grid-lft235 {
  grid-template-columns: 14.6875rem minmax(0, 1fr);
}

.grid-lft236 {
  grid-template-columns: 14.75rem minmax(0, 1fr);
}

.grid-lft237 {
  grid-template-columns: 14.8125rem minmax(0, 1fr);
}

.grid-lft238 {
  grid-template-columns: 14.875rem minmax(0, 1fr);
}

.grid-lft239 {
  grid-template-columns: 14.9375rem minmax(0, 1fr);
}

.grid-lft240 {
  grid-template-columns: 15rem minmax(0, 1fr);
}

.grid-lft241 {
  grid-template-columns: 15.0625rem minmax(0, 1fr);
}

.grid-lft242 {
  grid-template-columns: 15.125rem minmax(0, 1fr);
}

.grid-lft243 {
  grid-template-columns: 15.1875rem minmax(0, 1fr);
}

.grid-lft244 {
  grid-template-columns: 15.25rem minmax(0, 1fr);
}

.grid-lft245 {
  grid-template-columns: 15.3125rem minmax(0, 1fr);
}

.grid-lft246 {
  grid-template-columns: 15.375rem minmax(0, 1fr);
}

.grid-lft247 {
  grid-template-columns: 15.4375rem minmax(0, 1fr);
}

.grid-lft248 {
  grid-template-columns: 15.5rem minmax(0, 1fr);
}

.grid-lft249 {
  grid-template-columns: 15.5625rem minmax(0, 1fr);
}

.grid-lft250 {
  grid-template-columns: 15.625rem minmax(0, 1fr);
}

.grid-lft251 {
  grid-template-columns: 15.6875rem minmax(0, 1fr);
}

.grid-lft252 {
  grid-template-columns: 15.75rem minmax(0, 1fr);
}

.grid-lft253 {
  grid-template-columns: 15.8125rem minmax(0, 1fr);
}

.grid-lft254 {
  grid-template-columns: 15.875rem minmax(0, 1fr);
}

.grid-lft255 {
  grid-template-columns: 15.9375rem minmax(0, 1fr);
}

.grid-lft256 {
  grid-template-columns: 16rem minmax(0, 1fr);
}

.grid-lft257 {
  grid-template-columns: 16.0625rem minmax(0, 1fr);
}

.grid-lft258 {
  grid-template-columns: 16.125rem minmax(0, 1fr);
}

.grid-lft259 {
  grid-template-columns: 16.1875rem minmax(0, 1fr);
}

.grid-lft260 {
  grid-template-columns: 16.25rem minmax(0, 1fr);
}

.grid-lft261 {
  grid-template-columns: 16.3125rem minmax(0, 1fr);
}

.grid-lft262 {
  grid-template-columns: 16.375rem minmax(0, 1fr);
}

.grid-lft263 {
  grid-template-columns: 16.4375rem minmax(0, 1fr);
}

.grid-lft264 {
  grid-template-columns: 16.5rem minmax(0, 1fr);
}

.grid-lft265 {
  grid-template-columns: 16.5625rem minmax(0, 1fr);
}

.grid-lft266 {
  grid-template-columns: 16.625rem minmax(0, 1fr);
}

.grid-lft267 {
  grid-template-columns: 16.6875rem minmax(0, 1fr);
}

.grid-lft268 {
  grid-template-columns: 16.75rem minmax(0, 1fr);
}

.grid-lft269 {
  grid-template-columns: 16.8125rem minmax(0, 1fr);
}

.grid-lft270 {
  grid-template-columns: 16.875rem minmax(0, 1fr);
}

.grid-lft271 {
  grid-template-columns: 16.9375rem minmax(0, 1fr);
}

.grid-lft272 {
  grid-template-columns: 17rem minmax(0, 1fr);
}

.grid-lft273 {
  grid-template-columns: 17.0625rem minmax(0, 1fr);
}

.grid-lft274 {
  grid-template-columns: 17.125rem minmax(0, 1fr);
}

.grid-lft275 {
  grid-template-columns: 17.1875rem minmax(0, 1fr);
}

.grid-lft276 {
  grid-template-columns: 17.25rem minmax(0, 1fr);
}

.grid-lft277 {
  grid-template-columns: 17.3125rem minmax(0, 1fr);
}

.grid-lft278 {
  grid-template-columns: 17.375rem minmax(0, 1fr);
}

.grid-lft279 {
  grid-template-columns: 17.4375rem minmax(0, 1fr);
}

.grid-lft280 {
  grid-template-columns: 17.5rem minmax(0, 1fr);
}

.grid-lft281 {
  grid-template-columns: 17.5625rem minmax(0, 1fr);
}

.grid-lft282 {
  grid-template-columns: 17.625rem minmax(0, 1fr);
}

.grid-lft283 {
  grid-template-columns: 17.6875rem minmax(0, 1fr);
}

.grid-lft284 {
  grid-template-columns: 17.75rem minmax(0, 1fr);
}

.grid-lft285 {
  grid-template-columns: 17.8125rem minmax(0, 1fr);
}

.grid-lft286 {
  grid-template-columns: 17.875rem minmax(0, 1fr);
}

.grid-lft287 {
  grid-template-columns: 17.9375rem minmax(0, 1fr);
}

.grid-lft288 {
  grid-template-columns: 18rem minmax(0, 1fr);
}

.grid-lft289 {
  grid-template-columns: 18.0625rem minmax(0, 1fr);
}

.grid-lft290 {
  grid-template-columns: 18.125rem minmax(0, 1fr);
}

.grid-lft291 {
  grid-template-columns: 18.1875rem minmax(0, 1fr);
}

.grid-lft292 {
  grid-template-columns: 18.25rem minmax(0, 1fr);
}

.grid-lft293 {
  grid-template-columns: 18.3125rem minmax(0, 1fr);
}

.grid-lft294 {
  grid-template-columns: 18.375rem minmax(0, 1fr);
}

.grid-lft295 {
  grid-template-columns: 18.4375rem minmax(0, 1fr);
}

.grid-lft296 {
  grid-template-columns: 18.5rem minmax(0, 1fr);
}

.grid-lft297 {
  grid-template-columns: 18.5625rem minmax(0, 1fr);
}

.grid-lft298 {
  grid-template-columns: 18.625rem minmax(0, 1fr);
}

.grid-lft299 {
  grid-template-columns: 18.6875rem minmax(0, 1fr);
}

.grid-lft300 {
  grid-template-columns: 18.75rem minmax(0, 1fr);
}

.grid-lft301 {
  grid-template-columns: 18.8125rem minmax(0, 1fr);
}

.grid-lft302 {
  grid-template-columns: 18.875rem minmax(0, 1fr);
}

.grid-lft303 {
  grid-template-columns: 18.9375rem minmax(0, 1fr);
}

.grid-lft304 {
  grid-template-columns: 19rem minmax(0, 1fr);
}

.grid-lft305 {
  grid-template-columns: 19.0625rem minmax(0, 1fr);
}

.grid-lft306 {
  grid-template-columns: 19.125rem minmax(0, 1fr);
}

.grid-lft307 {
  grid-template-columns: 19.1875rem minmax(0, 1fr);
}

.grid-lft308 {
  grid-template-columns: 19.25rem minmax(0, 1fr);
}

.grid-lft309 {
  grid-template-columns: 19.3125rem minmax(0, 1fr);
}

.grid-lft310 {
  grid-template-columns: 19.375rem minmax(0, 1fr);
}

.grid-lft311 {
  grid-template-columns: 19.4375rem minmax(0, 1fr);
}

.grid-lft312 {
  grid-template-columns: 19.5rem minmax(0, 1fr);
}

.grid-lft313 {
  grid-template-columns: 19.5625rem minmax(0, 1fr);
}

.grid-lft314 {
  grid-template-columns: 19.625rem minmax(0, 1fr);
}

.grid-lft315 {
  grid-template-columns: 19.6875rem minmax(0, 1fr);
}

.grid-lft316 {
  grid-template-columns: 19.75rem minmax(0, 1fr);
}

.grid-lft317 {
  grid-template-columns: 19.8125rem minmax(0, 1fr);
}

.grid-lft318 {
  grid-template-columns: 19.875rem minmax(0, 1fr);
}

.grid-lft319 {
  grid-template-columns: 19.9375rem minmax(0, 1fr);
}

.grid-lft320 {
  grid-template-columns: 20rem minmax(0, 1fr);
}

.grid-lft321 {
  grid-template-columns: 20.0625rem minmax(0, 1fr);
}

.grid-lft322 {
  grid-template-columns: 20.125rem minmax(0, 1fr);
}

.grid-lft323 {
  grid-template-columns: 20.1875rem minmax(0, 1fr);
}

.grid-lft324 {
  grid-template-columns: 20.25rem minmax(0, 1fr);
}

.grid-lft325 {
  grid-template-columns: 20.3125rem minmax(0, 1fr);
}

.grid-lft326 {
  grid-template-columns: 20.375rem minmax(0, 1fr);
}

.grid-lft327 {
  grid-template-columns: 20.4375rem minmax(0, 1fr);
}

.grid-lft328 {
  grid-template-columns: 20.5rem minmax(0, 1fr);
}

.grid-lft329 {
  grid-template-columns: 20.5625rem minmax(0, 1fr);
}

.grid-lft330 {
  grid-template-columns: 20.625rem minmax(0, 1fr);
}

.grid-lft331 {
  grid-template-columns: 20.6875rem minmax(0, 1fr);
}

.grid-lft332 {
  grid-template-columns: 20.75rem minmax(0, 1fr);
}

.grid-lft333 {
  grid-template-columns: 20.8125rem minmax(0, 1fr);
}

.grid-lft334 {
  grid-template-columns: 20.875rem minmax(0, 1fr);
}

.grid-lft335 {
  grid-template-columns: 20.9375rem minmax(0, 1fr);
}

.grid-lft336 {
  grid-template-columns: 21rem minmax(0, 1fr);
}

.grid-lft337 {
  grid-template-columns: 21.0625rem minmax(0, 1fr);
}

.grid-lft338 {
  grid-template-columns: 21.125rem minmax(0, 1fr);
}

.grid-lft339 {
  grid-template-columns: 21.1875rem minmax(0, 1fr);
}

.grid-lft340 {
  grid-template-columns: 21.25rem minmax(0, 1fr);
}

.grid-lft341 {
  grid-template-columns: 21.3125rem minmax(0, 1fr);
}

.grid-lft342 {
  grid-template-columns: 21.375rem minmax(0, 1fr);
}

.grid-lft343 {
  grid-template-columns: 21.4375rem minmax(0, 1fr);
}

.grid-lft344 {
  grid-template-columns: 21.5rem minmax(0, 1fr);
}

.grid-lft345 {
  grid-template-columns: 21.5625rem minmax(0, 1fr);
}

.grid-lft346 {
  grid-template-columns: 21.625rem minmax(0, 1fr);
}

.grid-lft347 {
  grid-template-columns: 21.6875rem minmax(0, 1fr);
}

.grid-lft348 {
  grid-template-columns: 21.75rem minmax(0, 1fr);
}

.grid-lft349 {
  grid-template-columns: 21.8125rem minmax(0, 1fr);
}

.grid-lft350 {
  grid-template-columns: 21.875rem minmax(0, 1fr);
}

.grid-lft351 {
  grid-template-columns: 21.9375rem minmax(0, 1fr);
}

.grid-lft352 {
  grid-template-columns: 22rem minmax(0, 1fr);
}

.grid-lft353 {
  grid-template-columns: 22.0625rem minmax(0, 1fr);
}

.grid-lft354 {
  grid-template-columns: 22.125rem minmax(0, 1fr);
}

.grid-lft355 {
  grid-template-columns: 22.1875rem minmax(0, 1fr);
}

.grid-lft356 {
  grid-template-columns: 22.25rem minmax(0, 1fr);
}

.grid-lft357 {
  grid-template-columns: 22.3125rem minmax(0, 1fr);
}

.grid-lft358 {
  grid-template-columns: 22.375rem minmax(0, 1fr);
}

.grid-lft359 {
  grid-template-columns: 22.4375rem minmax(0, 1fr);
}

.grid-lft360 {
  grid-template-columns: 22.5rem minmax(0, 1fr);
}

.grid-lft361 {
  grid-template-columns: 22.5625rem minmax(0, 1fr);
}

.grid-lft362 {
  grid-template-columns: 22.625rem minmax(0, 1fr);
}

.grid-lft363 {
  grid-template-columns: 22.6875rem minmax(0, 1fr);
}

.grid-lft364 {
  grid-template-columns: 22.75rem minmax(0, 1fr);
}

.grid-lft365 {
  grid-template-columns: 22.8125rem minmax(0, 1fr);
}

.grid-lft366 {
  grid-template-columns: 22.875rem minmax(0, 1fr);
}

.grid-lft367 {
  grid-template-columns: 22.9375rem minmax(0, 1fr);
}

.grid-lft368 {
  grid-template-columns: 23rem minmax(0, 1fr);
}

.grid-lft369 {
  grid-template-columns: 23.0625rem minmax(0, 1fr);
}

.grid-lft370 {
  grid-template-columns: 23.125rem minmax(0, 1fr);
}

.grid-lft371 {
  grid-template-columns: 23.1875rem minmax(0, 1fr);
}

.grid-lft372 {
  grid-template-columns: 23.25rem minmax(0, 1fr);
}

.grid-lft373 {
  grid-template-columns: 23.3125rem minmax(0, 1fr);
}

.grid-lft374 {
  grid-template-columns: 23.375rem minmax(0, 1fr);
}

.grid-lft375 {
  grid-template-columns: 23.4375rem minmax(0, 1fr);
}

.grid-lft376 {
  grid-template-columns: 23.5rem minmax(0, 1fr);
}

.grid-lft377 {
  grid-template-columns: 23.5625rem minmax(0, 1fr);
}

.grid-lft378 {
  grid-template-columns: 23.625rem minmax(0, 1fr);
}

.grid-lft379 {
  grid-template-columns: 23.6875rem minmax(0, 1fr);
}

.grid-lft380 {
  grid-template-columns: 23.75rem minmax(0, 1fr);
}

.grid-lft381 {
  grid-template-columns: 23.8125rem minmax(0, 1fr);
}

.grid-lft382 {
  grid-template-columns: 23.875rem minmax(0, 1fr);
}

.grid-lft383 {
  grid-template-columns: 23.9375rem minmax(0, 1fr);
}

.grid-lft384 {
  grid-template-columns: 24rem minmax(0, 1fr);
}

.grid-lft385 {
  grid-template-columns: 24.0625rem minmax(0, 1fr);
}

.grid-lft386 {
  grid-template-columns: 24.125rem minmax(0, 1fr);
}

.grid-lft387 {
  grid-template-columns: 24.1875rem minmax(0, 1fr);
}

.grid-lft388 {
  grid-template-columns: 24.25rem minmax(0, 1fr);
}

.grid-lft389 {
  grid-template-columns: 24.3125rem minmax(0, 1fr);
}

.grid-lft390 {
  grid-template-columns: 24.375rem minmax(0, 1fr);
}

.grid-lft391 {
  grid-template-columns: 24.4375rem minmax(0, 1fr);
}

.grid-lft392 {
  grid-template-columns: 24.5rem minmax(0, 1fr);
}

.grid-lft393 {
  grid-template-columns: 24.5625rem minmax(0, 1fr);
}

.grid-lft394 {
  grid-template-columns: 24.625rem minmax(0, 1fr);
}

.grid-lft395 {
  grid-template-columns: 24.6875rem minmax(0, 1fr);
}

.grid-lft396 {
  grid-template-columns: 24.75rem minmax(0, 1fr);
}

.grid-lft397 {
  grid-template-columns: 24.8125rem minmax(0, 1fr);
}

.grid-lft398 {
  grid-template-columns: 24.875rem minmax(0, 1fr);
}

.grid-lft399 {
  grid-template-columns: 24.9375rem minmax(0, 1fr);
}

.grid-lft400 {
  grid-template-columns: 25rem minmax(0, 1fr);
}

.grid-lft401 {
  grid-template-columns: 25.0625rem minmax(0, 1fr);
}

.grid-lft402 {
  grid-template-columns: 25.125rem minmax(0, 1fr);
}

.grid-lft403 {
  grid-template-columns: 25.1875rem minmax(0, 1fr);
}

.grid-lft404 {
  grid-template-columns: 25.25rem minmax(0, 1fr);
}

.grid-lft405 {
  grid-template-columns: 25.3125rem minmax(0, 1fr);
}

.grid-lft406 {
  grid-template-columns: 25.375rem minmax(0, 1fr);
}

.grid-lft407 {
  grid-template-columns: 25.4375rem minmax(0, 1fr);
}

.grid-lft408 {
  grid-template-columns: 25.5rem minmax(0, 1fr);
}

.grid-lft409 {
  grid-template-columns: 25.5625rem minmax(0, 1fr);
}

.grid-lft410 {
  grid-template-columns: 25.625rem minmax(0, 1fr);
}

.grid-lft411 {
  grid-template-columns: 25.6875rem minmax(0, 1fr);
}

.grid-lft412 {
  grid-template-columns: 25.75rem minmax(0, 1fr);
}

.grid-lft413 {
  grid-template-columns: 25.8125rem minmax(0, 1fr);
}

.grid-lft414 {
  grid-template-columns: 25.875rem minmax(0, 1fr);
}

.grid-lft415 {
  grid-template-columns: 25.9375rem minmax(0, 1fr);
}

.grid-lft416 {
  grid-template-columns: 26rem minmax(0, 1fr);
}

.grid-lft417 {
  grid-template-columns: 26.0625rem minmax(0, 1fr);
}

.grid-lft418 {
  grid-template-columns: 26.125rem minmax(0, 1fr);
}

.grid-lft419 {
  grid-template-columns: 26.1875rem minmax(0, 1fr);
}

.grid-lft420 {
  grid-template-columns: 26.25rem minmax(0, 1fr);
}

.grid-lft421 {
  grid-template-columns: 26.3125rem minmax(0, 1fr);
}

.grid-lft422 {
  grid-template-columns: 26.375rem minmax(0, 1fr);
}

.grid-lft423 {
  grid-template-columns: 26.4375rem minmax(0, 1fr);
}

.grid-lft424 {
  grid-template-columns: 26.5rem minmax(0, 1fr);
}

.grid-lft425 {
  grid-template-columns: 26.5625rem minmax(0, 1fr);
}

.grid-lft426 {
  grid-template-columns: 26.625rem minmax(0, 1fr);
}

.grid-lft427 {
  grid-template-columns: 26.6875rem minmax(0, 1fr);
}

.grid-lft428 {
  grid-template-columns: 26.75rem minmax(0, 1fr);
}

.grid-lft429 {
  grid-template-columns: 26.8125rem minmax(0, 1fr);
}

.grid-lft430 {
  grid-template-columns: 26.875rem minmax(0, 1fr);
}

.grid-lft431 {
  grid-template-columns: 26.9375rem minmax(0, 1fr);
}

.grid-lft432 {
  grid-template-columns: 27rem minmax(0, 1fr);
}

.grid-lft433 {
  grid-template-columns: 27.0625rem minmax(0, 1fr);
}

.grid-lft434 {
  grid-template-columns: 27.125rem minmax(0, 1fr);
}

.grid-lft435 {
  grid-template-columns: 27.1875rem minmax(0, 1fr);
}

.grid-lft436 {
  grid-template-columns: 27.25rem minmax(0, 1fr);
}

.grid-lft437 {
  grid-template-columns: 27.3125rem minmax(0, 1fr);
}

.grid-lft438 {
  grid-template-columns: 27.375rem minmax(0, 1fr);
}

.grid-lft439 {
  grid-template-columns: 27.4375rem minmax(0, 1fr);
}

.grid-lft440 {
  grid-template-columns: 27.5rem minmax(0, 1fr);
}

.grid-lft441 {
  grid-template-columns: 27.5625rem minmax(0, 1fr);
}

.grid-lft442 {
  grid-template-columns: 27.625rem minmax(0, 1fr);
}

.grid-lft443 {
  grid-template-columns: 27.6875rem minmax(0, 1fr);
}

.grid-lft444 {
  grid-template-columns: 27.75rem minmax(0, 1fr);
}

.grid-lft445 {
  grid-template-columns: 27.8125rem minmax(0, 1fr);
}

.grid-lft446 {
  grid-template-columns: 27.875rem minmax(0, 1fr);
}

.grid-lft447 {
  grid-template-columns: 27.9375rem minmax(0, 1fr);
}

.grid-lft448 {
  grid-template-columns: 28rem minmax(0, 1fr);
}

.grid-lft449 {
  grid-template-columns: 28.0625rem minmax(0, 1fr);
}

.grid-lft450 {
  grid-template-columns: 28.125rem minmax(0, 1fr);
}

.grid-lft451 {
  grid-template-columns: 28.1875rem minmax(0, 1fr);
}

.grid-lft452 {
  grid-template-columns: 28.25rem minmax(0, 1fr);
}

.grid-lft453 {
  grid-template-columns: 28.3125rem minmax(0, 1fr);
}

.grid-lft454 {
  grid-template-columns: 28.375rem minmax(0, 1fr);
}

.grid-lft455 {
  grid-template-columns: 28.4375rem minmax(0, 1fr);
}

.grid-lft456 {
  grid-template-columns: 28.5rem minmax(0, 1fr);
}

.grid-lft457 {
  grid-template-columns: 28.5625rem minmax(0, 1fr);
}

.grid-lft458 {
  grid-template-columns: 28.625rem minmax(0, 1fr);
}

.grid-lft459 {
  grid-template-columns: 28.6875rem minmax(0, 1fr);
}

.grid-lft460 {
  grid-template-columns: 28.75rem minmax(0, 1fr);
}

.grid-lft461 {
  grid-template-columns: 28.8125rem minmax(0, 1fr);
}

.grid-lft462 {
  grid-template-columns: 28.875rem minmax(0, 1fr);
}

.grid-lft463 {
  grid-template-columns: 28.9375rem minmax(0, 1fr);
}

.grid-lft464 {
  grid-template-columns: 29rem minmax(0, 1fr);
}

.grid-lft465 {
  grid-template-columns: 29.0625rem minmax(0, 1fr);
}

.grid-lft466 {
  grid-template-columns: 29.125rem minmax(0, 1fr);
}

.grid-lft467 {
  grid-template-columns: 29.1875rem minmax(0, 1fr);
}

.grid-lft468 {
  grid-template-columns: 29.25rem minmax(0, 1fr);
}

.grid-lft469 {
  grid-template-columns: 29.3125rem minmax(0, 1fr);
}

.grid-lft470 {
  grid-template-columns: 29.375rem minmax(0, 1fr);
}

.grid-lft471 {
  grid-template-columns: 29.4375rem minmax(0, 1fr);
}

.grid-lft472 {
  grid-template-columns: 29.5rem minmax(0, 1fr);
}

.grid-lft473 {
  grid-template-columns: 29.5625rem minmax(0, 1fr);
}

.grid-lft474 {
  grid-template-columns: 29.625rem minmax(0, 1fr);
}

.grid-lft475 {
  grid-template-columns: 29.6875rem minmax(0, 1fr);
}

.grid-lft476 {
  grid-template-columns: 29.75rem minmax(0, 1fr);
}

.grid-lft477 {
  grid-template-columns: 29.8125rem minmax(0, 1fr);
}

.grid-lft478 {
  grid-template-columns: 29.875rem minmax(0, 1fr);
}

.grid-lft479 {
  grid-template-columns: 29.9375rem minmax(0, 1fr);
}

.grid-lft480 {
  grid-template-columns: 30rem minmax(0, 1fr);
}

.grid-lft481 {
  grid-template-columns: 30.0625rem minmax(0, 1fr);
}

.grid-lft482 {
  grid-template-columns: 30.125rem minmax(0, 1fr);
}

.grid-lft483 {
  grid-template-columns: 30.1875rem minmax(0, 1fr);
}

.grid-lft484 {
  grid-template-columns: 30.25rem minmax(0, 1fr);
}

.grid-lft485 {
  grid-template-columns: 30.3125rem minmax(0, 1fr);
}

.grid-lft486 {
  grid-template-columns: 30.375rem minmax(0, 1fr);
}

.grid-lft487 {
  grid-template-columns: 30.4375rem minmax(0, 1fr);
}

.grid-lft488 {
  grid-template-columns: 30.5rem minmax(0, 1fr);
}

.grid-lft489 {
  grid-template-columns: 30.5625rem minmax(0, 1fr);
}

.grid-lft490 {
  grid-template-columns: 30.625rem minmax(0, 1fr);
}

.grid-lft491 {
  grid-template-columns: 30.6875rem minmax(0, 1fr);
}

.grid-lft492 {
  grid-template-columns: 30.75rem minmax(0, 1fr);
}

.grid-lft493 {
  grid-template-columns: 30.8125rem minmax(0, 1fr);
}

.grid-lft494 {
  grid-template-columns: 30.875rem minmax(0, 1fr);
}

.grid-lft495 {
  grid-template-columns: 30.9375rem minmax(0, 1fr);
}

.grid-lft496 {
  grid-template-columns: 31rem minmax(0, 1fr);
}

.grid-lft497 {
  grid-template-columns: 31.0625rem minmax(0, 1fr);
}

.grid-lft498 {
  grid-template-columns: 31.125rem minmax(0, 1fr);
}

.grid-lft499 {
  grid-template-columns: 31.1875rem minmax(0, 1fr);
}

.grid-lft500 {
  grid-template-columns: 31.25rem minmax(0, 1fr);
}

.grid-lft501 {
  grid-template-columns: 31.3125rem minmax(0, 1fr);
}

.grid-lft502 {
  grid-template-columns: 31.375rem minmax(0, 1fr);
}

.grid-lft503 {
  grid-template-columns: 31.4375rem minmax(0, 1fr);
}

.grid-lft504 {
  grid-template-columns: 31.5rem minmax(0, 1fr);
}

.grid-lft505 {
  grid-template-columns: 31.5625rem minmax(0, 1fr);
}

.grid-lft506 {
  grid-template-columns: 31.625rem minmax(0, 1fr);
}

.grid-lft507 {
  grid-template-columns: 31.6875rem minmax(0, 1fr);
}

.grid-lft508 {
  grid-template-columns: 31.75rem minmax(0, 1fr);
}

.grid-lft509 {
  grid-template-columns: 31.8125rem minmax(0, 1fr);
}

.grid-lft510 {
  grid-template-columns: 31.875rem minmax(0, 1fr);
}

.grid-lft511 {
  grid-template-columns: 31.9375rem minmax(0, 1fr);
}

.grid-lft512 {
  grid-template-columns: 32rem minmax(0, 1fr);
}

.grid-lft513 {
  grid-template-columns: 32.0625rem minmax(0, 1fr);
}

.grid-lft514 {
  grid-template-columns: 32.125rem minmax(0, 1fr);
}

.grid-lft515 {
  grid-template-columns: 32.1875rem minmax(0, 1fr);
}

.grid-lft516 {
  grid-template-columns: 32.25rem minmax(0, 1fr);
}

.grid-lft517 {
  grid-template-columns: 32.3125rem minmax(0, 1fr);
}

.grid-lft518 {
  grid-template-columns: 32.375rem minmax(0, 1fr);
}

.grid-lft519 {
  grid-template-columns: 32.4375rem minmax(0, 1fr);
}

.grid-lft520 {
  grid-template-columns: 32.5rem minmax(0, 1fr);
}

.grid-lft521 {
  grid-template-columns: 32.5625rem minmax(0, 1fr);
}

.grid-lft522 {
  grid-template-columns: 32.625rem minmax(0, 1fr);
}

.grid-lft523 {
  grid-template-columns: 32.6875rem minmax(0, 1fr);
}

.grid-lft524 {
  grid-template-columns: 32.75rem minmax(0, 1fr);
}

.grid-lft525 {
  grid-template-columns: 32.8125rem minmax(0, 1fr);
}

.grid-lft526 {
  grid-template-columns: 32.875rem minmax(0, 1fr);
}

.grid-lft527 {
  grid-template-columns: 32.9375rem minmax(0, 1fr);
}

.grid-lft528 {
  grid-template-columns: 33rem minmax(0, 1fr);
}

.grid-lft529 {
  grid-template-columns: 33.0625rem minmax(0, 1fr);
}

.grid-lft530 {
  grid-template-columns: 33.125rem minmax(0, 1fr);
}

.grid-lft531 {
  grid-template-columns: 33.1875rem minmax(0, 1fr);
}

.grid-lft532 {
  grid-template-columns: 33.25rem minmax(0, 1fr);
}

.grid-lft533 {
  grid-template-columns: 33.3125rem minmax(0, 1fr);
}

.grid-lft534 {
  grid-template-columns: 33.375rem minmax(0, 1fr);
}

.grid-lft535 {
  grid-template-columns: 33.4375rem minmax(0, 1fr);
}

.grid-lft536 {
  grid-template-columns: 33.5rem minmax(0, 1fr);
}

.grid-lft537 {
  grid-template-columns: 33.5625rem minmax(0, 1fr);
}

.grid-lft538 {
  grid-template-columns: 33.625rem minmax(0, 1fr);
}

.grid-lft539 {
  grid-template-columns: 33.6875rem minmax(0, 1fr);
}

.grid-lft540 {
  grid-template-columns: 33.75rem minmax(0, 1fr);
}

.grid-lft541 {
  grid-template-columns: 33.8125rem minmax(0, 1fr);
}

.grid-lft542 {
  grid-template-columns: 33.875rem minmax(0, 1fr);
}

.grid-lft543 {
  grid-template-columns: 33.9375rem minmax(0, 1fr);
}

.grid-lft544 {
  grid-template-columns: 34rem minmax(0, 1fr);
}

.grid-lft545 {
  grid-template-columns: 34.0625rem minmax(0, 1fr);
}

.grid-lft546 {
  grid-template-columns: 34.125rem minmax(0, 1fr);
}

.grid-lft547 {
  grid-template-columns: 34.1875rem minmax(0, 1fr);
}

.grid-lft548 {
  grid-template-columns: 34.25rem minmax(0, 1fr);
}

.grid-lft549 {
  grid-template-columns: 34.3125rem minmax(0, 1fr);
}

.grid-lft550 {
  grid-template-columns: 34.375rem minmax(0, 1fr);
}

.grid-lft551 {
  grid-template-columns: 34.4375rem minmax(0, 1fr);
}

.grid-lft552 {
  grid-template-columns: 34.5rem minmax(0, 1fr);
}

.grid-lft553 {
  grid-template-columns: 34.5625rem minmax(0, 1fr);
}

.grid-lft554 {
  grid-template-columns: 34.625rem minmax(0, 1fr);
}

.grid-lft555 {
  grid-template-columns: 34.6875rem minmax(0, 1fr);
}

.grid-lft556 {
  grid-template-columns: 34.75rem minmax(0, 1fr);
}

.grid-lft557 {
  grid-template-columns: 34.8125rem minmax(0, 1fr);
}

.grid-lft558 {
  grid-template-columns: 34.875rem minmax(0, 1fr);
}

.grid-lft559 {
  grid-template-columns: 34.9375rem minmax(0, 1fr);
}

.grid-lft560 {
  grid-template-columns: 35rem minmax(0, 1fr);
}

.grid-lft561 {
  grid-template-columns: 35.0625rem minmax(0, 1fr);
}

.grid-lft562 {
  grid-template-columns: 35.125rem minmax(0, 1fr);
}

.grid-lft563 {
  grid-template-columns: 35.1875rem minmax(0, 1fr);
}

.grid-lft564 {
  grid-template-columns: 35.25rem minmax(0, 1fr);
}

.grid-lft565 {
  grid-template-columns: 35.3125rem minmax(0, 1fr);
}

.grid-lft566 {
  grid-template-columns: 35.375rem minmax(0, 1fr);
}

.grid-lft567 {
  grid-template-columns: 35.4375rem minmax(0, 1fr);
}

.grid-lft568 {
  grid-template-columns: 35.5rem minmax(0, 1fr);
}

.grid-lft569 {
  grid-template-columns: 35.5625rem minmax(0, 1fr);
}

.grid-lft570 {
  grid-template-columns: 35.625rem minmax(0, 1fr);
}

.grid-lft571 {
  grid-template-columns: 35.6875rem minmax(0, 1fr);
}

.grid-lft572 {
  grid-template-columns: 35.75rem minmax(0, 1fr);
}

.grid-lft573 {
  grid-template-columns: 35.8125rem minmax(0, 1fr);
}

.grid-lft574 {
  grid-template-columns: 35.875rem minmax(0, 1fr);
}

.grid-lft575 {
  grid-template-columns: 35.9375rem minmax(0, 1fr);
}

.grid-lft576 {
  grid-template-columns: 36rem minmax(0, 1fr);
}

.grid-lft577 {
  grid-template-columns: 36.0625rem minmax(0, 1fr);
}

.grid-lft578 {
  grid-template-columns: 36.125rem minmax(0, 1fr);
}

.grid-lft579 {
  grid-template-columns: 36.1875rem minmax(0, 1fr);
}

.grid-lft580 {
  grid-template-columns: 36.25rem minmax(0, 1fr);
}

.grid-lft581 {
  grid-template-columns: 36.3125rem minmax(0, 1fr);
}

.grid-lft582 {
  grid-template-columns: 36.375rem minmax(0, 1fr);
}

.grid-lft583 {
  grid-template-columns: 36.4375rem minmax(0, 1fr);
}

.grid-lft584 {
  grid-template-columns: 36.5rem minmax(0, 1fr);
}

.grid-lft585 {
  grid-template-columns: 36.5625rem minmax(0, 1fr);
}

.grid-lft586 {
  grid-template-columns: 36.625rem minmax(0, 1fr);
}

.grid-lft587 {
  grid-template-columns: 36.6875rem minmax(0, 1fr);
}

.grid-lft588 {
  grid-template-columns: 36.75rem minmax(0, 1fr);
}

.grid-lft589 {
  grid-template-columns: 36.8125rem minmax(0, 1fr);
}

.grid-lft590 {
  grid-template-columns: 36.875rem minmax(0, 1fr);
}

.grid-lft591 {
  grid-template-columns: 36.9375rem minmax(0, 1fr);
}

.grid-lft592 {
  grid-template-columns: 37rem minmax(0, 1fr);
}

.grid-lft593 {
  grid-template-columns: 37.0625rem minmax(0, 1fr);
}

.grid-lft594 {
  grid-template-columns: 37.125rem minmax(0, 1fr);
}

.grid-lft595 {
  grid-template-columns: 37.1875rem minmax(0, 1fr);
}

.grid-lft596 {
  grid-template-columns: 37.25rem minmax(0, 1fr);
}

.grid-lft597 {
  grid-template-columns: 37.3125rem minmax(0, 1fr);
}

.grid-lft598 {
  grid-template-columns: 37.375rem minmax(0, 1fr);
}

.grid-lft599 {
  grid-template-columns: 37.4375rem minmax(0, 1fr);
}

.grid-lft600 {
  grid-template-columns: 37.5rem minmax(0, 1fr);
}

.gap1 {
  gap: 0.0625rem;
}

.gap2 {
  gap: 0.125rem;
}

.gap3 {
  gap: 0.1875rem;
}

.gap4 {
  gap: 0.25rem;
}

.gap5 {
  gap: 0.3125rem;
}

.gap6 {
  gap: 0.375rem;
}

.gap7 {
  gap: 0.4375rem;
}

.gap8 {
  gap: 0.5rem;
}

.gap9 {
  gap: 0.5625rem;
}

.gap10 {
  gap: 0.625rem;
}

.gap11 {
  gap: 0.6875rem;
}

.gap12 {
  gap: 0.75rem;
}

.gap13 {
  gap: 0.8125rem;
}

.gap14 {
  gap: 0.875rem;
}

.gap15 {
  gap: 0.9375rem;
}

.gap16 {
  gap: 1rem;
}

.gap17 {
  gap: 1.0625rem;
}

.gap18 {
  gap: 1.125rem;
}

.gap19 {
  gap: 1.1875rem;
}

.gap20 {
  gap: 1.25rem;
}

.gap21 {
  gap: 1.3125rem;
}

.gap22 {
  gap: 1.375rem;
}

.gap23 {
  gap: 1.4375rem;
}

.gap24 {
  gap: 1.5rem;
}

.gap25 {
  gap: 1.5625rem;
}

.gap26 {
  gap: 1.625rem;
}

.gap27 {
  gap: 1.6875rem;
}

.gap28 {
  gap: 1.75rem;
}

.gap29 {
  gap: 1.8125rem;
}

.gap30 {
  gap: 1.875rem;
}

.gap31 {
  gap: 1.9375rem;
}

.gap32 {
  gap: 2rem;
}

.gap33 {
  gap: 2.0625rem;
}

.gap34 {
  gap: 2.125rem;
}

.gap35 {
  gap: 2.1875rem;
}

.gap36 {
  gap: 2.25rem;
}

.gap37 {
  gap: 2.3125rem;
}

.gap38 {
  gap: 2.375rem;
}

.gap39 {
  gap: 2.4375rem;
}

.gap40 {
  gap: 2.5rem;
}

.gap41 {
  gap: 2.5625rem;
}

.gap42 {
  gap: 2.625rem;
}

.gap43 {
  gap: 2.6875rem;
}

.gap44 {
  gap: 2.75rem;
}

.gap45 {
  gap: 2.8125rem;
}

.gap46 {
  gap: 2.875rem;
}

.gap47 {
  gap: 2.9375rem;
}

.gap48 {
  gap: 3rem;
}

.gap49 {
  gap: 3.0625rem;
}

.gap50 {
  gap: 3.125rem;
}

.gap51 {
  gap: 3.1875rem;
}

.gap52 {
  gap: 3.25rem;
}

.gap53 {
  gap: 3.3125rem;
}

.gap54 {
  gap: 3.375rem;
}

.gap55 {
  gap: 3.4375rem;
}

.gap56 {
  gap: 3.5rem;
}

.gap57 {
  gap: 3.5625rem;
}

.gap58 {
  gap: 3.625rem;
}

.gap59 {
  gap: 3.6875rem;
}

.gap60 {
  gap: 3.75rem;
}

.gap61 {
  gap: 3.8125rem;
}

.gap62 {
  gap: 3.875rem;
}

.gap63 {
  gap: 3.9375rem;
}

.gap64 {
  gap: 4rem;
}

.gap65 {
  gap: 4.0625rem;
}

.gap66 {
  gap: 4.125rem;
}

.gap67 {
  gap: 4.1875rem;
}

.gap68 {
  gap: 4.25rem;
}

.gap69 {
  gap: 4.3125rem;
}

.gap70 {
  gap: 4.375rem;
}

.gap71 {
  gap: 4.4375rem;
}

.gap72 {
  gap: 4.5rem;
}

.gap73 {
  gap: 4.5625rem;
}

.gap74 {
  gap: 4.625rem;
}

.gap75 {
  gap: 4.6875rem;
}

.gap76 {
  gap: 4.75rem;
}

.gap77 {
  gap: 4.8125rem;
}

.gap78 {
  gap: 4.875rem;
}

.gap79 {
  gap: 4.9375rem;
}

.gap80 {
  gap: 5rem;
}

.gap81 {
  gap: 5.0625rem;
}

.gap82 {
  gap: 5.125rem;
}

.gap83 {
  gap: 5.1875rem;
}

.gap84 {
  gap: 5.25rem;
}

.gap85 {
  gap: 5.3125rem;
}

.gap86 {
  gap: 5.375rem;
}

.gap87 {
  gap: 5.4375rem;
}

.gap88 {
  gap: 5.5rem;
}

.gap89 {
  gap: 5.5625rem;
}

.gap90 {
  gap: 5.625rem;
}

.gap91 {
  gap: 5.6875rem;
}

.gap92 {
  gap: 5.75rem;
}

.gap93 {
  gap: 5.8125rem;
}

.gap94 {
  gap: 5.875rem;
}

.gap95 {
  gap: 5.9375rem;
}

.gap96 {
  gap: 6rem;
}

.gap97 {
  gap: 6.0625rem;
}

.gap98 {
  gap: 6.125rem;
}

.gap99 {
  gap: 6.1875rem;
}

.gap100 {
  gap: 6.25rem;
}

.gap101 {
  gap: 6.3125rem;
}

.gap102 {
  gap: 6.375rem;
}

.gap103 {
  gap: 6.4375rem;
}

.gap104 {
  gap: 6.5rem;
}

.gap105 {
  gap: 6.5625rem;
}

.gap106 {
  gap: 6.625rem;
}

.gap107 {
  gap: 6.6875rem;
}

.gap108 {
  gap: 6.75rem;
}

.gap109 {
  gap: 6.8125rem;
}

.gap110 {
  gap: 6.875rem;
}

.gap111 {
  gap: 6.9375rem;
}

.gap112 {
  gap: 7rem;
}

.gap113 {
  gap: 7.0625rem;
}

.gap114 {
  gap: 7.125rem;
}

.gap115 {
  gap: 7.1875rem;
}

.gap116 {
  gap: 7.25rem;
}

.gap117 {
  gap: 7.3125rem;
}

.gap118 {
  gap: 7.375rem;
}

.gap119 {
  gap: 7.4375rem;
}

.gap120 {
  gap: 7.5rem;
}

.gap121 {
  gap: 7.5625rem;
}

.gap122 {
  gap: 7.625rem;
}

.gap123 {
  gap: 7.6875rem;
}

.gap124 {
  gap: 7.75rem;
}

.gap125 {
  gap: 7.8125rem;
}

.gap126 {
  gap: 7.875rem;
}

.gap127 {
  gap: 7.9375rem;
}

.gap128 {
  gap: 8rem;
}

.gap129 {
  gap: 8.0625rem;
}

.gap130 {
  gap: 8.125rem;
}

.gap131 {
  gap: 8.1875rem;
}

.gap132 {
  gap: 8.25rem;
}

.gap133 {
  gap: 8.3125rem;
}

.gap134 {
  gap: 8.375rem;
}

.gap135 {
  gap: 8.4375rem;
}

.gap136 {
  gap: 8.5rem;
}

.gap137 {
  gap: 8.5625rem;
}

.gap138 {
  gap: 8.625rem;
}

.gap139 {
  gap: 8.6875rem;
}

.gap140 {
  gap: 8.75rem;
}

.gap141 {
  gap: 8.8125rem;
}

.gap142 {
  gap: 8.875rem;
}

.gap143 {
  gap: 8.9375rem;
}

.gap144 {
  gap: 9rem;
}

.gap145 {
  gap: 9.0625rem;
}

.gap146 {
  gap: 9.125rem;
}

.gap147 {
  gap: 9.1875rem;
}

.gap148 {
  gap: 9.25rem;
}

.gap149 {
  gap: 9.3125rem;
}

.gap150 {
  gap: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .grid-sp-block {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-sp-block[class*=gap] {
    gap: 1.7142857143rem;
  }
  .grid-sp-col1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-sp-col2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-sp-col3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid-sp-col4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid-sp-col5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid-sp-col6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid-sp-col7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid-sp-col8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .gap-sp0 {
    gap: 0rem !important;
  }
  .gap-sp1 {
    gap: 0.0714285714rem !important;
  }
  .gap-sp2 {
    gap: 0.1428571429rem !important;
  }
  .gap-sp3 {
    gap: 0.2142857143rem !important;
  }
  .gap-sp4 {
    gap: 0.2857142857rem !important;
  }
  .gap-sp5 {
    gap: 0.3571428571rem !important;
  }
  .gap-sp6 {
    gap: 0.4285714286rem !important;
  }
  .gap-sp7 {
    gap: 0.5rem !important;
  }
  .gap-sp8 {
    gap: 0.5714285714rem !important;
  }
  .gap-sp9 {
    gap: 0.6428571429rem !important;
  }
  .gap-sp10 {
    gap: 0.7142857143rem !important;
  }
  .gap-sp11 {
    gap: 0.7857142857rem !important;
  }
  .gap-sp12 {
    gap: 0.8571428571rem !important;
  }
  .gap-sp13 {
    gap: 0.9285714286rem !important;
  }
  .gap-sp14 {
    gap: 1rem !important;
  }
  .gap-sp15 {
    gap: 1.0714285714rem !important;
  }
  .gap-sp16 {
    gap: 1.1428571429rem !important;
  }
  .gap-sp17 {
    gap: 1.2142857143rem !important;
  }
  .gap-sp18 {
    gap: 1.2857142857rem !important;
  }
  .gap-sp19 {
    gap: 1.3571428571rem !important;
  }
  .gap-sp20 {
    gap: 1.4285714286rem !important;
  }
  .gap-sp21 {
    gap: 1.5rem !important;
  }
  .gap-sp22 {
    gap: 1.5714285714rem !important;
  }
  .gap-sp23 {
    gap: 1.6428571429rem !important;
  }
  .gap-sp24 {
    gap: 1.7142857143rem !important;
  }
  .gap-sp25 {
    gap: 1.7857142857rem !important;
  }
  .gap-sp26 {
    gap: 1.8571428571rem !important;
  }
  .gap-sp27 {
    gap: 1.9285714286rem !important;
  }
  .gap-sp28 {
    gap: 2rem !important;
  }
  .gap-sp29 {
    gap: 2.0714285714rem !important;
  }
  .gap-sp30 {
    gap: 2.1428571429rem !important;
  }
  .gap-sp31 {
    gap: 2.2142857143rem !important;
  }
  .gap-sp32 {
    gap: 2.2857142857rem !important;
  }
  .gap-sp33 {
    gap: 2.3571428571rem !important;
  }
  .gap-sp34 {
    gap: 2.4285714286rem !important;
  }
  .gap-sp35 {
    gap: 2.5rem !important;
  }
  .gap-sp36 {
    gap: 2.5714285714rem !important;
  }
  .gap-sp37 {
    gap: 2.6428571429rem !important;
  }
  .gap-sp38 {
    gap: 2.7142857143rem !important;
  }
  .gap-sp39 {
    gap: 2.7857142857rem !important;
  }
  .gap-sp40 {
    gap: 2.8571428571rem !important;
  }
  .gap-sp41 {
    gap: 2.9285714286rem !important;
  }
  .gap-sp42 {
    gap: 3rem !important;
  }
  .gap-sp43 {
    gap: 3.0714285714rem !important;
  }
  .gap-sp44 {
    gap: 3.1428571429rem !important;
  }
  .gap-sp45 {
    gap: 3.2142857143rem !important;
  }
  .gap-sp46 {
    gap: 3.2857142857rem !important;
  }
  .gap-sp47 {
    gap: 3.3571428571rem !important;
  }
  .gap-sp48 {
    gap: 3.4285714286rem !important;
  }
  .gap-sp49 {
    gap: 3.5rem !important;
  }
  .gap-sp50 {
    gap: 3.5714285714rem !important;
  }
  .gap-sp51 {
    gap: 3.6428571429rem !important;
  }
  .gap-sp52 {
    gap: 3.7142857143rem !important;
  }
  .gap-sp53 {
    gap: 3.7857142857rem !important;
  }
  .gap-sp54 {
    gap: 3.8571428571rem !important;
  }
  .gap-sp55 {
    gap: 3.9285714286rem !important;
  }
  .gap-sp56 {
    gap: 4rem !important;
  }
  .gap-sp57 {
    gap: 4.0714285714rem !important;
  }
  .gap-sp58 {
    gap: 4.1428571429rem !important;
  }
  .gap-sp59 {
    gap: 4.2142857143rem !important;
  }
  .gap-sp60 {
    gap: 4.2857142857rem !important;
  }
  .gap-sp61 {
    gap: 4.3571428571rem !important;
  }
  .gap-sp62 {
    gap: 4.4285714286rem !important;
  }
  .gap-sp63 {
    gap: 4.5rem !important;
  }
  .gap-sp64 {
    gap: 4.5714285714rem !important;
  }
  .gap-sp65 {
    gap: 4.6428571429rem !important;
  }
  .gap-sp66 {
    gap: 4.7142857143rem !important;
  }
  .gap-sp67 {
    gap: 4.7857142857rem !important;
  }
  .gap-sp68 {
    gap: 4.8571428571rem !important;
  }
  .gap-sp69 {
    gap: 4.9285714286rem !important;
  }
  .gap-sp70 {
    gap: 5rem !important;
  }
  .gap-sp71 {
    gap: 5.0714285714rem !important;
  }
  .gap-sp72 {
    gap: 5.1428571429rem !important;
  }
  .gap-sp73 {
    gap: 5.2142857143rem !important;
  }
  .gap-sp74 {
    gap: 5.2857142857rem !important;
  }
  .gap-sp75 {
    gap: 5.3571428571rem !important;
  }
  .gap-sp76 {
    gap: 5.4285714286rem !important;
  }
  .gap-sp77 {
    gap: 5.5rem !important;
  }
  .gap-sp78 {
    gap: 5.5714285714rem !important;
  }
  .gap-sp79 {
    gap: 5.6428571429rem !important;
  }
  .gap-sp80 {
    gap: 5.7142857143rem !important;
  }
  .gap-sp81 {
    gap: 5.7857142857rem !important;
  }
  .gap-sp82 {
    gap: 5.8571428571rem !important;
  }
  .gap-sp83 {
    gap: 5.9285714286rem !important;
  }
  .gap-sp84 {
    gap: 6rem !important;
  }
  .gap-sp85 {
    gap: 6.0714285714rem !important;
  }
  .gap-sp86 {
    gap: 6.1428571429rem !important;
  }
  .gap-sp87 {
    gap: 6.2142857143rem !important;
  }
  .gap-sp88 {
    gap: 6.2857142857rem !important;
  }
  .gap-sp89 {
    gap: 6.3571428571rem !important;
  }
  .gap-sp90 {
    gap: 6.4285714286rem !important;
  }
  .gap-sp91 {
    gap: 6.5rem !important;
  }
  .gap-sp92 {
    gap: 6.5714285714rem !important;
  }
  .gap-sp93 {
    gap: 6.6428571429rem !important;
  }
  .gap-sp94 {
    gap: 6.7142857143rem !important;
  }
  .gap-sp95 {
    gap: 6.7857142857rem !important;
  }
  .gap-sp96 {
    gap: 6.8571428571rem !important;
  }
  .gap-sp97 {
    gap: 6.9285714286rem !important;
  }
  .gap-sp98 {
    gap: 7rem !important;
  }
  .gap-sp99 {
    gap: 7.0714285714rem !important;
  }
  .gap-sp100 {
    gap: 7.1428571429rem !important;
  }
  .gap-sp101 {
    gap: 7.2142857143rem !important;
  }
  .gap-sp102 {
    gap: 7.2857142857rem !important;
  }
  .gap-sp103 {
    gap: 7.3571428571rem !important;
  }
  .gap-sp104 {
    gap: 7.4285714286rem !important;
  }
  .gap-sp105 {
    gap: 7.5rem !important;
  }
  .gap-sp106 {
    gap: 7.5714285714rem !important;
  }
  .gap-sp107 {
    gap: 7.6428571429rem !important;
  }
  .gap-sp108 {
    gap: 7.7142857143rem !important;
  }
  .gap-sp109 {
    gap: 7.7857142857rem !important;
  }
  .gap-sp110 {
    gap: 7.8571428571rem !important;
  }
  .gap-sp111 {
    gap: 7.9285714286rem !important;
  }
  .gap-sp112 {
    gap: 8rem !important;
  }
  .gap-sp113 {
    gap: 8.0714285714rem !important;
  }
  .gap-sp114 {
    gap: 8.1428571429rem !important;
  }
  .gap-sp115 {
    gap: 8.2142857143rem !important;
  }
  .gap-sp116 {
    gap: 8.2857142857rem !important;
  }
  .gap-sp117 {
    gap: 8.3571428571rem !important;
  }
  .gap-sp118 {
    gap: 8.4285714286rem !important;
  }
  .gap-sp119 {
    gap: 8.5rem !important;
  }
  .gap-sp120 {
    gap: 8.5714285714rem !important;
  }
  .gap-sp121 {
    gap: 8.6428571429rem !important;
  }
  .gap-sp122 {
    gap: 8.7142857143rem !important;
  }
  .gap-sp123 {
    gap: 8.7857142857rem !important;
  }
  .gap-sp124 {
    gap: 8.8571428571rem !important;
  }
  .gap-sp125 {
    gap: 8.9285714286rem !important;
  }
  .gap-sp126 {
    gap: 9rem !important;
  }
  .gap-sp127 {
    gap: 9.0714285714rem !important;
  }
  .gap-sp128 {
    gap: 9.1428571429rem !important;
  }
  .gap-sp129 {
    gap: 9.2142857143rem !important;
  }
  .gap-sp130 {
    gap: 9.2857142857rem !important;
  }
  .gap-sp131 {
    gap: 9.3571428571rem !important;
  }
  .gap-sp132 {
    gap: 9.4285714286rem !important;
  }
  .gap-sp133 {
    gap: 9.5rem !important;
  }
  .gap-sp134 {
    gap: 9.5714285714rem !important;
  }
  .gap-sp135 {
    gap: 9.6428571429rem !important;
  }
  .gap-sp136 {
    gap: 9.7142857143rem !important;
  }
  .gap-sp137 {
    gap: 9.7857142857rem !important;
  }
  .gap-sp138 {
    gap: 9.8571428571rem !important;
  }
  .gap-sp139 {
    gap: 9.9285714286rem !important;
  }
  .gap-sp140 {
    gap: 10rem !important;
  }
  .gap-sp141 {
    gap: 10.0714285714rem !important;
  }
  .gap-sp142 {
    gap: 10.1428571429rem !important;
  }
  .gap-sp143 {
    gap: 10.2142857143rem !important;
  }
  .gap-sp144 {
    gap: 10.2857142857rem !important;
  }
  .gap-sp145 {
    gap: 10.3571428571rem !important;
  }
  .gap-sp146 {
    gap: 10.4285714286rem !important;
  }
  .gap-sp147 {
    gap: 10.5rem !important;
  }
  .gap-sp148 {
    gap: 10.5714285714rem !important;
  }
  .gap-sp149 {
    gap: 10.6428571429rem !important;
  }
  .gap-sp150 {
    gap: 10.7142857143rem !important;
  }
}
/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8.25rem;
  z-index: 9999;
  transition: var(--transit-default);
}
.header > .inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: 1.5rem;
}
@media screen and (max-width: 67.5em) {
  .header {
    height: 5.1428571429rem;
  }
  .header > .inner {
    padding-left: 1.875rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .header > .inner {
    padding-left: 0;
  }
}

.page-contact-simple .header {
  position: absolute;
}

/* --- logo -- */
.header--logo {
  width: 13.75rem;
}

.header--logo a,
.footer--logo a {
  display: inline-block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 67.5em) {
  .header--logo img {
    width: auto;
    max-height: 2.5rem;
  }
}

/* gnav
********************************************** */
@media print, screen and (min-width: 67.5625em) {
  .gnav,
  .gnav ul,
  .gnav li,
  .gnav a {
    height: 100%;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav {
    display: flex;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav {
    position: fixed;
    top: 5.1428571429rem;
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - 5.1428571429rem);
    margin: 0;
    padding: 1.5rem 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--color-bg-02);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
  }
  .gnav.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
  }
}
.gnav a > span {
  padding-inline: 1rem;
  transition: all 0.4s ease-out;
}
.gnav a .font-en {
  letter-spacing: 0.2em;
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  transition: all 0.4s ease-out;
}
@media screen and (max-width: 67.5em) {
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li:not(:last-child) {
    border-bottom: 1px dotted var(--color-primary-default);
  }
}

.gnav--menu > li > a > span {
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.5), -1px -1px 3px rgba(255, 255, 255, 0.5), -1px 1px 3px rgba(255, 255, 255, 0.5), 1px -1px 3px rgba(255, 255, 255, 0.5), 0px 1px 3px rgba(255, 255, 255, 0.5), 0 -1px 3px rgba(255, 255, 255, 0.5), -1px 0 3px rgba(255, 255, 255, 0.5), 1px 0 3px rgba(255, 255, 255, 0.5);
}

.gnav--link {
  padding: 0 1rem;
}
@media screen and (max-width: 67.5em) {
  .gnav--link {
    display: block;
    padding: 1.125rem 1rem;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--link {
    text-align: center;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.7142857143rem;
    margin: auto;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 1px solid var(--color-primary-default);
    border-right: 1px solid var(--color-primary-default);
    transform: rotate(45deg);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]):hover > span,
  .gnav--menu > li.current-menu-item > .gnav--link:hover > span {
    color: var(--color-wht);
    background-color: var(--color-primary-default);
    text-shadow: 1px 1px 0 var(--color-primary-default), -1px -1px 0 var(--color-primary-default), -1px 1px 0 var(--color-primary-default), 1px -1px 0 var(--color-primary-default), 0px 1px 0 var(--color-primary-default), 0 -1px 0 var(--color-primary-default), -1px 0 0 var(--color-primary-default), 1px 0 0 var(--color-primary-default);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}

.head_sub_nav--wrap {
  position: absolute;
  top: 6.125rem;
  left: 50%;
  z-index: 100;
  display: block;
  width: max-content;
  transform: translateX(-50%);
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .head_sub_nav--wrap {
    position: relative;
    width: max-content;
    border-top: 1px dotted var(--color-primary-default);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}
.head_sub_nav--wrap::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  width: 8px;
  height: 8px;
  margin: 0 auto;
  background-color: #0052B2;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 67.5em) {
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav {
    background-color: var(--color-primary-default);
  }
}
@media screen and (max-width: 67.5em) {
  .head_sub_nav {
    position: relative;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav li:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
}

.head_sub_nav a {
  display: block;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  word-break: keep-all;
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a {
    color: #fff;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a:hover {
    opacity: 0.5;
  }
}
.head_sub_nav a span {
  padding-left: 0;
  padding-right: 0;
}

@media print, screen and (min-width: 67.5625em) {
  .gnav_sub_toggle {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -1.25rem;
    width: 4rem;
    height: 4rem;
    transition: var(--transit-default);
    color: var(--color-primary-default);
    cursor: pointer;
  }
  .gnav_sub_toggle::before, .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.6875rem;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
}

/* ---btn --- */
.gnav_btn {
  position: absolute;
  top: 2rem;
  right: 15.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-out;
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  .gnav_btn {
    top: 9px;
    right: 2%;
    width: 56px;
    height: 56px;
    opacity: 1;
    pointer-events: all;
  }
}
.gnav_btn .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 56px;
  height: 56px;
}

.gnav_btn--lines {
  width: 45px;
  height: 26px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 47.9375em) {
  .gnav_btn--lines {
    width: 36px;
    height: 24px;
  }
}

.gnav_btn--lines span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.gnav_btn--lines span::before, .gnav_btn--lines span::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-primary-default);
}

.gnav_btn .font-antonio {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .gnav_btn .font-antonio {
    font-size: 18px;
  }
}

.gnav--cta {
  text-align: center;
}

@media screen and (max-width: 67.5em) {
  .gnav--cta li:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--contact {
    margin-left: 0.5rem;
  }
}

.gnav--contact {
  margin-left: 1.75rem;
}

.gnav--contact a {
  position: relative;
  padding: 2.5rem 2rem 1.5rem 1.5rem;
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--contact a {
    border-radius: 0;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--contact a {
    padding: 0.7142857143rem 1.1428571429rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.gnav--contact a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 14.625rem;
  height: 13.625rem;
  background-image: url(../images/common/gnav_btn_polygon.svg);
  background-size: cover;
}
.gnav--contact a img {
  position: relative;
  z-index: 1;
  width: 7.25rem;
  height: 3.5625rem;
}

.gnav--contact span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  mix-blend-mode: multiply;
}
.gnav--contact span::before, .gnav--contact span::after {
  content: "";
  position: absolute;
  background-color: #0052B2;
}
.gnav--contact span::before {
  right: 38%;
  bottom: -7.625rem;
  width: 6.1875rem;
  height: 7.0625rem;
  clip-path: polygon(0 0, 30% 100%, 100% 24%);
  transform: rotate(120deg);
}
.gnav--contact span::after {
  left: -8.125rem;
  bottom: -3rem;
  width: 3.875rem;
  height: 3rem;
  clip-path: polygon(0 0, 13% 100%, 100% 27%);
}

/* hbr-nav
********************************************** */
.hbr-nav {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-default);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-out;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav {
    padding: 4.5714285714rem 0 2.8571428571rem;
    align-items: flex-start;
    overflow-y: scroll;
  }
}

.hbr-nav.active {
  opacity: 1;
  pointer-events: all;
}

.hbr-nav--top .imgarea {
  display: flex;
  flex-direction: column;
  margin-right: 3.375rem;
  flex: 1;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--top .imgarea {
    display: none;
  }
}
.hbr-nav--top .imgarea .img {
  padding-right: 2.5rem;
}
.hbr-nav--top .imgarea .font-antonio {
  display: inline-block;
  margin-top: 16px;
  margin-left: auto;
  margin-right: 0;
  line-height: 1.5;
  letter-spacing: 0.3rem;
}

.hbr-nav--menu {
  padding: 0.375rem 3.375rem;
  border-left: 1px solid #fff;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--menu {
    padding: 0;
    border-left: none;
  }
  .hbr-nav--menu:nth-child(1) {
    margin-bottom: 1.1428571429rem;
  }
}

.hbr-nav--menu > li:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--menu > li:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}

.hbr-nav--menu li a {
  display: inline-block;
  padding: 6px 8px;
  line-height: 1;
  color: #fff;
}
.hbr-nav--menu li a:hover {
  background-color: #fff;
  color: var(--color-primary-default);
}
.hbr-nav--menu li a .font-en {
  font-size: 3.75rem;
  letter-spacing: -0.03125rem;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--menu li a .font-en {
    font-size: 1.7142857143rem;
  }
}

.hbr-nav--menu .sub-menu {
  margin-top: 0.5rem;
}
.hbr-nav--menu .sub-menu li {
  margin-top: 1rem;
}

.hbr-nav--menu .sub-menu a {
  position: relative;
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--menu .sub-menu a {
    font-size: 1.1428571429rem;
  }
}
.hbr-nav--menu .sub-menu a::before {
  content: "…";
  margin-right: 0.5rem;
}

.hbr-nav--btm {
  margin-top: 5rem;
  padding-top: 2rem;
  border-top: 1px solid #fff;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--btm {
    margin-top: 2.8571428571rem;
  }
}

.hbr-nav-subnav {
  margin-left: 1.5rem;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav-subnav {
    margin-left: 0;
    margin-bottom: 8px;
    text-align: center;
  }
}

.hbr-nav-subnav li:not(:last-child) {
  margin-right: 1rem;
}
.hbr-nav-subnav li a {
  color: #fff;
  font-size: 0.875rem;
}
.hbr-nav-subnav li a:hover {
  opacity: 0.7;
}

.hbr-nav-subnav svg {
  fill: #fff;
  margin-left: 0.5rem;
  vertical-align: middle;
  transform: translateY(-2px);
}

.hbr-nav--entry {
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--entry {
    display: none;
  }
}

.hbr-nav--entry a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.5rem 2rem 1.5rem 1.5rem;
}
.hbr-nav--entry a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 14.625rem;
  height: 13.625rem;
  background-image: url(../images/common/hbr_entry_polygon.svg);
  background-size: cover;
}

.hbr-nav--entry a span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hbr-nav--entry a span::before, .hbr-nav--entry a span::after {
  content: "";
  position: absolute;
  background-color: #FFF;
}
.hbr-nav--entry a span::before {
  right: 38%;
  bottom: -7.625rem;
  width: 6.1875rem;
  height: 7.0625rem;
  clip-path: polygon(0 0, 30% 100%, 100% 24%);
  transform: rotate(120deg);
}
.hbr-nav--entry a span::after {
  left: -8.125rem;
  bottom: -3rem;
  width: 3.875rem;
  height: 3rem;
  clip-path: polygon(0 0, 13% 100%, 100% 27%);
}

.hbr-nav--close {
  position: absolute;
  top: 2rem;
  right: 15.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--close {
    top: 6px;
    right: 2%;
    width: 56px;
    height: 56px;
  }
}
.hbr-nav--close .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 54px;
  height: 54px;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--close .wrap {
    width: 56px;
    height: 56px;
  }
}

.hbr-nav--close .icon {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  margin-bottom: 0.5rem;
}
.hbr-nav--close .icon::before, .hbr-nav--close .icon::after {
  position: absolute;
  content: "";
  top: 10px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #fff;
}
.hbr-nav--close .icon::before {
  transform: rotate(45deg);
}
.hbr-nav--close .icon::after {
  transform: rotate(-45deg);
}

.hbr-nav--close .font-antonio {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .hbr-nav--close .font-antonio {
    font-size: 18px;
  }
}

.hbr-nav .txt_slide_deco {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.2;
}

/* header-sm
********************************************** */
@media print, screen and (min-width: 67.5625em) {
  .header-sm {
    mix-blend-mode: multiply;
  }
  .header-sm .gnav--menu {
    opacity: 0;
    pointer-events: none;
  }
  .header-sm .gnav_btn {
    opacity: 1;
    pointer-events: all;
  }
}
.sec_line_wave {
  position: relative;
  height: 276px;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .sec_line_wave {
    height: 20vh;
  }
}
.sec_line_wave.top {
  margin-top: -200px;
}
@media screen and (max-width: 47.9375em) {
  .sec_line_wave.top {
    margin-top: -5vh;
  }
}
.sec_line_wave.btm {
  margin-bottom: -200px;
}
@media screen and (max-width: 47.9375em) {
  .sec_line_wave.btm {
    margin-bottom: -10vh;
  }
}

.sec_line_wave svg {
  position: absolute;
  top: 0;
  left: 0;
}

.sec_line_wave svg.wave1, .sec_line_wave svg.wave3 {
  transform: scale(-1, 1);
}

.sec_line_wave.top svg.wave2 {
  top: 10px;
}
.sec_line_wave.top svg.wave3 {
  top: 30px;
}
.sec_line_wave.top svg.wave4 {
  top: 60px;
}
.sec_line_wave.top svg.wave5 {
  top: 100px;
}

.sec_line_wave.btm svg.wave2 {
  bottom: 10px;
}
.sec_line_wave.btm svg.wave3 {
  bottom: 30px;
}
.sec_line_wave.btm svg.wave4 {
  bottom: 60px;
}
.sec_line_wave.btm svg.wave5 {
  bottom: 100px;
}

.js-text span {
  display: inline-block;
}

/* hero
********************************************** */
.hero {
  position: relative;
  height: 62.5rem;
}

.hero .txt_slide_deco {
  position: absolute;
  left: 0;
  bottom: -7.875rem;
  width: 100%;
  mix-blend-mode: multiply;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .hero .txt_slide_deco {
    bottom: -6%;
  }
}

.hero_slide {
  overflow: hidden;
}

.hero_slide-sp {
  display: none !important;
}

.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}

.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}

.hero--catch {
  top: 15%;
  left: 3%;
}
@media screen and (max-width: 47.9375em) {
  .hero--catch {
    top: 9%;
    left: 2%;
    width: 88%;
  }
}

.home_news {
  position: absolute;
  right: 0;
  bottom: 8%;
  max-width: 41.25rem;
  width: 92%;
  border-bottom: 1px solid;
  padding: 0 5rem 1rem 1rem;
}
@media screen and (max-width: 47.9375em) {
  .home_news {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0.5714285714rem 1.1428571429rem 0.5714285714rem 1.1428571429rem;
  }
}

.home_news--list .post {
  display: flex !important;
}

.home_news--list .post--txtarea {
  position: relative;
  flex: 1;
  margin-left: 1.25rem;
  padding-left: 1.25rem;
  overflow: hidden;
}
.home_news--list .post--txtarea::before {
  content: "/";
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.3;
}

.home_news--list .post--ttl {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 47.9375em) {
  .hero {
    height: 100vh;
  }
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
}
/* home_deco_area
********************************************** */
.home_deco_area {
  height: 16.875rem;
  padding-top: 10rem;
  background-position: bottom left;
  background-repeat: repeat-x;
  background-image: url(../images/home/txt_deco.png);
  animation: bgroop 100s linear infinite;
}
@media screen and (max-width: 47.9375em) {
  .home_deco_area {
    height: 7.1428571429rem;
    padding-top: 3.5714285714rem;
    background-size: auto 100%;
  }
}
.home_deco_area .font-antonio {
  line-height: 1.6;
}

/* home_story
********************************************** */
.home_story--catch {
  position: sticky;
  top: 0;
  padding-top: 11.25rem;
  padding-bottom: 12.875rem;
}
@media screen and (max-width: 47.9375em) {
  .home_story--catch {
    padding-top: 5.7142857143rem;
    padding-bottom: 2.8571428571rem;
  }
}

#story_catch {
  width: 62.5rem;
  height: 29.75rem;
  margin: 0 auto;
}
@media screen and (max-width: 47.9375em) {
  #story_catch {
    width: 100%;
    height: 15rem;
  }
}

@keyframes catch-anime {
  0% {
    stroke-dashoffset: 2100;
    fill: transparent;
  }
  40% {
    stroke-dashoffset: 2100;
    fill: transparent;
  }
  70% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #0052B2;
  }
}
.home_story--catch .ttl .line {
  position: relative;
  display: inline-block;
}
.home_story--catch .ttl .line::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all 0.6s ease-out;
}
.home_story--catch .ttl .line.line2 {
  margin-top: -1.5rem;
  padding-left: 1.5rem;
}

.home_story--catch .ttl .line.active::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: #fff;
}

.home_story--scroll {
  display: flex;
  width: 500%;
}
@media screen and (max-width: 47.9375em) {
  .home_story--scroll {
    display: block;
    width: 100%;
  }
}

.home_story--sec {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec {
    position: relative;
    z-index: 1;
    display: block;
    height: auto;
  }
}

.home_story--sec1 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 10%);
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec1 {
    padding-bottom: 3.2857142857rem;
  }
}

.home_story--sec1 .txtarea .ttl {
  margin-bottom: 2.5rem;
  font-size: 6.48vh;
  line-height: 1.4;
  letter-spacing: 0.13125rem;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec1 .txtarea .ttl {
    font-size: 9.5vw;
  }
}
.home_story--sec1 .txtarea p {
  font-size: 1.125rem;
  line-height: 2.2;
  letter-spacing: 0.03375rem;
}

.bgclipping,
.bgclipping-blue {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.home_story--sec1 .imgarea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec1 .imgarea {
    width: 100%;
  }
}

.home_story--sec1 .brids {
  position: relative;
  max-width: 471px;
  width: 100%;
  height: 418px;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec1 .brids {
    height: 32vh;
    margin-bottom: 2.8571428571rem;
  }
}
.home_story--sec1 .brids .bird1,
.home_story--sec1 .brids .bird2,
.home_story--sec1 .brids .bird3 {
  position: absolute;
}
.home_story--sec1 .brids .bird1 {
  top: 4.375rem;
  right: 0;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec1 .brids .bird1 {
    top: 0;
    width: 18.8125rem;
    height: 11.0625rem;
    transform: scale(0.8);
  }
}
.home_story--sec1 .brids .bird2 {
  left: 0;
  bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec1 .brids .bird2 {
    bottom: 0;
    width: 14.3125rem;
    height: 8.4375rem;
    transform: scale(0.6);
  }
}
.home_story--sec1 .brids .bird3 {
  right: 1.25rem;
  bottom: 1.25rem;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec1 .brids .bird3 {
    bottom: 0;
    width: 9.1875rem;
    height: 5.4375rem;
    transform: scale(0.5);
  }
}

.home_story--sec2 .imgarea {
  width: 54%;
  height: 100%;
  padding-top: 6.375rem;
  align-items: flex-start;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .imgarea {
    width: 100%;
    height: 58vh;
    padding-top: 1.7142857143rem;
  }
}
.home_story--sec2 .imgarea img.js-parallax {
  top: 0;
  right: 0;
  max-width: none;
  width: 110% !important;
  height: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .imgarea img.js-parallax {
    top: 0;
    right: -11%;
    object-fit: cover;
    width: 125% !important;
    height: auto;
  }
}
.home_story--sec2 .imgarea .ttl {
  position: relative;
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 0.045rem;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .imgarea .ttl {
    margin-bottom: 0;
    font-size: 1.7142857143rem;
  }
}
.home_story--sec2 .imgarea .ttl span {
  display: block;
}
.home_story--sec2 .imgarea .catch {
  position: relative;
}
.home_story--sec2 .imgarea .catch span {
  font-size: 5rem;
  letter-spacing: 0.1rem;
  line-height: 1;
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .imgarea .catch span {
    font-size: 10vw;
  }
}

.home_story--sec2 .txtarea,
.home_story--sec4 .txtarea {
  flex: 1;
  padding-inline: 6vw;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .txtarea,
  .home_story--sec4 .txtarea {
    padding-block: 3.2857142857rem;
    padding-inline: 8%;
  }
}

.home_story--sec2 .txtarea .txt,
.home_story--sec4 .txtarea .txt {
  z-index: 1;
  flex: 1;
}
.home_story--sec2 .txtarea .txt .deco,
.home_story--sec4 .txtarea .txt .deco {
  bottom: -5.875rem;
  z-index: -1;
  margin-left: 0 !important;
  font-size: 16.25rem;
  line-height: 1;
  letter-spacing: 0.325rem;
  color: #F2F9FF;
  word-break: keep-all;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .txtarea .txt .deco,
  .home_story--sec4 .txtarea .txt .deco {
    bottom: 0;
    font-size: 28vw;
  }
}

.home_story--sec2 .txtarea .ttl,
.home_story--sec4 .txtarea .ttl {
  margin-right: -58%;
  margin-bottom: 2.875rem;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .txtarea .ttl,
  .home_story--sec4 .txtarea .ttl {
    margin-right: 0;
  }
}
.home_story--sec2 .txtarea .ttl::before,
.home_story--sec4 .txtarea .ttl::before {
  content: "";
  display: block;
  width: 3.25rem;
  height: 1rem;
  background-image: url(../images/common/wave2.svg);
  background-repeat: repeat;
  background-size: cover;
  margin-bottom: 1.875rem;
}

.home_story--sec2 .txtarea .more,
.home_story--sec4 .txtarea .more {
  position: relative;
  z-index: 1;
  margin-left: 4.65vw;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .txtarea .more,
  .home_story--sec4 .txtarea .more {
    margin-top: 2.8571428571rem;
    margin-left: 0;
  }
}

.home_story--sec2 .txtarea .more a,
.home_story--sec4 .txtarea .more a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 1px solid var(--color-primary-default);
  color: var(--color-primary-default);
  text-align: center;
  font-size: 1.125rem;
  letter-spacing: 0.25rem;
  background-color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .txtarea .more a,
  .home_story--sec4 .txtarea .more a {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 99px;
    font-size: 1.2857142857rem;
  }
}
.home_story--sec2 .txtarea .more a::before, .home_story--sec2 .txtarea .more a::after,
.home_story--sec4 .txtarea .more a::before,
.home_story--sec4 .txtarea .more a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 96, 178, 0.1);
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .txtarea .more a::before, .home_story--sec2 .txtarea .more a::after,
  .home_story--sec4 .txtarea .more a::before,
  .home_story--sec4 .txtarea .more a::after {
    border-radius: 99px;
  }
}
.home_story--sec2 .txtarea .more a::after,
.home_story--sec4 .txtarea .more a::after {
  animation-delay: -2s;
}
.home_story--sec2 .txtarea .more a:hover::before, .home_story--sec2 .txtarea .more a:hover::after,
.home_story--sec4 .txtarea .more a:hover::before,
.home_story--sec4 .txtarea .more a:hover::after {
  animation: scale_anime 3s infinite;
}

.home_story--sec2 .txtarea .txt p:not(.mgn-btm72) {
  margin-left: 4.38vw;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec2 .txtarea .txt p:not(.mgn-btm72) {
    margin-left: 0;
  }
}

.home_story--sec3 {
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec3 {
    height: 46vh;
  }
}
.home_story--sec3 img {
  top: -5%;
  right: 0;
  max-width: none;
  width: 110% !important;
  height: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec3 img {
    top: 0;
    right: -15%;
    object-fit: cover;
    width: 130% !important;
    height: auto;
  }
}

.home_story--sec4 .imgarea {
  position: relative;
  width: 54%;
  height: 100%;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec4 .imgarea {
    width: 100%;
    height: 72vh;
  }
}
.home_story--sec4 .imgarea .img1,
.home_story--sec4 .imgarea .img2,
.home_story--sec4 .imgarea .img3 {
  position: absolute;
}
.home_story--sec4 .imgarea .img1 {
  top: 5vh;
  left: 6vh;
  width: 65.28vh;
  height: 43.52vh;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec4 .imgarea .img1 {
    top: 3%;
    left: 0;
    width: 86%;
    height: 50%;
  }
}
.home_story--sec4 .imgarea .img2 {
  right: 44vh;
  bottom: 5.625rem;
  z-index: 1;
  width: 29.26vh;
  height: 19.54vh;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec4 .imgarea .img2 {
    left: 13%;
    right: auto;
    bottom: 17%;
    width: 40%;
    height: 21%;
  }
}
.home_story--sec4 .imgarea .img3 {
  right: 17vh;
  bottom: 0;
  width: 29.63vh;
  height: 39.81vh;
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec4 .imgarea .img3 {
    right: 0;
    bottom: 6%;
    width: 37%;
    height: 44%;
  }
}

.home_story--sec5 {
  position: relative;
  background-repeat: repeat;
  background-size: cover;
  background-position: top 60% center;
  background-image: url(../images/home/home_story7.jpg);
}
@media screen and (max-width: 47.9375em) {
  .home_story--sec5 {
    height: 39vh;
  }
}

/* home_works
********************************************** */
@media screen and (max-width: 47.9375em) {
  .home_works {
    z-index: 1;
  }
}

.home_works--wrap {
  position: relative;
  background-color: var(--color-primary-default);
  padding-top: 12.5rem;
  padding-bottom: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--wrap {
    padding-top: 2.2857142857rem;
  }
}

.home_works--deco {
  position: absolute;
  top: 21%;
  right: 0;
  font-size: 12.5vw;
  color: #004987;
  letter-spacing: 0.3rem;
  line-height: 1.04;
  opacity: 0.5;
  mix-blend-mode: multiply;
}

.home_works--bg_img {
  opacity: 0.6;
}
.home_works--bg_img.img1 {
  top: 4%;
  left: 19.5%;
  width: 26.25rem;
  height: 17.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--bg_img.img1 {
    top: auto;
    left: 0;
    bottom: 32%;
    width: 30%;
    height: auto;
  }
}
.home_works--bg_img.img2 {
  top: 32.5%;
  right: 0;
  width: 37.375rem;
  height: 25rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--bg_img.img2 {
    top: auto;
    bottom: 20%;
    width: 44%;
    height: auto;
  }
}
.home_works--bg_img.img3 {
  left: 0;
  bottom: 9%;
  width: 37.125rem;
  height: 29.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--bg_img.img3 {
    top: auto;
    bottom: 0;
    width: 40%;
    height: auto;
  }
}

.home_works--point .txtarea {
  position: relative;
  z-index: 1;
  max-width: 75rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .txtarea {
    flex-direction: column-reverse;
  }
}

.home_works--ttl {
  position: relative;
  display: flex;
  font-size: 3.75rem;
  color: #fff;
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  word-break: keep-all;
  margin-left: 4.375rem;
  letter-spacing: 0.375rem;
  padding-top: 5.375rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ttl {
    margin-left: 0;
    writing-mode: horizontal-tb;
    padding-top: 2rem;
    font-size: 7.5vw;
  }
}
.home_works--ttl::before {
  content: "";
  position: absolute;
  top: 0;
  width: 9.8125rem;
  height: 3rem;
  background-image: url(../images/common/wave3.svg);
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ttl::before {
    width: 3.25rem;
    height: 1rem;
  }
}

.home_works--point .txtarea .txt {
  width: 20.125rem;
  margin-top: 12.5rem;
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .txtarea .txt {
    margin-top: 1.7142857143rem;
  }
}

.home_works--point .txtarea .more {
  margin-top: 2.5rem;
  text-align: center;
}

.home_works--point .txtarea .more a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  border: 1px solid;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  letter-spacing: 0.25rem;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .txtarea .more a {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 99px;
    font-size: 1.2857142857rem;
  }
}
.home_works--point .txtarea .more a::before, .home_works--point .txtarea .more a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .txtarea .more a::before, .home_works--point .txtarea .more a::after {
    border-radius: 99px;
  }
}
.home_works--point .txtarea .more a::after {
  animation-delay: -2s;
}
.home_works--point .txtarea .more a:hover::before, .home_works--point .txtarea .more a:hover::after {
  animation: scale_anime 3s infinite;
}

.home_works--point .imgarea {
  margin-top: -25.625rem;
  transform: translateX(-5.25rem);
  max-width: 99rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .imgarea {
    margin-top: 2.2857142857rem;
    transform: none;
    max-width: 100%;
    width: 100%;
  }
}

.home_works--point .maparea .map {
  display: block;
  text-align: right;
}
.home_works--point .maparea .map .pin-office {
  right: 12%;
  bottom: 35%;
  z-index: 6;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .map .pin-office {
    right: 11%;
    bottom: 36%;
    width: 19%;
  }
}
.home_works--point .maparea .map .pin-beach {
  right: 22%;
  bottom: 23%;
  z-index: 6;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .map .pin-beach {
    right: 31%;
    bottom: 23%;
    width: 19%;
  }
}
.home_works--point .maparea .map .pin-enoshima {
  right: 7.5%;
  bottom: 16%;
  z-index: 6;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .map .pin-enoshima {
    right: 14.5%;
    bottom: 11%;
    width: 13%;
  }
}

@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .map img {
    transform: translateX(-3%);
  }
}

.home_works--point .maparea .pin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 3.125rem;
}
.home_works--point .maparea .pin.pin1 {
  left: 90.9%;
  bottom: 35%;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin.pin1 {
    left: 83.5%;
    bottom: 33%;
  }
}
.home_works--point .maparea .pin.pin2 {
  left: 92.3%;
  bottom: 18.5%;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin.pin2 {
    left: 85.3%;
  }
}
.home_works--point .maparea .pin.pin2::after {
  height: 50px;
}
.home_works--point .maparea .pin.pin3 {
  left: 83%;
  bottom: 26%;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin.pin3 {
    left: 72%;
  }
}
.home_works--point .maparea .pin.pin4 {
  top: 23.5%;
  left: 76%;
  margin: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin.pin4 {
    left: 69%;
  }
}
.home_works--point .maparea .pin.pin5 {
  left: 91.5%;
  bottom: 40%;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin.pin5 {
    left: 87.5%;
  }
}
.home_works--point .maparea .pin.pin6 {
  left: 85.5%;
  bottom: 48%;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin.pin6 {
    left: 77.5%;
    bottom: 50%;
  }
}
.home_works--point .maparea .pin.pin7 {
  right: 42.5%;
  bottom: 24%;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin.pin7 {
    right: 48.5%;
    bottom: 21%;
  }
}
.home_works--point .maparea .pin::after {
  content: "";
  display: block;
  width: 7px;
  height: 40px;
  border-radius: 88px;
  background-color: var(--color-primary-default);
  margin-top: -4px;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin::after {
    display: none;
  }
}

.home_works--point .maparea .pin i {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  background-color: var(--color-primary-default);
  color: #fff;
  font-size: 1.5rem;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_works--point .maparea .pin i {
    width: 1.7142857143rem;
    height: 1.7142857143rem;
    font-size: 0.8571428571rem;
  }
}
.home_works--point .maparea .pin i::before, .home_works--point .maparea .pin i::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 82, 178, 0.4);
  z-index: -1;
  animation: scale_anime 3s infinite;
}
.home_works--point .maparea .pin i::after {
  animation-delay: -2s;
}

.home_works--ex_scroll {
  position: relative;
  overflow: hidden;
  padding-top: 70px;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex_scroll {
    padding-top: 30px;
  }
}

.home_works--ex {
  padding-block: 2px;
  border-top: 16px solid var(--color-primary-default);
  border-bottom: 1px solid #fff;
  box-shadow: 0 0 0 1px #fff, inset 0 1px 0 0 #fff;
}

.home_works--ex .slick-list {
  overflow: visible !important;
}

.home_works--ex .slick-track {
  display: flex !important;
}

.home_works--ex .slick-arrow {
  position: absolute;
  top: 45%;
  z-index: 10;
  width: 4.8571428571rem;
  height: 4.8571428571rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex .slick-arrow {
    width: 2.8571428571rem;
    height: 2.8571428571rem;
  }
}
.home_works--ex .slick-arrow::before {
  content: "";
  display: inline-block;
  width: 0.5714285714rem;
  height: 0.5714285714rem;
  border-right: 2px solid var(--color-primary-default);
  border-bottom: 2px solid var(--color-primary-default);
}
.home_works--ex .slick-arrow.slick-prev {
  left: 0;
}
.home_works--ex .slick-arrow.slick-prev::before {
  transform: rotate(135deg);
}
.home_works--ex .slick-arrow.slick-next {
  right: 0;
}
.home_works--ex .slick-arrow.slick-next::before {
  transform: rotate(-45deg);
}

.home_works--ex .post {
  position: relative;
  width: 39.25rem;
  padding: 2.75rem;
  margin: 2px;
  border: 1px solid;
  color: #fff;
  counter-increment: number;
  height: auto !important;
  min-height: auto !important;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex .post {
    width: 20rem;
    padding: 0.5714285714rem;
  }
}

.home_works--ex .post .num {
  position: absolute;
  top: -94px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.125rem;
  font-family: var(--font-en);
  color: #fff;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex .post .num {
    top: -48px;
    justify-content: flex-start;
    padding-left: 8px;
    font-size: 2.2857142857rem;
  }
}
.home_works--ex .post .num .suji {
  margin-left: 1.25rem;
  font-family: var(--font-en);
  font-size: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex .post .num .suji {
    margin-left: 4px;
    font-size: 2.8571428571rem;
  }
}

.home_works--ex .post--img {
  margin-bottom: 1rem;
}

.home_works--ex .post--ttl {
  font-size: 2.5rem;
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex .post--ttl {
    font-size: 1.7142857143rem;
  }
}

.home_works--ex .post--txt p {
  margin-top: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex .post--txt p {
    margin-top: 0.5714285714rem;
  }
}
.home_works--ex .post--txt p span {
  display: inline-block;
  background-image: linear-gradient(180deg, #fff 1px, transparent 1px); /* 罫線の色と太さ  */
  background-size: 100% 3em; /* 行の高さ */
  line-height: 3em; /* 文字の高さ */
  padding-bottom: 1px; /* 最終行の下にも罫線を引く */
}
@media screen and (max-width: 47.9375em) {
  .home_works--ex .post--txt p span {
    background-image: none;
    line-height: 1.85;
    padding-bottom: 8px;
    opacity: 0.8;
  }
}

.home_works--exwrap {
  padding-top: 6.875rem;
  margin-top: -6.875rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--exwrap {
    margin-top: 0;
  }
}

.home_works--more {
  position: absolute;
  top: 0;
  right: 26.8%;
  z-index: 2;
}
@media screen and (max-width: 47.9375em) {
  .home_works--more {
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
}

.home_works--more a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.25rem;
  height: 6.25rem;
  background-color: #3375c1;
  border-radius: 8px;
  padding-left: 1rem;
  border: 1px solid #fff;
  color: #fff;
}
.home_works--more a:hover {
  background-color: #2061ab;
}
.home_works--more a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  background-color: #3375c1;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: scaleX(0.5) rotate(-45deg);
  transition: all 0.4s ease-out;
}
.home_works--more a:hover::after {
  background-color: #2061ab;
}
.home_works--more a svg {
  margin-left: 8px;
}

/* home_interview
********************************************** */
.home_interview {
  padding-top: 15.625rem;
}
@media screen and (max-width: 47.9375em) {
  .home_interview {
    padding-top: 4.2857142857rem;
  }
}

.home_interview--ttl {
  margin-bottom: 2.5rem;
}
.home_interview--ttl .ttl {
  font-size: 13.02vw;
  color: var(--color-primary-default);
  line-height: 1;
  overflow: hidden;
}
.home_interview--ttl .lead {
  width: 21.5625rem;
  margin-left: 5rem;
  padding-bottom: 0.5rem;
  font-size: 1.125rem;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .home_interview--ttl .lead {
    margin-top: 1.1428571429rem;
    margin-left: 0;
    font-size: 1.1428571429rem;
    line-height: 1.65;
  }
}

.home_interview_member .member_nav {
  width: 31.25vw;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, var(--color-primary-default) 50%);
}
.home_interview_member .member_conts {
  width: calc(100% - 31.25vw);
}
@media screen and (max-width: 47.9375em) {
  .home_interview_member .member_conts {
    display: none;
  }
}

.home_interview_member .member_nav .slick-list {
  height: 100%;
}

.home_interview_member .member_nav .slick-track {
  width: 100% !important;
  height: 100%;
  transform: none !important;
}

.home_interview_member .member_nav li {
  position: relative;
  float: none !important;
  width: 100% !important;
  height: 25%;
  border-bottom: 1px solid #000;
  transition: all 0.4s ease-out;
  cursor: pointer;
}
.home_interview_member .member_nav li > .flex {
  align-items: flex-end;
  padding-left: 6.61vw;
}
.home_interview_member .member_nav li:hover {
  color: var(--color-primary-default);
}
.home_interview_member .member_nav li:nth-child(2) {
  border-bottom-color: #fff;
}
.home_interview_member .member_nav li:nth-child(3), .home_interview_member .member_nav li:nth-child(4) {
  color: #fff;
  border-bottom-color: #fff;
}
.home_interview_member .member_nav li:nth-child(3):hover, .home_interview_member .member_nav li:nth-child(4):hover {
  color: #64ADEB;
}
.home_interview_member .member_nav li .imgarea {
  margin-right: 2.08vw;
  width: 13.75rem;
  height: 13.75rem;
  overflow: hidden;
}
.home_interview_member .member_nav li .txtarea {
  flex: 1;
  padding-bottom: 1rem;
}

@media screen and (max-width: 47.9375em) {
  .home_interview_member .member_nav li .catch {
    padding: 0 5% 5%;
  }
  .home_interview_member .member_nav li .catch span {
    display: inline;
    font-size: 5.5vw;
    color: #fff;
    background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
    box-decoration-break: clone;
    padding: 0.2857142857rem 0.8571428571rem;
    line-height: 2;
  }
  .home_interview_member .member_nav li:nth-child(3) .catch span,
  .home_interview_member .member_nav li:nth-child(4) .catch span {
    color: var(--color-primary-default);
    background: linear-gradient(transparent 0%, #fff 0%);
  }
}
.home_interview_member .member_nav .imgarea[data-name="R.M"], .home_interview_member .member_nav .imgarea[data-name="T.T"] {
  display: flex;
  align-items: flex-end;
}
.home_interview_member .member_nav .imgarea[data-name="K.Y"] {
  padding-top: 0.5rem;
}
.home_interview_member .member_nav .imgarea[data-name="R.T"] img {
  width: 91% !important;
  margin-left: auto;
}
.home_interview_member .member_nav .imgarea[data-name="T.T"] img {
  width: 91% !important;
}
.home_interview_member .member_nav .imgarea[data-name="K.Y"] img {
  width: 75% !important;
  margin-left: auto;
  margin-right: auto;
}

.home_interview_member .member_nav .name {
  margin-bottom: 0.5rem;
  font-size: 3.75rem;
  letter-spacing: -0.03125rem;
  line-height: 1;
}

.home_interview_member .member_nav li:nth-child(3) .name a,
.home_interview_member .member_nav li:nth-child(4) .name a {
  color: #fff;
}
.home_interview_member .member_nav li:nth-child(3) .name a:hover,
.home_interview_member .member_nav li:nth-child(4) .name a:hover {
  color: #64ADEB;
}

.home_interview_member .member_nav .info {
  font-size: 1.125rem;
  letter-spacing: 0.05625rem;
  line-height: 1.6;
}

.home_interview_member .member_conts .slick-list,
.home_interview_member .member_conts .slick-track,
.home_interview_member .member_conts--bloc {
  height: 100%;
}

.home_interview_member .member_conts--bloc .catch {
  position: absolute;
  left: 7vw;
  bottom: 7.5rem;
  z-index: 1;
  color: #fff;
}
.home_interview_member .member_conts--bloc .catch span {
  display: inline;
  background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
  box-decoration-break: clone;
  font-size: 2.5rem;
  padding: 0.25rem 1rem;
  line-height: 2.2;
}

.member_conts .slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
}

.member_conts .slick-dots li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
}

.member_conts .slick-dots button {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  font-size: 0;
  background-color: #c91a1a;
  opacity: 1;
}

.member_conts .slick-dots .slick-active button {
  -webkit-animation: sliderNav 5.7s linear 0s normal forwards running;
  animation: sliderNav 5.7s linear 0s normal forwards running;
}

/* home_life
********************************************** */
.home_life {
  position: relative;
  padding-top: 11.25rem;
  padding-bottom: 25rem;
  background-color: var(--color-primary-default);
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_life {
    padding-top: 3.2857142857rem;
    padding-bottom: 55vh;
  }
}

@media screen and (max-width: 47.9375em) {
  .home_life > .inner {
    flex-direction: column-reverse;
  }
}

.home_life--ttl {
  position: relative;
  font-size: 3.75rem;
  color: #fff;
  writing-mode: vertical-rl;
  font-feature-settings: initial;
  word-break: keep-all;
  margin-left: 4.375rem;
  letter-spacing: 0.375rem;
  padding-top: 5.375rem;
}
@media screen and (max-width: 47.9375em) {
  .home_life--ttl {
    padding-top: 2rem;
    font-size: 7.5vw;
    writing-mode: horizontal-tb;
    margin-left: 0;
    margin-right: auto;
  }
}
.home_life--ttl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 9.8125rem;
  height: 3rem;
  background-size: cover;
  background-image: url(../images/common/wave3.svg);
}
@media screen and (max-width: 47.9375em) {
  .home_life--ttl::before {
    right: auto;
    width: 3.25rem;
    height: 1rem;
  }
}

.home_life--lead {
  width: 19rem;
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .home_life--lead {
    width: 100%;
    margin-top: 1.7142857143rem;
  }
}

.home_life--lead .more {
  margin-top: 4.375rem;
  text-align: center;
}

.home_life--lead .more a {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  border: 1px solid;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  letter-spacing: 0.25rem;
}
@media screen and (max-width: 47.9375em) {
  .home_life--lead .more a {
    width: 100%;
    height: auto;
    border-radius: 99px;
    padding: 10px;
    font-size: 1.2857142857rem;
  }
}
.home_life--lead .more a::before, .home_life--lead .more a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .home_life--lead .more a::before, .home_life--lead .more a::after {
    border-radius: 99px;
  }
}
.home_life--lead .more a::after {
  animation-delay: -2s;
}
.home_life--lead .more a:hover::before, .home_life--lead .more a:hover::after {
  animation: scale_anime 3s infinite;
}

.home_life--photo1 {
  top: 5rem;
  left: 0;
  width: 51.3125rem;
  height: 39.875rem;
}
@media screen and (max-width: 47.9375em) {
  .home_life--photo1 {
    top: auto;
    bottom: 21vh;
    width: 63%;
    height: auto;
  }
}

.home_life--photo2 {
  top: 48%;
  left: 15%;
  width: 39.5rem;
  height: 29.125rem;
}
@media screen and (max-width: 47.9375em) {
  .home_life--photo2 {
    top: auto;
    left: auto;
    right: 0;
    bottom: 5vh;
    width: 50%;
    height: auto;
  }
}

.home_life--img {
  right: 0;
  bottom: -6rem;
  width: 60.0625rem;
  height: 35.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .home_life--img {
    bottom: -2.1428571429rem;
    width: 78%;
    height: auto;
  }
}

.home_life--bg {
  left: 0;
  width: 100%;
}
@media screen and (max-width: 47.9375em) {
  .home_life--bg {
    bottom: 0;
  }
}

/* home_job
********************************************** */
.home_job {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 10rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, var(--color-primary-default) 30%);
}
@media screen and (max-width: 47.9375em) {
  .home_job {
    padding-top: 3.2857142857rem;
    padding-bottom: 4.5714285714rem;
  }
}
.home_job::before, .home_job::after {
  content: "";
  position: absolute;
}
.home_job::before {
  top: -6.1875rem;
  left: 0;
  width: 45%;
  height: 6.25rem;
  background-color: #fff;
  clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
}
@media screen and (max-width: 47.9375em) {
  .home_job::before {
    display: none;
  }
}
.home_job::after {
  left: 0;
  right: 0;
  bottom: -3.9375rem;
  margin: 0 auto;
  width: 10.125rem;
  height: 4rem;
  background-color: var(--color-primary-default);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
}
@media screen and (max-width: 47.9375em) {
  .home_job::after {
    bottom: -23px;
    width: 64px;
    height: 24px;
  }
}

.home_job--ttl {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2.5rem;
  font-size: 4.375rem;
  letter-spacing: 0.13125rem;
  color: var(--color-primary-default);
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_job--ttl {
    flex-direction: column;
    font-size: 2.8571428571rem;
  }
}
.home_job--ttl::before {
  content: "";
  display: inline-block;
  margin-top: 0.5rem;
  margin-left: 1.25rem;
  margin-right: 1.875rem;
  width: 3.25rem;
  height: 1rem;
  background-image: url(../images/common/wave.svg);
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .home_job--ttl::before {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.1428571429rem;
  }
}

.home_job--blocs li {
  position: relative;
  width: 50%;
  height: 53.75rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_job--blocs li {
    height: auto;
  }
}
.home_job--blocs li::before, .home_job--blocs li::after {
  content: "";
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  transition: all 0.4s ease-out;
}
.home_job--blocs li::before {
  filter: blur(15px);
  background-size: cover;
  background-position: center;
}
.home_job--blocs li.job1::before {
  background-image: url(../images/home/job_img1.png);
}
.home_job--blocs li.job2::before {
  background-image: url(../images/home/job_img2.jpg);
}
.home_job--blocs li::after {
  background: linear-gradient(0deg, rgba(0, 82, 178, 0.7) 0%, rgba(0, 82, 178, 0.7) 100%);
  background-blend-mode: multiply, normal;
}

.home_job--blocs a {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .home_job--blocs a {
    padding-block: 4.2857142857rem;
  }
}
.home_job--blocs a .txtarea {
  width: 23.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .home_job--blocs a .txtarea {
    width: 80%;
  }
}

.home_job--blocs .ttl {
  border-bottom: 1px solid;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  font-size: 3.75rem;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .home_job--blocs .ttl {
    padding-bottom: 1.1428571429rem;
    margin-bottom: 1.1428571429rem;
    font-size: 2rem;
  }
}

.home_job--other {
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
}
.home_job--other::before, .home_job--other::after {
  content: "";
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  transition: all 0.4s ease-out;
}
.home_job--other::before {
  filter: blur(15px);
  background-size: cover;
  background-position: center;
  background-image: url(../images/home/job_img3.jpg);
}
.home_job--other::after {
  background: linear-gradient(0deg, rgba(0, 82, 178, 0.7) 0%, rgba(0, 82, 178, 0.7) 100%);
  background-blend-mode: multiply, normal;
}
.home_job--other a {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 17.5rem;
  color: #fff;
  text-align: center;
  font-size: 3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .home_job--other a {
    height: 14.2857142857rem;
    font-size: 2rem;
  }
}

.home_job--blocs li:hover::before,
.home_job--other:hover::before {
  filter: blur(0);
}

/* home_
********************************************** */
/* ########################################################################

 スタンダード用TOP

######################################################################## */
.standard {
  /* hero
  ********************************************** */
  /* home_newsline
  ********************************************** */
  /* 回り込みレイアウト
  ********************************************** */
  /* スライド
  ********************************************** */
  /* 背景写真カラム
  ********************************************** */
  /* 新着情報、ブログ
  ********************************************** */
  /* お問い合わせ
  ********************************************** */
  /* フッター上リンク（名称求む…）
  ********************************************** */
}
.standard .hero--cont {
  position: absolute;
  top: auto;
  left: 9.6875rem;
  bottom: 7.875rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .hero--cont {
    left: 8%;
    right: 8%;
    bottom: 10%;
  }
}
.standard .hero--catch {
  font-size: 3rem;
  line-height: 1.6666666667;
}
@media screen and (max-width: 47.9375em) {
  .standard .hero--catch {
    font-size: 2.2857142857rem;
    line-height: 1.3;
  }
}
.standard .hero--lead {
  line-height: 2;
}
.standard .home_newsline {
  padding-block: 1rem;
}
.standard .home_newsline .inner {
  gap: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_newsline .inner {
    gap: 0.7142857143rem;
  }
}
.standard .home_newsline .post {
  gap: 2.5rem;
  margin-block: -1rem;
  padding-block: 1rem;
}
@media screen and (min-width: 48em) {
  .standard .home_newsline .post {
    width: 45.3125vw;
  }
}
.standard .home_newsline--more {
  display: flex;
  gap: 0.875rem;
  margin-block: -1rem;
  padding-block: 1rem;
  color: var(--color-primary-default);
  font-size: max(13px, 0.9375rem);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_newsline--more {
    margin-left: auto;
  }
}
.standard .home_newsline--more svg {
  width: 1rem;
  aspect-ratio: 1;
  fill: currentColor;
}
.standard .lps_parts--img_text .inner:not(.inner-lg) {
  width: 100%;
}
.standard .lps_parts--img_text:has(.inner-lg) {
  margin-inline: calc(50% - 50vw);
  margin-bottom: -5rem;
  counter-reset: number;
}
@media screen and (max-width: 47.9375em) {
  .standard .lps_parts--img_text:has(.inner-lg) {
    margin-bottom: 1.7142857143rem;
  }
}
.standard .lps_parts--img_text:has(.inner-lg) .ttl-03 {
  font-size: 2.5rem;
  line-height: 1.5;
}
@media screen and (max-width: 47.9375em) {
  .standard .lps_parts--img_text:has(.inner-lg) .ttl-03 {
    font-size: 2rem;
  }
}
.standard .lps_parts--img_text:has(.inner-lg) .ttl-03::before {
  counter-increment: number;
  content: "0" counter(number);
  position: absolute;
  right: 0;
  top: -0.5em;
  z-index: -1;
  color: var(--color-primary-default);
  font-size: 12.5rem;
  font-family: var(--font-en);
  line-height: var(--line-height-none);
  letter-spacing: 0;
  opacity: 0.2;
}
@media screen and (max-width: 47.9375em) {
  .standard .lps_parts--img_text:has(.inner-lg) .ttl-03::before {
    top: -0.3em;
    font-size: 7.1428571429rem;
  }
}
.standard .lps_parts--img_text:has(.inner-lg) .lps_parts + .lps_parts {
  margin-top: 0;
}
@media print, screen and (min-width: 48em) {
  .standard .lps_parts--img_text:has(.inner-lg) .img_text--txt {
    min-height: 45rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .lps_parts--img_text:has(.inner-lg) .img_text--txt {
    padding-block: 2.2857142857rem;
  }
}
.standard .lps_parts--img_text .img {
  overflow: hidden;
}
.standard .slide-query .slick-list {
  margin-inline: calc(50% - 50vw);
}
.standard .slide-query .slick-slide {
  transition: var(--transit-default);
}
.standard .slide-query .slick-slide:not(.slick-active) {
  opacity: 0.3;
}
.standard .slide-query .slick-arrow {
  position: absolute;
  z-index: 1;
  display: grid;
  place-items: center;
  width: 4rem;
  height: 4rem;
  color: var(--color-primary-default);
  border-radius: 50%;
  background-color: var(--color-wht);
}
.standard .slide-query .slick-arrow::before, .standard .slide-query .slick-arrow::after {
  content: "";
  position: absolute;
}
.standard .slide-query .slick-arrow::before {
  inset: 0;
  border-radius: 50%;
  box-shadow: 0 0 2rem var(--color-primary-default);
  opacity: 0.2;
}
.standard .slide-query .slick-arrow::after {
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
}
.standard .slide-query .slick-prev::after {
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.standard .slide-query .slick-next::after {
  border-top: 2px solid;
  border-right: 2px solid;
}
.standard .posts-query01 .post {
  width: 23.625rem;
  margin-inline: 1rem;
}
.standard .posts-query01 .post--txt {
  text-align: justify;
}
.standard .posts-query01 .slide-query .slick-arrow {
  top: 5.8125rem;
}
.standard .posts-query01 .slide-query .slick-prev {
  left: -3rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .posts-query01 .slide-query .slick-prev {
    left: 0;
  }
}
.standard .posts-query01 .slide-query .slick-next {
  right: -3rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .posts-query01 .slide-query .slick-next {
    right: 0;
  }
}
.standard .posts-query02 .post {
  width: 23.625rem;
  margin-inline: 1rem;
  padding: 2.375rem 2.375rem 3.375rem;
  background-color: var(--color-wht);
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}
.standard .posts-query02 .post--num {
  display: block;
  color: var(--color-primary-default);
  font-size: 5rem;
  font-family: var(--font-en);
  text-align: center;
  line-height: var(--line-height-none);
  letter-spacing: 0;
}
.standard .posts-query02 .post--img {
  width: 11.5rem;
  margin-inline: auto;
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1;
}
.standard .posts-query02 .post--ttl {
  text-align: center;
}
.standard .posts-query02 .post--txt {
  text-align: justify;
}
.standard .posts-query02 .slide-query .slick-track {
  display: flex;
}
.standard .posts-query02 .slide-query .slick-list {
  padding-top: 6.4375rem !important;
  padding-bottom: 2rem !important;
}
@media screen and (min-width: 48em) {
  .standard .posts-query02 .slide-query .slick-list {
    padding-left: calc(23.625rem - 1rem);
  }
}
.standard .posts-query02 .slide-query .slick-slide {
  height: auto;
}
.standard .posts-query02 .slide-query .slick-arrow {
  top: 0;
}
.standard .posts-query02 .slide-query .slick-prev {
  right: 5.4375rem;
}
.standard .posts-query02 .slide-query .slick-next {
  right: 0;
}
.standard .bg_section {
  position: relative;
  display: flex;
  align-items: center;
  padding-block: 5rem;
}
@media print, screen and (min-width: 48em) {
  .standard .bg_section {
    min-height: 45rem;
  }
}
.standard .bg_section:nth-of-type(odd) .bg_section--txt {
  margin-left: auto;
}
.standard .bg_section--img {
  position: absolute;
  inset: 0;
  z-index: -1;
}
@media screen and (min-width: 48em) {
  .standard .bg_section--txt {
    width: 30.1875rem;
  }
}
.standard .home_information01 {
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_information01 {
    flex-direction: column;
  }
}
.standard .home_information01 + .home_information01 {
  margin-top: 6.25rem;
}
.standard .home_information01--ttl {
  flex: 1;
}
@media screen and (min-width: 48em) {
  .standard .home_information01--cont {
    width: 50rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_information01--ttl {
    text-align: center;
  }
  .standard .home_information01--ttl .ttl-01-sub::after {
    margin-inline: auto;
  }
  .standard .home_information01 .btn-arrow {
    text-align: center;
  }
}
.standard .home_information01 .posts-news .post--txt,
.standard .home_information01 .posts-blog .post--txt {
  -webkit-line-clamp: 1;
  opacity: 0.5;
  pointer-events: none;
}
.standard .home_information01 .posts-news .post--date {
  font-size: max(16px, 1.125rem);
}
.standard .home_information01 .posts-news .post--txtarea {
  flex: 1;
}
.standard .home_information01 .posts-blog .post {
  padding-block: 1.75rem;
}
.standard .home_information01 .posts-blog .post--date {
  font-size: max(11px, 0.8125rem);
}
.standard .home_information02--wrap {
  display: grid;
  gap: 1.25rem 4.6875rem;
}
@media print, screen and (min-width: 48em) {
  .standard .home_information02--wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 48em) {
  .standard .home_information02 {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    gap: 0.625rem;
  }
}
.standard .home_information02 .posts-news .post--date,
.standard .home_information02 .posts-blog .post--date {
  font-size: max(11px, 0.8125rem);
}
.standard .home_information02 .posts-news {
  padding: 2.5rem 3rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_information02 .posts-news {
    padding: 0.5714285714rem 1.1428571429rem;
  }
}
.standard .home_information02 .posts-news .post {
  display: block;
}
.standard .home_information02 .posts-news .post:first-child {
  border-top: none;
}
.standard .home_information02 .posts-news .post:last-child {
  border-bottom: none;
}
.standard .home_information02 .posts-news .post--txt {
  -webkit-line-clamp: 2;
  opacity: 0.5;
  font-size: max(12px, 0.875rem);
  line-height: 1.5714285714;
  pointer-events: none;
}
.standard .home_information02 .posts-news .post--info {
  margin-bottom: 0.5rem;
}
.standard .home_information02 .posts-news .post--ttl {
  margin-bottom: 0.5rem;
}
.standard .home_information02 .posts-blog .post {
  padding: 0;
  border: none;
  gap: 2rem;
}
@media print, screen and (min-width: 48em) {
  .standard .home_information02 .posts-blog .post {
    grid-template-columns: 13.5rem minmax(0, 1fr);
  }
}
.standard .home_information02 .posts-blog .post:not(:last-child) {
  margin-bottom: 2rem;
}
.standard .home_information02 .posts-blog .post--info {
  align-items: flex-start;
  flex-direction: column-reverse;
}
.standard .home_information02 .posts-blog .cat_list {
  margin-left: 0 !important;
  margin-bottom: 0.5rem;
}
.standard .home_cta {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 12rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_cta {
    padding-bottom: 7.1428571429rem;
  }
}
.standard .home_cta .inner {
  padding: 7.5rem;
  border-radius: 0.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_cta .inner {
    padding: 2.2857142857rem;
  }
}
.standard .home_cta--bg {
  position: absolute;
  bottom: 0;
  z-index: -1;
  inset-inline: 0;
  aspect-ratio: 1920/520;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_cta--bg {
    aspect-ratio: 375/200;
  }
}
.standard .home_footer_toplink .related_page--list {
  border-radius: 0;
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding-top: 6.25rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl {
    padding-top: 2.8571428571rem;
  }
}

.page_ttl--bg {
  width: 100%;
  height: 43.6875rem;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl--bg {
    height: 14.2857142857rem;
    background-size: cover;
  }
}

@media screen and (max-width: 47.9375em) {
  .single-interview .page_ttl--bg {
    height: 28.5714285714rem;
    background-position: center right 34%;
  }
}

.page_ttl > .inner {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl > .inner {
    flex-direction: column;
    align-items: flex-start;
  }
}

.page_ttl-jp {
  margin-left: 3.75rem;
  margin-bottom: 1rem;
  padding-inline: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #0052B2;
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-jp {
    margin-left: 0;
    margin-bottom: 0;
    padding-inline: 0;
    font-size: 1.1428571429rem;
  }
}
.page_ttl-jp::before {
  content: "";
  display: inline-block;
  width: 3.25rem;
  height: 1rem;
  margin-right: 1rem;
  background-size: cover;
  background-image: url(../images/common/wave.svg);
}

.page_ttl-en {
  display: block;
  text-transform: capitalize;
  font-size: 9.5rem;
  line-height: 1.11;
  letter-spacing: 0.05em;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    margin-bottom: 1.1428571429rem;
    font-size: 12.5vw;
  }
}

/* --- page_ttl（タイトル画像なし） --- */
/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg .breadcrumbs span span:not(:last-child)::after {
  background-color: #CCC;
}

.page_ttl .breadcrumbs {
  margin-left: auto;
  margin-right: -4%;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl .breadcrumbs {
    margin-top: 1.1428571429rem;
    margin-left: 0;
    margin-right: 0;
  }
}

/* --- ttl --- */
.lps_sec_ttl {
  position: relative;
}
.lps_sec_ttl:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 3em;
}
.lps_sec_ttl:not(.txt-ctr) > .inner {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec_ttl:not(.txt-ctr) > .inner {
    flex-direction: column;
    align-items: center;
  }
}

.lps_sec_ttl.txt-ctr .ttl-01 + .ttl-01-sub {
  margin-top: 1.5rem;
}

.lps_sec_ttl:not(.txt-ctr) .ttl-01-sub {
  margin-left: 3.75rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec_ttl:not(.txt-ctr) .ttl-01-sub {
    width: 100%;
    margin-top: 1.1428571429rem;
    margin-left: 0;
  }
}

.ttl-01 {
  font-size: 3rem;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-size: 2rem;
    text-align: center;
  }
}
.ttl-01.clr-wh + .ttl-01-sub {
  color: var(--color-wht);
}
.ttl-01.font-en span {
  display: block;
  font-size: 7.5rem;
  line-height: 1.1;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01.font-en span {
    margin-top: 0.5714285714rem;
    font-size: 15vw;
  }
}
.ttl-01.font-jp span {
  display: block;
}

.ttl-01-sub {
  display: block;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .ttl-01-sub {
    text-align: center;
  }
}
.ttl-01-sub span {
  display: inline-block;
  padding-block: 8px;
  border-top: 1px solid var(--color-primary-default);
  border-bottom: 1px solid var(--color-primary-default);
  font-size: 2rem;
  color: #000;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01-sub span {
    font-size: 1.7142857143rem;
  }
}

.clr-wh .ttl-01-sub span {
  border-top-color: #fff;
  border-bottom-color: #fff;
  color: #fff;
}

.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
}
.ttl-03:not([class*=mgn-btm]):not(:last-child),
.ttl-04:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 1em;
}

.ttl-02 {
  position: relative;
  padding-block: 2.5rem;
  border-top: 1px solid;
  border-bottom: 1px solid;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 0.03em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    padding-block: 1.1428571429rem;
  }
}
.ttl-02:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 2em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 1.7142857143rem;
  }
}
.ttl-02::first-letter {
  color: var(--color-primary-default);
}

.clr-wh .ttl-02::first-letter {
  color: #fff;
}

.ttl-03 {
  font-size: max(22px, 1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.4285714286rem;
  }
}

.ttl-04 {
  font-size: max(22px, 1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .ttl-04 {
    font-size: 1.4285714286rem;
  }
}

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  position: sticky;
  z-index: 999;
  top: 35%;
  right: 0;
  float: right;
  transform: translateX(100%);
  transition: all 0.4s ease-out;
  margin-right: -100%;
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list_wrap {
    position: fixed;
    margin-right: 0;
  }
}

.anchor_link--list_wrap.active {
  transform: translateX(0);
}

.anchor_link--list {
  background-color: #fff;
  border-radius: 0 0 0 8px;
  box-shadow: 0 0 24px 0 rgba(30, 42, 77, 0.15);
  padding: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list {
    padding: 1.7142857143rem;
  }
}

.anchor_link--list li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.anchor_link--list a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #404044;
}
.anchor_link--list a.current {
  color: #F94B2E;
}
.anchor_link--list a:hover {
  opacity: 0.7;
}
.anchor_link--list a::before {
  content: "";
  display: inline-block;
  width: 3.25rem;
  height: 1rem;
  margin-right: 0.75rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../images/common/wave.svg);
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list a::before {
    display: none;
  }
}

.anchor_link--ttl {
  position: absolute;
  top: 0;
  left: -62px;
  width: 62px;
  height: 10.1875rem;
  padding-block: 24px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px 0px rgba(30, 42, 77, 0.15);
  background-color: var(--color-primary-default);
  color: #fff;
  font-weight: 300;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-size: 1.5rem;
  letter-spacing: 1px;
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--ttl {
    left: -46px;
    width: 46px;
    height: auto;
    font-size: 1.4285714286rem;
  }
}

.anchor_link--ttl .icon {
  position: relative;
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  transform: translateX(1px);
  margin-bottom: 8px;
}
.anchor_link--ttl .icon::before, .anchor_link--ttl .icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  background-color: #fff;
  transition: all 0.4s ease-out;
}
.anchor_link--ttl .icon::after {
  transform: rotate(90deg);
}

.anchor_link--ttl.active .icon::before {
  transform: rotate(-45deg);
}
.anchor_link--ttl.active .icon::after {
  transform: rotate(45deg);
}

.anchor_link--list_wrap + .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(30, 42, 77, 0.4);
  opacity: 0;
  transition: all 0.4s ease-out;
  z-index: 10;
}

.anchor_link--list_wrap.active + .overlay {
  opacity: 1;
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
.main_side {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 16.5rem;
  gap: 5rem;
}
@media screen and (max-width: 67.5em) {
  .main_side {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2.8571428571rem;
  }
}

/* main_column
**************************************** */
/* --- post --- */
.post {
  position: relative;
}

.post--img {
  background-color: var(--color-wht);
}

.post--img .img-contain {
  max-width: 80%;
}

.post--link:not([href="javascript:void(0);"]):hover {
  color: var(--color-primary-default);
}
.post--link:hover svg {
  fill: var(--color-primary-default);
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 0.125rem;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: max(11px, 0.8125rem);
  background-color: var(--color-primary-default);
  color: var(--color-wht);
  padding-inline: 1em;
  border-radius: 0.25rem;
}

.cat_list a:hover {
  opacity: 0.7;
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .side_section:not(:last-child) {
    margin-bottom: 2.2857142857rem;
  }
}

.side--ttl {
  letter-spacing: 0.2em;
  line-height: 1;
  margin-bottom: 1rem;
}

.side--ttl span {
  opacity: 0.5;
  letter-spacing: 0.15em;
  display: block;
  line-height: 1;
  margin-top: 0.5rem;
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post {
  display: grid;
  grid-template-columns: 4rem minmax(0, 1fr);
  gap: 1rem;
  align-items: center;
}
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: var(--line-height-hdr);
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.archive_list--ttl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--transit-default);
}
.archive_list--ttl.active::after {
  transform: rotate(315deg);
}

.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 1.5rem;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid var(--color-bg-02);
}
.archive-pulldown .archive_list a:hover {
  background-color: var(--color-bg-02);
}
.archive-pulldown .archive_list--label {
  margin-right: 0.5rem;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--color-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: max(14px, 1rem);
  line-height: var(--line-height-default);
  font-weight: 500;
  transition: var(--transit-default);
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: var(--transit-default);
}
.archive-pulldown .archive_list--btn:hover {
  background-color: var(--color-bg-02);
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--color-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default);
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* interview
**************************************** */
@media screen and (max-width: 47.9375em) {
  .posts-interview .post {
    margin-bottom: 4.5714285714rem;
  }
}

.posts-interview .post--img {
  position: relative;
  height: 23.375rem;
  display: flex;
  align-items: flex-end;
}
.posts-interview .post--img img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 91%;
  object-fit: contain;
}

.posts-interview .post--catch {
  margin-bottom: 1rem;
}
.posts-interview .post--catch span {
  display: inline;
  font-size: 1.5rem;
  color: #fff;
  background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
  box-decoration-break: clone;
  padding: 0.375rem 1rem;
  line-height: 2.2;
  font-feature-settings: "palt";
}

.posts-interview .name {
  margin-right: 1rem;
  font-size: 2.5rem;
  line-height: 1;
}

.posts-interview .data {
  line-height: 1.5;
}

/* requirements
**************************************** */
@media screen and (max-width: 47.9375em) {
  .posts-requirements .post {
    margin-bottom: 2.8571428571rem;
  }
}

/* blog
**************************************** */
.posts-blog .post {
  display: grid;
  gap: 2.5rem;
  padding-block: 3rem;
  border-bottom: 1px solid #e5e5e5;
}
@media print, screen and (min-width: 48em) {
  .posts-blog .post {
    grid-template-columns: 17.5rem minmax(0, 1fr);
    align-items: center;
  }
}
@media screen and (max-width: 47.9375em) {
  .posts-blog .post {
    gap: 1.7142857143rem;
    padding-block: 2.2857142857rem;
  }
}
.posts-blog .post:first-child {
  border-top: 1px solid #e5e5e5;
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--ttl {
  font-size: max(16px, 1.125rem);
}

/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: var(--color-wht);
}

/* --- posts-news --- */
.posts-news .post {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
}
.posts-news .post:first-child {
  border-top: 1px solid #dedede;
}
@media screen and (max-width: 47.9375em) {
  .posts-news .post {
    display: block;
  }
}
@media print, screen and (min-width: 48em) {
  .posts-news .post--info {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .posts-news .post--info {
    margin-bottom: 0.5714285714rem;
  }
}
.posts-news .post--txtarea {
  flex: 1;
}
.posts-news .post--ttl {
  font-size: max(16px, 1.125rem);
}
.posts-news .post--info.flex .post--date + .cat_list {
  margin-left: 1rem;
}

/* ##############################################################################

    PAGE

############################################################################## */
/* related_page
********************************************** */
.section-related_page > .inner {
  border-bottom: 1px solid;
  padding-bottom: 2rem;
}

.related_page--ttl {
  font-size: 7.5rem;
  line-height: 1.1;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .related_page--ttl {
    margin-bottom: 0.5714285714rem;
    font-size: 2.2857142857rem;
  }
}

.related_page--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 1;
}
@media screen and (max-width: 47.9375em) {
  .related_page--list {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.related_page--list li {
  margin-left: 3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .related_page--list li {
    margin-top: 0.5714285714rem;
    margin-left: 0;
  }
}

.related_page--list a {
  position: relative;
  display: inline-block;
  padding-left: 3rem;
  font-size: 1.125rem;
}
.related_page--list a::before, .related_page--list a::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.related_page--list a::before {
  left: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--color-primary-default);
}
.related_page--list a::after {
  top: -0.25rem;
  left: 0.6875rem;
  width: 0.5rem;
  height: 0.875rem;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
}

@media screen and (max-width: 47.9375em) {
  .page-company .mce-content-body span[style="font-size: 400%;"] {
    font-size: 250% !important;
  }
}

@media screen and (max-width: 47.9375em) {
  .page-company .mce-content-body span[style="font-size: 300%;"] {
    font-size: 200% !important;
  }
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

#search-list .ttl-01 {
  margin: 4.375rem 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: 1.5rem;
  background-color: #fafafa;
}

.ez-toc-title-container {
  margin-bottom: 0.625rem;
}

div#ez-toc-container p.ez-toc-title {
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: max(18px, 1.25rem);
}

#ez-toc-container.counter-hierarchy ul {
  font-size: max(15px, 1.0625rem);
}

#ez-toc-container.counter-hierarchy ul li {
  margin-top: 0.5rem;
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}
#ez-toc-container.counter-hierarchy ul ul a::before {
  content: "";
  position: absolute;
  top: 0.625rem;
  left: 0;
  width: 0.5rem;
  height: 0;
  border-bottom: 1px solid;
}

/* requirements
**************************************** */
.requirements--info .ttl-03 {
  margin-right: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .requirements--info .ttl-03 {
    margin-right: 0;
    margin-bottom: 1.1428571429rem;
  }
}

.requirements--info .table1 th {
  width: 24%;
}
@media screen and (max-width: 47.9375em) {
  .requirements--info .table1 th {
    width: 100%;
  }
}
.requirements--info .table1 td {
  padding-inline: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .requirements--info .table1 td {
    padding-inline: 0;
  }
}

.requirements--info .editor-ttl3 {
  color: var(--color-primary-default);
}

.salary_ttl {
  font-size: 1.25rem;
  color: var(--color-primary-default);
}

/* interview
**************************************** */
.profile_bloc {
  position: relative;
  z-index: 10;
  margin-top: -21.25rem;
  margin-bottom: 10rem;
}
@media screen and (max-width: 47.9375em) {
  .profile_bloc {
    margin-top: -22.8571428571rem;
    margin-bottom: 4rem;
  }
}

.profile_bloc .inner {
  border-bottom: 1px solid;
}

.profile_bloc .txtarea {
  margin-left: 4.375rem;
  padding-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .profile_bloc .txtarea {
    margin-left: 0;
    padding-bottom: 1.7142857143rem;
  }
}

.profile_bloc .catch {
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .profile_bloc .catch {
    margin-bottom: 1.7142857143rem;
  }
}
.profile_bloc .catch span {
  display: inline;
  font-size: 2.5rem;
  color: #fff;
  background: linear-gradient(transparent 0%, var(--color-primary-default) 0%);
  box-decoration-break: clone;
  padding: 0.5rem 1rem;
  line-height: 2.2;
}
@media screen and (max-width: 47.9375em) {
  .profile_bloc .catch span {
    font-size: 5.5vw;
  }
}

.profile_bloc .name {
  margin-right: 2.5rem;
  font-size: 3.75rem;
  line-height: 1;
}

.profile_bloc .data {
  font-size: 1.125rem;
  line-height: 1.8;
}

.interviews_wrap {
  padding-bottom: 5rem;
}

.interviews_nav {
  position: sticky;
  top: 7.5rem;
}

.interviews_nav li {
  display: none;
}
.interviews_nav li.is-active {
  display: block;
}

@media screen and (max-width: 47.9375em) {
  .interviews_nav li,
  .schedule_bloc--ttl,
  .interviews_bloc--ttl,
  .other_interview--ttl {
    text-align: center;
  }
}
.interviews_nav li .en,
.schedule_bloc--ttl .en,
.interviews_bloc--ttl .en,
.other_interview--ttl .en {
  display: block;
  margin-bottom: 2.5rem;
  font-size: 7.5rem;
  color: var(--color-primary-default);
  line-height: 1.1;
}
@media screen and (max-width: 47.9375em) {
  .interviews_nav li .en,
  .schedule_bloc--ttl .en,
  .interviews_bloc--ttl .en,
  .other_interview--ttl .en {
    margin-bottom: 1.1428571429rem;
    font-size: 10vw;
  }
  .interviews_nav li .en br,
  .schedule_bloc--ttl .en br,
  .interviews_bloc--ttl .en br,
  .other_interview--ttl .en br {
    display: none;
  }
}
.interviews_nav li .jp,
.schedule_bloc--ttl .jp,
.interviews_bloc--ttl .jp,
.other_interview--ttl .jp {
  display: inline-block;
  padding: 0.5rem 0;
  border-top: 1px solid var(--color-primary-default);
  border-bottom: 1px solid var(--color-primary-default);
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .interviews_nav li .jp,
  .schedule_bloc--ttl .jp,
  .interviews_bloc--ttl .jp,
  .other_interview--ttl .jp {
    padding: 0.5714285714rem 0;
    font-size: 1.4285714286rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .interviews_bloc--ttl {
    margin-bottom: 2.2857142857rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .schedule_bloc {
    padding-top: 0;
  }
}

.interviews_bloc--cont {
  max-width: 57.5rem;
}

.interviews_bloc--qa dt {
  padding-bottom: 2rem;
  font-size: 2.5rem;
  color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .interviews_bloc--qa dt {
    padding-bottom: 0.5714285714rem;
    font-size: 1.5714285714rem;
  }
}
.interviews_bloc--qa dt::before {
  content: "Q.";
}

.interviews_bloc--qa dd {
  line-height: 2;
  border-bottom: 1px solid;
  padding-bottom: 3rem;
  margin-bottom: 2.5rem;
}

.interviews_bloc--img {
  margin-top: 7.5rem;
  margin-right: -18.5%;
}
@media screen and (max-width: 47.9375em) {
  .interviews_bloc--img {
    margin-top: 2.8571428571rem;
    margin-left: -8%;
    margin-right: -8%;
  }
}

.interviews_bloc--cont:not(:last-child) .interviews_bloc--img {
  margin-bottom: 5.625rem;
}

.schedule_bloc--timeline {
  flex: 1;
  margin-top: 7.75rem;
  margin-left: 5.625rem;
  column-count: 2;
}
@media screen and (max-width: 47.9375em) {
  .schedule_bloc--timeline {
    margin-top: 2.2857142857rem;
    margin-left: 0;
    column-count: 1;
  }
}

.timeline_list li {
  position: relative;
  break-inside: avoid;
  margin-left: 2.5rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .timeline_list li {
    margin-left: 0;
    font-size: 1.1428571429rem;
  }
}
.timeline_list li::before {
  content: "";
  position: absolute;
  top: 2.625rem;
  left: 0.625rem;
  height: calc(100% - 2.625rem);
  border-left: 3px dashed var(--color-primary-default);
}

.timeline_list .time {
  display: flex;
  align-items: center;
}
.timeline_list .time::before {
  content: "";
  display: inline-block;
  width: 3.25rem;
  height: 1rem;
  margin-right: 1rem;
  background-size: cover;
  background-image: url(../images/common/wave.svg);
}

.timeline_list .txt {
  padding-left: 4.25rem;
  line-height: 1.45;
}

.life_bloc .sec_line_wave.top {
  margin-top: 0;
}

.life_bloc .sec_line_wave.btm {
  margin-bottom: 0;
}

.life_bloc--wrap {
  padding: 5rem 0;
  background-color: var(--color-primary-default);
}

.life_bloc--ttl {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .life_bloc--ttl {
    margin-bottom: 4rem;
  }
}
.life_bloc--ttl .en {
  font-size: 15rem;
  color: #004987;
  line-height: 1;
  opacity: 0.5;
  mix-blend-mode: multiply;
  letter-spacing: 0.3rem;
}
@media screen and (max-width: 47.9375em) {
  .life_bloc--ttl .en {
    font-size: 12vw;
  }
}
.life_bloc--ttl .jp {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: inline-block;
  padding: 1rem 3.75rem;
  border-top: 1px solid;
  border-bottom: 1px solid;
  color: #fff;
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .life_bloc--ttl .jp {
    padding: 1.1428571429rem 1.7142857143rem;
    font-size: 8vw;
    width: 100%;
    text-align: center;
  }
}

.life_bloc--img {
  max-width: 54.0625rem;
  padding-left: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .life_bloc--img {
    padding-left: 0;
  }
}
.life_bloc--img img {
  transform: rotate(-4deg);
  border: 16px solid #fff;
}
@media screen and (max-width: 47.9375em) {
  .life_bloc--img img {
    border: 8px solid #fff;
  }
}

.life_bloc--message {
  flex: 1;
  margin-left: 8.75rem;
}
@media screen and (max-width: 47.9375em) {
  .life_bloc--message {
    margin-left: 0;
  }
}
.life_bloc--message span {
  display: inline;
  font-size: 2rem;
  color: var(--color-primary-default);
  background: linear-gradient(transparent 0%, #fff 0%);
  box-decoration-break: clone;
  padding: 0.5rem 1rem;
  line-height: 2.5;
}
@media screen and (max-width: 47.9375em) {
  .life_bloc--message span {
    font-size: 5vw;
  }
}

.other_interview--ttl {
  text-align: center;
  margin-bottom: 3.5rem;
}
@media screen and (max-width: 47.9375em) {
  .other_interview--ttl {
    margin-bottom: 1.7142857143rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .other_interview--scroll {
    overflow-x: scroll;
  }
  .other_interview--scroll .posts-interview {
    width: 1168px;
  }
}

@media screen and (max-width: 47.9375em) {
  .posts-interview .post {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .posts-interview .post--catch span {
    word-break: keep-all;
  }
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 0.3125rem;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 0.25rem 0.375rem;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* share
**************************************** */
.share--list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding: 0.5em;
  line-height: 1;
  font-size: max(10px, 0.75rem);
  font-weight: bold;
  border: 1px solid #ddd;
}

.share--list li.x a {
  color: #000;
}
.share--list li.facebook a {
  color: #2477F2;
}
.share--list li.line a {
  color: #27C754;
}
.share--list li.pocket a {
  color: #EF4056;
}
.share--list li.linkedin a {
  color: #2867B2;
}
.share--list li.hatena a {
  color: #29A4DE;
}

.share--list img {
  margin-right: 0.625rem;
}

.share--list a:hover {
  opacity: 0.5;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: clamp(3.5416666667px, 0.2083333333vw, 0.25rem);
  white-space: nowrap;
}

.wp-pagenavi > * {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wp-pagenavi .pages {
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0 1em;
  height: clamp(35.4166666667px, 2.0833333333vw, 2.5rem);
  background-color: var(--color-primary-default);
  color: var(--color-wht);
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi a {
    height: 2.5714285714rem;
  }
}
.wp-pagenavi a:hover {
  background-color: var(--color-primary-dark);
}

.archive .wp-pagenavi > *:not(.pages) {
  width: clamp(35.4166666667px, 2.0833333333vw, 2.5rem);
  aspect-ratio: 1/1;
}
@media screen and (max-width: 47.9375em) {
  .archive .wp-pagenavi > *:not(.pages) {
    width: 2.5714285714rem;
  }
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  aspect-ratio: 1/1;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5em;
  aspect-ratio: 1/1;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -0.375rem;
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -0.375rem;
}

/* sitemap
*************************************************** */
@media print, screen and (min-width: 48em) {
  .sitemap--menu {
    column-count: 2;
    gap: 5%;
  }
}

.sitemap--menu > li {
  break-inside: avoid;
}

.sitemap--menu > li > a {
  font-size: max(16px, 1.125rem);
  border-bottom: 1px solid #D3D3D3;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}
.sitemap--menu a:hover {
  color: var(--color-primary-default);
}

.sitemap_sub_nav--blc {
  padding-left: 1em;
}
.sitemap_sub_nav--blc:not(:last-child) {
  margin-bottom: 1em;
}

.sitemap--tax {
  border-bottom: 1px solid #eee;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.sitemap_sub_nav a {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.25em;
}
.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0.375rem;
  height: 0.375rem;
  color: var(--color-primary-default);
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
}

/* lps_parts--button
********************************************** */
@media print, screen and (min-width: 48em) {
  .lps_parts--button {
    width: fit-content;
    min-width: min(60rem, 90%);
    margin-left: auto;
    margin-right: auto;
  }
}

/* lps_parts--column
********************************************** */
.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .text {
  flex: 1;
}

.lps_parts--column .img + .text {
  padding-top: 5%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column .img + .text {
    padding-top: 1.7142857143rem;
  }
}

.lps_parts--column .bg-wh .text {
  padding: 8%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column .bg-wh .text {
    padding: 1.7142857143rem;
  }
}

.lps_parts--column .lps_parts--child:not(.grid-col1) table {
  width: 100%;
}

.lps_parts--column .grid-col1 .box {
  padding: 2.5rem;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column {
    margin-top: 1.7142857143rem;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .flex-sp-block .column_item:not(:last-child) {
    margin-bottom: 2.5rem;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column .grid-col1 .box {
    padding: 1.7142857143rem;
  }
}
/* lps_parts--cta
********************************************** */
.lps_parts--cta .inner {
  background-color: var(--color-bg-02);
  border-radius: 0.5rem;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--cta .cta_contact {
    padding-top: calc(1em + 0.625rem);
  }
}

.lps_parts--cta .bg {
  position: absolute;
  bottom: 0;
  inset-inline: 0;
  height: 32.5rem;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--cta .bg {
    height: 25rem;
  }
}

/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 10;
  padding: 1rem;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--faq .ttl-03 {
  font-size: max(22px, 1.5rem);
  margin-bottom: 0 !important;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 {
    font-size: 1.2142857143rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .img {
    width: 39.0625%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .mce-content-body {
    margin-bottom: 0 !important;
    flex: 1;
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) .faq_item {
  border: 2px solid var(--color-primary-default);
  background-color: var(--color-wht);
}
.lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03,
.lps_parts--faq:not(.faq-toggle) .text {
  padding: 1.75rem 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .ttl-03,
  .lps_parts--faq:not(.faq-toggle) .text {
    padding: 1.7142857143rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03 {
  background-color: var(--color-primary-default);
  color: var(--color-wht);
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle .faq_item {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 2.5rem 1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .faq_item {
    padding: 1.7142857143rem 0;
  }
}
.lps_parts--faq.faq-toggle .faq_item:not(:first-child) {
  border-top: 0;
}
.lps_parts--faq.faq-toggle .ttl-03 {
  cursor: pointer;
  padding-right: 3.5rem;
}
.lps_parts--faq.faq-toggle .ttl-03:not(:last-child) {
  margin-bottom: 1.5em;
}
.lps_parts--faq.faq-toggle .ttl-03::before {
  content: "";
  position: absolute;
  inset: -2.5rem -1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .ttl-03::before {
    inset: -1.7142857143rem 0;
  }
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 3rem;
  height: 3rem;
  color: var(--color-wht);
  background-color: var(--color-primary-default);
  border-radius: 50%;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::before, .lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 0.75rem;
  height: 2px;
  border-bottom: 2px solid;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  transform: rotate(90deg);
}
.lps_parts--faq.faq-toggle .ttl-03:hover .toggle {
  opacity: 0.5;
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle {
  transform: rotate(180deg);
  background-color: #DAEEFF;
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle::before {
  opacity: 0.8;
  border-bottom-color: #0052B2;
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle::after {
  opacity: 0;
}
.lps_parts--faq.faq-toggle .text {
  padding-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .text {
    padding-top: 1.4285714286rem;
  }
}

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--flow .flow_item {
  position: relative;
  padding-left: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 4rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 2.2857142857rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 1.875rem;
  border-right: 0.25rem dotted var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    left: 1.2857142857rem;
  }
}
.lps_parts--flow .flow_item.clr-wh:not(:last-child)::before {
  border-color: var(--color-wht);
}
.lps_parts--flow .ttl-03 {
  color: var(--color-primary-default);
}
.lps_parts--flow .ttl-03::before {
  position: absolute;
}
.lps_parts--flow .clr-wh .ttl-03 {
  color: var(--color-wht);
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .img {
    width: 39.0625%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .img:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}
.lps_parts--flow .mce-content-body {
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .mce-content-body {
    flex: 1;
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) .flow_item:not(:last-child)::before {
  top: 2.5rem;
  left: 1rem;
  bottom: 0.25rem;
}
.lps_parts--flow:not(.flow-num) .ttl-03::before {
  content: "";
  top: 0.375rem;
  left: -5.6875rem;
  width: 3.25rem;
  height: 1rem;
  background-image: url(../images/common/wave.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow:not(.flow-num) .ttl-03::before {
    left: -4rem;
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num .flow_item {
  counter-increment: number;
}
.lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
  top: 6.875rem;
  bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
    top: 3.2857142857rem;
    bottom: 1.1428571429rem;
  }
}
.lps_parts--flow.flow-num .ttl-03 {
  padding-top: 1.25rem;
  padding-left: 2rem;
  margin-bottom: 4.5rem;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow.flow-num .ttl-03 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .ttl-03 {
    padding-top: 0;
    padding-left: 1.2857142857rem;
    margin-bottom: 2.125rem;
  }
}
.lps_parts--flow.flow-num .ttl-03::before {
  content: counter(number);
  top: -0.625rem;
  left: -6rem;
  width: 6rem;
  height: 6.6875rem;
  background-image: url(../images/common/deco-polygon.svg);
  background-size: cover;
  text-align: center;
  color: var(--color-wht);
  font-family: var(--font-en);
  font-size: max(22px, 1.5rem);
  letter-spacing: 0;
  line-height: 7rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .ttl-03::before {
    top: -1.5rem;
    left: -4rem;
    width: 4.2857142857rem;
    height: 4.7857142857rem;
    line-height: 5.1428571429rem;
  }
}
.lps_parts--flow.flow-num .clr-wh .ttl-03::after {
  color: var(--color-primary-default);
  background-color: var(--color-wht);
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}

.icon-new_tab {
  width: 0.875rem;
  height: 0.875rem;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.lps_sec {
  position: relative;
}
.lps_sec:nth-child(odd) .bg-wh {
  background-color: #F2F9FF;
}
.lps_sec:nth-child(even) {
  background-color: #F2F9FF;
}
.lps_sec.lps_sec_bg {
  min-height: 50rem;
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

.layout_one_column .lps_wrap {
  max-width: 97.5rem;
  width: 90%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 47.9375em) {
  .layout_one_column .lps_wrap {
    flex-direction: column;
  }
}

.layout_one_column .lps_parts_wrap {
  width: 57.5rem;
}
@media screen and (max-width: 47.9375em) {
  .layout_one_column .lps_parts_wrap {
    width: 100%;
  }
}

.layout_one_column .inner {
  width: 100%;
}

.layout_one_column .lps_sec_ttl {
  flex: 1;
  margin-right: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .layout_one_column .lps_sec_ttl {
    margin-right: 0;
  }
}

.layout_one_column .lps_sec_ttl > .inner {
  display: block;
}

.layout_one_column .lps_sec_ttl .ttl-01-sub {
  margin-top: 1.5rem;
  margin-left: 0;
  margin-bottom: 0;
}

.lps_sec:has(+ .nami_sec) {
  padding-bottom: 12.5rem;
}

.nami_sec + .lps_sec {
  padding-top: 12.5rem;
}
@media screen and (max-width: 47.9375em) {
  .nami_sec + .lps_sec {
    padding-top: 5.7142857143rem;
  }
}

.lps_sec.nami_sec {
  padding-block: 0 !important;
  background-color: transparent !important;
}
.lps_sec.nami_sec::before {
  content: "";
  position: absolute;
  top: 276px;
  left: 0;
  width: 100%;
  height: calc(100% - 552px);
  background-color: var(--color-primary-default);
}
@media screen and (max-width: 47.9375em) {
  .lps_sec.nami_sec::before {
    top: 20vh;
    height: calc(100% - 40vh);
  }
}
.lps_sec.nami_sec .sec_line_wave {
  pointer-events: none;
  position: relative;
  z-index: 1;
}
.lps_sec.nami_sec .sec_line_wave.top {
  margin-top: -8.625rem;
}
.lps_sec.nami_sec .sec_line_wave.btm {
  margin-bottom: -8.625rem;
}
.lps_sec.nami_sec .lps_wrap {
  padding-block: 5rem;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts:not(:last-child) {
  margin-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts:not(:last-child) {
    margin-bottom: 2.8571428571rem;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 1.5rem;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .lps_parts--child {
  max-width: 97.5rem;
}

.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wh .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .img_text--child .img {
  height: 100%;
}

.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .img_text--txt {
    padding-top: 2rem;
  }
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5.3333333333%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 1.7142857143rem;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner:not(.flex-reverse) .img_text--txt {
    padding-left: 5.3333333333%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner.flex-reverse .img_text--txt {
    padding-right: 5.3333333333%;
  }
}

.lps_parts--img_text .ttl-03 {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

/* --- L --- */
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    min-height: 37.9775rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    width: 86% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
    padding-left: 10.989010989%;
    padding-right: 0;
  }
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
    padding-left: 0;
    padding-right: 10.989010989%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.8571428571rem;
  }
}
.lps_parts--img_text .inner-lg .ttl-03::before {
  content: "";
  display: block;
  width: 3.25rem;
  height: 1rem;
  margin-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/common/wave.svg);
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-wh + .bg-wh {
    margin-top: -2.8571428571rem;
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide {
  /* --- textのみ --- */
  /* --- 1枚だけ --- */
}
.lps_parts--slide .slick-track {
  display: flex;
}
.lps_parts--slide .slick-slide {
  height: auto !important;
}
.lps_parts--slide .slick-list {
  margin-bottom: 2rem;
}
.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 2.2857142857rem;
  }
}
.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  transform: rotate(45deg);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.1428571429rem;
    height: 1.1428571429rem;
  }
}
.lps_parts--slide .slick-prev {
  left: -3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slick-prev::after {
  margin-right: -20%;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.lps_parts--slide .slick-next {
  right: -3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}
.lps_parts--slide .slick-next::after {
  margin-left: -20%;
  border-top: 2px solid;
  border-right: 2px solid;
}
.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 0.25rem;
  }
}
.lps_parts--slide .slick-dots li {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default);
  margin: 0.25rem;
}
.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--color-text-primary);
}
.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}
.lps_parts--slide .img + .text {
  padding-top: 2rem;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--slide .ttl-03 {
    font-size: 2rem;
  }
}
.lps_parts--slide .text:only-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 5rem;
  background-color: var(--color-bg-01);
  height: 100%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .text:only-child {
    padding: 1.7142857143rem 2.8571428571rem;
  }
}
.lps_parts--slide:has(.slick-slide:only-child) .slick-dots {
  display: none;
}

/* slide-center
********************************************** */
.lps_parts--slide.slide-center {
  /* --- textのみ --- */
}
.lps_parts--slide.slide-center .slick-slide {
  width: 75rem;
  margin: 0 1.875rem;
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-slide {
    width: 62.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-slide {
    width: 22.8571428571rem;
    margin: 0 1.1428571429rem;
  }
}
.lps_parts--slide.slide-center .slick-prev {
  left: calc(50% - 41.25rem);
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-prev {
    left: calc(50% - 35rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-prev {
    left: calc(50% - 13.7142857143rem);
  }
}
.lps_parts--slide.slide-center .slick-next {
  right: calc(50% - 41.25rem);
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-next {
    right: calc(50% - 35rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-next {
    right: calc(50% - 13.7142857143rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .text:only-child {
    padding: 1.7142857143rem;
  }
}

/* slide-fit
********************************************** */
.layout_one_column .lps_parts--slide.slide-fit {
  width: 100vw;
  margin: 0 calc(50% - 67vw);
}
@media screen and (max-width: 47.9375em) {
  .layout_one_column .lps_parts--slide.slide-fit {
    margin: 0 calc(50% - 50vw);
  }
}

.lps_parts--slide.slide-fit {
  /* --- textのみ --- */
}
.lps_parts--slide.slide-fit .slick-track {
  background-color: var(--color-bg-01);
}
.lps_parts--slide.slide-fit .slick-slide {
  position: relative;
}
.lps_parts--slide.slide-fit .slick-prev {
  left: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .slick-prev {
    left: 1.1428571429rem;
  }
}
.lps_parts--slide.slide-fit .slick-next {
  right: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .slick-next {
    right: 1.1428571429rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--slide.slide-fit .img {
    aspect-ratio: 1920/800;
  }
}
.lps_parts--slide.slide-fit .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 15rem);
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .text {
    width: calc(100% - 8.5714285714rem);
  }
}
.lps_parts--slide.slide-fit .img + .text,
.lps_parts--slide.slide-fit .slick-arrow {
  color: var(--color-wht);
}
.lps_parts--slide.slide-fit .img + .text {
  position: absolute;
  inset: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .img + .text {
    padding-top: 2.8571428571rem;
    padding-bottom: 2.8571428571rem;
  }
}
.lps_parts--slide.slide-fit:has(.img + .text) .slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit:has(.img + .text) .slick-dots {
    bottom: 2.8571428571rem;
  }
}
.lps_parts--slide.slide-fit:has(.text:only-child) .slick-arrow {
  color: var(--color-text-primary);
}
.lps_parts--slide.slide-fit .text:only-child {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .trim-widescreen {
    aspect-ratio: 3/1.5;
  }
}

/* 背景色調整
********************************************** */
.lps_sec:nth-child(even) .lps_parts--slide .text:only-child,
.lps_sec:nth-child(even) .lps_parts--slide.slide-fit .slick-track,
.lps_sec[style*=background-color] .lps_parts--slide .text:only-child,
.lps_sec[style*=background-color] .lps_parts--slide.slide-fit .slick-track {
  background-color: var(--color-wht);
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-list {
    margin-bottom: 0;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}