@use "../abstracts" as *;

/* --- section_pdg --- */
.section_pdg {
  padding-block: rem(120);
  @include mq(sp) {
    padding-block: sprem(40);
  }
}
.section_pdg-sm {
  padding-block: rem(40);
  @include mq(sp) {
    padding-block: sprem(24);
  }
}
