@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */
.foot_entry {
  padding-top: rem(136);
  padding-bottom: rem(80);
  @include mq(sp) {
    padding-top: sprem(70);
    padding-bottom: sprem(64);
  }
}
.foot_entry--ttl {
  margin-bottom: rem(24);
  font-size: rem(70);
  letter-spacing: rem(2.1);
  color: var(--color-primary-default);
  @include mq(sp) {
    font-size: 12vw;
  }
  &::before {
    content: "";
    display: block;
    width: rem(52);
    height: rem(16);
    background-size: cover;
    background-image: url(../images/common/wave.svg);
    margin-bottom: rem(16);
  }
  span {
    background:linear-gradient(transparent 60%, rgba(#64ADEB,0.2) 60%);
  }
}
.foot_entry--lead {
  width: rem(440);
  margin-bottom: rem(24);
  font-size: rem(18);
  @include mq(sp) {
    width: 100%;
    font-size: sprem(18);
  }
}
.foot_entry--link {
  text-indent: rem(-7.9);
}
.foot_entry--link a {
  position: relative;
  font-size: 19vw;
  letter-spacing: rem(7.9);
  padding-right: rem(80);
  line-height: 1;
  color: #F0F0F0;
  @include mq(sp) {
    font-size: 28vw;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: rem(-44);
    bottom: 0;
    height: rem(58);
    margin: auto 0;
    border: rem(44) solid transparent;
    border-left: rem(58) solid;
  }
}
.foot_entry .imgarea {
  flex: 1;
  text-align: right;
  margin-left: -9%;
  margin-right: -16%;
  @include mq(sp) {
    margin-top: sprem(40);
    margin-left: 0;
    margin-right: 0;
  }
}
.foot_entry .imgarea video {
  width: 100% !important;
  height: auto;
}

.footer {
  background-color: var(--color-primary-default);
}

/* footer_top
********************************************** */
.footer_top {
  padding-top: rem(124);
  @include mq(sp) {
    padding-top: sprem(64);
  }
}
.footer_top--info {
  @include mq(sp) {
    text-align: center;
    margin-bottom: sprem(16);
  }
}
/* ---fnav --- */
.fnav {
  flex: 1;
}
.fnav--menu {
  border-left: 1px solid #fff;
  padding-left: rem(32);
  margin-left: rem(72);
  @include mq(sp) {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
}
.fnav--menu:nth-child(1) {
  @include mq(sp) {
    & > li {
      border-bottom: 1px solid rgba(255, 255, 255, .2);
    }
  }
}
.fnav--menu:nth-child(2) {
  @include mq(sp) {
    margin-bottom: sprem(16);
    & > li:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, .2);
    }
  }
}
.fnav--menu a {
  display: block;
  padding: 0.5em 1em;
  color: #fff;
  font-size: rem(18);
  @include mq(sp) {
    position: relative;
    padding: 0.5em;
    font-size: sprem(16);
  }
  &:hover {
    opacity: 0.7;
  }
  .font-en {
    letter-spacing: .027em;
    font-family: "Antonio", sans-serif;
  }
}
.fnav--menu .sub-menu li a {
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(14);
  }
  &::before {
    content: "…";
    margin-right: rem(4);
  }
}
.page_top {
  width: rem(100);
  height: rem(100);
  margin-left: auto;
  @include mq(sp) {
    display: none;
  }
}
.page_top a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  border-radius: 50%;
  border: 1px solid;
  color: #fff;
  text-align: center;
  padding: rem(20);
  .font-en {
    margin-top: rem(12);
    padding-top: rem(12);
    width: 100%;
    border-top: 1px dashed #fff;
    margin-right: rem(-4);
    font-family: "Antonio", sans-serif;
    letter-spacing: rem(4);
    line-height: 1;
  }
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  @include rect(24);
  fill: #fff;
  &.icon-x {
    @include rect(22);
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  & > .inner {
    margin-top: rem(16);
    padding-top: rem(32);
    padding-bottom: rem(32);
    border-top: 1px solid #fff;
  } 
}
.footer_btm--nav {
  @include mq(sp) {
    text-align: center;
    margin-bottom: sprem(16);
  }
}
.footer_btm--nav li {
  &:not(:last-child) {
    margin-right: rem(24);
  }
}
.footer_btm--nav a {
  color: #fff;
  font-size: rem(14);
  &:hover {
    opacity: .7;
  }
  svg {
    fill: #fff;
    margin-left: rem(8);
    vertical-align: middle;
    transform: translateY(-2px);
  }
}
.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0;
    text-align: center;
  }
}
// .pbl a,
// .pbl img {
//   display: block;
// }
.pbl a {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.footer_under {
  position: relative;
  .txt_slide_deco {
    opacity: .2;
  }
  p {
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    font-size: rem(24);
    text-align: center;
  }
}

/* ##############################################################################

    スタンダード用

############################################################################## */

/* footer-simple
*************************************************** */
.footer-simple {
  padding: rem(8) 0;
}
.footer-simple:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}