@use "../abstracts" as *;

/* bg
********************************************** */
.bg-primary {
  background-color: var(--color-primary-default);
}
.bg-01 {
  background-color: var(--color-bg-01);
}
.bg-02 {
  background-color: var(--color-bg-02);
}
.bg-wh {
  background-color: var(--color-wht);
}