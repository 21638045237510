@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: rem(960);
  @include auto-margin;
  .flow_item {
    position: relative;
    padding-left: rem(96);
    @include mq(sp) {
      padding-left: sprem(56);
    }
    &:not(:last-child) {
      padding-bottom: rem(64);
      @include mq(sp) {
        padding-bottom: sprem(32);
      }
      &::before {
        content: '';
        position: absolute;
        left: rem(30);
        border-right: rem(4) dotted var(--color-primary-default);
        @include mq(sp) {
          left: sprem(18);
        }
      }
    }
    &.clr-wh:not(:last-child)::before {
      border-color: var(--color-wht);
    }
  }
  .ttl-03 {
    color: var(--color-primary-default);
    &::before {
      position: absolute;
    }
  }
  .clr-wh .ttl-03 {
    color: var(--color-wht);
  }
  .text {
    @include mq(sp, min, ps) {
      display: flex;
      gap: perc(32,768,'%');
    }
  }
  .img {
    @include mq(sp, min, ps) {
      width: perc(300,768,'%');
    }
    @include mq(sp) {
      &:not(:last-child) {
        margin-bottom: sprem(24);
      }
    }
  }
  .mce-content-body {
    margin-bottom: 0 !important;
    @include mq(sp, min, ps) {
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) {
  .flow_item:not(:last-child)::before {
    top: rem(40);
    left: rem(16);
    bottom: rem(4);
  }
  .ttl-03::before {
    content: '';
    top: rem(6);
    left: rem(-91);
    width: rem(52);
    height: rem(16);
    background-image: url(../images/common/wave.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include mq(sp) {
      left: sprem(-56);
    }
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num {
  .flow_item {
    counter-increment: number;
    &:not(:last-child)::before {
      top: rem(110);
      bottom: rem(40);
      @include mq(sp) {
        top: sprem(46);
        bottom: sprem(16);
      }
    }
  }
  .ttl-03 {
    padding-top: rem(20);
    padding-left: rem(32);
    margin-bottom: rem(72);
    @include mq(sp, min, ps) {
      font-size: rem(32);
    }
    @include mq(sp) {
      padding-top: 0;
      padding-left: sprem(18);
      margin-bottom: rem(34);
    }
    &::before {
      content: counter(number);
      top: rem(-10);
      left: rem(-96);
      width: rem(96);
      height: rem(107);
      background-image: url(../images/common/deco-polygon.svg);
      background-size: cover;
      text-align: center;
      color: var(--color-wht);
      font-family: var(--font-en);
      font-size: calc-fz(24);
      letter-spacing: 0;
      line-height: rem(112);
      @include mq(sp) {
        top: sprem(-21);
        left: sprem(-56);
        width: sprem(60);
        height: sprem(67);
        line-height: sprem(72);
      }
    }
  }
  .clr-wh .ttl-03::after {
    color: var(--color-primary-default);
    background-color: var(--color-wht);
  }
}